/* ************************
04.17: Team
********************* */
.member {
    text-align: center;
    background-color: $white;
    @extend .box-shadow;
    padding: 40px 20px;
    margin-bottom: 40px;
    .member-img {
        margin-bottom: 28px;
    }
    .member-content {
        h3 {
            font-weight: $medium;
            a {
                @extend .email_c1;
                &:hover {
                    @extend .email_c2;
                }
            }
        }
        p {
            font-size: 18px;
            margin: 0;
        }
    }
}

.social-links {
    a {
        display: inline-block;
        padding: 5px;
        @extend .text-color;
        font-size: 14px;
        &:hover {
            @extend .email_c2;
        }
    }
}