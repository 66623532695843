/* ************************
   02.8: Back to Top
   ********************* */
.back-to-top {
    position: fixed;
    opacity: 0;
    visibility: hidden;
    cursor: pointer;
    width: 35px;
    height: 35px;
    box-shadow: 0 4px 14px rgba($color: #000000, $alpha: .1);
    font-size: 16px;
    color: $white !important;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    line-height: 35px;
    bottom: 50%;
    right: 4%;
    transform: scale(0);
    transition: .3s ease-in;
    @extend .c2-bg;
    &.show {
        transform: scale(1);
        opacity: 1;
        visibility: visible;
        bottom: 10%;
        z-index: 999;
    }
    &:hover {
        opacity: .6;
    }

    &.pay {
        @extend .pay_c2-bg;
    }
    &.crm {
        @extend .crm_c2-bg;
    }
    &.plug {
        @extend .plug_c1-bg;
    }
    &.crs {
        @extend .crs_c2-bg;
    }
    &.book {
        @extend .crs_c2-bg;
    }
    &.email {
        @extend .email_c2-bg;
    }
    &.man {
        @extend .management_c2-bg;
    }
    &.analytic {
        @extend .analytic_c2-bg;
    }
    &.host {
        @extend .host_c2-bg;
    }
    &.app {
        @extend .app_c2-bg;
    }
    &.app2 {
        @extend .app2_c2-bg;
    }
    &.proto {
        @extend .proto_c2-bg;
    }
}
