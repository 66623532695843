/* ************************
   02.3: Preloader
   ********************* */
   .preloader {
        background: linear-gradient(90deg, #00b377, #00d68f);
        background: #000;
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        overflow: hidden;
        z-index: 9999999;
        display: flex;
        justify-content: center;
        align-items: center;
   }
  .loader-circle {
      position: absolute;
      width: 120px;
      height: 120px;
      border-radius: 50%;
      box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1);
      .content {
        font-size: 40px;
        font-weight: $bold;
        color: $white;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }
  }
  
  .loader-line-mask {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 60px;
    height: 120px;
    margin-left: -60px;
    margin-top: -60px;
    overflow: hidden;
    transform-origin: 60px 60px;
    -webkit-mask-image: -webkit-linear-gradient(top, #000000, rgba(0, 0, 0, 0));
    mask-image: -webkit-linear-gradient(top, #000000, rgba(0, 0, 0, 0));
    animation: spin 1.2s infinite linear;
}

.loader-line-mask .loader-line {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.5);
}