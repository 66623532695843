/* ************************
   04.1: How Earn
   ********************* */

.swiper-container {
    width: 100%;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    &.style--two {
        height: 440px;
        @include medium {
            height: 600px;
        }
        @include tab {
            height: 400px;
            margin-bottom: 80px;
            padding-top: 10px;
        }
        @include mobileMd {
            height: 500px;
        }
        @include mobileXs {
            height: 600px;
        }
    }
}
.swiper-slide {
    font-size: 18px;
    background: #fff;
    padding-left: 60px;
    @include mobileMd {
        font-size: 14px;
        padding-left: 50px;
    }

    h3 {
        color: $black;
        font-weight: $medium;
        margin-bottom: 12px;
        cursor: pointer;
        @include mobileMd {
            font-size: 20px;
            margin-bottom: 6px;
        }
    }
    P {
        @include mobileSm {
            font-size: 16px;
            line-height: 1.5;
        }
    }
}


.dot-line {
    width: 2px;
    background: #CECECE;
    height: 127%;
    position: absolute;
    left: 10px;
    @extend .trans3;

    &:after {
        position: absolute;
        content: "";
        width: 12px;
        height: 12px;
        background: $white;
        border: 2px solid #CECECE;
        left: -4.5px;
        top: 0;
        @extend .radius;
        @extend .trans3;
    }
}

.swiper-slide {
    &.slide-active {
        .dot-line {
            background: $c1;
            animation: fullHeight .5s linear 0s backwards;
            &:after {
                width: 20px;
                height: 20px;
                border: 3px solid $c1;
                left: -9px;
            }
        }
    }
}

  /* Swiper Slider Next Prev Button*/
.swiper-button-prev, .swiper-button-next {
    background: transparent;
    position: absolute;
    top: 0px;
    left: 14.5px;
    &:after {
        position: absolute;
        top: -30px;
        left: -1px;
        content: "\f106";
        font-family: fontAwesome;
        font-size: 40px;
        @extend .c2;
    }
    &.swiper-button-disabled {
        &:after {
            color: #B8B8B8;
        }
    }
}
.swiper-button-next {
    top:auto;
    bottom: -50px;
    &:after {
        content:"\f107";
        top:auto;
        bottom: 0;
    }
}

/* HomePage POS Swiper */
.swiper-button-prev.style--two, .swiper-button-next.style--two {
    left: 4px;
    bottom: -60px;
    &:after {
        @extend .analytic_c1;
    }
}
.swiper-button-prev.style--two {
    top: 10px;
}
.dot-line.style--two {
    left: 15px;

    &:after, &:before {
        width: 16px;
        height: 16px;
        border: 3px solid $analytic_c1;
        left: -6.5px;
        top: 0;
    }
    &:before {
        position: absolute;
        content: "";
        @extend .radius;
        @extend .trans3;
        width: 0;
        height: 0;
        background-color: $analytic_c2;
        left: 50%;
        transform: translate(-50%);
        top: 8px;
        border: none;
        z-index: 1;
    }
}

.swiper-slide.style--two {
    p {
        font-size: 20px;
    }
    &.slide-active {
        .dot-line {
            background: #CECECE;
            animation: fullHeight .5s linear 0s backwards;
            &:after {
                width: 30px;
                height: 30px;
                border: 2px solid $analytic_c1;
                left: -14px;
            }
            &:before {
                width: 18px;
                height: 18px;
                top: 6px;
                left: 50%;
                transform: translate(-50%);
            }
        }
    }
}

/* HomePage Learning App Swiper */
.swiper-container {
    &.how-work-slider {
        height: 600px;
        @include medium {
            height: 700px;
        }
        @include tab {
            height: 600px;
            margin-bottom: 60px;
        }
        .swiper-slide {
            background: transparent;
            padding-left: 0;
            padding: 20px 30px;
            @include mobileSm {
                padding: 10px;
            }
            .img {
                margin-right: 30px;
                @include mobileSm {
                    margin-right: 20px;
                }
            }
            .content {
                h3 {
                    font-size: 30px;
                    font-weight: $semi-bold;
                    @include mobileMd {
                        font-size: 22px;
                    }
                }
                p {
                    font-size: 20px;
                    @include mobileMd {
                        font-size: 16px;
                    }
                }
            }
            &.active {
                background-color: $white;
                @extend .radius-10;
                @extend .box-shadow;
            }
        }
    }
}







/* Swiper Slider Tab CSS*/
[data-tab] {
    display: none;
  }
  [data-tab].active {
    display: block;
  }