/* ************************
   04.6: Feature
   ********************* */
.feature-content {
    h2 {
        //@extend .crm_c1;
        margin-bottom: 10px;
    }
    @include tab {
        margin-bottom: 60px;
    }

    &.style--two {
        &.pay {
            padding: 140px 0 130px;
            padding-right: 100px;
            @include medium {
                padding-right: 60px;
            }
            @include tab {
                padding: 80px 0 70px;
                padding-right: 0;
                margin-bottom: 60px;
            }
            
        }
        &.pay2 {
            padding: 140px 0 90px;
            padding-left: 140px;
            @include medium {
                padding-left: 100px;
            }
            @include tab {
                padding-left: 0px;
                padding: 80px 0 30px;
            }
        }
    }
    &.plug {
        margin-bottom: 0;
        .section-title {
            margin-bottom: 40px;
            h2 {
                margin-bottom: 10px;
                font-weight: $semi-bold;
            }
        }
        &.style--two {
            .section-title {
                margin-bottom: 10px;
            }
        }
        .content {
            p {
                margin-bottom: 14px;
            }
            p.mb-30 {
                margin-bottom: 30px;
            }
        }
    }
    &.management {
        .section-title {
            margin-bottom: 30px;
            h2 {
                font-weight: $semi-bold;
                margin-bottom: 10px;
                line-height: 1.2;
                @include customMq(1200px, 1500px) {
                    br {
                        display: none;
                    }
                }
            }
            p {
                margin: 0;
            }
        }
        h2 {
            @extend .management_c1;
        }
    }
    &.pos {
        position: relative;
        padding: 60px 75px;
        background-color: #FFFCF2;
        margin-left: -30px;
        @include customMq(1200px, 1500px) {
            margin-left: 0;
            padding: 50px;
        }
        @include medium {
            margin-left: 0;
            padding: 50px;
            .list-check.pos {
                li p {
                    font-size: 18px;
                }
            }
        }
        @include mobileMd {
            padding: 50px 15px;
        }
    }
    &.app {
        padding: 0 50px;
        @include tab {
            padding: 0;
        }
        h2 {
           font-size: 50px;
           font-weight: $semi-bold;
           line-height: 1.3;
           color: $black;
           margin-bottom: 20px;
           @include mobileMd {
               font-size: 40px;
           }
        }
        p {
            margin-bottom: 30px;
        }
        &.style--two {
            padding: 0;
            padding-right: 120px;
            margin: 0;
            @include medium {
                padding-right: 50px;
            }
            @include tab {
                padding: 0;
                padding-bottom: 20px;
            }
        }
    }
    &.app2 {
        padding-left: 120px;
        margin: 0;
        @include medium {
            padding-left: 40px;
        }
        @include tab {
            padding-left: 0;
        }
    }
    &.l-app {
        h2 {
            font-size: 50px;
            color: $black;
            line-height: 1.2;
            margin-bottom: 20px;
            @include tab {
                margin-bottom: 50px;
            }
            @include medium {
                br {
                    display: none;
                }
            }
            @include mobileMd {
                font-size: 40px;
            }
        }
        p{
            margin-bottom: 30px;
            font-weight: $regular;
        }
    }
    &.proto {
        h2 {
            @extend .proto_c1;
            margin-bottom: 20px;
            line-height: 1.25;
            @include mobileLg {
                font-size: 36px;
            }
        }
        p {
            margin-bottom: 30px;
        }
    }
}

.feature-img {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
    .f-bg {
        position: relative;
        left: auto;
        right: -250px;
        width: 118%;
        top: 0px;
        z-index: -1;
        &.crm {
            position: absolute;
            top: -70px;
            @include customMq(1200px, 1602px) {
                top: 0;
            }
            @include medium {
                top: -100px;
            }
            @include tab {
                top: 0px;
                left: auto;
                right: -15px;
                width: 100%;
            }
       }

        @include customMq(1200px, 1602px) {
            left: 140px;
            right: auto;
            width: 100%;
        }
        @include medium {
            left: 0px;
        }
    }
    .f-img {
        position: absolute;
        left: auto;
        right: -50px;
        &.crm {
             position: relative;
        }

        @include medium {
            right: 0px;
            padding: 0 15px;
        }
    }
    .mobile-bg {
        position: absolute;
        z-index: -1;
        animation: spin 60s linear .3s infinite forwards;
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            bottom: 80px;
        }
    }
    .pay-f2, .host-f2 {
        @include customMq(992px, 1500px) {
            padding-right: 30px;
        }
    }
    &.style--two {
        .f-bg {
            left: -270px;
            @include medium {
                left: -100px;
            }
        }
        .f-img {
            right: 0px;
            @include medium {
                right: 0px;
            }
        }
    }
    &.style--three {
        .f-bg {
            left: 290px;
            @include medium {
                left: 100px;
            }
            @include tab {
                right: 0;
            }
        }
        .f-img {
            right: -90px;
            @include customMq(1200px, 1602px) {
                right: -60px;
            }
            @include medium {
                right: 0px;
            }
            @include tab {
                right: 0;
            }
        }
    }
    &.plug {
        @include tab {
            margin-bottom: 60px;
        }
    }
    &.analytic {
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            .feature-map3 {
                width: 100%;
            }
        }
    }
    &.app {
        img {
            margin-left: -150px;
            max-width: none;
            @include customMq(991px, 1500px) {
                max-width: 140%;
            }
            @include tab {
                max-width: 100%;
                margin-left: 0;
                margin-bottom: 30px;
            }
        }
    }
    &.app2 {
        img {
            margin-left: -15px;
            max-width: none;
            @include customMq(991px, 1500px) {
                max-width: 120%;
            }
            @include tab {
                max-width: 100%;
                margin-left: 0;
                margin-bottom: 40px;
            }
        }
    }
    &.l-app {
        margin-left: 60px;
        @include tab {
            margin-left: 0;
        }
        img {
            @extend .box-shadow;
        }
        &.two {
            margin: 0;
            margin-right: 60px;
            @include tab {
                margin: 0;
            }
        }
    }
    &.proto {
        padding-left: 20px;
        @include tab {
            padding-left: 0px;
        }
        &.left {
            padding-left: 0;
        }
        .proto-img {
            max-width: none;
            @include tab {
                max-width: 100%;
            }
            &.left {
                left: -270px;
                position: relative;
                @include customMq(1200px, 1500px) {
                    left: -360px;
                }
                @include medium {
                    left: -400px;
                }
                @include tab {
                    left: 0px;
                }
            }
        }
    }
}

//Only For Mozila
@-moz-document url-prefix() {
    .feature-img {
        &.proto {
            .proto-img {
                max-width: none;
                min-width: 808px;
                @include tab {
                    max-width: 100%;
                    min-width: auto;
                }
            }
        }
    }
}



.pay-bg1, .pay-bg2 {
    position: absolute;
    left: -1000%;
    top: 0;
    background: #F9F9F9;
    width: 1100%;
    height: 100%;
    z-index: -1;
    @include tab {
        height: calc(100% - 60px);
    }
}
.pay-bg2 {
    left: auto;
    right: -1000%;
    @include tab {
        height: calc(100% - 60px);
    }
}

.feature {
    padding-top: 70px;
    padding-bottom: 190px;
    @include customMq(1200px, 1602px) {
        padding-top: 0px;
        padding-bottom: 140px;
    }
    @include medium {
        padding-top: 100px;
        padding-bottom: 140px;
    }
    @include tab {
        margin-top: 0;
        padding-top: 80px;
        padding-bottom: 80px;
    }
}
.e-feature-img {
    width: 745px;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        width: 110%;
    }
    @include customMq(1200px, 1680px) {
        width: 110%;
    }
    @include medium {
        width: 100%;
    }
    &.two {
        position: relative;
        left: -190px;
        @include customMq(1200px, 1602px) {
            left: -10%;
        }
        @include medium {
            left: 0;
        }
    }
}

.feature-wrap {
    @extend .box-shadow;
    padding-top: 90px;
    padding-bottom: 8px;
    @include mobileMd {
        padding-top: 60px;
    }
    .single-service {
        margin-bottom: 80px;
        @include mobileMd {
            margin-bottom: 50px;
            padding: 0;
        }
        &.proto {
            margin-bottom: 60px;
            @include mobileMd {
                margin-bottom: 40px;
            }
        }
    }
    &.plug {
        padding-top: 80px;
        padding-bottom: 0;
        background-color: #4E2FA9;
        @extend .radius-20;
        .single-service {
            background: transparent;
            margin-bottom: 80px;
            .service-content {
                h3 {
                    color: $white;
                }
            }
        }
    }
    &.proto {
        margin: 0 30px;
        padding-top: 60px;
        padding-bottom: 40px;
        background-color: #D9E5FF;
        border-radius: 60px;
        position: relative;
        z-index: 1;
        @include customMq(1200px, 1500px) {
            margin: 0 -50px;
        }
        @include medium {
            margin: 0 -50px;
        }
        @include tab {
            margin: 0;
        }
        @include mobileMd {
            padding-top: 40px;
            padding-bottom: 20px;
        }
        &:after {
            content: "";
            position: absolute;
            background-color: #F1F5FE;
            width: calc(100% + 60px);
            height: 100%;
            left: -30px;
            bottom: 20px;
            border-radius: 60px;
            z-index: -1;
        }
    }
}
.tab-content.app2-tab {
    div > img {
        animation: banner3Shake 10s ease-in infinite;
    }
}

.get-card-wrap, .choose-card-wrap {
    .feature-img {
        margin-bottom: -140px;
        @include tab {
            margin-bottom: 60px;
        }
    }
    position: relative;
    .pay-bg3, .pay-bg4 {
        position: absolute;
        width: 1030%;
        height: 100%;
        background: #F9F9F9;
        left: -1000%;
        top: 0;
        z-index: -1;
        @include tab {
            display: none;
        }
    }
}
.get-card-wrap {
    padding-bottom: 140px;
    @include tab {
        padding-bottom: 0;
    }
}
.choose-card-wrap {
    .feature-img {
        margin-bottom: 0px;
        @include tab {
            margin-bottom: 60px;
        }
    }
    position: relative;
    .pay-bg4 {
        left: auto;
        right: -1000%;
    }
}

/* Book Page Feature*/
.single-feature-wrap:nth-child(even) {
    margin-top: 50px;
    @include mobileLg {
        margin-top: 0;
    }
}
.single-feature-wrap {
    @include mobileLg {
        &:not(:last-child) {
            margin-bottom: 30px;
        }
    }
}
.single-feature {
    padding: 50px 40px 44px;
    background: #FFFCF1;
    border-top-left-radius: 50px;
    @include medium {
        padding: 40px 20px 34px;
    }
    .feature-icon {
        margin-bottom: 35px;
    }
    .feature-content {
        margin-bottom: 0;
        h3 {
            font-size: 26px;
            font-weight: $medium;
            margin-bottom: 6px;
            color: $black;
            @include mobileMd {
                font-size: 24px;
            }
        }
        p {
            font-size: 20px;
            @include mobileMd {
                font-size: 16px;
            }
        }
    }
    &.two {
        background: #F8FBFF;
        border-top-left-radius: 0px;
        border-bottom-right-radius: 50px;
    }
    &.three {
        background: #F9FFF1;
    }
    &.four {
        
        background: #FFF3F1;
        border-top-left-radius: 0px;
        border-top-right-radius: 50px;
    }
    &.app {
        text-align: center;
        background-color: transparent;
        padding: 0;
        margin-bottom: 40px;
        .feature-icon {
            margin-bottom: 45px;
            @include mobileSm {
                margin-bottom: 30px;
            }
        }
        .feature-content {
            h3 {
                font-weight: $semi-bold;
                font-size: 30px;
                margin-bottom: 20px;
            }
        }
    }
    &.app2 {
        text-align: center;
        background-color: transparent;
        padding: 0;
        margin-bottom: 40px;
        .feature-icon {
            margin-bottom: 30px;
            .circle {
                position: relative;
                width: 172px;
                height: 172px;
                img, .svg {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
                &.left {
                    &:after {
                        position: absolute;
                        content: "";
                        z-index: -1;
                        height: 100%;
                        width: 50%;
                        background: #EBF6FF;
                        border-bottom-left-radius: 172px;
                        border-top-left-radius: 172px;
                        left: 0;
                        top: 0;
                    }
                }
                &.bottom {
                    &:after {
                        position: absolute;
                        content: "";
                        z-index: -1;
                        height: 50%;
                        width: 100%;
                        background: #E2FFF9;
                        border-bottom-left-radius: 172px;
                        border-bottom-right-radius: 172px;
                        left: 0;
                        top: auto;
                        bottom: 0;
                    }
                }
                &.right {
                    &:after {
                        position: absolute;
                        content: "";
                        z-index: -1;
                        height: 100%;
                        width: 50%;
                        background: #FFF6F3;
                        border-bottom-right-radius: 172px;
                        border-top-right-radius: 172px;
                        left: auto;
                        right:0;
                        top: 0;
                    }
                }
            
            }
        }
        .feature-content {
            h3 {
                font-weight: $semi-bold;
                font-size: 30px;
                margin-bottom: 20px;
            }
        }
    }
    &.style--two {
        background: transparent;
        border-radius: 0;
        text-align: center;
    }
}

/* Ebook Feature */
.feature-box {
    position: relative;
    @include tab {
        margin-bottom: 80px;
    }

    &:after, .ebook-reveal {
        background: #F8FBFF;
        position: absolute;
        width: 1116%;
        height: 100%;
        content: "";
        left: -1000%;
        top: 0;
        z-index: -1;
        border-top-right-radius: 150px;
    }
    &:before {
        position: absolute;
        content: "01.";
        left: 0;
        top: -50px;
        font-size: 150px;
        @extend .hind;
        @extend .crs_c2;
        font-weight: $semi-bold;
        line-height: 1;
        @include mobileMd {
            font-size: 100px;
            top: -30px;
        }
    }

    .img {
        margin-bottom: 60px;
    }
    h2 {
        font-weight: $semi-bold;
        @extend .book_c1;
        margin-bottom: 18px;
        @include mobileMd {
            font-size: 30px;
        }
    }

    &.two {
        margin-top: 140px;
        @include tab {
            margin-top: 0;
        }
        &:after {
            width: 1116%;
            height: 100%;
            left: auto;
            right: -1000%;
            border-top-right-radius: 0px;
            border-top-left-radius: 150px;
        }
        
        &:before {
            content: "02.";
            @extend .crs_c2;
        }
        h2 {
            @extend .crs_c2;
        }
        &.four {
            &:after {
                border-radius: 0 0 0 150px;
            }
            &:before {
                content: "04.";
            }
            h2 {
                @extend .book_c1;
            }
        }
    }
    &.three {
        &:after {
            border-radius: 0 0 150px 0;
        }
        &:before {
            content: "03.";
            @extend .crs_c2;
        }
        h2 {
            @extend .crs_c2;
        }
    }
}

/* Plugins Feature*/
.single-feature.box {
    text-align: center;
    @extend .radius-20;
    background: #DFF5FF;
    padding: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 30px;
    h2 {
        font-weight: $medium;
        font-size: 60px;
        color: #21BCFF;
        margin-bottom: 5px;
    }
    p {
        font-weight: $regular;
        text-transform: capitalize;
        color: $black;
    }
    &.box2 {
        background: #FFF3EB;
        h2 {
            color: #F99C5E;
        }
    }
    
    &.box3 {
        background: #DFFFF6;
        h2 {
            color: #2DCEA5;
        }
    }
    &.box4 {
        background: #E9F3FF;
        h2 {
            color: #0472FA;
        }
    }
}

.plugin-feature-bg, .plugin-feature-bg2 {
    position: absolute;
    left: 0;
    top: 40px;
    width: 40%;
    height: calc(100% - 180px);
    background-color: #857DFB;
    @include tab {
        height: calc(55% - 100px);
        width: 70%;
    }
    @include mobileLg {
        display: none;
    }
}
.plugin-feature-bg2 {
    left: auto;
    right: 0;
    bottom: 140px;
}

/* ONLY IE */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .feature-img .f-bg {
         height: 560px;
    }
    .feature-img {
        .f-img {
            &.crm2, &.crm3 {
                top:60px;
            }
        }
    }
}

 /* Email Feature */
 .email-fbg {
     background-color: #FAFAFA;
 }
.feature-one-content {
    position: relative;
    z-index: 1;
    h2 {
        color: $black;
        font-weight: $semi-bold;
        margin-bottom: 15px;
        &.management_c1 {
            @extend .management_c1;
        }
    }
    p {
        margin-bottom: 40px;
    }
    &.two {
        h2 {
            line-height: 1.23;
        }
        p {
            margin-bottom: 32px;
        }
    }
}
.feature-two-content {
    h3 {
        font-weight: $medium;
        font-size: 22px;
        color: $black;
        margin-bottom: 25px;
    }
}
.integration-bg {
    background-color: #F5F8FF;
}


/* Works Slider */ 
.work-slider {
    .work-slide-text {
        h2 {
            color: $black;
            font-size: 32px;
            font-weight: $semi-bold;
            margin-bottom: 20px;
        }
        p {
            font-size: 22px;
        }
    }
    .work-slide-image {
        @include tab {
            margin-bottom: 60px;
        }
    }
}
.work-slider-dots {
    margin-top: -5px;
    margin-bottom: 55px;
    padding: 0px 40px;
}
@media only screen and (max-width: 991px) {
    .work-slider-dots {
        display: none !important;
    }
}
.work-slider-dots .owl-stage {
    display: flex;
    align-items: flex-end;
}
.work-slider-dots .owl-item {
    text-align: center;
}
.work-slider-dots .active .dots-count {
    padding-bottom: 17px;
    position: relative;
    font-size: 26px;
    line-height: 1.2;
    font-weight: $medium;
    color: #BCBCBC;
    @extend .hind;
    cursor: pointer;
    display: inline-block;
    @include medium {
        font-size: 22px;
    }
}
.work-slider-dots .active .dots-count .process-bar {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 2px;
    background-color: transparent;
}
.work-slider-dots .active .dots-count .process-bar-active {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 0;
    height: 2px;
    background-color: #232323;
}
.work-slider-dots .active.current .dots-count {
    color: #232323;
}
.work-slider-dots .active.current .dots-count .process-bar {
    background-color: #dbdbdb;
}
.work-slider-dots .active.current .dots-count .process-bar-active {
    transition: width 4.9s;
    width: 100%;
}

/* POS Feature Slider */
.row.feature-bg {
    padding-left: 80px;
    padding-right: 80px;
    position: relative;
    @include mobileMd {
        padding-left: 0px;
        padding-right: 0px;
    }
    &:after {
        background-color: #F3FFF7;
        position: absolute;
        @extend .radius-20;
        content: "";
        width: 100%;
        height: calc(100% + 20px);
        left: 0;
        top: -120px;
        z-index: -1;
    }
}
.feature-wrapper {
    padding-left: 80px;
    padding-right: 80px;
    position: relative;
    z-index: 1;
    @include mobileMd {
        padding-left: 0px;
        padding-right: 0px;
    }
    &:after {
        background-color: #F1F5FE;
        position: absolute;
        @extend .radius-60;
        content: "";
        width: 100%;
        height: 70%;
        left: 0;
        top: -100px;
        z-index: -1;
        @include tab {
            height: 90%;
            top: -80px;
        }
        @include mobileMd {
            height:100%;
            top: -60px;
            left: -15px;
            width: calc(100% + 30px);
        }
    }
}
.pos-feature-slider {
    .owl-stage-outer {
        padding: 20px;
        margin: -20px;
    }
}
.feature-single {
    @extend .box-shadow;
    background-color: $white;
    padding: 60px 20px;
    text-align: center;
    @extend .radius-20;
    .feature-icon {
        margin-bottom: 28px;
    }
    .feature-content {
        h3 {
            font-weight: $medium;
            color: $black;
        }
    }

    &.retailer {
        margin-bottom: 50px;
        @include tab {
            margin-bottom: 30px;
        }
        .feature-content {
            margin: 0;
        }
        &:hover {
            .feature-icon {
                animation: fadeInUp10 .5s ease-in;
            }
        }
    }
}

/* Trapezium */
.trapezium-section {
    @include mobileLg {
        margin-bottom: 50px;
    }
}
.trapezium {
    position: relative;
    padding: 80px 20px;
    text-align: center;
    margin-bottom: 100px;
    @include mobileLg {
        margin-bottom: 50px;
    }
    &:before {
        content: "";
        position: absolute;
        background-color: #F1F5FE;
        width: 114%;
        @extend .radius-20;
        top: 0;
        bottom: 0;
        left: -1%;
        right: 0;
        z-index: -1;
        -webkit-transform: perspective(50em) rotateX(30deg);
        transform: perspective(800px) rotateY(30deg);
        @extend .trans3;
        @include customMq(992px, 1500px) {
            left: -4%;
        }
        @include mobileLg {
            left: 15px;
            width: 112%;
        }
        @include mobileMd {
            left: 0;
        }
    }
    &.two {
        &:before {
            left: auto;
            right: -1%;
            -webkit-transform: perspective(50em) rotateX(-30deg);
            transform: perspective(800px) rotateY(-30deg);
            @include medium {
                right: -3%;
            }
            @include mobileLg {
                right: 15px;
            }
            @include mobileMd {
                right: 0;
            }
        }
        &:hover {
            &:before {
                left: auto;
                right: 0;
            }
        }
    }
    &:hover {
        &:before {
            width: 100%;
            left: 0;
            -webkit-transform: perspective(50em) rotateX(0deg);
            transform: perspective(800px) rotateY(0deg);
        }
    }
    .feature-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 100px;
        background-color: $white;
        @extend .radius-20;
        margin: 0 auto 30px;
    }
    .feature-content {
        h3 {
            @extend .proto_c1;
            font-size: 26px;
            font-weight: $medium;
            margin-bottom: 10px;
        }
        p {
            font-size: 18px;
            max-width: 390px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

/* Prototype Availability */
.tab-btn.proto {
    margin-bottom: 40px;
    li {
        display: inline-block;
        @extend .hind;
        font-size: 26px;
        font-weight: $medium;
        cursor: pointer;
        position: relative;
        user-select: none;
        color: #BCBCBC;
        @include mobileXs {
            font-size: 24px;
        }
        &:not(:last-child) {
            margin-right: 65px;
            @include mobileLg {
                margin-right: 30px;
            }
            @include mobileMd {
                margin-bottom: 10px;
            }

        }
        &:after {
            position: absolute;
            content: "";
            @extend .proto_c2-bg;
            width: 0%;
            height: 2px;
            left: 50%;
            bottom: 0;
            transition-delay: .1s;
            @extend .trans3;
        }
        &.active {
            @extend .proto_c1;
            &:after {
                width: 100%;
                left: 0;
            }
        }
    }
}
.tab-content.proto {
    img {
        margin-bottom: 30px;
        &:not(:last-child) {
            margin-right: 55px;
            @include medium {
                margin-right: 30px;
            }
        }
        @include mobileSm {
            &:nth-child(even) {
                margin-right: 0;
            }
        }
    }
    div.active {
        animation: fadeInUp10 .4s linear 0s forwards;
    }
}