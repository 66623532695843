/* ************************
   04.4: Earn Path
   ********************* */

.earn-path-feature {
    background: #FBFBFB;
    //background: #dddddd;
    padding: 100px 120px 20px;
    @extend .radius-60;

    @include medium {
        padding: 80px 50px 0px;
    }

    .single-service {
        background: transparent;
        padding: 0;
        margin-bottom: 80px;
        @include mobileSm {
        }

        .service-icon {
            width: 100px;
            height: 100px;
            background: $white;
            @extend .radius-20;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 40px;
            @include mobileSm {
                margin: 0;
                margin-bottom: 20px;
            }
        }

        .service-content {
            p {
                @extend .text-color;
            }
        }
    }
}