/* ************************
   04.11: Company Logo
   ********************* */
.owl-carousel {
    &.logo-carousel {
        .single-slide {
            text-align: center;
        }
        .owl-item img {
            display: inline-block;
            width: auto;
            opacity: .4;
            @extend .trans3;
            &:hover {
                opacity: 1;
                transform: scale(.9);
            }
        }
    }
}