/* ************************
   04.10: Price
   ********************* */

.price-box {
    padding: 50px 60px 60px;
    background: $white;
    @extend .radius-20;
    margin-bottom: 30px;
    @extend .box-shadow;
    @include medium {
        padding: 30px 30px 40px;
    }
    .price-head {
        margin-bottom: 12px;
        span {
            font-size: 18px;
            strong {
                font-size: 60px;
                font-weight: $semi-bold;
                @extend .hind;
                @extend .crs_c1;
                line-height: 1.48;
                padding-right: 10px;
            }
            .d-price {
                position: relative;
                bottom: -7px;
            }
            del {
                font-size: 24px;
                @extend .hind;
                font-weight: $semi-bold;
                line-height: 1;
                color: #d5d5d5;
            }
        }
        p {
            @extend .feature-p;
            font-weight: $light;
            max-width: 320px;
        }
    }
    &.two {
        @extend .crs_c1-bg;
        @extend .text-white;
        .price-head {
            span {
                strong {
                    color: $white;
                }
                .d-price del {
                    color: #cbfff5;
                }
            }
        }
        ul.list-check {
            li {
                color: $white;
                &:after {
                    color: $white;
                }
            }
        }
    }
    .btn-crs.white {
        @extend .crs_c1;
        background: $white;
        &:hover {
            @extend .crs_c2-bg;
            color: $white;
        }
    }
}

.price-list {
    @extend .list-unstyled;
}


/* Analytic Price toggle button */
.pricing-toogle {
    line-height: 1;
    &.host {
        @include tab {
            margin-top: 30px;
        }
        .toggler--is-active {
            color: $host_c3;
        }
        .toggle {
            @extend .host_c3-bg;
        }
        .switch {
            @extend .host_c2-bg;
        }
    }
}

.toggle, .toggler {
    display: inline-block;
    vertical-align: middle;
}
.toggler {
    color: $black;
    transition: .2s;
    font-weight: $regular;
    font-size: 16px;
    cursor: pointer;
    line-height: 1;
} 
.toggler--is-active {
    color: $analytic_c2;
}
.b {
    display: block;
}
.toggle {
    position: relative;
    width: 60px;
    height: 26px;
    border-radius: 100px;
    @extend .analytic_c2-bg;
    overflow: hidden;
    @extend .box-shadow;
    margin: 0 16px;
} 
.check {
    position: absolute;
    display: block;
    cursor: pointer;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 6;
}
.check:checked ~ .switch {
    right: 2px;
    left: 57.5%;
    transition: 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transition-property: left, right;
    transition-delay: .08s, 0s;
}
.switch {
    position: absolute;
    left: 2px;
    top: 2px;
    bottom: 2px;
    right: 57.5%;
    background-color: $white;
    border-radius: 26px;
    z-index: 1;
    @extend .trans2;
    transition-property: left, right;
    transition-delay: 0s, .08s;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}
.hide {
    display: none;
}

/* Analytic Single Price */
.single-price {
    padding: 60px 100px;
    border: 1px solid $analytic_c1;
    text-align: center;
    position: relative;
    z-index: 1;
    .price-head {
        padding-bottom: 35px;
        border-bottom: 1px solid #BCBCBC;
        h3 {
            font-size: 30px;
            font-weight: $semi-bold;
            color: $black;
            margin-bottom: 8px;
        }
        p {
            font-size: 18px;
            font-weight: $regular;
            margin-bottom: 30px;
        }
        .price {
            strong {
                font-size: 46px;
                @extend .hind;
                @extend .analytic_c1;
                line-height: 1.2;
            }
        }
        > span {
            font-size: 16px;
            font-weight: $regular;
        }
    }
    .price-body {
        .price-list {
            padding: 42px 0;
            li {
                font-size: 22px;
                font-weight: $light;
                &:not(:last-child) {
                    margin-bottom: 5px;
                }
            }
        }
        .btn-pay {
            &:hover {
                @extend .analytic_c2-bg;
                @extend .analytic_c1;
            }
        }
    }
    &.active {
        &:after {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            z-index: -1;
            @extend .analytic_c1-bg;
            transform: scale(1.15, 1.06);
            @include customMq(1200px, 1500px) {
                transform: scale(1.18, 1.04);
            }
            @include medium {
                transform: scale(1.21, 1.03);
            }
            @include tab {
                transform: scale(1, 1);
            }
        }
        .price-head {
            h3 {
                color: $white;
            }
            .price {
                strong {
                    @extend .analytic_c2;
                }
            }
        }
    }
    @include customMq(1200px, 1880px) {
        padding: 60px 50px;
    }
    @include medium {
        padding: 60px 50px;
    }
    @include tab {
        margin-bottom: 30px;
    }
    &.app {
        background-color: #A7D3F6;
        @extend .box-shadow;
        border: none;
        background-position: center bottom;
        padding-bottom: 18px;
        @include medium {
            padding: 60px 30px 20px;
        }
        .price-head {
            padding-bottom: 60px;
            border-bottom: 1px solid #EFEFEF;
            h3 {
                margin-bottom: 3px;
            }
            p {
                font-size: 16px;
                margin-bottom: 25px;
                color: $black;
            }
        }
        .price-body {
            .price-list {
                padding-top: 50px;
                padding-bottom: 80px;
                li {
                    font-size: 20px;
                    font-weight: $regular;
                    color: $black;
                    &:not(:last-child) {
                        margin-bottom: 12px;
                    }
                }
            }
        }
        .price-footer {
            span {
                @extend .hind;
                font-weight: $medium;
                font-size: 18px;
                color: $white;
                strong {
                    font-size: 40px;
                    font-weight: $bold;
                    line-height: 1;
                }
            }
        }
        &.two {
            background-color: #FFB99F;
        }
        &.three {
            background-color: #86E9D4;
            margin-bottom: 0;
        }
        .price-footer {
            .white-btn {
                color: $white;
                @extend .trans2;
                text-transform: capitalize;
                &:hover {
                    letter-spacing: 1px;
                }
            }
        }
    }
    
    &.app2 {
        .price-head {
            padding-bottom: 30px;
            h3 {
                margin-bottom: 3px;
            }
            span {
                color: $black;
                font-size: 18px;
                font-weight: $medium;
                @extend .hind;
                margin-top: 12px;
                .link-btn {
                    color: $app2_c1;
                    &:hover {
                        color: $app2_c2;
                    }
                }
            }
        }
        .price-body {
            .price-list {
                li {
                    color: $text-color;
                }
            }
        }
    }
}


/* Hosting Pricing */
.tab-btn ul, .price-feature {
    @extend .list-unstyled;
}
.pkg{
    .pkg-list {
        @extend .box-shadow;
        @extend .radius-20;
        @extend .trans3;
        background-color: $white;
        cursor: pointer;
        padding: 60px;
        align-items: center;
        margin-bottom: 20px;
        overflow: hidden;
        position: relative;
        z-index: 2;
        @include customMq(1200px, 1500px) {
            padding: 60px 40px;
        }
        @include mobileMd {
            flex-direction: column;
            padding: 40px 20px;
        }
        > span {
            position: absolute;
            width: 0;
            height: 0;
            display: block;
            @extend .host_c3-bg;
            z-index: -1;
            @extend .radius;
            transform: translate(-50%, -50%);
            transition: .7s;
        }
        &:hover {
            > span {
                width: 300%;
                height: 2380px;
            }
        }

        .left {
            margin-right: 20px;
            @include mobileMd {
                margin-right: 0;
                margin-bottom: 20px;
            }
            h3 {
                font-size: 30px;
                color: $black;
                margin-bottom: 8px;
                font-weight: $semi-bold;
                @extend .trans5;
            }
            p {
                font-size: 18px;
                max-width: 320px;
                @extend .trans5;
            }
        }
        .right {
            @include mobileMd {
                margin-right: auto;
            }
            div {
                @extend .host_c2;
                font-weight: $light;
                margin-bottom: 14px;
                @extend .trans3;
                strong {
                    font-size: 46px;
                    @extend .hind;
                    font-weight: $semi-bold;
                    line-height: 1;
                    @extend .trans3;
                }
            }
        }
    }
    &.active,
    &:hover {
        .pkg-list {
            .left {
                h3, p {
                    color: $white;
                }
            }
            .right {
                div {
                    color: $white;
                }
                .btn-pay.host {
                    @extend .host_c2-bg;
                    &:hover {
                        @extend .host_c2-bg;
                        @extend .host_c3;
                    }
                }
            }
        }
    }
    &.active {
        .pkg-list {
            @extend .host_c3-bg;
        }
    }
}

.pkg-info {
    @extend .box-shadow;
    @extend .radius-20;
    padding: 50px 60px 57px;
    background-color: $white;
    @include customMq(1200px, 1500px) {
        padding: 50px 33px 57px;
    }
    @include medium {
        padding: 40px 30px;
    }
    @include tab {
        padding: 50px 60px;
    }
    @include mobileMd {
        padding: 40px 20px;
    }
    h3.pkg-name {
        display: inline-block;
        background-color: #FFF4DE;
        color: $host_c2;
        margin-bottom: 40px;
        font-size: 30px;
        font-weight: $semi-bold;
        border-radius: 7px;
        padding: 15px 20px;
    }
    .price-feature {
        li {
            &:not(:last-child) {
                margin-bottom: 36px;
            }
        }
        h3 {
            color: $black;
            font-weight: $medium;
            margin-bottom: 6px;
        }
        p {
            font-size: 18px;
            line-height: 1.5;
        }
    }

}