//Default Colors
$c1: #5F52DC;
$c2: #FCBF21;
$text-color: #707070;
$white: #ffffff;
$black: #292929;

//CRM Page Colors
$crm_c1: #57548D;
$crm_c2: #EBD281;

//Course Page Colors
$crs_c1: #5DD9C1;
$crs_c2: #FBC006;

//Payment Page Colors
$pay_c1: #5A50A5;
$pay_c2: #FE957B;

//Ebook Page Colors
$book_c1: #205CE2;
//$crs_c2: #FBC006;

//Plugin Page Colors
$plug_c1: #5DD9C1;
$plug_c2: #FBF349;

//Email Page Colors
$email_c1: #857DFF;
$email_c2: #FF937D;

//Management Page Colors
$management_c1: #625FD1;
$management_c2: #FF4D8C;

//Analytic Page Colors
$analytic_c1: #00418E;
$analytic_c2: #FF5B83;

//Hosting Page Colors
$host_c1: #3E4491;
$host_c2: #F7A400;
$host_c3: #180D5B;

//App Landing Page Colors
$app_c1: #8EC9F8;
$app_c2: #FB7D4E;

//Learning App Page Colors
$app2_c1: #5CB6FE;
$app2_c2: #FF6773;

//Prototype Page Colors
$proto_c1: #5A50A5;
$proto_c2: #FFA68B;




// Fonts
$hind: 'Hind', sans-serif;
$ubuntu: 'Ubuntu', sans-serif;

// Font Weight
$thin: 100;
$extra-light: 200;
$light: 300;
$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;
$extra-bold: 800;
$bolder: 900;