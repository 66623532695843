/* ************************
   04.12: Author
   ********************* */
   .author-content {
       padding-left: 50px;
       @include mobileLg {
            padding-left: 0px;
        }
       .author-badge {
           font-size: 16px;
           padding-left: 35px;
           margin-bottom: 12px;

           &:before {
                position: absolute;
                content: url('../img/icons/author-badge.png');
                left: 0;
                top: 5px;
           }
       }

       h2 {
            font-weight: $semi-bold;
       }
       span {
           font-size: 22px;
           margin-bottom: 10px;
           display: block;
       }
   }

   .author-img {
       padding-left: 15px;
       @include tab {
           padding-left: 50px;
           margin-bottom: 50px;
       }
       @include mobileLg {
            padding-left: 0px;
        }
   }

   /* Author Newsletter */
   .author-newsletter {
       h3 {
           font-weight: $semi-bold;
       }
   }