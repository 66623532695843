/* ************************
   04.5: Call To Action
   ********************* */

.pos-cta {
    padding-top: 80px;
    @include medium {
        padding-top: 120px;
    }
    @include tab {
        padding-top: 60px;
    }
}

/* Cta Shape */
.cta-line1, .cta-line2 {
    position: absolute;
    left: 5%;
    top: 50%;
    transform: translateY(-50%);
}
.cta-line2 {
    left: auto;
    right: 5%;
}

.cta-icon1, .cta-icon2, .cta-icon3 {
    position: absolute;
    opacity: 0.6;
    left: 5%;
    top: 8%;
}
.cta-icon2 {
    left: 60%;
    top: auto;
    bottom: 5%;
}
.cta-icon3 {
    left: auto;
    right: 5%;
}

.analytic-cta-img {
    position: absolute;
    left: 0;
    bottom: 0;
}

.cta-proto {
    @include mobileLg {
        padding-top: 40px;
    }
}

/* Cta Content */
.cta-content {
    position: relative;
    z-index: 2;
    padding-left: 15px;
    padding-right: 15px;
    h2 {
        line-height: 1.2;
        font-size: 46px;
        font-weight: $semi-bold;
        margin-bottom: 44px;
        @include tab {
            br {
                display: none;
            }
        }
        @include mobileMd {
            font-size: 28px;
        }
    }
    a.btn-pay.analytic:hover {
        @extend .analytic_c2-bg;
        @extend .analytic_c1;
    }   
    .cta-quote {
        padding-bottom: 100px;
        margin-top: 152px;
        @include mobileMd {
            padding-bottom: 40px;
            margin-top: 100px;
        }
        h3 {
            font-size: 26px;
            font-weight: $medium;
            color: $white;
            position: relative;
            &:after {
                content: url(../img/icons/quote.svg);
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: -52px;
            }
        }
    }
    &.email {
        h2 {
            font-size: 40px;
            line-height: 1.35;
        }
    }
    &.style--two {
        @include tab {
            margin-bottom: 60px;
        }
        h2 {
            font-size: 40px;
            font-weight: $bold;
            margin-bottom: 15px;
            @extend .crm_c1;
            @include mobileMd {
                font-size: 30px;
            }
        }
        p{
            margin-bottom: 44px;
        }
    }
    &.crs {
        h2 {
            font-size: 40px;
            margin-bottom: 17px;
            margin-top: -6px;
            @include tab {
                br {
                    display: none;
                }
            }
            @include mobileMd {
                font-size: 30px;
            }
        }
    }
    &.book {
        h2 {
            font-size: 40px;
            margin-bottom: 8px;
            @include mobileSm {
                font-size: 36px;
            }
        }
        h3 {
            font-size: 22px;
            font-weight: $medium;
            margin-bottom: 15px;
        }
        p {
            margin-bottom: 34px;
        }

        .paperback{
            @include tab {
                margin-bottom: 60px;
            }
            .order-box {
                padding: 40px 60px;
                background: #FFF0B8;
                
                &:not(:last-child) {
                    margin-right: 30px;
                }

                @include customMq(1200px, 1500px) {
                    padding: 40px 40px;
                }
                @include medium {
                    padding: 40px 60px;
                    &:not(:last-child) {
                        margin-right: 0px;
                        margin-bottom: 20px;
                    }
                }
                @include tab {
                    padding: 40px;
                    &:not(:last-child) {
                        margin-right: 20px;
                        margin-bottom: 0px;
                    }
                }
                @include mobileMd {
                    &:not(:last-child) {
                        margin-right: 0px;
                        margin-bottom: 20px;
                    }
                }

                h3 {
                    color: $black;
                    font-size: 24px;
                    font-weight: $semi-bold;
                    margin-bottom: 10px;
                }

                .price {
                    margin-bottom: 25px;
                    strong {
                        @extend .hind;
                        @extend .book_c1;
                        font-size: 30px;
                        font-weight: $semi-bold;
                        margin-right: 14px;
                        line-height: 1;
                    }
                    .stroke-text del {
                        @extend .hind;
                        @extend .text-color;
                        font-size: 22px;
                        font-weight: $regular;
                        line-height: 1;
                    }
                }
                .btn-book {
                    &.s-btn {
                        @extend .book_c1-bg;
                        color: $white;
                        &:hover {
                            background: #D2E5FF;
                            @extend .book_c1;
                        }
                    }
                }
                &.box2 {
                    background: $white;
                    .btn-book {
                        &.s-btn {
                            background: #D2E5FF;
                            @extend .book_c1;
                            &:hover {
                                @extend .book_c1-bg;
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
    }
    &.management {
        h2 {
            font-size: 40px;
            margin-bottom: 10px;
        }
        p {
            max-width: 590px;
            margin: 0 auto;
            margin-bottom: 40px;
        }
    }
    &.pos {
        padding: 0;
        h2 {
            margin-bottom: 14px;
        }
        p {
            max-width: 560px;
        }
    }
    &.app {
        padding: 0;
        h2 {
            margin-bottom: 24px;
            color: $black;
            font-size: 50px;
            font-weight: $bold;
            @include mobileMd {
                font-size: 40px;
            }
        }
        p {
            font-weight: $regular;
            margin-bottom: 55px;
        }
    }
    &.proto {
        position: relative;
        z-index: 1;
        padding-top: 100px;
        padding-bottom: 100px;
        margin-bottom: -300px;
        @include mobileLg {
            margin-bottom: 0;
            padding-top: 80px;
            padding-bottom: 80px;
        }
        &:after {
            position: absolute;
            left: 0;
            top: 0;
            width: 111%;
            height: 100%;
            content: "";
            @extend .proto_c2-bg;
            transform: perspective(2850px) rotateY(30deg);
            @extend .radius-20;
            z-index: -1;
            @include tab {
                width: 115%;
                left: -28px;
            }
            @include mobileLg {
                transform: perspective(2850px) rotateY(0deg);
                width: 200%;
                left: -50%;
            }
        }

        h2 {
            font-size: 40px;
            font-weight: $bold;
            margin-bottom: 15px;
            @include mobileLg {
                font-size: 36px;
            }
            @include mobileSm {
                font-size: 32px;
            }
        }
        p {
            max-width: 640px;
            margin: 0 auto 44px;
        }
    }
    &.email {
        h2 {
            @include customMq(0px, 1500px) {
                br {
                    display: none;
                }
            }
        }
    }
}

/* Cta Img */
.cta-img {
    &.book {
        @include customMq(1200px, 1500px) {
            padding-right: 15px;
        }
        @include medium {
            padding-right: 15px;
        }
    }
    &.pos {
        padding-bottom: 10px;
        @include tab {
            margin-bottom: 40px;
        }
    }
}

/* Payment Cta */
.Payment-cta {
    margin-bottom: -110px;
    position: relative;
    .cta-wrap {
        padding: 85px 30px;
        background: #3D52A2;
        @include mobileSm {
            padding: 60px 30px;
        }
        .cta-content {
            h2 {
                margin-bottom: 0;
                font-size: 40px;
                text-align: center;
                @include customMq(992px, 1500px) {
                    font-size: 36px;
                }
                @include mobileSm {
                    font-size: 28px;
                }
                @include medium {
                    margin-bottom: 10px;
                }
            }
        }
    }
}

/* Plugins Cta */
.plug-cta {
    background-color: #4E2FA9;
}

/* Book CTA */
.bg-left {
    position: absolute;
    left: -200%;
    top: 0;
    width: calc(300% - 15px);
    height: 100%;
    @extend .book_c1-bg;
    border-radius: 0 0 150px 0;
    z-index: -1;
    @include mobileLg {
        width: 600%;
    }
}


/* POS CTA ROW BG */
.pos-cta-bg {
    @include medium {
        background-color: #F2FFFF;
    }
}
.row.cta-bg {
    position: relative;
    z-index: 1;
    &:after {
        position: absolute;
        background-color: #F2FFFF;
        content: "";
        left: 15px;
        top:auto;
        bottom: 0;
        width: calc(100% - 30px);
        height: calc(100% - 40px);
        z-index: -1;
        @extend .radius-20;
        @include medium {
            display: none;
        }
    }
}






