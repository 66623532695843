/* ************************
   03.4: Footer
   ********************* */
.footer {
    padding-top: 98px;
    padding-bottom: 50px;
    .theme-input-group {
        input {
            &:placeholder {
                color: $text-color !important;
            }
        }
        &.affiliate, &.course, &.style--four, &.style--two.analytic, &.error404 {
            input[type=email] {
                &::placeholder {
                    color: $white !important;
                }
                &::-webkit-input-placeholder {
                    color: $white !important;
                }
            }
        }
    } 
    .widget {
        margin-bottom: 30px;
        padding: 0;
        background-color: transparent;
        &:last-child {
            margin-bottom: 50px;
        }
        .widget_title {
            h3 {
                font-size: 26px;
                color: $black;
                font-weight: $bold;
                margin-bottom: 24px;
            }
        }
        .widget-logo {
            margin-bottom: 30px;
        }
        &.widget_text {
            p {
                margin-bottom: 30px;
                @extend .feature-p;
                font-weight: $light;
                max-width: 380px;
            }
        }
    }

    .footer-top {
        margin-bottom: 90px;
        .footer-top-left {
            h2 {
                margin-bottom: 16px;
                font-size: 46px;
                font-weight: $semi-bold;
                @extend .crm_c1;
                @include mobileMd {
                    font-size: 30px;
                }
            }
            p {
                max-width: 550px;
                font-size: 22px;
            }
            &.pos {
                h2 {
                    @extend .analytic_c1;
                }
            }
        }
        .footer-top-right {
            .custom-checkbox {
                label {
                    font-size: 16px;
                }
            }
        }
    }

    &.style--two {
        background-color: #fcf3f1;
        padding-top: 138px;
        padding-bottom: 140px;
        @include tab {
            padding-top: 80px;
            padding-bottom: 80px;
        }

        .footer-main {
            .footer-boxed {
                background: $white;
                padding-top: 100px;
                padding-bottom: 50px;
                @include mobileMd {
                    padding-top: 60px;
                    padding-bottom: 10px;
                }
            }
        }
         
        .widget {
            .widget_title {
                h3 {
                    color: $crm_c1;
                    margin-bottom: 34px;
                }
            }
        }
        &.analytic {
            @extend .analytic_c1-bg;
            padding-bottom: 0;
            .widget {
                .widget_title {
                    h3 {
                        color: $black;
                        margin-bottom: 34px;
                    }
                }
                &.widget_text {
                    p {
                        font-weight: $regular;
                    }
                }
            }
        }
        &.pos {
            background-color: #EEF8F0;
        }
    }
    &.pay-footer {
        padding-top: 210px;
        padding-bottom: 50px;
        @include vertical-gradient(#9578FA, #6449D6);
        .widget {
            .widget_title {
                h3 {
                    color: $white;
                }
            }
        }
    }
    &.plugin-footer {
        padding-top: 180px;
        padding-bottom: 50px;
        position: relative;
        .footer-shape {
            position: absolute;
            left: 0;
            top: -1px;
            width: 100%;
            height: 80px;
        }
        .widget {
            .widget_title {
                h3 {
                    color: $white;
                }
            }
        }
    }
    &.course {
        @include vertical-gradient(#F6F8F5, #FFFFFF);
    }
    &.style--three {
        font-size: 18px;
        font-weight: $light;
        padding: 48px 0 34px;
        line-height: 1;
        .footer-logo {
            margin-bottom: 15px;
        }
    }
    &.style--four {
        padding-top: 140px;
        padding-bottom: 130px;
        @include tab {
            padding-top: 80px;
            padding-bottom: 70px;
        }
        .footer-main {
            .footer-main-inner {
                border-bottom: 1px solid #BFBFBF;
                padding-bottom: 10px;
            }
        }
        .footer-bottom {
            padding-top: 60px;
            .footer-bottom-left {
                h2 {
                    font-weight: $semi-bold;
                    margin-bottom: 15px;
                }
            }
            p {
                font-size: 16px;
            }
        }
        .widget {
            .widget_title {
                h3 {
                    color: $white;
                }
            }
            .footer_menu li {
                a {
                    color: $white;
                }
                &:hover {
                    a {
                        @extend .email_c2;
                    }
                }
            }
        }
        .footer-icon1, .footer-icon2, .footer-icon3, .footer-icon4 {
            position: absolute;
            opacity: 0.6;
            left: 1%;
            top: 3%;
        }
        .footer-icon2 {
            left: auto;
            right: 2.5%;
            top: 4%;
        }
        .footer-icon3 {
            left: 40%;
            top: 62%;
        }
        .footer-icon4 {
            left: auto;
            right: 2%;
            top: auto;
            bottom: 2%;
        }
    }
    &.management {
        padding-top: 0;
        padding-bottom: 0;
        .footer-main {
            padding-top: 100px;
            padding-bottom: 50px;
        }
        .widget {
            .widget_title {
                h3 {
                    color: $white;
                }
            }
            .footer_menu {
                li {
                    a {
                        color: $white;
                        &:hover {
                            @extend .management_c2;
                        }
                    }
                }
            }
        }
        .footer-bottom {
            background-color: #6C69CF;
            font-size: 18px;
            padding: 23px 0 25px;
            a:hover {
                @extend .management_c2;
            }
        }
    }
    &.pos {
        padding-top: 118px;
        padding-bottom: 120px;
        @include tab {
            padding-top: 78px;
            padding-bottom: 80px;
        }
        .widget {
            &.widget_text {
                p {
                    font-weight: $regular;
                }
            }
            .widget_title {
                h3 {
                    color: $black;
                }
            }
            .footer_menu {
                li {
                    a {
                        @extend .text-color;
                        font-weight: $regular;
                        &:hover {
                            @extend .analytic_c2;
                        }
                    }
                }
            }
        }
    }
    &.app {
        background-size: cover;
        background-position: top center;
        position: relative;
        padding-top: 300px;
        margin-top: 240px;
        @include medium {
            padding-top: 250px;
        }
        @include mobileMd {
            margin-top: 120px;
        }
        @include mobileSm {
            padding-top: 170px;
        }
        .footer-img {
            display: flex;
            justify-content: center;
            img {
                position: absolute;
                bottom: 300px;
                
                @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                    left: 8%;
                }
            }
        }
        .footer-content {
            .footer-logo {
                margin-bottom: 30px;
            }
            p {
                font-size: 16px;
                max-width: 560px;
                margin: 0 auto 30px;
            }
        }
    }
    &.app2 {
        padding-top: 0;
        padding-bottom: 0;
        .footer-main {
            padding-top: 100px;
            padding-bottom: 50px;
        }
        .widget {
            .widget_title {
                h3 {
                    color: $black;
                }
            }
            .footer_menu {
                li {
                    a {
                        color: $text-color;
                        &:hover {
                            @extend .app2_c2;
                        }
                    }
                }
            }
        }
        .footer-bottom {
            background-color: #E8EFFF;
            font-size: 18px;
            padding: 23px 0 25px;
            color: $black;
            a {
                color: $black;
            }
            a:hover {
                @extend .app2_c2;
            }
        }
    }
    &.proto {
        padding-top: 370px;
        padding-bottom: 115px;
        position: relative;
        @extend .proto_c1-bg;
        @include mobileLg {
            padding-top: 80px;
            padding-bottom: 10px;
        }
        > img {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: -1px;
            @include mobileLg {
                display: none;
            }
        }
        .widget {
            .widget_title {
                h3 {
                    color: $white;
                }
            }
            .footer_menu {
                li {
                    a {
                        color: $white;
                        &:hover {
                            @extend .proto_c2;
                        }
                    }
                }
            }
        }
    }

    .copyright-footer {
        padding: 56px 0;
        font-size: 16px;
        .copyright-text {
            color: $white;
            a {
                color: $white;
                &:hover {
                    @extend .c2;
                }
            }
            &.style--two {
                color: $text-color;
                a {
                    color: $text-color;
                    &:hover {
                        @extend .crm_c1;
                    }
                }
            }
        }
    }
}