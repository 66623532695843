/* ************************
   03.6: Coming Soon
   ********************* */
.content-coming-soon {
    h1 {
        font-size: 60px;
        line-height: 1.4;
        margin-bottom: 8px;
        font-weight: $semi-bold;
        color: $black;
        @include mobileMd {
            font-size: 50px;
        }
    }
    > p {
        margin-bottom: 24px;
    }
}
.coming-soon-img {
    @extend .email_c1-bg;
}