/* ************************
04.2: Service
********************* */

.service-bg {
    background-color: #F5F8FF;
}
/* Analitic Service BG */
.analytic-fbg {
    background: #F7FBFE;
}

.single-service {
    padding: 60px 40px;
    margin-bottom: 30px;
    background-color: $white;
    position: relative;
    @extend .radius-20;
    @extend .trans3;
    transform: translateY(0);
    @include mobileMd {
        padding: 40px 20px;
    }

    &:hover {
        transform: translateY(-5px);
    }

    .service-icon {
        @extend .radius-10;
        width: 80px;
        height: 80px;
        background: #6BF1D6;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 30px;
        &.two {
            background: #AE88E6;
        }
        &.three {
            background: #47CBF3;
        }
        &.four {
            background: #FFBD52;
        }
        &.five {
            background: #F091BA;
        }
        &.six {
            background: #77A8FF;
        }
    }

    .service-content {
        h3 {
            color: $black;
            margin-bottom: 8px;
            font-weight: $medium;
        }
        P {
            @extend .feature-p;
        }
    }

    @include mobileSm {
        flex-direction: column;
        justify-content: center;
        .service-icon {
            margin-right: 0px;
            margin-bottom: 30px;
        }
        .service-content {
            text-align: center;
        }
    }

    &.style--two {
        @extend .box-shadow;
        overflow: hidden;
        > span {
            position: absolute;
            width: 0;
            height: 0;
            display: block;
            @extend .crm_c1-bg;
            z-index: -1;
            @extend .radius;
            transform: translate(-50%, -50%);
            transition: .7s;
        }
        &:hover {
            > span {
                width: 300%;
                height: 1320px;
            }
        }
        
        .service-icon {
            margin: 0px;
            margin-bottom: 38px;
            width: 120px;
            height: 120px;
            @extend .radius-20;
            background-color: #ECEBFF;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            @extend .trans3;
            &.bg-2 {background-color: #FFF1D9;}
            &.bg-3 {background-color: #D9FFF1;}
            .svg {
                path {
                   @extend .trans3;
                }
            }
        }
        .service-content {
            h3 {
                color: $crm_c1;
                margin-bottom: 15px;
                font-weight: $semi-bold;
                @extend .trans3;
            }
            P {
                font-size: 18px;
                font-weight: $light;
                @extend .trans3;
            }
        }

        &:hover {
            transform: translateY(0);
            .service-icon {
                background-color: $white;
                .svg {
                    path {
                        fill: $crm_c2;
                    }
                }
            }
            .service-content {
                h3 {
                    color: $crm_c2;
                }
                P {
                    color: $white;
                }
                .btn-crm {
                    &.btn-view-more {
                        color: $crm_c2;
                        .svg {
                            line, path {
                                stroke: $crm_c2;
                            }
                        }
                    }
                }
            }
        }
        &.management {
            > span {
                @extend .management_c1-bg;
            }
            .service-content {
                h3 {
                    color: $management_c1;
                }
                P {
                    font-size: 18px;
                    font-weight: $light;
                }
                .btn-crm {
                    &.btn-view-more {
                        color: $management_c1;
                        .svg {
                            line, path {
                                stroke: $management_c1;
                            }
                        }
                    }
                }
            }
            &:hover {
                transform: translateY(0);
                .service-icon {
                    background-color: $white;
                }
                .service-content {
                    h3 {
                        color: $white;
                    }
                    .btn-crm {
                        &.btn-view-more {
                            color: $management_c2;
                            .svg {
                                line, path {
                                    stroke: $management_c2;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.style--three {
        padding: 0 40px;
        margin-bottom: 52px;
        @include mobileSm {
            padding: 0;
        }
        .service-icon {
            margin: 0px auto;
            margin-bottom: 38px;
            background: transparent;
        }
        .service-content {
            h3 {
                color: $black;
                margin-bottom: 15px;
                font-weight: $semi-bold;
            }
            P {
                font-size: 18px;
                font-weight: $light;
            }
        }
        &.pay {
            .service-icon {
                margin-bottom: 28px;
                @extend .radius;
                width: 120px;
                height: 120px;
                background: #DFF5FF;
                display: flex;
                justify-content: center;
                align-items: center;
                @extend .trans5;
                .svg {
                    g {
                        path {
                            @extend .trans4;
                        }
                    }
                }
                &.two {
                    background: #FFF3EB;
                }
                &.three {
                    background: #D9FFF6;
                }
                &.four {
                    background: #FFF0EF;
                }
                &.five {
                    background: #DFFFF6;
                }
                &.six {
                    background: #E9F3FF;
                }
            }
            &:hover {
                .service-icon {
                    background: #21BCFF;
                    .svg {
                        g {
                            path {
                                fill: $white;
                            }
                        }
                    }
                    &.two {
                        background: #F99C5E;
                    }
                    &.three {
                        background: #2DCEA5;
                    }
                    &.four {
                        background: #FF8781;
                    }
                    &.five {
                        background: #04E2A3;
                    }
                    &.six {
                        background: #0472FA;
                    }
                }
            }
            .service-content {
                h3 {
                    margin-bottom: 0px;
                    font-weight: $medium;
                    font-size: 26px;
                }
            }
            &:hover {
                transform: translateY(0);
            }
        }
        &.plug {
            .service-icon {
                margin-bottom: 28px;
                width: 120px;
                height: 120px;
                background: #ffffff;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .service-content {
                h3 {
                    margin-bottom: 0px;
                    font-weight: $medium;
                    font-size: 26px;
                }
            }
        }
    }
    &.style--four {
        padding: 40px;
        background: #929DFF;
        text-align: center;
        margin-bottom: 0;
        &:hover {
            box-shadow: 0 10px 24px rgba(#929DFF, 0.5);
        }
        .service-icon {
            margin: 0px;
            margin-bottom: 58px;
            height: auto;
            width: auto;
            background: transparent;
        }
        .service-content {
            h3 {
                color: $white;
                margin-bottom: 15px;
                font-weight: $semi-bold;
            }
        }
        &:hover {
            transform: translateY(0px);
        }

        &.one {
            background: #85CFFF;
        &:hover {
            box-shadow: 0 10px 24px rgba(#85CFFF, 0.5);
        }
        }
        &.two {
            background: #BF87FF;
            &:hover {
                box-shadow: 0 10px 24px rgba(#BF87FF, 0.5);
            }
        }
        &.three {
            background: #FFCB87;
            &:hover {
                box-shadow: 0 10px 24px rgba(#FFCB87, 0.5);
            }
        }
        &.four {
            background: #85b6ff;
            &:hover {
                box-shadow: 0 10px 24px rgba(#85b6ff, 0.5);
            }
        }
    }
    &.style--five {
        margin: 0;
        padding: 0;
        overflow: hidden;
        position: relative;
        .service-icon {
            padding: 10px;
            background-color: #E6FFFA;
            margin-right: 0;
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            border-radius: 0;

            &.one {
                background: #D7EFF9;
            }
            &.two {
                background: #E9E6FF;
            }
            &.three {
                background: #FFF6DB;
            }
        }
        .service-content {
            padding: 28px 20px 22px;
            padding-left: 100px;
            h3 {
                margin-bottom: 5px;
                font-weight: $medium;
            }
            p {
                font-weight: $light;
            }
        }
        &:hover {
            transform: translateY(0px);
        }
    }
    &.style--six {
        @extend .box-shadow;
        .service-icon {
            background: #DFF5FF;
            &.two {
                background: #FFF3EB;
            }
            &.three {
                background: #DFFFF6;
            }
            &.four {
                background: #E9F3FF;
            }
            .svg {
                @extend .trans3;
            }
        }
        .service-content {
            p {
                font-weight: $light;
            }
        }
        &:hover {
            .service-icon {
                .svg {
                    transform: rotate(10deg);
                }
            }
        }
    }
    &.style--seven {
        margin-bottom: 50px;
        padding: 0px;
        background: transparent;
        
        img {
            margin-bottom: 30px;

        }
        h3 {
            font-size: 22px;
        }
        &.management {
            margin-bottom: 73px;
            @include mobileMd {
                margin-bottom: 53px;
            }
        }
        &.management2 {
            padding: 0 10px;
            h3 {
                font-size: 26px;
                line-height: 1.3;
                margin-bottom: 0;
            }
        }
    }
    &.analytic {
        padding: 0;
        margin-bottom: 53px;
        background: transparent;
        .service-icon {
            margin: 0px auto;
            margin-bottom: 18px;
            background: transparent;
        }
        .service-content {
            h3 {
                color: $black;
                margin-bottom: 5px;
                font-weight: $semi-bold;
                font-size: 26px;
            }
            P {
                font-size: 22px;
                font-weight: $light;
                max-width: 340px;
                margin: 0 auto;
            }
        }
    }
    &.host {
        text-align: center;
        padding: 50px;
        margin-bottom: 0;
        @include medium {
            padding: 30px;
        }
        @include mobileMd {
            padding: 30px 20px;
            margin-bottom: 10px;
        }
        .service-icon {
            margin: 0 auto 28px;
            background: #FDCE36;
            &.two {
                background: #AE88E6;
            }
            &.three {
                background: #82D0E8;
            }
            &.four {
                background: #6BF1D6;
            }
            &.five {
                background: #F091BA;
            }
            &.six {
                background: #B6CDF6;
            }
        }
        .service-content {
            h3 {
                margin-bottom: 13px;
            }
            p {
                font-weight: $light;
            }
        }
        &:hover {
            @extend .box-shadow;
        }
        &.style-two {
            .service-icon {
                background: transparent;
            }
        }
    }
    &.proto {
        padding: 0;
        background-color: transparent;
        margin-bottom: 60px;
        .service-icon {
            width: 140px;
            height: 140px;
            @extend .radius-20;
            background-color: $white;
            @include mobileSm {
                margin-bottom: 20px;
            }
        }
        .service-content h3 {
            color: $proto_c1;
        }
        &:hover {
            transform: translateY(0);
        }
    }
}

.host-wc-right {
    position: relative;
    span.bg {
        position: absolute;
        width: 1100%;
        right: -1000%;
        height: 100%;
        top: 0px;
        left: 0;
        right: 0;
        background-color: #F3F7FF;
        z-index: -1;
        border-radius: 20px 0 0 20px;
    }
    .single-service {
        padding: 20px 0 20px 117px;
        @include customMq(1200px, 1500px) {
            padding-left: 90px;
        }
        @include medium {
            padding: 20px 0 20px 50px;
        }
        @include mobileMd {
            padding: 20px;
        }
        @extend .radius-20;
        margin-bottom: 14px;
        background-color: transparent;
        .service-icon {
            background-color: #AF86E6;
            img, .svg {
                @extend .trans3;
            }
            &.two {
                background-color: #9ED2E3;
            }
            &.three {
                background-color: #FECF2F;
            }
        }
        .service-content {
            p {
                font-size: 18px;
                font-weight: $light;
            }
        }
        &:hover {
            .service-icon {
                img, .svg {
                    transform: rotate(10deg);
                }
            }
        }
    }
}

.service-slider.owl-carousel .owl-stage-outer {
    padding: 30px;
    margin: 0 -30px;
}