/* ************************
   04.7: Insight
   ********************* */

   .insight-title {
       padding: 140px 80px;
       height: 100%;
       border-radius: 30px 0 0 30px;
       .title-content {
            max-width: 440px;
       }
       @include customMq(1200px, 1499px) {
            padding: 100px 40px;
        }
        @include medium {
            flex: 0 0 100%;
            padding: 100px 30px;
        }
        @include tab {
            border-radius: 30px 30px 0 0;
        }
        @include mobileMd {
            padding: 80px 30px;
        }
   }

   .title-content {
       h2 {
            margin-bottom: 20px;
            @include mobileMd {
                font-size: 34px;
            }
       }
       p {
           margin-bottom: 44px;
       }
       @include medium {
           flex: 0 0 100%;
           height: auto;
       }
   }

   .insight-data {
       background-color: #FCF3F1;
       border-radius: 0 30px 30px 0;
       padding: 75px 40px 68px;
       justify-content: space-between;
        @include customMq(1200px, 1499px) {
            padding: 90px 40px 100px;
        }
        @include medium {
            flex: 0 0 100%;
            padding: 70px 30px 78px;
        }
        @include tab {
            border-radius: 0 0 30px 30px;
        }
        @include mobileMd {
            padding: 50px 30px 58px;
            justify-content: center;
        }
   }

   .single-data {
       text-align: center;
       padding: 15px;
       flex: 0 0 50%;
       margin-bottom: 15px;
       @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        flex: 0 0 45%;
       }
       @include mobileMd {
            padding: 40px 0px;
            justify-content: center;
            flex: 0 0 100%;
        }
       strong {
           font-size: 80px;
           @extend .hind;
           font-weight: $semi-bold;
           line-height: 1.3;
           @extend .crm_c1;
       }
       h3 {
           font-weight: $regular;
           color: $black;
           line-height: 1.3;
           @include mobileSm {
               font-size: 22px;
           }
       }
   }