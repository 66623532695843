/* ************************
04.15: Sign Up
********************* */

.signup-form-wrap, .signin-form-wrap {
    @extend .box-shadow;
}
.signup-form-left {
    flex: 0 0 40%;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        flex: 0 0 38%;
    }
    @include customMq(1200px, 1500px) {
        flex: 0 0 52%;
    }
    @include medium {
        display: none;
    }
}
.signup-form-right {
    padding: 60px;
    flex: 0 0 60%;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        flex: 0 0 53%;
    }
    @include customMq(1200px, 1500px) {
        flex: 0 0 48%;
    }
    @include medium {
        flex: 0 0 100%;
    }
    @include mobileMd {
        padding: 30px 15px;
    }
}
.signup-form-title, .signin-form-title {
    margin-bottom: 60px;
    h2 {
        font-size: 36px;
        color: $black;
        font-weight: $semi-bold;
        margin-bottom: 15px;
        @include mobileMd {
            font-size: 30px;
        }
    }
    p {
        max-width: 540px;
        margin: 0 auto;
    }
}
.signup-form {
    label {
        margin-bottom: 15px;
        padding: 0;
    }
    .login-text {
        @extend .hind;
        font-weight: $regular;
        color: $black;
        a {
            @extend .email_c1;
            &:hover {
                @extend .email_c2;
            }
        }
    }
}

/* Sign In */
.signin-form-left {
    flex: 0 0 50%;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        flex: 0 0 46%;
    }
    @include customMq(1200px, 1500px) {
        display: none;
    }
    @include medium {
        display: none;
    }
}
.signin-form-right {
    padding: 60px;
    flex: 0 0 50%;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        flex: 0 0 45%;
    }
    @include customMq(1200px, 1500px) {
        flex: 0 0 80%;
    }
    @include medium {
        flex: 0 0 100%;
    }
    @include mobileMd {
        padding: 30px 15px;
    }
}
.signin-help {
    @include mobileLg {
        flex-direction: column;
    }
    .forgot-password {
        @include mobileLg {
            margin-bottom: 6px;
        }
    }
}