/* ************************
   04.9: Syllabus
   ********************* */
.accordion {
    > .card {
        padding: 40px 0;
        border: 0;
        @extend .box-shadow;
        @extend .radius-20;
        background: $white;
        overflow: hidden;
        margin-bottom: 30px;

        &.active {
            .accordion-title {
                border-bottom-color: #EEEEEE;
                padding-bottom: 30px;
                > h3 {
                    &:after {
                        @include fa("\f106");
                    }
                }
            }
        }

        .accordion-title {
            padding: 18px 0 10px;
            margin-left: 300px;
            margin-right: 100px;
            border-bottom: 1px solid transparent;
            @include customMq(1200px, 1500px) {
                margin-left: 250px;
            }
            @include medium {
                margin-left: 200px;
            }
            @include tab {
                margin-left: 30px;
                margin-right: 30px;
                margin-top: 100px;
            }
            @include mobileMd {
                margin-left: 15px;
                margin-right: 15px;
            }
            > h3 {
                font-size: 30px;
                color: $black;
                margin-bottom: 20px;
                font-weight: $semi-bold;
                position: relative;
                @include mobileLg {
                    font-size: 24px;
                }
                &:after {
                    @include fa("\f107");
                    position: absolute;
                    right: 0px;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 30px;
                    font-weight: $regular;
                    cursor: pointer;
                }
            }
            p {
                margin-bottom: 6px;
                max-width: 900px;
                @include mobileLg {
                    font-size: 16px;
                }
            }
            .accordion-title-meta {
                span {
                    display: inline-block;
                    font-size: 18px;
                    color: $black;
                    @extend .hind;
                    font-weight: $medium;
                    @include mobileLg {
                        font-size: 14px;
                    }
                    &:not(:last-child) {
                        margin-right: 40px;
                        @include mobileLg {
                            margin-right: 20px;
                        }
                    }
                }
            }

            .accordion-absolute {
                text-align: center;
                position: absolute;
                left: 100px;
                top: 79px;
                @include customMq(1200px, 1500px) {
                    left: 75px;
                }
                @include medium {
                    top: 100px;
                    left: 50px;
                }
                @include tab {
                    top: 50px;
                    left: 30px;
                }
                @include mobileMd {
                    left: 15px;
                }

                h3 {
                    font-weight: $semi-bold;
                    color: $black;
                }

                .svg {
                    margin-bottom: 17px;
                }
            }
        }
        .accordion-content {
            max-width: 900px;
            margin-left: 300px;
            margin-top: 31px;
            margin-right: 100px;
            @include customMq(1200px, 1500px) {
                margin-left: 250px;
            }
            @include medium {
                margin-left: 200px;
            }
            @include tab {
                margin-left: 15px;
                margin-right: 15px;
            }
        }
   }
}

.lecture-container {
    margin-bottom: 15px;
    .left-content {
        flex: 0 0 50%;
        .play-circle {
            margin-right: 20px;
            display: inline-block;
        }
        .title {
            @extend .text-color;
            font-size: 18px;
            @include mobileLg {
                font-size: 14px;
            }
            a {
                color: $black;
            }
        }
    }
    .details {
        flex: 0 0 50%;
        justify-content: flex-end;
        .preview {
            width: 30%;
            display: block;
            text-align: right;
            @include mobileLg {
                width: 40%;
            }
            .preview-text {
                width: 100%;
            }
        }
        .preview-text {
            @extend .crs_c1;
            font-size: 18px;
            @extend .hind;
            font-weight: $semi-bold;
            width: 30%;
            display: block;
            text-align: right;
            @include mobileLg {
                font-size: 14px;
                width: 40%;
            }
            .svg {
                margin-right: 23px;
                @include mobileLg {
                    margin-right: 15px;
                }
            }
        }
        .content-summary {
            font-size: 18px;
            @include mobileLg {
                font-size: 14px;
            }
        }
    }
}


/* Bootstrap */
.accordion>.card:not(:first-of-type):not(:last-of-type) {
    border-radius: 20px;
}
.accordion>.card:first-of-type {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
}
.accordion>.card:last-of-type {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}


/* Book Chaptar */
.chaptar {
    .chaptar-box {
        padding: 40px 30px;
        @extend .trans2;
        background: #F8FBFF;
        @extend .radius-20;
        &:not(:last-child) {
            margin-bottom: 20px;
        }
        @include tab {
            margin-bottom: 20px;
        }
        h3 {
            color: $black;
            font-size: 26px;
            font-weight: $semi-bold;
            margin-bottom: 10px;
            cursor: pointer;

            span {
                margin-right: 5px;
            }
        }
        @include mobileMd {
            padding: 30px 20px;
            h3 {
                font-size: 20px;
            }
            p {
                font-size: 16px;
            }
        }
        &:hover, &.active {
            @extend .book_c1-bg;
            h3, p {
                color: $white;
            }
        }
    }
}

.chaptar-img {
    @include tab {
        margin-bottom: 40px;
    }
}