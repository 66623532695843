/* ************************
   03.5: 404
   ********************* */
.error-404 {
    @include tab {
        padding: 80px 0;
    }
}
.content-404 {
    h1 {
        font-size: 60px;
        line-height: 1.4;
        margin-bottom: 8px;
        font-weight: $semi-bold;
        color: $black;
        @include mobileMd {
            font-size: 50px;
        }
    }
    a {
        @extend .email_c1;
        font-weight: $medium;
    }
    > p {
        margin-bottom: 34px;
    }
    .search-form {
        p {
            font-weight: $semi-bold;
            margin-bottom: 20px;
        }
    }
}
.img-404-absolute {
    display: none;
    @include customMq(1980px, 3600px) {
        display: block;
        position: absolute;
        width: 50%;
        right: 0;
        top: 0;
        height: 100%;
    }
}
.img-404 {
    img {
        max-width: none;
        @include customMq(1980px, 3600px) {
            display: none;
        }
        @include tab {
            max-width: 100%;
            margin-bottom: 60px;
        }
    }
}

.icon404-1 {
    position: absolute;
    animation: banner3Shake 3s linear 0s infinite forwards;
    left: 5%;
    top: 20%;
    z-index: -1;
}