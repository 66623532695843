/* ************************
   02.6: Page Title
   ********************* */
.page-title-wrap {
    background-color: #ECF7FF;
    margin-top: 100px;
    padding: 100px 0;
    z-index: 1;
}
.page-title {
    h2 {
        margin-top: -13px;
        margin-bottom: 8px;
        font-size: 50px;
        font-weight: $semi-bold;
        line-height: 1.4;
        color: $black;
    }
    ul {
        margin-bottom: -8px;
        li {
            font-size: 20px;
            font-weight: $regular;
            @extend .c2;
            color: $black;
            &:not(:last-child) {
                position: relative;
                padding-right: 15px;
                margin-right: 10px;
                color: $black;
                a {
                    color: $black;
                    &:hover {
                        @extend .email_c1;
                    }
                }
                &:after {
                    right: 0;
                    position: absolute;
                    content: '/';
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }
}


.breadcrumb-icon {
    position: absolute;
    left: 5%;
    top: 10%;
    z-index: -1;
}
.bread1 {
    animation: banner3Shake 5s linear 0s infinite forwards;
}
.bread2 {
    left: 0;
    top: auto;
    bottom: 0;
    animation: leftToRight 300s linear .3s infinite forwards;
}
.bread3 {
    left: 85%;
    top: 30%;
    animation: spin 80s linear .2s infinite reverse forwards;
}
.bread4 {
    left: 30%;
    top: auto;
    bottom: 0;
    animation: banner3Shake 15s ease-in 0s infinite forwards;
}