/* ************************
04.16: Contact
********************* */
.contact-form-left {
    @include tab {
        margin-bottom: 80px;
    }
    .content {
        margin-bottom: 70px;
    }
    h2 {
        color: $black;
        font-size: 36px;
        font-weight: $semi-bold;
        margin-bottom: 20px;
    }
    p {
        max-width: 540px;
    }

}

.contact-form {
    padding: 80px 60px;
    @extend .box-shadow;
    background-color: $white;
    @include mobileMd {
        padding: 50px 15px;
    }
    &.style--two {
        margin-top: -200px;
        @include tab {
            margin-top: -150px;
        }
        @include mobileMd {
            margin-top: -100px;
        }
    }
    .contact-form-top {
        margin-bottom: 65px;
        h2 {
            margin-bottom: 15px;
            font-size: 36px;
            color: $black;
            font-weight: $semi-bold;
        }
        p {
            max-width: 540px;
            margin: 0 auto;
        }
    }
}


/* Map */
#map {
    width: 100%;
    height: 700px;

    @include tab {
        height: 550px;
    }
    @include mobileMd {
        height: 400px;
    }
    &.map2 {
        height: 800px;
        @include tab {
            height: 600px;
        }
        @include mobileMd {
            height: 400px;
        }
    }
}

/* Marker Popup */
.gm-style-iw {
    border-radius: 0px !important;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.06) !important;
    padding: 17px 5px 5px 17px !important;
    text-align: center;
    left: 100px !important;
    width: 210px !important;
    button {
        display: none !important;
    }
}
.gm-style .gm-style-iw-t::after {
  display: none !important;
}
#marker-info {
  line-height: 1.5;
}