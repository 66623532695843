/* ************************
   03.2: Banner
   ********************* */
/* Default Banner */
.banner {
    padding-top: 240px;
    padding-bottom: 140px;
    position: relative;
    background-size: cover;
    @include medium {
        padding-top: 180px;
        padding-bottom: 80px;
    }
    @include mobileLg {
        padding-top: 150px;
        padding-bottom: 80px;
    }
    @include mobileSm {
        padding-top: 120px;
        padding-bottom: 50px;
    }
    z-index: 1;
    .banner-content {
        @include tab {
            margin-bottom: 60px;
        }
        h1 {
            font-size: 60px;
            font-weight: $semi-bold;
            line-height: 1.17;
            color: $white;
            margin-bottom: 0px;
            @include mobileMd {
                font-size: 50px;
                br {
                    display: none;
                }
            }
        }
        p {
            margin-bottom: 44px;
        }
    }
    .banner-img {
        img {
            @extend .trans2;
        }
    }
    &.cource-banner {
        background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(228,255,239,0.8) 20%, rgba(228,255,239,0) 100%);
        background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(228,255,239,0.8) 20%,rgba(228,255,239,0) 100%);
        background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(228,255,239,0.8) 20%,rgba(228,255,239,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00e4ffef',GradientType=0 );
        padding-bottom: 100px;
        .banner-content {
            .content {
                margin-bottom: 34px;
            }
            @include medium {
                margin-bottom: 60px;
            }
            h1 {
                color: $black;
            }
            p {
                color: #5C6B56;
                margin-bottom: 34px;
            }
        }

        .banner-right {
            position: relative;
            z-index: 1;
            .cbat {
                position: absolute;
                left: -75px;
                top: -60px;
                z-index: -1;
                @include tab {
                    display: none;
                }
            }
            .cbab {
                position: absolute;
                right: 0;
                bottom: -70px;
                z-index: -1;
                @include tab {
                    display: none;
                }
            }
            .course-b {
                @include tab {
                    margin-bottom: 50px;
                }
            }
        }
    }
    /* Banner Affilliate */
    &.style--one {
        padding: 295px 0;
        background: #FFF5DB;
        @include customMq(1200px, 1602px) {
            padding: 250px 0;
        }
        @include medium {
           padding-top: 200px;
           padding-bottom: 100px;
        }
        @include tab {
            padding-top: 150px;
            padding-bottom: 40px;
        }
        @include mobileMd {
            padding-bottom: 20px;
        }
        .banner-content {
            h1 {
                font-size: 70px;
                color: $black;
                margin-bottom: 22px;
                line-height: 1.15;
                font-weight: $bold;
                @include medium {
                    br {
                        display: none;
                    }
                }
                @include mobileMd {
                    font-size: 50px;
                }
            }
        }
        .banner-img {
            .b-thumb {
                right: 0px;
                top: 80px;
                position: absolute;
                max-width: 56%;

                @include customMq(1200px, 1602px) {
                    top: 50%;
                    margin-top: -349px;
                    //transform: translateY(-50%);
                    //animation: none;
                }
                @include medium {
                    top: 50%;
                    margin-top: -220px;
                    //animation: none;
                }
            }
            @include tab {
                display: none;
            }
        }
    }
    /* Banner CRM */
    &.style--two {
        padding-top: 240px;
        padding-bottom: 140px;
        @include medium {
            padding-top: 180px;
            padding-bottom: 80px;
        }
        @include mobileLg {
            padding-top: 150px;
            padding-bottom: 80px;
        }
        @include mobileSm {
            padding-top: 120px;
            padding-bottom: 50px;
        }

        .banner-content {
            &.crm {
                h1 {
                    margin-bottom: 25px;
                }
            }
            h1 {
                font-size: 60px;
                margin-bottom: 14px;
                line-height: 1.3;
                font-weight: $bold;
                @extend .crm_c1;
                @include medium {
                    font-size: 44px;
                }
                @include mobileSm {
                    font-size: 42px;
                }
                @include customMq(1200px, 1499px) {
                    br {
                        display: none;
                    }
                }
            }
            animation: fadeInLeft .6s linear 1.5s backwards;
        }
        .crm-bg {
            position: absolute;
            right: 0;
            top: 0;
            animation: bg-scale .6s linear .3s backwards;
            width: 58%;
            @include customMq(1200px, 1602px) {
                width: 62%;
            }
            @include medium {
                width: 64%;
            }
            @include tab {
                width: auto;
            }
        }
        .banner-img {
            .b-thumb {
                animation: fadeInRight .6s linear .9s backwards;
                @include medium {
                    margin-bottom: 60px;
                }
            }
        }
        &.plugins {
            z-index: 2;
            padding-top: 200px;
            padding-bottom: 140px;
            @include tab {
                padding-top: 140px;
            }
            .plug-banner-shape {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 160px;
                z-index: 0;
            }
            .banner-content {
                position: relative;
                h1 {
                    @extend .plug_c2;
                    font-size: 70px;
                    line-height: 1;
                    margin-bottom: 14px;
                    @include mobileMd {
                        font-size: 50px;
                    }
                }
            }
            .banner-img {
                position: relative;
                margin-bottom: -140px;
                @include tab {
                    margin-bottom: 0;
                }
            }
        }
        &.management {
            padding-top: 150px;
            padding-bottom: 150px;
            @include medium {
                padding-bottom: 80px;
            }
            @include mobileSm {
                padding-top: 120px;
                padding-bottom: 0px;
            }

            .banner-content {
                animation: fadeInLeft2 .6s linear 1.5s backwards;
                .content {
                    margin-bottom: 34px;
                }
                h1 {
                    font-size: 48px;
                    line-height: 1.2;
                    font-weight: $semi-bold;
                    @extend .management_c1;
                    @include medium {
                        font-size: 40px;
                    }
                    @include medium {
                        br {
                            display: none;
                        }
                    }
                }
                
                p {
                    margin-bottom: 34px;
                }
            }
            .crm-bg {
                animation: bg-scale .6s linear .3s backwards;
                width: 64%;
                @include tab {
                    width: 100%;
                }
                @include mobileMd {
                    display: none;
                }
            }
            .banner-img {
                .b-thumb {
                    animation: fadeInRight2 .6s linear .9s backwards;
                    padding-left: 50px;
                    @include medium {
                        padding-left: 0;
                    }
                }
            }
        }
    }
    &.email {
        min-height: 900px;
        padding-top: 140px;
        padding-bottom: 30px;
        &:after {
            position: absolute;
            content: "";
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: #857DFF;
            z-index: -1;
            border-radius: 50% 0 0 50%;
            transform-origin: right center;
            animation: wide 1s linear .3s backwards;
            @include tab {
                display: none;
            }
        }
        @include customMq(1200px, 1680px) {
            min-height: 800px;
        }
        @include medium {
            min-height: 700px;
            padding-top: 180px;
        }
        @include tab {
            min-height: auto;
            background-color: $white;
            padding-bottom: 0px;
        }
        @include mobileMd {
            padding-top: 140px;
        }

        .banner-content {
            h1 {
                font-size: 60px;
                margin-bottom: 27px;
                line-height: 1.17;
                font-weight: $semi-bold;
                color: $black;
                @include medium {
                    font-size: 44px;
                }
                @include mobileSm {
                    font-size: 34px;
                }
                @include customMq(1200px, 1499px) {
                    br {
                        display: none;
                    }
                }
            }
            animation: fadeInLeft2 .7s linear .7s backwards;
            @include tab {
                margin-bottom: 60px;
            }
        }
        .email-bg {
            position: absolute;
            left: 0;
            top: 0;
            bottom: -1px;
            width: 100%;
            height: 100%;
            @include tab {
                display: none;
            }
        }
        .banner-img {
            position: relative;
            z-index: 1;
            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                height: 500px;
             }
            .email-thumb {
                position: relative;
                z-index: -1;
                right: -130px;
                bottom: -40px;
                animation: fadeInUp10 .7s linear .5s backwards;

                @include customMq(1200px, 1680px) {
                    padding-left: 20px;
                    right: -110px;
                    bottom: 0px;
                }
                @include medium {
                    padding-left: 70px;
                    right: -40px;
                    bottom: 0px;
                }
                @include tab {
                    padding-left: 0px;
                    right: 0px;
                    position: relative;
                }
            }
        }
        
        &.plugins {
            z-index: 2;
            padding-top: 200px;
            padding-bottom: 140px;
            @include tab {
                padding-top: 140px;
            }
            .plug-banner-shape {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 160px;
                z-index: 0;
            }
            .banner-content {
                position: relative;
                h1 {
                    @extend .plug_c2;
                }
            }
            .banner-img {
                position: relative;
                margin-bottom: -140px;
                @include tab {
                    margin-bottom: 0;
                }
            }
        }
    }
    &.payment-banner {
        padding-top: 230px;
        padding-bottom: 435px;
        position: relative;
        .payment-banner-bg {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: -50%;
            z-index: -1;
            @include vertical-gradient(#9578FA, #6449D6);
            animation: zoomDown .6s linear .3s backwards;
            border-radius: 0 0 50% 50%;
            @include tab {
                border-radius: 0;
                animation: none;
            }
        }
        @include customMq(1200px, 1602px) {
            padding-top: 180px;
            padding-bottom: 300px;
        }
        @include medium {
            padding-top: 140px;
            padding-bottom: 100px;
        }
        @include tab {
            padding-bottom: 180px;
        }
        .pay-banner-shape {
            position: absolute;
            left: 0;
            bottom: -1px;
            width: 100%;
            height: 100%;
            
            @include mobileLg {
                display: none;
            }
        }
        .banner-content {
            animation: fadeInUp .8s linear 1.4s backwards;
            .content {
                h1 {
                    color: $white;
                    font-size: 60px;
                    margin-bottom: 15px;
                    @include mobileLg {
                        font-size: 50px;
                    }
                }
                p {
                    max-width: 100%;
                    margin-bottom: 30px;
                }
            }
        }
    }
    &.book {
        padding-top: 240px;
        border-bottom-right-radius: 200px;
        @include tab {
            padding-top: 180px;
        }
        @include mobileLg {
            padding-top: 140px;
            border-bottom-right-radius: 100px;
        }
        .banner-content {
            @include tab {
                margin-bottom: 60px;
            }
            .doller {
                font-weight: $medium;
                font-size: 36px;
                @extend .crs_c2;
                margin-bottom: 18px;
            }
            h1 {
                font-size: 70px;
                font-weight: $semi-bold;
                color: $white;
                @include customMq(992px, 1500px) {
                    font-size: 60px;
                    br {
                        display: none;
                    }
                }
                @include mobileMd {
                    font-size: 50px;
                    br {
                        display: none;
                    }
                }
            }
            .star-rating {
                h4 {
                    font-size: 22px;
                    display: inline-block;
                    font-weight: $medium;
                    margin-right: 26px;
                }
                i {
                    font-size: 16px;
                    @extend .crs_c2;
                }
            }
        }
    }
    &.analytic {
        margin-bottom: 140px;
        @include tab {
            margin-bottom: 80px;
        }
        padding-top: 220px;
        @include medium {
            padding-top: 180px;
            padding-bottom: 80px;
        }
        @include mobileLg {
            padding-top: 150px;
            padding-bottom: 80px;
        }
        @include mobileSm {
            padding-top: 120px;
            padding-bottom: 50px;
        }
        @include tab {
            @include horizontal-gradient(#ffffff, #DBF0FF);
        }
        
        .analytic-shape {
            position: absolute;
            left: 0px;
            top: 0px;
            z-index: -1;
            @include tab {
                display: none;
            }
        }
        .banner-content {
            @include tab {
                margin-bottom: 60px;
            }
            h1 {
                font-size: 70px;
                font-size: $semi-bold;
                line-height: 1.17;
                @extend .analytic_c1;
                margin-bottom: 24px;
                @include mobileMd {
                    font-size: 50px;
                    br {
                        display: none;
                    }
                }
            }
            p {
                font-size: 22px;
                margin-bottom: 44px;
                color: $black;
            }
            p.info {
                font-size: 16px;
                font-weight: $regular;
                @extend .text-color;
                margin-top: 15px;
            }
        }
    }
    &.hosting {
        padding-top: 325px;
        padding-bottom: 366px;
        background-size: cover;
        background-position: center;
        @include customMq (1200px, 1500px) {
            padding-top: 280px;
            padding-bottom: 315px;
        }
        @include medium {
            padding-top: 180px;
            padding-bottom: 80px;
        }
        @include mobileLg {
            padding-top: 150px;
            padding-bottom: 80px;
        }
        @include mobileSm {
            padding-top: 120px;
            padding-bottom: 50px;
        }
        .banner-content {
            padding-right: 118px;
            margin-bottom: 0;
            @include medium {
                padding-right: 0;
            }
            h1 {
                margin-bottom: 30px;
                @include customMq (1200px, 1500px) {
                    font-size: 53px;
                    br {
                        display: none;
                    }
                }
                @include medium {
                    font-size: 54px;
                    br {
                        display: none;
                    }
                }
                @include mobileLg {
                    font-size: 50px;
                }
            }
            p {
                margin-bottom: 40px;
            }
        }
    }
    &.pos {
        @include tab {
            margin-bottom: 60px;
        }
        padding-top: 220px;
        padding-bottom: 200px;
        @include medium {
            padding-top: 180px;
            padding-bottom: 80px;
        }
        @include mobileLg {
            padding-top: 150px;
            padding-bottom: 80px;
        }
        @include mobileSm {
            padding-top: 120px;
            padding-bottom: 50px;
        }
        .pos-banner-bg {
            background-color: #F3FFF7;
            position: absolute;
            width: 35%;
            height: 100%;
            right: 0;
            top: 0;
            @include tab {
                width: 100%;
            }
        }
        .banner-content {
            @include tab {
                margin-bottom: 60px;
            }
            h1 {
                font-size: 70px;
                color: $black;
                margin-bottom: 10px;
                @include customMq(1200px, 1500px) {
                    br {
                        display: none;
                    }
                }
                @include medium {
                    font-size: 60px;
                }
                @include mobileLg {
                    font-size: 50px;
                }
                
                @include mobileXs {
                    font-size: 44px;
                }
            }
            p {
                margin-bottom: 44px;
            }
        }
    }
    &.app {
        padding-top: 180px;
        padding-bottom: 160px;
        @include medium {
            padding-bottom: 80px;
        }
        @include mobileLg {
            padding-top: 150px;
            padding-bottom: 80px;
        }
        @include mobileSm {
            padding-top: 120px;
            padding-bottom: 50px;
        }
        .pos-banner-bg {
            background-color: #F3FFF7;
            position: absolute;
            width: 35%;
            height: 100%;
            right: 0;
            top: 0;
            @include tab {
                width: 100%;
            }
        }
        .banner-content {
            @include tab {
                margin-bottom: 60px;
            }
            h1 {
                font-size: 80px;
                color: $black;
                line-height: 1.1;
                margin-bottom: 30px;
                @include customMq(1200px, 1500px) {
                    br {
                        display: none;
                    }
                }
                @include medium {
                    font-size: 76px;
                    br {
                        display: none;
                    }
                }
                @include mobileLg {
                    font-size: 48px;
                }
            }
            p {
                margin-bottom: 34px;
            }
        }
    }
    &.app2 {
        background-color: #F5F8FF;
        padding-top: 130px;
        padding-bottom: 80px;
        @include medium {
            padding-top: 180px;
        }
        @include mobileSm {
            padding-top: 120px;
            padding-bottom: 50px;
        }
        .banner-content {
            .content {
                margin-bottom: 34px;
            }
            animation: fadeInUp10 .7s ease-in forwards;
            padding-right: 120px;
            @include customMq(991px, 1500px) {
                padding-right: 0;
            }
            @include tab {
                margin-bottom: 60px;
                padding-right: 0;
            }
            .star-rating {
                font-size: 16px;
                span {
                    font-weight: $light;
                }
            }
            h1 {
                font-size: 60px;
                color: $black;
                line-height: 1.18;
                margin-bottom: 20px;
                @include customMq(1200px, 1500px) {
                    br {
                        display: none;
                    }
                }
                @include tab {
                    br {
                        display: none;
                    }
                }
                @include mobileLg {
                    font-size: 48px;
                }
            }
            p {
                margin-bottom: 25px;
            }
        }
        .banner-img {
            padding-right: 50px;
            animation: rotate .7s ease-in forwards;
            @include tab {
                padding-right: 0;
            }
        }
    }
    &.proto {
        padding-top: 150px;
        padding-bottom: 150px;
        position: relative;
        z-index: 1;
        @include medium {
            padding-top: 180px;
            padding-bottom: 120px;
        }
        @include mobileSm {
            padding-top: 120px;
            padding-bottom: 50px;
        }
        .proto-bg {
            left: 10px;
            top: 0;
            position: absolute;
            z-index: -1;
            height: 100%;
            @include tab {
                left: 0px;
            }
        }
        .banner-content {
            animation: fadeInUp10 1.1s linear 0s 1 forwards;
            h1 {
                font-size: 60px;
                @extend .proto_c1;
                line-height: 1.18;
                margin-bottom: 30px;
                @include customMq(1200px, 1500px) {
                    br {
                        display: none;
                    }
                }
                @include tab {
                    br {
                        display: none;
                    }
                }
                @include mobileLg {
                    font-size: 48px;
                }
                @include mobileSm {
                    font-size: 40px;
                }
            }
            p {
                margin-bottom: 25px;
            }
        }
        .banner-img {
            padding-left: 35px;
            @include tab {
                padding-left: 0px;
            }
            img {
                max-width: none;
                @include tab {
                    max-width: 100%;
                }
            }
        }
    }
}

.banner-content {
    position: relative;
    z-index: 2;

    h1 {
        font-size: 50px;
        font-weight: $semi-bold;
        color: $black;
        text-transform: capitalize;
        margin-bottom: 8px;
    }
    .content {
        margin-bottom: 44px;

        P {
            max-width: 560px;
            font-weight: $light;
            &.affiliate {
                color: #81724D;
            }
        }
    }
    .person {
        margin-bottom: 10px;
        display: inline-block;
        span {
            color: $crs_c1;
            font-weight: $semi-bold;
        }
    }
}

/* Star Rating */
.star-rating {
    margin-bottom: 6px;
    i {
        @extend .c2;
    }

    span {
        display: inline-block;
        margin-left: 5px;
    }
    &.mb12 {
        margin-bottom: 12px;
    }
} 

.pay-img-wrap {
    animation: fadeInUp .8s linear 1.4s backwards;
    position: relative;
    margin-top: -400px;
    z-index: 2;
    @include customMq(1200px, 1602px) {
        margin-top: -230px;
    }
    @include medium {
        margin-top: -100px;
    }
    @include tab {
        margin-top: -180px;
    }
}

/* Course Info */
.course-info {
    text-align: center;
    margin-bottom: 30px;

    h3 {
        font-size: 30px;
        @extend .hind;
        font-weight: $medium;
        color: $black;
        text-transform: capitalize;
        margin-bottom: 15px;
        @include medium {
            font-size: 28px;
        }
    }
    p {
        text-transform: capitalize;
        color: #5C6B56;
    }
}
  
/* App Page Banner Icons */
.app-line, .app-circle, .app-triangle, .app-hexa {
    position: absolute;
    z-index: -1;
}
.app-line {
    left: 7%;
    top: 28%;
    animation: levitate 15s linear alternate backwards;
}
.app-circle {
    left: 2%;
    top: 53%;
    animation: zoomDown 3s linear 0s infinite both;
}
.app-triangle {
    left: 32%;
    top: 16%;
    animation: spin 180s linear 1s infinite alternate both;
}
.app-hexa {
    left: 38%;
    top: 76%;
    animation: spin 10s linear infinite both;
}