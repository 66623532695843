/* ************************
   04.14: JOB
   ********************* */
.filter-control {
    @include tab {
        flex-direction: column;
        .filter-title {
            margin-bottom: 20px;
        }
    }
}
.filter {
    @include mobileSm {
        flex-direction: column;
    }
}
.filter-title {
    h3 {
        font-weight: $semi-bold;
        font-size: 30px;
        color: $black;
    }
}

/* Custom Select Dropdown */
.custom-select {
    position: relative;
    background: $white;
    height: 44px;
    padding: 0;
    background: #fff;
    border: none;
    width: auto;
    &:not(:last-child) {
        margin-right: 40px;
        @include mobileSm {
            margin-right: 0;
            margin-bottom: 20px;
        }
    }
    select {
        display: none;
    }
    .select-items div,.select-selected {
        padding: 8px  20px;
        cursor: pointer;
        color: $text-color;
        user-select: none;
        text-transform: capitalize;
    }
    .select-selected {
        padding: 8px 70px 8px 20px;
        color: $black;
        background-color: $white;
        border: 1px solid #eeeeee;
        @extend .trans2;
        font-size: 18px;
        &:after {
            position: absolute;
            content:"\f107";
            top: 8px;
            right: 30px;
            width: 0;
            height: 0;
            color: $black;
            font-family: fontAwesome;
        }
        &.select-arrow-active {
            @extend .email_c1-bo;
            &:after {
                content:"\f106";
            }
        }
    }
    .select-items {
        position: absolute;
        background-color: $white;
        @extend .box-shadow;
        top: calc(100% + 1px);
        left: 0;
        right: 0;
        z-index: 99;
        font-size: 16px;
        padding-top: 20px;
        padding-bottom: 15px;
        div:hover, .same-as-selected {
            @extend .email_c1;
        }
    }
    .select-hide {
        display: none;
    }
}

/* Single Job Box */
.single-job {
    border: 1px solid #eeeeee;
    padding: 40px 40px 60px;
    margin-bottom: 60px;
    @extend .trans3;
    &.style--two {
        padding: 70px 60px;
        margin-bottom: 30px;
        @include tab {
            flex-direction: column;
        }
        @include mobileMd {
            padding: 50px 40px;
        }
        @include mobileMd {
            padding: 40px 20px;
        }
        .job-logo {
            margin-bottom: 0px;
            flex: 0 0 29%;
            @include medium {
                flex: 0 0 35%;
            }
            @include tab {
                flex: 0 0 100%;
                margin-bottom: 20px;
            }
        }
        .job-content {
            .meta-box {
                margin-bottom: 0px;
            }
            @include tab {
                margin-bottom: 20px;
            }
        }
        .job-button {
            margin-left: auto;
            @include tab {
                margin-left: 0;
            }
        }
    }
    &:hover {
        @extend .box-shadow;
        border-color: transparent;
    }
    .job-logo {
        margin-bottom: 30px;
    }
    .job-content {
        .job-category {
            display: block;
            font-size: 16px;
            @extend .email_c1;
            margin-bottom: 10px;
        }
        h3 {
            font-size: 26px;
            font-weight: $medium;
            color: $black;
            margin-bottom: 15px;
            line-height: 1.1;
            a {
                color: $black;
                &:hover {
                    @extend .email_c1;
                }
            }
        }
        .meta-box {
            margin-bottom: 20px;
            p {
                display: inline-flex;
                align-items: center;
                font-size: 16px;
                font-weight: $regular;
                margin-bottom: 0;
                &:not(:last-child) {
                    margin-right: 40px;
                }
                img {
                    margin-right: 10px;
                }
            }
        }
    }
}

/* Job Details */
.job-details {
    @include tab {
        margin-bottom: 70px;
    }
}
.job-details-content {
    h3 {
        font-size: 30px;
        font-weight: $semi-bold;
        color: $black;
        margin-bottom: 22px;
    }
    p {
        margin-bottom: 15px;
    }
}
.job-details-img {
    margin-bottom: 70px;
}
.post-meta {
    margin-bottom: 45px;
    li {
        display: inline-block;
        font-weight: $light;
        img, i {
            margin-right: 10px;
        }
        &:not(:last-child) {
            margin-right: 30px;
            margin-bottom: 5px;
        }
    }
}






/* Modal */
.form-group, .widget {
    margin-bottom: 0;
    .custom-select {
        margin-bottom: 20px;
    }
    .col-form-label {
        padding: 0;
        margin-bottom: 15px;
        line-height: 1;
        @extend .hind;
        text-transform: capitalize;
    }
    .theme-input-style {
        font-size: 18px;
        @extend .text-color;
        font-weight: $light;
    }
    .custom-select {
        .select-selected {
            @extend .text-color;
            font-size: 18px;
            font-weight: $light;

            &:after {
                @extend .text-color;
            }
            &.select-arrow-active {
                border-color: #eeeeee !important;
            }
        }
    }
    textarea.theme-input-style {
        height: 340px;
        @include mobileMd {
            height: 200px;
        }
        @include mobileSm {
            height: 150px;
        }
    }
}
.modal-dialog {
    max-width: 930px;
    margin: 50px auto;
    @extend .box-shadow;
    @include tab {
        max-width: 700px;
    }
    @include mobileLg {
        max-width: 520px;
    }
    @include mobileMd {
        max-width: 100%;
        margin: 50px 15px;
    }
}
.modal-content {
    border: none;
    padding: 64px 104px;
    border-radius: 0;
    @include tab {
        padding: 50px 60px;
    }
    @include mobileMd {
        padding: 40px;
    }
    @include mobileSm {
        padding: 24px 0px;
    }
}
.modal-header {
    border: none;
    margin-bottom: 50px;
    button.close {
        position: absolute;
        top: -25px;
        left: auto;
        right: -25px;
        width: 50px;
        height: 50px;
        @extend .radius;
        @extend .email_c2-bg;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0;
        opacity: 1;
        @extend .trans3;
        @include mobileMd {
            width: 30px;
            height: 30px;
            right: -10px;
            top: -10px;
            .svg {
                width: 10px;
                height: 10px;
            }
        }
    }
    .modal-title {
        margin: 0 auto;
        .modal-title-icon {
            width: 72px;
            height: 72px;
            @extend .radius;
            @extend .email_c1-bg;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto 25px;
        }
        h3 {
            font-size: 26px;
            font-weight: $semi-bold;
            color: $black;
        }
    }
}

.file-drop-area {
    background-color: #FBFBFB;
    color: #D7D7EF;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    padding: 40px 20px 35px;
    border: 1px dashed $text-color;
    border-radius: 3px;
    margin-bottom: 40px;
    img, .svg {
        margin-bottom: 15px;
    }
}

.fake-btn {
flex-shrink: 0;
font-size: 12px;
text-transform: capitalize;
@extend .hind;
font-size: 22px;
font-weight: $semi-bold;
@extend .text-color;
line-height: 1;
}

.file-msg {
font-size: small;
font-weight: 300;
line-height: 1.4;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
}

.file-input {
position: absolute;
left: 0;
top: 0;
height: 100%;
width: 100%;
cursor: pointer;
opacity: 0;
&:focus {
    outline: none;
}
}