/* ************************
   03.1: Header
   ********************* */
.header {
    @extend .trans2;
    &.scrollDown {
        animation: fadeInDown .5s linear;
    }
    &.scrollUp {
        transform: translateY(-100%);
        box-shadow: none !important;
    }
    &.payment-header {
        .header-main {
            margin-top: 15px;
            animation: fadeInUp .5s linear .9s backwards;
            &.sticky {
                margin-top: 0;
                .header-btn {
                    .btn-pay.s-btn {
                        color: $white !important;
                        background: $pay_c2;
                        &:hover {
                            background: #BBAAFF;
                            color: $white;
                        }
                    }
                }
            }
        }
    }
    &.inner-page {
        background: $white;
    }
    min-height: 100px;
    .header-main {
        display: flex;
        align-items: center;
        min-height: 100px;
        &.sticky {
            background-color: $white;
            @extend .box-shadow;
            .logo {
                .default-logo {
                    display: none;
                }
                .sticky-logo {
                    display: inline-block;
                }
            }
            .main-menu .nav > li {
                
                position: relative;
                &.current-menu-parent,
                &.current-menu-item,
                &:hover {
                    > a {
                        color: $c1 !important;
                    }
                }
                > a {
                    color: $black !important;

                    &:hover {
                        color: $c1 !important;
                    }
                }
            }
        }
        .logo {
            line-height: 1;
            a {
                display: inline-block;
            }
            @extend .trans4;
            .sticky-logo {
                display: none;
            }
        }
        &.style--two {
            &.sticky {
                .main-menu .nav > li {
                    
                    position: relative;
                    &.current-menu-parent,
                    &.current-menu-item,
                    &:hover {
                        > a {
                            color: $crm_c1 !important;
                        }
                    }
                    > a {
                        color: $black !important;
    
                        &:hover {
                            color: $crm_c1 !important;
                        }
                    }
                }
            }
            .main-menu {
                flex: 1;
                .nav {
                    margin: 0 auto;
                    > li {
                        > a {
                            color: $black;
                        }
                    }
                }
            }
            .main-menu {
                #menu-button {
                    @extend .crm_c1;
                }
                .nav {
                    li {
                        &.current-menu-parent,
                        &.current-menu-item,
                        &:hover {
                            > a {
                                color: $crm_c1;
                            }
                        }
                        a {
                            &:hover {
                                @extend .crm_c1;
                            }
                        }
                    }
                }
            }
        }
        &.email {
            &.sticky {
                .main-menu .nav > li {
                    
                    position: relative;
                    &.current-menu-parent,
                    &.current-menu-item,
                    &:hover {
                        > a {
                            color: $email_c1 !important;
                        }
                    }
                    > a {
                        color: $black !important;
    
                        &:hover {
                            color: $email_c1 !important;
                        }
                    }
                }
                .header-btn {
                    .e-btn {
                        background: $email_c1;
                        &:after {
                            border: 1px solid $black;
                        }
                        &:hover {
                            background: $email_c1;
                        }
                    }
                    .btn-book.line-btn {
                        color: $email_c2;
                        &:before,
                        &:after {
                            background: $email_c2;
                        }
                    }
                }
            }
            .main-menu {
                flex: 1;
                .nav {
                    margin: 0 auto;
                    > li {
                        > a {
                            color: $black;
                        }
                    }
                }
            }
            .main-menu {
                #menu-button {
                    @extend .email_c1;
                }
                .nav {
                    li {
                        &.current-menu-parent,
                        &:hover {
                            > a {
                                color: $email_c1;
                            }
                        }
                        &.current-menu-item {
                            > a {
                                color: $email_c2;
                            }
                        }
                        a {
                            &:hover {
                                @extend .email_c2;
                            }
                        }
                        
                        &.has-sub-item {
                            &:after {
                                content:"+";
                                position: absolute;
                                right: 30px;
                                top: 50%;
                                transform: translateY(-50%);
                                z-index: 1;
                                font-size: 30px;
                                color: $black;
                                @extend .trans5;
                            }
                            &:hover {
                                &:after {
                                    content: '-';
                                    color: $email_c2;
                                }
                            }
                        }
                        
                        ul {
                            li {
                                @extend .c1;
                                &:hover {
                                    > a {
                                        color: $email_c2;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &.style--three {
            &.sticky {
                .main-menu .nav > li {
                    
                    position: relative;
                    &.current-menu-parent,
                    &.current-menu-item,
                    &:hover {
                        > a {
                            color: $crs_c1 !important;
                        }
                    }
                    > a {
                        &:hover {
                            color: $crs_c1 !important;
                        }
                    }
                }
            }
            .main-menu {
                flex: 1;
                .nav {
                    margin-left: auto;
                    margin-right: 26px;
                    > li {
                        > a {
                            color: #5C6B56;
                        }
                    }
                }
            }
            .main-menu {
                #menu-button {
                    @extend .crs_c1;
                }
                .nav {
                    li {
                        &.current-menu-parent,
                        &.current-menu-item,
                        &:hover {
                            > a {
                                color: $crs_c1;
                            }
                        }
                        a {
                            &:hover {
                                @extend .crs_c1;
                            }
                        }
                    }
                }
            }
        }
        &.style--four {
            #menu-button {
                span {
                    &,
                    &:before,
                    &:after {
                        background: $white;
                    }
                }
            }
            &.sticky {
                #menu-button {
                    span {
                        &,
                        &:before,
                        &:after {
                            background: $pay_c2;
                        }
                    }
                }
                .main-menu .nav > li {
                    position: relative;
                    &.current-menu-parent,
                    &.current-menu-item,
                    &:hover {
                        > a {
                            color: $pay_c2 !important;
                        }
                    }
                    > a {
                        &:hover {
                            color: $pay_c2 !important;
                        }
                    }
                }
            }
            .main-menu {
                flex: 1;
                .nav {
                    > li {
                        > a {
                            color: $white;
                        }
                    }
                }
            }
            .main-menu {
                .nav {
                    li {
                        &.current-menu-parent,
                        &.current-menu-item,
                        &:hover {
                            > a {
                                color: $pay_c2;
                            }
                        }
                        a {
                            &:hover {
                                @extend .pay_c2;
                            }
                        }
                    }
                }
            }
        }
        &.ebook {
            #menu-button {
                span {
                    &,
                    &:before,
                    &:after {
                        background: $crs_c2;
                    }
                }
            }
            &.sticky {
                .main-menu .nav > li {
                    &.current-menu-parent,
                    &.current-menu-item,
                    &:hover {
                        > a {
                            color: $crs_c2 !important;
                        }
                    }
                    > a {
                        &:hover {
                            color: $crs_c2 !important;
                        }
                    }
                }
                .header-btn {
                    &.book {
                        .btn-book {
                            background: $crs_c2;
                            color: $white;
                            &:hover {
                                background: $book_c1;
                                color: $crs_c2;
                            }
                        }
                        .cart-btn {
                            .svg {
                                g {
                                    path {
                                        fill: $book_c1;
                                    }
                                }
                            }
                        }
                    }
                }
                #menu-button {
                    span {
                        &,
                        &:before,
                        &:after {
                            background: $book_c1;
                        }
                    }
                }
            }
            .main-menu {
                flex: 1;
                .nav {
                    > li {
                        > a {
                            color: $white;
                        }
                    }
                }
            }
            .main-menu {
                margin-right: 26px;
                .nav {
                    li {
                        &.current-menu-parent,
                        &.current-menu-item,
                        &:hover {
                            > a {
                                color: $crs_c2;
                            }
                        }
                        a {
                            &:hover {
                                @extend .crs_c2;
                            }
                        }
                    }
                }
            }
        }
        &.plugin {
            #menu-button {
                span {
                    &,
                    &:before,
                    &:after {
                        background: $plug_c1;
                    }
                }
            }
            &.sticky {
                .main-menu .nav > li {
                    &.current-menu-parent,
                    &.current-menu-item,
                    &:hover {
                        > a {
                            color: $plug_c1 !important;
                        }
                    }
                    > a {
                        &:hover {
                            color: $plug_c1 !important;
                        }
                    }
                }
            }
            .main-menu {
                flex: 1;
                .nav {
                    > li {
                        > a {
                            color: $white;
                        }
                    }
                }
            }
            .main-menu {
                margin-right: 26px;
                .nav {
                    li {
                        &.current-menu-parent,
                        &.current-menu-item,
                        &:hover {
                            > a {
                                color: $plug_c1;
                            }
                        }
                        a {
                            &:hover {
                                @extend .plug_c1;
                            }
                        }
                    }
                }
            }
        }
        &.management {
            #menu-button {
                span {
                    &,
                    &:before,
                    &:after {
                        background: $management_c2;
                    }
                }
            }
            &.sticky {
                .main-menu .nav > li {
                    &.current-menu-parent,
                    &.current-menu-item,
                    &:hover {
                        > a {
                            color: $management_c2 !important;
                        }
                    }
                    > a {
                        &:hover {
                            color: $management_c2 !important;
                        }
                    }
                }
            }
            .main-menu {
                flex: 1;
                .nav {
                    > li {
                        > a {
                            color: $black;
                        }
                    }
                }
            }
            .main-menu {
                //margin-right: 26px;
                .nav {
                    li {
                        &.current-menu-parent,
                        &.current-menu-item,
                        &:hover {
                            > a {
                                color: $management_c2;
                            }
                        }
                        a {
                            &:hover {
                                @extend .management_c2;
                            }
                        }
                    }
                }
            }
        }
        &.analytic {
            &.sticky {
                .main-menu .nav > li {
                    &.current-menu-parent,
                    &.current-menu-item,
                    &:hover {
                        > a {
                            color: $analytic_c2 !important;
                        }
                    }
                    > a {
                        &:hover {
                            color: $analytic_c2 !important;
                        }
                    }
                }
            }
            .main-menu {
                flex: 1;
                .nav {
                    > li {
                        > a {
                            color: $black;
                        }
                    }
                }
            }
            .main-menu {
                margin-right: 26px;
                .nav {
                    li {
                        &.current-menu-parent,
                        &.current-menu-item,
                        &:hover {
                            > a {
                                color: $analytic_c2;
                            }
                        }
                        a {
                            &:hover {
                                @extend .analytic_c2;
                            }
                        }
                    }
                }
            }
        }
        &.host {
            #menu-button {
                span {
                    &,
                    &:before,
                    &:after {
                        background: $host_c2;
                    }
                }
            }
            &.sticky {
                .main-menu .nav > li {
                    &.current-menu-parent,
                    &.current-menu-item,
                    &:hover {
                        > a {
                            color: $host_c2 !important;
                        }
                    }
                    > a {
                        &:hover {
                            color: $host_c2 !important;
                        }
                    }
                }
            }
            .main-menu {
                flex: 1;
                .nav {
                    > li {
                        > a {
                            color: $white;
                        }
                    }
                }
            }
            .main-menu {
                .nav {
                    li {
                        &.current-menu-parent,
                        &.current-menu-item,
                        &:hover {
                            > a {
                                color: $host_c2;
                            }
                        }
                        a {
                            &:hover {
                                @extend .host_c2;
                            }
                        }
                    }
                }
            }
        }
        &.app {
            margin-top: 20px;
            @include tab {
                margin-top: 0;
            }
            #menu-button {
                span {
                    &,
                    &:before,
                    &:after {
                        background: $app_c1;
                    }
                }
            }
            &.sticky {
                margin-top: 0;
                .main-menu .nav > li {
                    &.current-menu-parent,
                    &.current-menu-item,
                    &:hover {
                        > a {
                            color: $app_c1 !important;
                        }
                    }
                    > a {
                        &:hover {
                            color: $app_c1 !important;
                        }
                    }
                }
            }
            .main-menu {
                margin-right: 25px;
                .nav {
                    > li {
                        > a {
                            color: $black;
                        }
                    }
                }
            }
            .main-menu {
                .nav {
                    li {
                        &.current-menu-parent,
                        &.current-menu-item,
                        &:hover {
                            > a {
                                color: $app_c1;
                            }
                        }
                        a {
                            &:hover {
                                @extend .app_c1;
                            }
                        }
                    }
                }
            }
        }
        &.app2 {
            margin-top: 20px;
            @include tab {
                margin-top: 0;
            }
            #menu-button {
                span {
                    &,
                    &:before,
                    &:after {
                        background: $app2_c2;
                    }
                }
            }
            &.sticky {
                margin-top: 0;
                .main-menu .nav > li {
                    &.current-menu-parent,
                    &.current-menu-item,
                    &:hover {
                        > a {
                            color: $app2_c2 !important;
                        }
                    }
                    > a {
                        &:hover {
                            color: $app2_c2 !important;
                        }
                    }
                }
                .btn.white-btn {
                    color: $white;
                    position: relative;
                    z-index: 1;
                    overflow: hidden;
                    @include vertical-gradient(#FF6773, #FF896B);
                    &:after {
                        left: 0;
                        top: -100%;
                        width: 100%;
                        height: 100%;
                        content: "";
                        position: absolute;
                        border-radius: 0 5px 5px 0;
                        z-index: -1;
                        @extend .trans3;
                        @include vertical-gradient(#FF896B, #FF6773);
                    }
                    &:hover {
                        color: $white;
                        &:after {
                            top: 0;
                        }
                    }
                }
            }
            .main-menu {
                margin-right: 25px;
                .nav {
                    > li {
                        > a {
                            color: $black;
                        }
                    }
                }
            }
            .main-menu {
                .nav {
                    li {
                        &.current-menu-parent,
                        &.current-menu-item,
                        &:hover {
                            > a {
                                color: $app2_c2;
                            }
                        }
                        a {
                            &:hover {
                                @extend .app2_c2;
                            }
                        }
                    }
                }
            }
        }
        &.proto {
            margin-top: 20px;
            @include tab {
                margin-top: 0;
            }
            #menu-button {
                span {
                    &,
                    &:before,
                    &:after {
                        background: $proto_c2;
                    }
                }
            }
            &.sticky {
                margin-top: 0;
                .main-menu .nav > li {
                    &.current-menu-parent,
                    &.current-menu-item,
                    &:hover {
                        > a {
                            color: $proto_c2 !important;
                        }
                    }
                    > a {
                        &:hover {
                            color: $proto_c2 !important;
                        }
                    }
                }
            }
            .main-menu {
                margin-right: 25px;
                .nav {
                    > li {
                        > a {
                            color: $black;
                        }
                    }
                }
            }
            .main-menu {
                .nav {
                    li {
                        &.current-menu-parent,
                        &.current-menu-item,
                        &:hover {
                            > a {
                                color: $proto_c2;
                            }
                        }
                        a {
                            &:hover {
                                @extend .proto_c2;
                            }
                        }
                    }
                }
            }
        }

        .main-menu {
            #menu-button {
                display: none;
                font-size: 28px;
                cursor: pointer;
                @extend .c1;
            }
            .nav {
                li {
                    position: relative;
                    &.current-menu-parent,
                    &.current-menu-item,
                    &:hover {
                        > a {
                            color: $c1;
                        }
                    }
                    a {
                        display: block;
                        line-height: 1;
                        position: relative;
                        z-index: 1;
                        text-transform: capitalize;
                        font-weight: $regular;
                        &:hover {
                            @extend .c1;
                        }
                    }
                    ul {
                        position: absolute;
                        left: 0;
                        top: -2000px;
                        opacity: 0;
                        visibility: hidden;
                        background-color: $white;
                        width: 270px;
                        padding: 24px 0;
                        transform: translate3d(0, -5px, 0);
                        transition: transform 0.2s ease-out;
                        z-index: 9;
                        @extend .box-shadow;
                        li {
                            display: block;
                            width: 100%;
                            line-height: 1;
                            font-size: 18px;
                            @extend .c1;
                            &:hover {
                                > a {
                                    margin-left: 5px;
                                    opacity: 0.8;
                                }
                            }
                            a {
                                padding: 13px 40px;
                                color: $black;
                            }
                            ul {
                                top: 0;
                                left: calc(100% + 20px);
                                @extend .trans2;
                            }
                            &:hover {
                                > ul {
                                    opacity: 1;
                                    visibility: visible;
                                    top: 0;
                                    left: 100%;
                                }
                            
                            }
                        }
                    }
                }
                > li {
                    display: inline-block;
                    
                    > a {
                        line-height: 100px;
                        position: relative;
                        color: #81724D;
                        font-size: 20px;
                        padding: 0 24px;

                        @include medium {
                            padding: 0 10px;
                        }
                    }
                    &:hover {
                        > ul {
                            opacity: 1;
                            visibility: visible;
                            top: 100%;
                            transform: translate3d(0, 0, 0);
                        }
                    }
                }
            }
            .offcanvas-trigger {
                display: inline-block;
                width: 30px;
                cursor: pointer;
                span {
                    display: block;
                    height: 3px;
                    @extend .c1-bg;
                    @extend .trans3;
                    &:nth-child(2) {
                        margin: 5px 0;
                        margin-left: 5px;
                    }
                    &:nth-child(3) {
                        margin-left: 10px;
                    }
                }
                &:hover {
                    span {
                        margin-left: 0 !important;
                    }
                }
            }
        }
        
        &:not(.sticky) {
            &.text-white {
                .main-menu {
                    #menu-button {
                        span,
                        span:before,
                        span:after {
                            background-color: $white;
                        }
                    }
                    .nav {
                        > li {
                            &.current-menu-item,
                            &.current-menu-parent,
                            &:hover {
                                > a {
                                    opacity: .8;
                                    color: $white !important;
                                }
                            }
                        }
                    }
                    .offcanvas-trigger {
                        span {
                            background-color: #fff;
                        }
                    }
                }
            }
        }
    }
}

/* Offcanvas Overlay */
.offcanvas-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #000;
    opacity: 0;
    visibility: hidden;
    @extend .trans3;
    z-index: 9998;
    &.opened {
        opacity: .3;
        visibility: visible;
    }
}

/* Offcanvas Panel */
#mobile_menu {
    position: fixed;
    width: 400px;
    height: 100vh;
    left: auto;
    right: 0;
    top: 0;
    z-index: 9999;
    background-color: #fff;
    padding: 50px;
    @extend .box-shadow;
    overflow: hidden;
    overflow-y: auto;
    @extend .trans3;
    transform: translateX(100%);
    @include mobileMd {
        width: 100%;
    }

    &.opened {
        transform: translateX(0);
    }

    .offcanvas-header {
        margin-bottom: 50px;

        .offcanvas-close {
            cursor: pointer;
        }
    }

    .mobile-main-menu {
        .nav {
            li {
                display: block;
                &.current-menu-parent,
                &.current-menu-item {
                    > a {
                        @extend .c1;
                    }

                    span.sub-menu {
                        a {
                            color: $black;
                        }
                    }
                }

                a { 
                    display: block;
                    color: #81724D;
                    font-size: 20px;
                    padding: 8px 0px;
                    text-transform: capitalize;
                    &:hover {
                        @extend .c1;
                    }
                }

                .sub-menu {
                    padding-left: 20px;
                    margin-top: 10px;

                    a {
                        font-size: 18px;
                    }
                }
            }

            span.submenu-button {
                width: 48px;
                height: 48px;
                position: absolute;
                right: 30px;
                cursor: pointer;

                &:after {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    @include fa('\f0da');
                }

                &.sub-menu-oppened {
                    &:after {
                        @include fa('\f0d7');
                    }
                }
            }

            .sub-menu {
                display: none;

                &.sub-menu-oppened {
                    display: block;
                }
            }
        }
        &.style--two {
            .nav {
                li {
                    &.current-menu-parent,
                    &.current-menu-item {
                        > a {
                            @extend .crm_c1;
                        }
                    }

                    a { 
                        color: $black;
                        &:hover {
                            @extend .crm_c1;
                        }
                    }
                }
            }
        }
        &.email {
            .nav {
                li {
                    &.current-menu-parent,
                    &.current-menu-item {
                        > a {
                            @extend .email_c2;
                        }
                    }

                    a { 
                        color: $black;
                        &:hover {
                            @extend .email_c2;
                        }
                    }
                }
            }
        }
        &.style--three {
            .nav {
                li {
                    &.current-menu-parent,
                    &.current-menu-item {
                        > a {
                            @extend .crs_c1;
                        }
                    }

                    a { 
                        color: #5C6B56;
                        &:hover {
                            @extend .crs_c1;
                        }
                    }
                }
            }
        }
        &.style--four {
            .nav {
                li {
                    &.current-menu-parent,
                    &.current-menu-item {
                        > a {
                            @extend .pay_c2;
                        }
                    }

                    a { 
                        color: $black;
                        &:hover {
                            @extend .pay_c2;
                        }
                    }
                }
            }
        }
        &.ebook {
            .nav {
                li {
                    &.current-menu-parent,
                    &.current-menu-item {
                        > a {
                            @extend .crs_c2;
                        }
                    }

                    a { 
                        color: $black;
                        &:hover {
                            @extend .crs_c2;
                        }
                    }
                }
            }
        }
        &.plugin {
            .nav {
                li {
                    &.current-menu-parent,
                    &.current-menu-item {
                        > a {
                            @extend .plug_c1;
                        }
                    }

                    a { 
                        color: $black;
                        &:hover {
                            @extend .plug_c1;
                        }
                    }
                }
            }
        }
        &.management {
            .nav {
                li {
                    &.current-menu-parent,
                    &.current-menu-item {
                        > a {
                            @extend .management_c2;
                        }
                    }

                    a { 
                        color: $black;
                        &:hover {
                            @extend .management_c2;
                        }
                    }
                }
            }
        }
        &.host {
            .nav {
                li {
                    &.current-menu-parent,
                    &.current-menu-item {
                        > a {
                            @extend .host_c2;
                        }
                    }

                    a { 
                        color: $black;
                        &:hover {
                            @extend .host_c2;
                        }
                    }
                }
            }
        }
        &.analytic {
            .nav {
                li {
                    &.current-menu-parent,
                    &.current-menu-item {
                        > a {
                            @extend .analytic_c2;
                        }
                    }

                    a { 
                        color: $black;
                        &:hover {
                            @extend .analytic_c2;
                        }
                    }
                }
            }
        }
        &.app {
            .nav {
                li {
                    &.current-menu-parent,
                    &.current-menu-item {
                        > a {
                            @extend .app_c1;
                        }
                    }

                    a { 
                        color: $black;
                        &:hover {
                            @extend .app_c1;
                        }
                    }
                }
            }
        }
        &.app2 {
            .nav {
                li {
                    &.current-menu-parent,
                    &.current-menu-item {
                        > a {
                            @extend .app2_c2;
                        }
                    }

                    a { 
                        color: $black;
                        &:hover {
                            @extend .app2_c2;
                        }
                    }
                }
            }
        }
        &.proto {
            .nav {
                li {
                    &.current-menu-parent,
                    &.current-menu-item {
                        > a {
                            @extend .proto_c2;
                        }
                    }

                    a { 
                        color: $black;
                        &:hover {
                            @extend .proto_c2;
                        }
                    }
                }
            }
        }
    }
}



/* Menu Button */
#menu-button {
    height: 25px;
    float: right;
    z-index: 99999;
    position: relative;
    top: 12px;
    cursor: pointer;
    span {
        position: relative;
        &:before,
        &:after {
            position: absolute;
            content: '';
        }
        &,
        &:before,
        &:after {
            display: block;
            width: 25px;
            height: 2px;
            @extend .text-color-bg;
            @extend .trans3;
        }
        &::before {
            top: -7px;
        }
        &::after {
            top:  7px;
        }
    }
}

