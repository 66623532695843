/* ************************
    02.4: Buttons
    ********************* */

/* Default Btn */
.btn {
    display: inline-block;
    padding: 20px 33px;
    font-weight: $medium;
    line-height: 1;
    font-size: 18px;
    position: relative;
    cursor: pointer;
    color: $white;
    @extend .c2-bg;
    @extend .radius-50;
    @extend .trans3;
    @extend .hind;
    border: 1px solid transparent;
    text-transform: capitalize;
    &:hover {
        color: $white;
        background: $c1;
    }
    &.two {
        &:hover {
            background: $white;
            color: #5F52DC;
        }
    }
    &:focus,
    &.focus {
        box-shadow: none;
    }
    /* Secondary Button */
    &.s-btn {
        @extend .c1-bg;
        &:hover {
            color: $white;
            background: $c2;
        }
    }
    /* Animate Btn */
    &.animate-btn {
        @extend .c1;
        background: $white;
        position: relative;
        padding: 12px 20px;
        transform: translateX(-20px);

        &:after {
            width: 48px;
            height: 100%;
            @extend .c2-bg;
            @extend .radius-50;
            opacity: .3;
            position: absolute;
            content: "";
            right: 0;
            top: 0;
            @extend .trans3;
        }
        
        &:hover {
            @extend .c1;
            transform: translateX(0px);
            &:after {
                width: 100%;
                height: 100%;
            }
        }

        i {
            font-size: 20px;
            padding-left: 10px;
            position: relative;
            top: 2px;
        }
    }
    /* Border Button */
    &.border-btn {
        @extend .text-color;
        border: 1px solid $text-color;
        background: transparent;
        &:hover {
            @extend .c2-bg;
            border: 1px solid transparent;
            color: $white;
        }
    }
    /* Pricing Button */
    &.pricing-btn {
        border: 1px solid transparent;
        &:hover {
            border: 1px solid transparent;
        }
    }
    /* App Page btn*/
    &.app-btn {
        padding: 15px 33px;
        background-color: #FFE5DB;
        @extend .app_c2;
        &:hover {
            @extend .app_c2-bg;
            color: $white;
        }
        &.price1 {
            background-color: #E6F4FF;
            color: #4EA8F1;
            &:hover {
                background-color: #4EA8F1;
                color: $white;
            }
        }
        &.price2 {
            background-color: #DCFEF6;
            color: #3BEBC5;
            &:hover {
                background-color: #3BEBC5;
                color: $white;
            }
        }
    }
    &.white-btn {
        background-color: $white;
        color: $black;
        @extend .radius-5;
        @extend .box-shadow;
        padding-top: 15px;
        padding-bottom: 15px;
        @extend .trans2;
        &:hover {
            @extend .app2_c2-bg;
            color: $white;
        }
    }
    &.app2 {
        padding: 12px 26px;
        @extend .radius-5;
        z-index: 1;
        overflow: hidden;
        @include vertical-gradient(#FF6773, #FF896B);
        &:after {
            left: 0;
            top: -100%;
            width: 100%;
            height: 100%;
            content: "";
            position: absolute;
            border-radius: 0 5px 5px 0;
            z-index: -1;
            @extend .trans3;
            @include vertical-gradient(#FF896B, #FF6773);
        }
        &:hover {
            color: $white;
            &:after {
                top: 0;
            }
        }
    }
    &.proto {
        @extend .radius-10;
        padding: 15px 30px;
        @extend .proto_c1-bg;
        &:hover {
            @extend .proto_c2-bg;
        }
        .banner-content {
            padding-right: 0;
        }
    }
    &.download {
        @extend .radius-10;
        padding: 14px 28px;
        @extend .proto_c1-bg;
        position: relative;
        overflow: hidden;
        z-index: 1;
        border: none;
        .svg {
            margin-right: 10px;
            path {
                @extend .trans2;
            }
        }
        &:after {
            position: absolute;
            left: 0;
            top: -100%;
            width: 100%;
            height: 100%;
            background-color: #FFE4DC;
            content: "";
            @extend .trans3;
            z-index: -1;
        }
        &:hover {
            &:after {
                top: 0;
            }
            @extend .proto_c1;
            .svg {
                g {
                    path {
                        fill: $proto_c1;
                    }
                }
            }
        }
        &.s-btn {
            background-color: #FFE4DC;
            @extend .proto_c1;
            &:hover {
                &:after {
                    @extend .proto_c1-bg;
                }
                color: $white;
                .svg {
                    path {
                        fill: $white;
                    }
                }
            }
        }
    }
    &.submit-btn {
        padding: 20px 35px;
        border-radius: 0px;
        font-weight: $semi-bold;
        @extend .email_c2-bg;
        color: $white;
        &:hover {
            @extend .email_c1-bg;
            color: $white;
        }
    }
}

.link-btn {
    &.analytic {
        color: $black;
        text-transform: capitalize;
        font-size: 18px;
        font-weight: $medium;
        @extend .hind;
        &:hover {
            @extend .analytic_c2;
        }
    }
}

.cart-btn {
    position: relative;

    span {
        width: 16px;
        height: 16px;
        @extend .radius;
        @extend .crs_c2-bg;
        color: $white;
        font-size: 12px;
        font-weight: $regular;
        position: absolute;
        right: -7px;
        top: 0;
        display: inline-flex;
        justify-content: center;
        align-items: center;

    }
}

/* Arrow Btn */
.arrow-btn {
    color: $analytic_c2;
    font-size: 18px;
    font-weight: $medium;
    @extend .hind;
    text-transform: capitalize;

    .svg {
        margin-left: 10px;
        width: 24px;
        @extend .trans3;
    }
    &:hover {
        color: $analytic_c2;
        .svg {
            margin-left: 15px;
        }
    }
}

/* Home CRM Btn */
.btn-crm {
    @extend .btn;
    border-radius: 6px;
    background: $crm_c1;
    &:hover {
        background: $crm_c2;
    }
    &.s-btn {
        @extend .crm_c2-bg;
        @extend .crm_c1;
        &:hover {
            color: $crm_c2 !important;
            background: $crm_c1;
        }
        &.two {
            &:hover {
                color: $crm_c1 !important;
                background: $white;
            }
        }
    }
    &.border-btn {
        @extend .crm_c1;
        border: 1px solid $crm_c1;
        &:hover {
            @extend .crm_c2-bg;
            border: 1px solid transparent;
            color: $crm_c1;
        }
        &.style--two {
            color: $white;
            border: 1px solid $plug_c1;
            .svg {
                margin-right: 5px;
            }
            &:hover {
                @extend .plug_c1-bg;
                border: 1px solid transparent;
                color: $white;
                .svg {
                    margin-right: 5px;
                    path {
                        fill: $plug_c2;
                    }
                }
            }   
        }
        &.style--three {
            color: $white;
            border: 1px solid $white;
            .svg {
                margin-right: 5px;
                path {
                    fill: $white;
                }
            }
            &:hover {
                @extend .host_c2-bg;
                border: 1px solid transparent;
                color: $white;
            }   
        }
    }
    &.btn-view-more {
        background: transparent;
        color: $crm_c1;

        .svg {
            margin-left: 5px;
            line, path {
                @extend .trans3;
            }
        }

        &:hover {
            color: $crm_c2;
            .svg {
                animation: arrowRight .8s linear .2s infinite;
                line, path {
                    stroke: $crm_c2;
                }
            }
        }
    }
}

/* Home Corse & Plugins Btn */
.btn-crs {
    @extend .btn;
    border-radius: 6px;
    background: $crs_c1;
    padding: 15px 33px;
    &.plug {
        background: $plug_c2;
        color: #4E2FA9;
        &:hover {
            background: $plug_c1;
            color: $white;
        }
    }
    &.host {
        background: $host_c2;
        color: $white;
        overflow: hidden;
        position: relative;
        &:hover {
            background: #BDC2FC;
            color: $host_c1;
        }
    }
    &.host3 {
        background: rgba($host_c2, 0.3);
        color: $host_c2;
        &:hover {
            background: $host_c2;
            color: $white;
        }
    }
    &:hover {
        background: $crs_c2;
    }
    &.s-btn {
        @extend .crs_c2-bg;
        color: $white;
        &:hover {
            color: $white !important;
            background: $crs_c1;
        }
        &.plug {
            background: $plug_c1;
            color: $white;
            &:hover {
                background: $white;
                color: #4E2FA9 !important;
            }
        }
        &.host {
            background: #BDC2FC;
            color: $host_c1;
            &:hover {
                background: $host_c2;
                color: $white;
            }
        }
    }
    &.border-btn {
        color: $black;
        border: 1px solid $crs_c1;
        &:hover {
            @extend .crs_c1-bg;
            border: 1px solid transparent;
            color: $white;
        }
    }
    &.line-btn {
        color: $black;
        background: transparent;
        padding: 0;
        &:before {
            position: absolute;
            content: '';
            background: $black;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 1px;
            transition: .3s;
        }
        &:after {
            position: absolute;
            content: '';
            background: $black;
            left: 0;
            bottom: 0;
            width: 0;
            height: 1px;
            transition: .3s;
            transition-delay: .3s;
        }
        &:hover {
            background: transparent;
            &:before {
                left: auto;
                right: 0;
                width: 0;
            }
            &:after {
                width: 100%;
            }
        }
    }
    &.btn-view-more {
        background: transparent;
        color: $crs_c1;

        .svg {
            margin-left: 5px;
            line, path {
                @extend .trans3;
            }
        }

        &:hover {
            color: $crs_c2;
            .svg {
                line, path {
                    stroke: $crs_c2;
                }
            }
        }
    }
    &.syllabus-btn {
        position: absolute;
        right: 15px;
        top: -10px;
    }
}

/* Home Payment Btn */
.btn-pay {
    @extend .btn;
    border-radius: 0px;
    background: $pay_c2;
    padding: 15px 33px;
    &:hover {
        background: #BBAAFF;
        color: $white;
    }
    &.s-btn {
        background: #BBAAFF;
        color: $white;
        &:hover {
            color: $white !important;
            background: $pay_c2;
        }
    }
    &.border-btn {
        color: $black;
        border: 1px solid $crs_c1;
        &:hover {
            @extend .crs_c1-bg;
            border: 1px solid transparent;
            color: $white;
        }
    }
    &.line-btn {
        color: $black;
        background: transparent;
        padding: 0;
        &:before {
            position: absolute;
            content: '';
            background: $black;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 1px;
            transition: .3s;
        }
        &:after {
            position: absolute;
            content: '';
            background: $black;
            left: 0;
            bottom: 0;
            width: 0;
            height: 1px;
            transition: .3s;
            transition-delay: .3s;
        }
        &:hover {
            background: transparent;
            &:before {
                left: auto;
                right: 0;
                width: 0;
            }
            &:after {
                width: 100%;
            }
        }

    }
    &.btn-view-more {
        background: transparent;
        color: $crs_c1;

        .svg {
            margin-left: 5px;
            line, path {
                @extend .trans3;
            }
        }

        &:hover {
            color: $crs_c2;
            .svg {
                line, path {
                    stroke: $crs_c2;
                }
            }
        }
    }
    &.syllabus-btn {
        position: absolute;
        right: 15px;
        top: -10px;
    }
    &.analytic {
        background: $analytic_c2;
        &:hover {
            background: $analytic_c1;
        }
        &.s-btn {
            background: $analytic_c1;
            &:hover {
                background: $analytic_c2;
            }
        }
    }
    &.pos {
        background: $analytic_c2;
        @extend .radius-5;
        padding: 17px 33px;
        &:hover {
            background: $analytic_c1;
        }
        &.s-btn {
            background: $analytic_c1;
            padding: 17px 33px;
            &:hover {
                background: $analytic_c2;
            }
        }
        &.border-btn {
            color: $black;
            @extend .analytic_c2-bo;
            background-color: transparent;
            &:hover {
                @extend .analytic_c2-bg;
                //border: 1px solid $analytic_c2;
                color: $white;
                //color: $analytic_c1;
            }
            &.two {
                @extend .radius-10;
            }
        }
    }
    &.host {
        background: $host_c3;
        @extend .radius-10;
        &:hover {
            background: $host_c2;
        }
        &.s-btn {
            background: $host_c2;
            padding: 20px 35px;
            &:hover {
                color: $host_c3 !important;
            }
        }
    }
}

/* Home Ebook Btn */
.btn-book {
    @extend .btn;
    padding: 15px 33px;
    background: $white;
    color: $crs_c2;
    &:hover {
        background: $crs_c2;
        color: $white;
    }
    &.s-btn {
        background: $crs_c2;
        color: $white;
        &:hover {
            background: $white;
            color: $crs_c2;
        }
    }
    &.line-btn {
        color: $white;
        background: transparent;
        padding: 0;
        &:before {
            position: absolute;
            content: '';
            background: $white;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 1px;
            transition: .3s;
        }
        &:after {
            position: absolute;
            content: '';
            background: $white;
            left: 0;
            bottom: 0;
            width: 0;
            height: 1px;
            transition: .3s;
            transition-delay: .3s;
        }
        &:hover {
            background: transparent;
            &:before {
                left: auto;
                right: 0;
                width: 0;
            }
            &:after {
                width: 100%;
            }
        }
    }
    &.style--two {
        color: $crs_c2;
        &:before {
            background: $crs_c2;
        }
        &:after {
            background: $crs_c2;
        }
    }
    
    &.style--three {
        color: $book_c1;
        &:before {
            background: $book_c1;
        }
        &:after {
            background: $book_c1;
        }
    }
    
    &.style--four {
        color: $plug_c1;
        &:before {
            background: $plug_c1;
        }
        &:after {
            background: $plug_c1;
        }
    }
}

/* Home Management Btn */
.m-btn {
    @extend .btn;
    &.s-btn {
        @extend .management_c2-bg;
        &:hover {
            background: $management_c1;
        }
    }
    &.border-btn {
        border-color: $management_c2;
        color: $black;
        &:hover {
            background: $management_c2;
            color: $white;
        }
        &.style--two {
            @extend .management_c1-bo;
            @extend .management_c1;
            &:hover {
                @extend .management_c1-bg;
                color: $white;
            }
        }
    }
}


/* Header Button */
.header-btn {
    a {
        &:not(:last-child) {
            margin-right: 36px;
        }
    }
    &.book {
        a {
            display: inline-block;
            &:not(:last-child) {
                margin-right: 50px;
            }
        }
    }
    .btn-crm {
        &.style--two, &.style--three {
            padding: 15px 33px;
        }
    }
    .btn-crs {
        &.style--three {
            padding: 15px 33px;
        }
    }
    .link-btn {
        color: $black;
        @extend .hind;
        font-size: 18px;
        font-weight: $medium;
        position: relative;
        text-transform: capitalize;
        &:hover {
            @extend .c1;
        }

        &.style--two {
            color: $black;
            &:hover {
                @extend .crm_c2;
            }
        }
        &.style--three {
            @extend .crs_c2;
            &:before {
                position: absolute;
                content: '';
                @extend .crs_c2-bg;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 1px;
                transition: .3s;
            }
            &:after {
                position: absolute;
                content: '';
                @extend .crs_c2-bg;
                left: 0;
                bottom: 0;
                width: 0;
                height: 1px;
                transition: .3s;
                transition-delay: .3s;
            }
            &:hover {
                &:before {
                    left: auto;
                    right: 0;
                    width: 0;
                }
                &:after {
                    width: 100%;
                }
            }
        }
    }
    &.management {
        .link-btn {
            position: relative;
            line-height: 1;
            &:not(:last-child) {
                padding-right: 20px;
                margin-right: 16px;
                &:hover {
                    @extend .management_c2;
                }
                &:after {
                    position: absolute;
                    content: '';
                    top: 0;
                    right: -1px;
                    width: 1.5px;
                    height: 100%;
                    background: $black;
                }
            }
            &:last-child {
                @extend .management_c1;
                &:hover {
                    @extend .management_c2;
                }
            }
        }
    }
    &.pos {
        .link-btn {
            position: relative;
            line-height: 1;
            &:not(:last-child) {
                padding-right: 20px;
                margin-right: 16px;
                @extend .analytic_c1;
                &:hover {
                    @extend .analytic_c2;
                }
                &:after {
                    position: absolute;
                    content: '';
                    top: 0;
                    right: -1px;
                    width: 1.5px;
                    height: 100%;
                    background-color: #8A8A8A;
                }
            }
            &:last-child {
                @extend .analytic_c2;
                &:hover {
                    @extend .analytic_c1;
                }
            }
        }
    }
}

/* Banner Btn Group */
.banner-btn-group {
    a {
        display: inline-block;
        &:not(:last-child) {
            margin-right: 26px;
        }
        @include medium {
            &:not(:last-child) {
                margin-right: 20px;
            }
        }
        @include mobileMd {
            &:not(:last-child) {
                margin-right: 10px;
                margin-bottom: 10px;
            } 
        }
    }
}

/* CTA Button Group */
.cta-btn-group {
    @include mobileMd {
        display: inline-flex;
        flex-direction: column;
    }
    a {
        &:not(:last-child) {
            margin-right: 20px;
        }
        @include mobileMd {
            &:not(:last-child) {
                margin-right: 0px;
                margin-bottom: 10px;
            }
        }
    }
    .btn-crs.plug {
        padding: 20px 35px;
    }
}

/* Ebook-btn */
.e-btn {
    @extend .btn-pay;
    top: 6px;
    left: -6px;
    &:after {
        position: absolute;
        left: 6px;
        top: -6px;
        width: 100%;
        height: 100%;
        background: transparent;
        border: 1px solid $white;
        content: "";
        z-index: -1;
        @extend .trans3;
    }
    &:hover {
        background: $email_c2;
        top: 0;
        left: 0;
        &:after {
            left: 0px;
            top: 0px;
        }
    }
    &.s-btn {
        background: $email_c1;
        color: $white;
        &:after {
            border-color: $black;
        }
        &:hover {
            background: $email_c1;
        }
    }
    &.inner {
        &:after {
            border: 1px solid $black;
        }

    }
}

/* Line Btn */
.line-btn {
    @extend .email_c1;
    position: relative;
    padding: 0;
    text-transform: capitalize;
    font-size: 18px;
    font-weight: $semi-bold;
    @extend .hind;
    &:before {
        position: absolute;
        content: '';
        @extend .email_c1-bg;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        transition: .3s;
    }
    &:after {
        position: absolute;
        content: '';
        @extend .email_c1-bg;
        left: 0;
        bottom: 0;
        width: 0;
        height: 1px;
        transition: .3s;
        transition-delay: .3s;
    }
    &:hover {
        @extend .email_c1;
        &:before {
            left: auto;
            right: 0;
            width: 0;
        }
        &:after {
            width: 100%;
        }
    }
    &.analytic {
        @extend .analytic_c2;
        &:before {
            @extend .analytic_c2-bg;
        }
        &:after {
            @extend .analytic_c2-bg;
        }
    }
    &.app {
        @extend .app_c2;
        &:before {
            @extend .app_c2-bg;
        }
        &:after {
            @extend .app_c2-bg;
        }
    }
    &.proto {
        @extend .proto_c2;
        &:before {
            @extend .proto_c2-bg;
        }
        &:after {
            @extend .proto_c2-bg;
        }
    }
    &.job {
        @extend .email_c2;
        &:before {
            display: none;
        }
        &:after {
            width: 60%;
            @extend .email_c2-bg;
        }
        &:hover:after {
            width: 100%;
        }
    }
    &.job-header {
        @extend .email_c2;
        &:before {
            @extend .email_c2-bg;
        }
        &:after {
            @extend .email_c2-bg;
        }
    }
    &.reading-btn {
        color: $black;
        &:before {
            background-color: $black;
        }
        &:after {
            background-color: $black;
        }
    } 

}

/* Download Button */
.download-btn {
    margin-right: 30px;
    display: inline-block;
    padding: 16px 30px !important;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        width: 195px;
    }
    @include mobileMd {
        margin-right: 0;
        margin-bottom: 20px;
    }
    .icon {
        margin-right: 10px;
    }
    .content {
        p {
            line-height: 1;
            color: $white;
            margin-bottom: 5px !important;
            font-size: 12px;
            font-weight: $regular;
        }
        h5 {
            color: $white;
            font-weight: $semi-bold;
        }
    }
}
.download-btn2 {
    display: inline-block;
    padding: 16px 30px !important;
    background: $white !important;
    @extend .box-shadow;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        width: 180px;
    }
    .icon {
        margin-right: 10px;
        .svg {
            path {
                @extend .trans2;
            }
        }
    }
    .content {
        p {
            line-height: 1;
            color: $black;
            margin-bottom: 5px !important;
            font-size: 12px;
            font-weight: $regular;
            @extend .trans2;
        }
        h5 {
            color: $black;
            font-weight: $semi-bold;
            @extend .trans2;
        }
    }
    &:hover {
        .icon {
            margin-right: 10px;
            .svg {
                path {
                    fill: $white;
                }
            }
        }
        .content {
            p {
                color: $white;
            }
            h5 {
                color: $white;
            }
        }
    }
}

/* CTA Button Group Responsive */
.cta-btn-group {
    &.proto {
        @include mobileLg {
            a {
                &:not(:last-child) {
                    margin-right: 0;
                    margin-bottom: 14px;
                }
            }
        }
    }
}