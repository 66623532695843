/* ************************
   01.1: Reset
   ********************* */
*,
*::before,
*::after {
    outline: none !important;
    box-sizing: border-box;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
    
input[type="number"] {
    -moz-appearance: textfield;
}
    
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}
    
::selection {
    text-shadow: none;
    color: #ffffff;
    @extend.c1-bg
}

::placeholder {
    color: $text-color !important;
    opacity: 1 !important;
}
    
iframe {
    max-width: 100%;
}

button:focus,
input:focus,
select:focus,
textarea:focus {
    outline: none !important;
}

input[type=checkbox] {
    margin-right: 5px;
    cursor: pointer;
}

label {
    margin: 0;
}
    
button,
button[type="submit"],
input[type="submit"] {
    border: none;
    background-color: transparent;
    cursor: pointer;
    -webkit-appearance: button;
    -moz-appearance: button;
}
    
select {
    width: 100%;
    max-width: 100%;
    color: $text-color;
}
 
textarea {
    resize: none;
}
    
img {
    max-width: 100%;
    height: auto;
}

a {
    @extend .c1;
    @extend .trans3;
    &:hover {
        @extend .c2
    }
}

a,
a:hover,
a:active,
a:focus,
input,
input:hover,
input:focus,
input:active,
select,
textarea {
    text-decoration: none;
    outline: none !important;
}
    
h1,
h2,
h3,
h4,
h5,
h6 {
    padding: 0;
    margin: 0;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
    font-weight: $bold;
    line-height: 1;
    @extend .c1;
    text-transform: capitalize;
}

p:last-child {
    margin: 0;
}

h1 {
    font-size: 46px;
}
   
h2 {
    font-size: 40px;
}
  
h3 {
    font-size: 24px;
}
  
h4 {
    font-size: 20px;
}

h5 {
    font-size: 18px;
}

h6 {
    font-size: 16px;
}

table {
    width: 100%;
	max-width: 100%;
	th,td {
        padding: 15px 30px;
        @include mobileSm {
            padding: 10px;
        }
	}
    th {
		color: $black;
		font-weight: $bold;
		border-bottom: 1px solid #D1D1D1;
	}
    td {
        border-bottom: 1px solid #F6F6F6;
        a {
            color: $black;
            font-weight: $bold;
            font-size: 14px;
            &:hover {
                color: $email_c2;
            }
        }
    }
}


.list-unstyled {
    margin: 0;
    padding: 0;
    list-style: none;
}

p {
    margin-bottom: 20px;
    font-weight: $light;
    @include mobileMd {
        font-size: 18px;
    }
}

.feature-p {
    font-size: 16px;
    font-weight: $regular;
}


pre {
    border: 1px solid;
    border-radius: 4px;
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 1.6em;
    max-width: 100%;
    overflow: auto;
    padding: 0.8em 1.6em;
}

code {
    background-color: rgba(27,31,35,.05);
    border-radius: 3px;
    font-size: 85%;
    margin: 0;
    padding: .2em .4em;
}
html, body {
    overflow-x: hidden !important;
}

body {
    font-size: 20px;
    line-height: 1.78;
    color: $text-color;
}