/* ************************
   04.3: Calculate Earning
   ********************* */

.ce-content {
    h2 {
        font-weight: $semi-bold;
        margin-bottom: 14px;
        line-height: 1.28;
    }
    p {
        margin-bottom: 30px;
    }

    @include tab {
        margin-bottom: 60px;
    }
}

.customer {
    font-size: 16px;
    font-weight: $regular;
    line-height: 1;
    opacity: .5;
    @include mobileSm {
        font-size: 12px;
    }

    span {
        display: block;
        @extend .hind;
        font-size: 22px;
        font-weight: $semi-bold;
        color: $black;
        margin-bottom: 8px;
        @include mobileSm {
            font-size: 16px;
        }
    }
    &.one {
        opacity: 1;
    }
}

.ui-range-slider {
    padding: 60px;
    @extend .radius-30;
    @include mobileMd {
        padding: 30px;
    }
    p {
        font-weight: $regular;
    }
    strong {
        font-size: 60px;
        font-weight: $semi-bold;
        line-height: 1.3;
    }
    .sale {
        margin-bottom: 38px;
        strong {
            @extend .c2;
            @extend .hind;
            font-weight: $semi-bold;
        }
    }
    .total-earning {
        margin-bottom: 90px;
        strong {
            @extend .c1;
            @extend .hind;
            font-weight: $semi-bold;
        }
    }
}

#slider {
    border: none;
    height: 2px;
    background: #CECECE;
    position: relative;
}
#slider span {
    width: 12px;
    height: 12px;
    background: white;
    border: 2px solid #CECECE;
    border-radius: 50%;
    left: 0;
    top: -6px;
    position: absolute;
    z-index: 999;
}
#slider span.one {
    border-color: $c1;
}
#slider span.two {
    left: 33.33%;
}
#slider span.three {
    left: 66.66%;
}
#slider span.four {
    left: 100%;
}
#slider .focus {
    left: 0;
    top: -1px;
    width: 33.33%;
    height: 4px;
    background: $c1;
    position: absolute;
}
#slider span.ui-slider-handle {
    border-color: $c1;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    top: -10px;
}
