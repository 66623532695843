/* ************************
   04.8: Testimonials
   ********************* */
.crm-testimonial.owl-carousel {
    > .owl-nav {
        @include mobileLg {
            display: none;
        }
        button{
            top: -175px;
            background: transparent !important;
            i {
                display: none;
            }

            .svg {
                g path {
                    @extend .trans3;
                }
            }

            &:hover {
                .svg {
                    animation: arrowRight .8s linear .2s infinite;
                    g path {
                        stroke: $crm_c2;
                    }
                    &.left-svg {
                        animation: arrowLeft .8s linear .2s infinite;
                    }
                }
            }
        }
    }
}

.testimonial-single {
    background-color: $white;
    padding: 60px 40px 55px;
    @extend .radius-20;
    @include mobileMd {
        padding: 40px 20px 35px;
    }

    .ts-top {
        margin-bottom: 20px;
        @include mobileMd {
            flex-direction: column;
        }
    }

    .tst-content {
        .ts-img {
            margin-right: 20px;
            img {
                @extend .radius;
                width: 70px;
                height: 70px;
            }
        }
        .content {
            h4 {
                font-weight: $medium;
                color: $crm_c1;
            }
            span {
                font-size: 14px;
                font-weight: $light;
                display: block;
            }
        }
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            width: 100%;
        }
    }

    .review {
        @include mobileMd {
            margin-top: -15px;
            margin-left: 5px;
        }
        strong {
            @extend .c2;
            font-weight: $semi-bold;
            font-size: 20px;
        }
    }
    .ts-bottom {
        p {
            @extend .feature-p;
            position: relative;
            padding-left: 28px;

            &:before {
                content: '"';
                position: absolute;
                left: -3px;
                top: 0;
                font-size: 60px;
                line-height: 1;
                @extend .crm_c1;
            }
        }
    }
    &.style--two {
        padding: 50px 50px 46px;

        @include medium {
            padding: 50px 20px 46px;
        }
        @include mobileMd {
            padding: 30px 30px 26px;
        }
        .tst-content {
            .ts-img {
                margin-right: 20px;
                img {
                    width: 80px;
                    height: 80px;
                }
            }
            .content {
                .star-rating {
                    margin-bottom: 6px;
                    i {
                        font-size: 16px;
                    }
                }
                h5 {
                    font-weight: $semi-bold;
                    font-size: 18px;
                    color: $c1;
                }
                span {
                    font-size: 14px;
                    font-weight: $light;
                    display: block;
                }
            }
        }
        .ts-bottom {
            p {
                padding-left: 0px;
                font-size: 20px;
                font-weight: $light;
                &:before {
                    display: none;
                }
                @include mobileSm {
                    font-size: 16px;
                }
            }
        }
        &.book {
            background: #FFF3F1;
            border-radius: 0 50px 0 0;
            padding: 60px;
            @include medium {
                padding: 30px;
            }
            .ts-bottom {
                p {
                    font-size: 18px;
                }
            }
        }
        &.plug {
            background: $white;
            @extend .radius-20;
            @extend .box-shadow;
        }
    }
    &.style--three {
        padding: 60px 40px 54px;
        margin-bottom: 30px;
        @extend .box-shadow;
        @include mobileMd {
            padding: 30px 30px 26px;
        }

        .ts-top {
            margin-bottom: 30px;
        }

        .tst-content {
            .ts-img {
                margin-right: 30px;
                max-width: 44%;
                img {
                    width: auto;
                    height: auto;
                    border-radius: 0;
                }
            }
            .content {
                h3 {
                    font-weight: $semi-bold;
                    color: $black;
                }
                span {
                    font-size: 18px;
                    font-weight: $regular;
                    display: block;
                    margin-bottom: 15px;
                }
            }
        }
        @include medium {
            .tst-content {
                justify-content: flex-start;
                .ts-img {
                    margin: 0px;
                    margin-bottom: 30px;
                    max-width: 60%;
                }
            }
        }
        @include tab {
            .tst-content {
                justify-content: center;
                .ts-img {
                    margin-bottom: 0;
                    margin-right: 30px;
                    max-width: 44%;
                }
            }
        }
        @include mobileLg {
            .tst-content {
                justify-content: flex-start;
                .ts-img {
                    margin: 0px;
                    margin-bottom: 30px;
                    max-width: 60%;
                }
            }
        }

        .ts-bottom {
            p {
                padding-left: 0px;
                font-size: 20px;
                font-weight: $light;
                &:before {
                    display: none;
                }
                @include mobileSm {
                    font-size: 16px;
                }
            }
        }
    }
}

/* Book Review Slider */
.book-review-slider {
    .owl-item {
        &:nth-child(even) {
            .testimonial-single {
                margin-top: 50px;
                background: #F9FFF1;
                border-radius: 0 0 0 50px;
                @include tab {
                    margin-top: 0;
                }
            }
        }
    }
}


/* Student Review Slider Rating */
.rating {
    font-size: 20px;
    color: #5C6B56;
    strong {
        font-size: 60px;
        font-weight: $semi-bold;
        @extend .crs_c2;
        line-height: 1.5;
        display: block;
    }
    &.student-rating {
        strong {
            @extend .crs_c1;
        }
        @include mobileMd {
            margin-top: 20px;
        }
    }
}

/* Customer Review Slider Shadow */
.customer-review-slider.owl-carousel {
    .owl-stage-outer {
        padding: 15px;
        margin: -15px;
    }
}

/* Email Page Testimonial Slider */
.email-testimonial-slider {
    .testimonial-item {
        h3 {
            font-size: 26px;
            @extend .email_c2;
            font-weight: $medium;
            margin-bottom: 25px;

            span {
                font-size: 20px;
                font-weight: $light;
                color: $black;
            }
        }
        P {
            color: $black;
            @extend .hind;
            font-size: 22px;
            line-height: 1.6;
            font-weight: $medium;
            @include mobileSm {
                font-size: 16px;
            }
        }
    }
    &.host {
        .testimonial-item {
            h3 {
                font-size: 24px;
                @extend .host_c1;
                margin-bottom: 6px;
            }
            P {
                font-size: 20px;
                font-weight: $light;
                line-height: 1.8;
                margin-bottom: 24px;
                @include mobileSm {
                    font-size: 16px;
                }
            }
        }
    }
    &.app2 {
        .testimonial-item {
            h3 {
                font-size: 20px;
                color: $black;
                font-weight: $semi-bold;
                margin-bottom: 6px;
                span {
                    @extend .ubuntu;
                    font-size: 16px;
                    @extend .text-color;
                    display: block;
                    margin-top: 7px;
                }
            }
            P {
                font-size: 22px;
                font-weight: $light;
                font-style: italic;
                @extend .ubuntu;
                line-height: 1.9;
                margin-bottom: 46px;
                @include mobileSm {
                    font-size: 18px;
                }
            }
        }
    }
}
.email-clients-slider {
    .clients-item {
        padding: 0 20px;
        @include tab {
            padding: 0;
        }
    }
    &.host {
        .clients-item {
            padding: 0;
        }
    }
}
.logo-carousel.email {
    @include medium {
        padding-top: 50px;
    }
    @include mobileLg {
        padding-top: 0px;
    }
}

/* App Page Testimonial Slider */
.app-testimonial-slider {
    background-position: center bottom;
    background-size: auto;
    @include mobileMd {
        background-size: 180% 30%;
    }
    .single-slide {
        text-align: center;
        padding-bottom: 100px;
        @include mobileMd {
            padding-bottom: 40px;
        }
        p {
            max-width: 810px;
            font-size: 24px;
            font-weight: $light;
            color: $black;
            margin: 0 auto 50px;
            font-style: italic;
            @include mobileMd {
                font-size: 18px;
            }
        }
        img {
            margin-bottom: 30px;
            width: auto;
            display: inline-block;
            @include mobileMd {
                width: 200px;
            }
        }
        h4 {
            color: $white;
            font-size: 20px;
            font-weight: $semi-bold;
            text-transform: capitalize;

            span {
                font-size: 18px;
                font-weight: $regular;
                @extend .ubuntu;
                display: block;
                margin-top: 5px;
            }
        }
    }
}

.testimonial-slider.student-review {
    .owl-stage-outer {
        padding: 20px;
        margin: -20px;
        .testimonial-single.style--two {
            @extend .box-shadow;
        }
    }
}