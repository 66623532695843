/* ************************
   03.3: Blog
   ********************* */
/* Single Blog */
.single-blog-item {
    position: relative;
    margin-bottom: 50px;
    .blog-image {
        img {
            display: block;
            width: 100%;
        }
    }
    .blog-content {
        margin-top: 15px;
        .blog-meta {
            a {
                @extend .text-color;
                font-size: 14px;
            }
        }
        h3 {
            line-height: 1.25;
            margin-bottom: 10px;
            margin-top: 10px;
            color: $black;
        }
        p {
            font-weight: $regular;
            font-size: 16px;
            margin-bottom: 0;
        }
    }
}


/* Blog Details */
.post-details {
    font-size: 16px;
    figcaption {
        margin-top: 10px;
    }
    .entry-header {
        .entry-thumbnail {
            margin-bottom: 50px;
        }
        .entry-title {
            font-size: 36px;
            line-height: 1.28;
            margin-bottom: 5px;
            color: $black;
        }
        .entry-meta {
            margin-bottom: 42px;
            li {
                &:not(:last-child) {
                    margin-right: 50px;
                }
                span {
                    font-weight: $bold;
                    margin-right: 7px;
                    font-size: 16px;
                }
                a {
                    @extend .text-color;
                    font-size: 14px;
                    &:hover {
                        @extend .email_c2;
                    }
                }
            }
        }
    }

    .entry-tags,
    .entry-categories {
        ul {
            li {
                &:first-child {
                    @extend .hind;
                    color: $black;
                    font-size: 16px;
                    font-weight: $bold;
                    margin-right: 5px;
                }
                a {
                    @extend .text-color;
                    font-size: 14px;
                    line-height: 1.57;
                    &:not(:last-child) {
                        &:after {
                            content: ',';
                        }
                    }
                    &:hover {
                        @extend .c1;
                    }
                }
            }
        }
    }
}

/* Blog Details Content */
.entry-content,
.comment-content,
.page--content {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        line-height: 1.5;
        margin-bottom: 15px;
        color: $black;
    }
    p {
        margin-bottom: 30px;
        color: $black;
    }
    table {
        margin-bottom: 50px;
    }

    select {
        @extend .theme-input-style;
        margin-bottom: 50px;
    }
    
    > * {
        &:last-child {
            margin-bottom: 0;
        }
    }
    a {
        color: $black;
        font-weight: $bold;
        font-size: 14px;
        &:hover {
            text-decoration: underline;
        }
        i {
            margin-right: 5px;
        }
    }
}

/* Post Author Info */
.post-author {
    padding: 40px 0;
    .author-thumb {
        margin-right: 30px;
    }
    .author-info {
        h4 {
            font-size: 18px;
            margin-bottom: 5px;
            color: $black;
        }
        p {
            font-size: 16px;
        }
    }
}
.post-title {
    color: $black;
}
/* Post Navigation */
.post-navigation {
    .nav-link {
        flex-basis: 50%;
        max-width: 50%;
        .nav-title {
            display: block;
            font-size: 14px;
            font-weight: $bold;
            opacity: .5;
            margin-bottom: 17px;
            color: $black;
            @extend .trans3;
            i {
                margin-right: 10px;
            }
            &.text-right {
                i {
                margin-left: 10px;
            }
            }
        }
        .posted-on {
            margin: 10px 0 2px;
            display: block;
            font-size: 14px;
            @extend .text-color;
        }
        &:hover {
            .nav-title {
                opacity: 1;
            }
        }
    }
}

/* Related Post */
.related-post {
    h4 {
        color: $black;
        margin-bottom: 30px;
    }
}

/* Post Comments */
.comments-area {
    .comments-title {
        margin-bottom: 25px;
        color: $black;
    }
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    .comment {
        .single-comment {
            padding-bottom: 30px;
            margin-bottom: 30px;
            border-bottom: 1px solid #E2E2E2;
            @include mobileSm {
                flex-direction: column;
            }
            .comment-author-image {
                min-width: 65px;
                margin-right: 30px;
                border-radius: 50%;
                overflow: hidden;
                @include mobileSm {
                    margin-right: 0;
                    margin-bottom: 30px;
                }
            }
            &:not(.trackback):not(.pingback) {
                .comment-content {
                    max-width: calc(100% - 95px);
                    width: 100%;
                    @include mobileSm {
                        max-width: 100%;
                    }
                }
            }
            .comment-content {
                .author_name {
                    margin-right: 10px;
                    margin-bottom: 0;
                    font-size: 16px;
                }
                .commented-on {
                    font-size: 12px;
                }
                p {
                    margin: 7px 0 5px;
                    font-size: 16px;
                    color: $text-color;
                    font-weight: $regular;
                }
            }
        }
        ul.children {
            margin-left: 95px;
            @include mobileSm {
                margin-left: 20px;
            }
        }
    }
}

/* Comments Form */
.comment-respond {
    @include tab {
        margin-bottom: 60px;
    }
    .comment-reply-title {
        margin-bottom: 20px;
        color: $black;
    }
    .comment-form {
        .comment-notes {
            margin-bottom: 30px;
            font-size: 16px;
        }
        .theme-input-style {
            margin-bottom: 35px;
        }
        label {
            margin-bottom: 10px;
            @extend .hind;
        }
        .custom-checkbox {
            margin-bottom: 25px;
            label {
                font-size: 16px;
            }
        }
    }
}

/* Responsive Table */
@include tab {
    .responsive_table {
        thead {
            display: none;
        }
        tbody {
            tr {
                display: block;
                &:not(:last-child) {
                    margin-bottom: 50px;
                }
            }
            td {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
    }
}

.labelText {
    @extend .hind;
    color: $black;
    font-size: 18px;
    font-weight: $bold;
}