/* ************************
   04.13: FAQ
   ********************* */
.accordion {
    &.management {
        .accordion-card {
            border-bottom: 1px solid #EEEEEE;
            padding: 38px 0 34px 40px;
            &:first-child {
                border-top: 1px solid #EEEEEE;
            }
            &.active {
                .accordion-title {
                    h3 {
                        &:after {
                            content:"\f106";
                        }
                    }
                }
            }
        }
        .accordion-title {
            position: relative;
            h3 {
                color: $black;
                cursor: pointer;
                font-weight: $medium;
                font-size: 22px;
                position: relative;
                line-height: 1.2;
                &:after {
                    content:"\f107";
                    position: absolute;
                    right: 20px;
                    top: 50%;
                    transform: translateY(-50%);
                    color: $black;
                    font-family: fontAwesome;
                }
            }
            .accordion-whatsign {
                position: absolute;
                left: -40px;
                top: -1px;
            }
        }
        .accordion-content {
            margin-top: 15px;
        }
    }  
}

.quick-links {
    margin-bottom: 40px;
    h3 {
        font-size: 30px;
        font-weight: $medium;
        color: $black;
        margin-bottom: 20px;
    }
    ul {
        @extend .list-unstyled;
        li {
            margin-bottom: 10px;
            a {
                color: $black;
                font-size: 18px;
                i {
                    margin-right: 5px;
                }
                &:hover {
                    @extend .email_c2;
                    margin-left: 5px;
                }
            }
        }
    }
}