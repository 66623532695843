/* ************************
   02.5: Section Title
   ********************* */
.section-title {
    margin-bottom: 60px;
    @include tab {
        margin-bottom: 40px;
    }
    h2 {
        font-size: 40px;
        line-height: 1.35;
        margin-top: -10px;
        margin-bottom: 10px;
        &.black {
            color: $black;
        }
        @include mobileMd {
            font-size: 36px;
        }
    }
    p {
        max-width: 660px;
        margin: 0 auto;
        @include mobileMd {
            font-size: 16px;
        }
    }
    &.style-two {
        margin-bottom: 40px;
    }
    &.crs {
        margin-bottom: 30px;
        @include tab {
            margin-bottom: 10px;
        }
    }
    &.book {
        h2 {
            font-weight: $semi-bold;
        }
    }
    &.style--two {
        margin-bottom: 24px;
        h2 {
            font-size: 40px;
            margin-bottom: 10px;

            &.black {
                color: $black;
            }
            @include mobileMd {
                font-size: 36px;
            }
        }
        p {
            font-size: 20px;
            line-height: 1.67;
            margin-bottom: 20px;
            @include mobileMd {
                font-size: 16px;
            }
            b {
                font-weight: $semi-bold;
                color: #131313;
            }
        }
    }
    &.style--three {
        margin-bottom: 42px;
        h2 {
            margin-bottom: 10px;
            font-weight: $semi-bold;
        }
        p {
            max-width: 910px;
            &.mxw-640 {
                max-width: 640px;
                margin: 0;
            }
        }
    }
    &.analytic {
        &.m-less {
            margin-bottom: 20px;
        }
        h2 {
            font-size: 46px;
            font-weight: $semi-bold;
            margin-bottom: 10px;
            @include mobileMd {
                font-size: 36px;
            }
        }
        p {
            max-width: 840px;
            font-size: 22px;
        }
    }
    &.host {
        margin-bottom: 50px;
        &.m-less {
            margin-bottom: 20px;
        }
        &.mb-40 {
            margin-bottom: 40px;
        }
        h2 {
            font-size: 46px;
            font-weight: $semi-bold;
            margin-bottom: 13px;
            line-height: 1.25;
            @include mobileMd {
                font-size: 36px;
            }
        }
    }
    @include tab {
        br {
            display: none;
        }
    }
    &.author-newsletter {
        margin-bottom: 35px;
    }
    &.app2 {
        margin-bottom: 40px;
        h2 {
            font-size: 50px;
            line-height: 1;
            @include mobileMd {
                font-size: 36px;
            }
        }
    }
    &.proto {
        margin-bottom: 40px;
        h2 {
            margin-bottom: 8px;
            @include mobileLg {
                font-size: 36px;
            }
        }
    }
    &.about {
        h2 {
            font-weight: $semi-bold;
            color: $black;
        }
    }
    &.semi-bold {
        h2 {
            font-weight: $semi-bold;
        }
    }
}