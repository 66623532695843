/* ************************
   01.3: Color
   ********************* */
@include colors($c1, c1);
@include colors($c2, c2);
@include colors($text-color, text-color);

/* CRM Page color */
@include colors($crm_c1, crm_c1);
@include colors($crm_c2, crm_c2);

/* Course Page color */
@include colors($crs_c1, crs_c1);
@include colors($crs_c2, crs_c2);

/* Payment Page color */
@include colors($pay_c1, pay_c1);
@include colors($pay_c2, pay_c2);

/* Ebook Page color */
@include colors($book_c1, book_c1);
@include colors($crs_c2, crs_c2);

/* Plugin Page color */
@include colors($plug_c1, plug_c1);
@include colors($plug_c2, plug_c2);

/* Email Page color */
@include colors($email_c1, email_c1);
@include colors($email_c2, email_c2);

/* Management Page color */
@include colors($management_c1, management_c1);
@include colors($management_c2, management_c2);

/* Analytic Page color */
@include colors($analytic_c1, analytic_c1);
@include colors($analytic_c2, analytic_c2);

/* Hosting Page color */
@include colors($host_c1, host_c1);
@include colors($host_c2, host_c2);
@include colors($host_c3, host_c3);

/* App Landing Page color */
@include colors($app_c1, app_c1);
@include colors($app_c2, app_c2);

/* Learning App Page color */
@include colors($app2_c1, app2_c1);
@include colors($app2_c2, app2_c2);

/* Prototype Page color */
@include colors($proto_c1, proto_c1);
@include colors($proto_c2, proto_c2);