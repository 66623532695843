/* ************************
   02.1: Common Classes
   ********************* */
/* Transitions */
.trans2 {
    @include transition(.2s, linear);
}

.trans3 {
    @include transition(.3s, linear);
}

.trans4 {
    @include transition(.4s, linear);
}

.trans5 {
    @include transition(.5s, linear);
}

/* Font Family */
h1,
h2,
h3,
h4,
h5,
h6,
.hind {
    font-family: $hind;
}

body,
.ubuntu {
    font-family: $ubuntu;
}

/* Text White */
.text-white {
    *,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $white;
    }
} 

/* Border Radius */
.radius-5 {
    border-radius: 5px;
}

.radius-10 {
    border-radius: 10px;
}

.radius-20 {
    border-radius: 20px;
}

.radius-30 {
    border-radius: 30px;
}

.radius-40 {
    border-radius: 40px;
}

.radius-50 {
    border-radius: 50px;
}

.radius-60 {
    border-radius: 60px;
}

.radius {
    border-radius: 50%;
}

/* Box Shadow */
.box-shadow {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.06);
}

.no-shadow {
    box-shadow: none;
}

/* List Check */
.list-check {
    @extend .list-unstyled;
    li {
        font-size: 20px;
        padding-left: 35px;
        position: relative;
        font-weight: $medium;
        &:not(:last-child) {
            margin-bottom: 20px;
        }
        &:after {
            position: absolute;
            @include fa('\f00c');
            line-height: inherit;
            @extend .crm_c2;
            left: 0px;
            top: -2px;
        }
    }
    &.style--two {
        margin-top: 40px;
        margin-bottom: 44px;
        li {
            h3 {
                font-size: 26px;
                color: $black;
                font-weight: $medium;
                margin-bottom: 12px;
            }
            &:not(:last-child) {
                margin-bottom: 30px;
            }
            padding-left: 50px;
            font-weight: $medium;
            &:after {
                left: 0px;
                top: 0px;
                content: url('../img/feature/fb1.png');
            }
            &:nth-child(2) {
                &:after {
                    content: url('../img/feature/fb2.png');
                }
            }
        }
        &.pay {
            margin-top: 40px;
            margin-bottom: 0px;
            li {
                h3 {
                    font-size: 26px;
                    color: $black;
                    font-weight: $medium;
                    margin-bottom: 12px;
                }
                &:not(:last-child) {
                    margin-bottom: 30px;
                }
                padding-left: 50px;
                font-weight: $medium;
                &:after {
                    left: 0px;
                    top: 0px;
                    content: url('../img/icons/pay-a1.png');
                }
                &:nth-child(2) {
                    &:after {
                        content: url('../img/icons/pay-a2.png');
                    }
                }
                &:nth-child(3) {
                    &:after {
                        content: url('../img/icons/pay-a3.png');
                    }
                }
            }
        }
        
        &.pay2 {
            li {
                &:after {
                    left: 0px;
                    top: 0px;
                    content: url('../img/icons/pay-a4.png');
                }
                &:nth-child(2) {
                    &:after {
                        content: url('../img/icons/pay-a5.png');
                    }
                }
                &:nth-child(3) {
                    &:after {
                        content: url('../img/icons/pay-a6.png');
                    }
                }
            }
        }
    }
    &.style--three {
        margin-bottom: 44px;
        li {
            font-size: 16px;
            font-weight: $regular;
            color: $black;
            padding-left: 30px;
            &:not(:last-child) {
                margin-bottom: 5px;
            }
            &:after {
                top: -1px;
                @extend .crs_c1;
            }
        }
        &.price {
            margin-bottom: 32px;
        }
    }
    &.author-meta {
        li {
            font-size: 16px;
            font-weight: $light;
            &:not(:last-child) {
                margin-bottom: 5px;
            }
            &:after {
                content: url('../img/icons/a1.png');
                top: 4px;
            }
            &:nth-child(2) {
                &:after {
                    content: url('../img/icons/a2.png');
                }
            }
            &:nth-child(3) {
                &:after {
                    content: url('../img/icons/a3.png');
                }
            }
        }
    }
    &.cards, &.pos {
        li {
            &:not(:last-child) {
                margin-bottom: 30px;
            }
            h3 {
                color: $black;
                font-weight: $medium;
                margin-bottom: 10px;
            }
            &:after {
                @extend .pay_c2;
                top: -8px;
                font-size: 22px;
            }
        } 
    }
    &.email-check {
        display: flex;
        flex-wrap: wrap;
        li {
            flex: 0 0 50%;
            margin-bottom: 14px;
            text-transform: capitalize;
            font-size: 20px;
            font-weight: $light;
            @include mobileMd {
                flex: 0 0 100%;
            }
            &:after {
                @extend .email_c2;
            }
        }
    }
    &.host {
        li {
            margin-bottom: 18px;
            font-size: 18px;
            font-weight: $medium;
            @extend .hind;
            line-height: 1.3;
            padding-left: 30px;
            @include mobileMd {
                flex: 0 0 100%;
            }
            &:after {
                @extend .host_c2-bg;
                content: "";
                width: 10px;
                height: 10px;
                @extend .host_c1;
                @extend .radius;
                top: 4px;

            }
        }
    }
    &.pos {
        li {
            padding-left: 40px;
            &:after {
                @extend .analytic_c2;
            }
        }
    }
    &.job {
        li {
            font-weight: $light;
            &:not(:last-child) {
                margin-bottom: 15px;
            }
            &:after {
                content:"\f10c";
                @extend .email_c1;
                font-size: 12px;
                top: 8px;
            }
        }
    }
}
.list-uncheck {
    @extend .list-unstyled;
    li {
        &:not(:last-child) {
            margin-bottom: 35px;
        }
        h3 {
            font-size: 22px;
            font-weight: $medium;
            color: $black;
            margin-bottom: 8px;
        }
    }
    &.host {
        li {
            &:not(:last-child) {
                margin-bottom: 25px;
            }
            h3 {
                font-size: 24px;
            }
            p {
                font-size: 18px;
                max-width: 500px;
            }
        }
    }
}
.list-inline {
    li {
        display: inline-block;
    }
}

/* List Proses */
.list-proses {
    padding-left: 50px;
    @include medium {
        padding-left: 0;
    }
    li {
        padding: 40px;
        padding-bottom: 35px;
        @extend .radius-10;
        @extend .trans3;
        @include mobileMd {
            padding: 10px;
        }
        &:hover {
            background: #F1FAFF;
        }
        &:not(:last-child) {
            margin-bottom: 10px;
        }
        .img {
            margin-right: 30px;
        }
        .content {
            h3 {
                color: $black;
                font-size: 30px;
                font-weight: $medium;
                margin-bottom: 10px;
            }
            p {
                font-weight: $regular;
            }
        }
        &:nth-child(2) {
            &:hover {
                background: #E9FFFA;
            }
        }
        &:nth-child(3) {
            &:hover {
                background: #FFF4F3;
            }
        }
    }
}
.list-how-works {
    li {
        &:not(:last-child) {
            margin-bottom: 60px;
        }
        .img {
            margin-right: 50px;
            @include medium {
                margin-right: 40px;
            }
            @include mobileSm {
                margin: 0 auto 30px;
            }
        }
        @include mobileSm {
            flex-direction: column;
            text-align: center;
        }
        .content {
            h3 {
                color: $black;
                font-size: 30px;
                font-weight: $semi-bold;
                margin-bottom: 18px;
            }
            p {
                font-weight: $regular;
            }
        }
    }
}

/* Social Icons */
.social-icon {
    a {
        width: 40px;
        height: 40px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        @extend .radius;
        color: $black;
        background: #f9f9f9;
        &:not(:last-child) {
            margin-right: 10px;
        }

        &:hover {
            @extend .c1-bg;
            color: $white;
        }
    }
    &.style--two {
        a {
            @extend .crm_c1-bg;
            color: $white;
            &:hover {
                @extend .crm_c2-bg;
                @extend .crm_c1;
            }
        }
    }
    &.analytic {
        a {
            @extend .analytic_c1-bg;
            color: $white;
            &:hover {
                @extend .analytic_c2-bg;
                color: $white;
            }
        }
    }
    &.pos {
        a {
            @extend .analytic_c1-bg;
            color: $white;
            &:hover {
                @extend .analytic_c2-bg;
                color: $white;
            }
        }
    }
    &.course {
        a {
            &:hover {
                @extend .crs_c1-bg;
                color: $white;
            }
        }
    }
    &.pay {
        a {
            background: $white;
            i {
                @extend .pay_c1;
                @extend .trans3;
            }
            &:hover {
                @extend .pay_c2-bg;
                i {
                    color: $white;
                }
            }
        }
    }
    &.plug {
        a {
            background: $white;
            i {
                color: $black;
                @extend .trans3;
            }
            &:hover {
                @extend .plug_c2-bg;
                i {
                    color: #4E2FA9;
                }
            }
        }
    }
    &.email {
        a {
            background: $white;
            i {
                color: $email_c1;
                @extend .trans3;
            }
            &:hover {
                @extend .email_c2-bg;
                i {
                    color: $white;
                }
            }
        }
    }
    &.management {
        a {
            background: $white;
            i {
                color: $management_c1;
                @extend .trans3;
            }
            &:hover {
                @extend .management_c2-bg;
                i {
                    color: $white;
                }
            }
        }
    }
    &.host {
        a {
            background: $white;
            i {
                color: $host_c3;
            }
            &:hover {
                @extend .host_c2-bg;
                i {
                    color: $white;
                }
            }
        }
    }
    &.app {
        a {
            background: $white;
            i {
                color: #2154FC;
            }
            &:hover {
                background-color: #2154FC;
                i {
                    color: $white;
                    animation: fadeInUp10 .3s linear .1s 1 backwards;
                }
            }
            &:nth-child(2) {
                i {
                    color: #3DB1FF;
                }
                &:hover {
                    background-color: #3DB1FF;
                    i {
                        color: $white;
                    }
                }
            }
            &:nth-child(3) {
                i {
                    color: #FF65A8;
                }
                &:hover {
                    background-color: #FF65A8;
                    i {
                        color: $white;
                    }
                }
            }
            &:nth-child(4) {
                i {
                    color: #238BDE;
                }
                &:hover {
                    background-color: #238BDE;
                    i {
                        color: $white;
                    }
                }
            }
        }
    }
    &.app2 {
        a {
            background: $white;
            @extend .box-shadow;
            i {
                color: $black;
            }
            &:hover {
                @extend .app2_c2-bg;
                i {
                    color: $white;
                }
            }
        }
    }
    &.proto {
        a {
            background: $white;
            i {
                color: $proto_c1;
                @extend .trans2;
            }
            &:hover {
                @extend .proto_c2-bg;
                i {
                    color: $white;
                }
            }
        }
    }
    &.about {
        span {
            display: block;
            margin-bottom: 10px;
        }
        a {
            @extend .email_c1-bg;
            color: $white;
            &:hover {
                @extend .email_c2-bg;
                color: $white;
            }
        }
    }
    &.style--three {
        a {
            background-color: #FFF6F1;
            color: $black;
            &:hover {
                background-color: #FFE8DC;
                color: $black;
            }
        }
    }
}
.footer-social-icons {
    @include tab {
        margin-bottom: 15px;
    }
    span {
        display: inline-block;
        margin-right: 5px;
        font-size: 18px;
    }
    a {
        display: inline-block;
        padding: 0 10px;
        font-size: 16px;
        &:hover {
            i {
                color: $crs_c2;
            }
        }
    }
}

/* Owl Dots */
.owl-carousel {
    .owl-dots {
        position: absolute;
        right: 50px;
        top: 50%;
        transform: translateY(-50%);
        width: 30px;
    }
    button.owl-dot {
        display: inline-block;
        height: 3px;
        width: 16px;
        @extend .c1-bg;
        @extend .trans3;
        &.active {
            width: 28px;
            @extend .c2-bg;
        }
    }
}

/* Owl Nav */
.owl-carousel {
    .owl-nav {
        button {
            position: absolute;
            right: 0;
            top: -80px;
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #C9FFF5 !important;
            @extend .radius-5;
            @extend .trans3;
            @include tab {
                top: -70px;
            }
            i {
                color: #5DD9C1;
                font-size: 30px;
            }
            &:hover {
               background: #5DD9C1 !important;
               i {
                    color: $white;
                }
            }
            &.owl-prev {
                right: 66px;
            }
        }
        @include tab {
            display: none;
        }
    }
    &.logo-carousel {
        .owl-nav {
            button {
                position: absolute;
                left:auto;
                right: -50px;
                top: calc(50% - 25px);
                width: 50px;
                height: 50px;
                background: $white !important;
                i {
                    color: $text-color;
                    font-size: 30px;
                }
                &:hover {
                   background: $white !important;
                   i {
                        color: $pay_c2;
                    }
                }
                &.owl-prev {
                    right: auto;
                    left: -50px;
                }
            }
            @include mobileLg {
                display: none;
            }
        }
        &.analytic {
            .owl-nav {
                button {
                    &:hover {
                       i {
                            color: $analytic_c2;
                        }
                    }
                }
            }
        }
    }
    &.book-review-slider,
    &.customer-review-slider {
        .owl-nav {
            button {
                position: absolute;
                left:auto;
                right: -90px;
                top: calc(50% - 25px);
                width: 50px;
                height: 50px;
                background: $white !important;
                @extend .radius;
                @include tab {
                    display: none;
                }
                i {
                    display: none;
                }
                &.owl-prev {
                    right: auto;
                    left: -90px;
                }
                .svg {
                    width: 22px;
                    g path {
                        @extend .trans3;
                        stroke: $black;
                    }
                }
    
                &:hover {
                    background: $book_c1 !important;
                    .svg {
                        g path {
                            stroke: $white;
                        }
                    }
                }
            }
            @include mobileLg {
                display: none;
            }
        }
    }
    &.customer-review-slider {
        .owl-nav {
            button {
                &:hover {
                    background: #4E2FA9 !important;
                }
            }
        }
    }
    &.student-review {
        .owl-nav {
            button {
                top: -110px;
                @include tab {
                    top: -86px;
                }
            }
        }
    }
    &.email-testimonial-slider,
    &.app-testimonial-slider {
        .owl-nav {
            button {
                top: auto;
                bottom: -50px;
                left: 0;
                background: transparent !important;
                padding: 0 !important;
                width: auto;
                height: auto;
                &.owl-next {
                    left: 53px;
                }
                .svg {
                    g {
                        fill: $text-color;
                        @extend .trans2;
                        path {
                            stroke: $text-color;
                            @extend .trans2;
                        }
                    }
                }
                &:hover {
                    .svg {
                        g {
                            fill: $email_c2;
                            path {
                                stroke: $email_c2;
                            }
                        }
                    }
                }
            }
        }
        &.host {
            .owl-nav {
                button {
                    &:hover {
                        .svg {
                            g {
                                fill: $host_c2;
                                path {
                                    stroke: $host_c2;
                                }
                            }
                        }
                    }
                }
            }
        }
        &.app2 {
            .owl-nav {
                button {
                    &:hover {
                        .svg {
                            g {
                                fill: $app2_c2;
                                path {
                                    stroke: $app2_c2;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.pos-feature-slider {
        .owl-nav {
            button {
                top: -140px;
                background: transparent !important;
                @include tab {
                    top: -70px;
                }
                i {
                    color: #707070;
                    font-size: 30px;
                }
                &:hover {
                   background: transparent !important;
                   i {
                        color: $analytic_c1;
                    }
                }
                &.owl-prev {
                    right: 66px;
                }
            }
            @include mobileLg {
                display: none;
            }
        }
    }
    &.app-testimonial-slider {
        .owl-nav {
            button {
                top: auto;
                bottom: 190px;
                left: -30px;
                @include tab {
                    bottom: 190px;
                }
                i {
                   display: none;
                }
                .svg {
                    width: 26px;
                }
                &:hover {
                    .svg g path {
                        stroke: $app_c1;
                    }
                }
                &.owl-next {
                    left: auto;
                    right: -30px;
                }
            }
            @include mobileLg {
                display: none;
            }
        }
    }
}

/* Theme Input Style */
.theme-input-style {
    border: 1px solid #eeeeee;
    height: 52px;
    width: 100%;
    padding: 0 20px;
    background-color: $white;
    font-size: 16px;
    @extend .trans3;
    margin-bottom: 20px;
    &:focus {
        background-color: #F8F8FF;
    }
}

textarea.theme-input-style {
    height: 200px;
    padding: 20px 20px;
}

/* Theme Input Group */
.theme-input-group {
    position: relative;
    width: 100%;
    input {
        width: 100%;
        height: 60px;
        border: none;
        padding-left: 25px;
        padding-right: 150px;
        color: $white;
        @extend .c1-bg;
        @extend .radius-50;
        margin: 0;
        @include mobileSm {
            padding-right: 130px;
            padding-left: 10px;
        }
    }
    button, button.submit-btn {
        position: absolute; 
        top: 0px;
        right: 0px;
        height: 100%;
        padding: 15px 30px;
        line-height: 1;
        font-weight: $semi-bold;
        color: $white;
        @extend .trans2;
        @extend .c2-bg;
        &:hover {
            @extend .c2-bg;
            @extend .c1;
        }
        @include mobileSm {
            font-size: 16px;
            padding: 20px;
        }
    }
    &.style--two {
        display: flex;
        margin-bottom: 12px;
        input {
            width: calc(100% - 170px);
            height: 50px;
            border: none;
            border-bottom: 1px solid $crm_c1;
            padding-left: 0px;
            padding-right: 0px;
            @extend .text-color;
            background: transparent;
            border-radius: 0;
            font-size: 18px;
            font-weight: $light;
            @include mobileSm {
                width: calc(100% - 100px);
            }
        }
        button {
            position: static;
            padding: 15px 30px;
            margin-left: 30px;
            line-height: 1;
            border-radius: 6px;
            font-weight: $semi-bold;
            @extend .trans2;
            @extend .crm_c2-bg;
            @extend .crm_c1;
            &:hover {
                @extend .crm_c1-bg;
                @extend .crm_c2;
            }
            @include mobileSm {
                margin-left: 5px;
                padding: 15px;
            }
        }
        &.analytic {
            input {
                border-bottom: 1px solid $white;
                color: $white;
                border-radius: 0;
            }
            button {
                border-radius: 0px;
                @extend .analytic_c2-bg;
                color: $white;
                &:hover {
                    @extend .analytic_c2-bg;
                    @extend .analytic_c1;
                }
            }
        }
    }
    &.email-banner {
        display: inline-flex;
        margin-bottom: 12px;
        @include mobileSm {
            flex-direction: column;
        }
        input {
            flex: 1;
            height: 58px;
            border: 1px solid #BFBFBF;
            @extend .text-color;
            background: transparent;
            padding-right: 25px;
            border-radius: 0;
            font-size: 16px;
            font-weight: $regular;
            @include mobileMd {
                padding-left: 10px;
                padding-right: 10px;
            }
            @include mobileSm {
                margin-bottom: 10px;
                padding: 20px 10px;
            }
        }
        button {
            position: static;
            padding: 20px 35px;
            margin-left: 20px;
            line-height: 1;
            border-radius: 0px;
            font-weight: $semi-bold;
            @extend .trans3;
            @extend .email_c1-bg;
            color: $white;
            &:hover {
                @extend .email_c2-bg;
                color: $white;
            }
            @include mobileSm {
                margin: 0px;
            }
        }
    }
    &.app-banner {
        @extend .box-shadow;
        border-radius: 50px;
        input {
            height: 58px;
            @extend .text-color;
            background: $white;
            padding-right: 180px;
            font-size: 16px;
            font-weight: $regular;
            @include mobileSm {
                padding-left: 10px;
                padding-right: 120px;
            }
        }
        button {
            padding: 20px 35px;
            @extend .app_c1-bg;
            color: $white;
            border-radius: 0 50px 50px 0;
            &:hover {
                @extend .app_c2-bg;
                color: $white;
            }
            @include mobileSm {
                padding: 20px 14px;
            }
        }
        &.cta {
            max-width: 810px;
            margin: 0 auto;
        }
    }
    &.app2 {
        @extend .box-shadow;
        @extend .radius-5;
        input {
            height: 58px;
            @extend .text-color;
            background: $white;
            padding-right: 180px;
            font-size: 16px;
            font-weight: $regular;
            @extend .radius-5;
            @include mobileSm {
                padding-left: 10px;
                padding-right: 120px;
            }
        }
        button {
            padding: 20px 35px;
            color: $white;
            border-radius: 0 5px 5px 0;
            z-index: 1;
            overflow: hidden;
            @include vertical-gradient(#FF6773, #FF896B);
            &:after {
                left: 0;
                top: -100%;
                width: 100%;
                height: 100%;
                content: "";
                position: absolute;
                border-radius: 0 5px 5px 0;
                z-index: -1;
                @extend .trans3;
                @include vertical-gradient(#FF896B, #FF6773);
            }
            &:hover {
                color: $white;
                &:after {
                    top: 0;
                }
            }
            @include mobileSm {
                padding: 20px 14px;
            }
        }
        &.footer {
            @include tab {
                input {
                    padding-left: 10px;
                    padding-right: 109px;
                }
                button {
                    padding: 15px;
                }
            }
        }
    }
    &.crs {
        max-width: 690px;
        margin: 0 auto;
        padding: 0 15px;
        .input-wrap {
            position: relative;
            .highlight {
                position: absolute;
                display: block;
                width: 0%;
                height: 1px;
                left: 0;
                bottom: 0;
                background: $crs_c2;
                @extend .trans5;
            }
            input:focus + .highlight {
                width: 100%;
            }
        }
        input {
            padding-left: 0px;
            padding-right: 30px;
            background: transparent;
            border-radius: 0;
            border-bottom: 1px solid $white;
            font-size: 18px;
            &::placeholder {
                color: $white !important;
            }
        }
        button {
            position: absolute; 
            top: 50%;
            right: 5px;
            height: auto;
            padding: 10px;
            background: transparent;
            transform: translateY(-50%);
            .svg {
                g {
                    path {
                        @extend .trans3;
                    }
                    g {
                        @extend .trans3;
                    }
                }
            }
            &:hover {
                @extend .c2-bg;
                background: transparent;
                .svg {
                    g {
                        path {
                            stroke: $crs_c2;
                        }
                        g {
                            fill: $crs_c2;
                        }
                    }
                }
            }
        }
    }
    &.course {
        input {
            @extend .crs_c1-bg;
            color: $white;
        }
        button {
            @extend .crs_c2-bg;
            &:hover {
                @extend .crs_c2-bg;
                color: $black;
            }
        }
    }
    &.pay {
        input {
            background: $white;
            border-radius: 0;
            @extend .text-color;
        }
        button {
            @extend .pay_c2-bg;
            border-radius: 0;
            &:hover {
                @extend .pay_c2-bg;
            }
        }
        &.plug {
            input {
                @extend .radius-5;
                color: $black;
            }
            button {
                @extend .plug_c2-bg;
                @extend .radius-5;
                color: #4E2FA9;
                &:hover {
                    @extend .plug_c2-bg;
                    opacity: .9;
                }
            }
        }
    }
    &.author-newsletter {
        max-width: 630px;
        margin: 0 auto;
        input {
            background: $book_c1;
            &::placeholder {
                color: $white !important;
            }
        }
        button {
            @extend .crs_c2-bg;
            right: -1px;
            &:hover {
                @extend .crs_c2-bg;
                @extend .book_c1;
            }
        }
    }
    &.style--four {
        display: flex;
        margin-bottom: 15px;
        input {
            width: calc(100% - 160px);
            height: 50px;
            border: 1px solid $white;
            padding-left: 25px;
            padding-right: 25px;
            color: $white;
            background: transparent;
            border-radius: 0;
            font-size: 18px;
            font-weight: $light;
            @include mobileSm {
                width: calc(100% - 100px);
                padding-left: 10px;
                padding-right: 10px;
            }
        }
        button {
            position: static;
            padding: 16px 30px;
            height: 50px;
            margin-left: 20px;
            line-height: 1;
            border-radius: 6px;
            font-weight: $semi-bold;
            border-radius: 0;
            @extend .trans2;
            @extend .email_c2-bg;
            @extend .white;
            @include mobileSm {
                margin-left: 5px;
                padding: 15px;
            }
            &:hover {
                @extend .email_c2-bg;
                @extend .email_c1;
            }
        }
        &.host {
            input {
                border-radius: 6px;
            }
            button {
                border-radius: 6px;
                @extend .host_c2-bg;
                &:hover {
                    @extend .host_c2-bg;
                    @extend .host_c3;
                }
            }
        }
    }
    &.management {
        max-width: 570px;
        margin-left: auto;
        margin-right: auto;
        input {
            background: $white;
            font-size: 20px;
            font-weight: $light;
            @extend .text-color;
            padding-right: 170px;
            @include medium {
                padding-right: 150px;
            }
        }
        button {
            @extend .management_c2-bg;
            font-size: 18px;
            padding: 20px 40px;
            @include medium {
                padding: 20px 30px;
            }
        }
    }
    &.management2 {
        input {
            background: $white;
            font-size: 20px;
            font-weight: $light;
            @extend .text-color;
            padding-right: 170px;
            @include medium {
                padding-right: 150px;
            }
            @include mobileSm {
                padding-right: 120px;
            }
        }
        button {
            @extend .management_c2-bg;
            font-size: 18px;
            padding: 20px 30px;
            @include mobileSm {
                padding: 20px;
            }
        }
    }
    &.pos {
        input {
            border-color: #707070;
        }
        button {
            background: $analytic_c2;
            color: $white;
            &:hover {
                background: $analytic_c1;
                color: $white;
            }
        }
    }
    &.proto {
        @extend .box-shadow;
        @extend .radius-10;
        input {
            height: 60px;
            @extend .text-color;
            background: $white;
            padding-right: 160px;
            font-size: 16px;
            font-weight: $regular;
            @extend .radius-10;
            @include mobileLg {
                height: 50px;
            }
            @include mobileSm {
                padding-left: 10px;
                padding-right: 120px;
            }
        }
        button {
            padding: 20px 35px;
            color: $white;
            border-radius: 0 10px 10px 0;
            z-index: 1;
            @extend .proto_c2-bg;
            &:hover {
                @extend .proto_c1-bg;
                color: $white;
            }
            @include mobileSm {
                padding: 20px 14px;
            }
        }
    }
    &.affiliate {
        .theme-input-style {
            color: $white;
            &:focus {
                background-color: $c1;
            }
        }
    }
}

/* Others */
.ov-hidden {
    overflow: hidden;
}
.ovx-hidden {
    overflow-x: hidden;
}

/* Before After */
.ba {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    @extend .c2-bg;
}

/* Pagination*/
.pagination {
    > ul {
        margin-bottom: 0;
    }
    li {
        margin: 0 5px;
        a,
        span {
            width: 40px;
            height: 40px;
            line-height: 1;
            position: relative;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            background-color: $white;
            border: 1px solid #eeeeee;
            color: #5E5E5E;
            font-size: 22px;
            @extend .hind;
            font-weight: $semi-bold;
            &:hover,
            &.current {
                color: $white;
                @extend .email_c2-bg;
            }
        }
        &.nav-btn {
            a.disabled {
                cursor: not-allowed;
                opacity: .5;
            }
        }
    }
}

/* Blockquote */
blockquote {
    padding: 0px 20px 30px 20px;
    position: relative;
    font-size: 18px;
    font-style: italic;
    line-height: 1.33;
    font-weight: $regular;
    max-width: 540px;
    margin: 0 auto;
    cite {
        display: block;
        margin-top: 15px;
        font-size: 16px;
        line-height: 1.44;
        font-style: normal;
        font-weight: $regular;
    }
}

/* Custom Container */
@media (min-width: 1500px) {
    .container {
        max-width: 1410px;
    }
}

/* Form Response */
.form-response {
    background-color: rgba($c1, .2);
    span {
        display: block;
        margin-top: 20px;
        margin-bottom: 30px;
        padding: 10px 15px;
    }  
}

/* Bg */
.light-bg {
    background-color: #FFFCF6;
}

/* Bg Img */
.bg-img {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

/* Search Form */
.search-form {
    .theme-input-group {
        input {
            @extend .email_c1-bg;
            color: $white;
            border-radius: 0;
            color: $white;
            padding-right: 100px;
            &::placeholder {
                color: $white !important;
            }
        }
        button {
            @extend .email_c2-bg;
            &:hover {
                color: $white;
            }
        }
    }
    &.faq {
        h2 {
            color: $black;
            font-weight: $semi-bold;
            margin-bottom: 30px;
            text-align: center;
        }
        .theme-input-group {
            input {
                background-color: $white;
                color: $text-color;
                border: 1px solid $black;
                width: calc(100% - 150px);
                &::placeholder {
                    color: $text-color !important;
                }
                @include mobileSm {
                    width: calc(100% - 100px);
                    padding: 0 10px;
                }
            }
            button {
                padding: 15px 38px;
                font-weight: $medium;
                @include mobileSm {
                    padding: 15px 25px;
                }
            }
        }
    }
}
form.search-form, form.newsletter-form {
    .theme-input-group {
        .theme-input-style {
            border-bottom: 1px solid #999999 !important;
            padding: 0 10px;
            background-color: transparent;
            height: 40px;
            color: $text-color;
            border-radius: 0;
            &::placeholder {
                color: #D1D1D1 !important;
            }
        }
        .submit-btn {
            border: none;
            padding: 10px;
            background-color: transparent;
        }
    }
}

/* Count Down Timer */
.countdown-wrapper {
    padding: 50px;
    padding-bottom: 44px;
    @extend .box-shadow;
    @extend .radius-20;
    background: $white;
    @include tab {
        margin-top: 30px;
    }
    @include mobileMd {
        padding: 30px 20px 26px;
    }
    .countdown-tag {
        display: inline-block;
        background-color: #C9FFF5;
        @extend .crs_c1;
        @extend .radius-50;
        padding: 4px 22px;
        font-size: 18px;
        font-weight: $medium;
        margin-bottom: 26px;

    }
}
#countdown {
    .single-countdown {
        text-align: center;
        h2 {
            font-size: 60px;
            font-weight: $semi-bold;
            color: $black;
            @include mobileMd {
                font-size: 40px;
            }
        }
        p {
            @include mobileMd {
                font-size: 14px;
            }
        }
    }
}

/* Video Play Button */
.video-play-button {
    width: 68px;
    height: 68px;
    @extend .radius;
    @extend .crs_c2-bg;
    color: $white;
    position: absolute;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;

    &:hover {
        @extend .crs_c1-bg;
        color: $white;
        i {
            @extend .trans2;
        }
    }
    &.proto {
        @extend .proto_c2-bg;
        &:hover {
            i {
                @extend .proto_c1;
            }
        }
    }
}

/* Trapezium Video */
.trapezium-video {
    position: relative;
    z-index: 1;
    &:after {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: $white;
        opacity: 0.3;
        content: "";
        position: absolute;
    }
}

/* Course BG */
#course {
    background: #F6F8F5;
}

.bg-right {
    position: absolute;
    left: auto;
    right: -300%;
    top: 0;
    width: calc(400% - 15px);
    height: 100%;
    @extend .book_c1-bg;
    border-radius: 150px 0 0 0;
    z-index: -1;
    @include mobileLg {
        width: 600%;
    }
}

/* Custom Checkbox */
.custom-checkbox {
    input[type=checkbox] {
        position: relative;
        top: 1px;
    }
    &.pos {
        label {
            display: block;
            position: relative;
            padding-left: 30px;
            cursor: pointer;
            user-select: none;
        }
        label input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }
        .checkmark {
            position: absolute;
            top: 6px;
            left: 0;
            height: 16px;
            width: 16px;
            background-color: transparent;
            border: 1px solid $analytic_c1;
        }
        label input:checked ~ .checkmark {
            background-color: $analytic_c1;
        }
        .checkmark:after {
            content: "";
            position: absolute;
            display: none;
        }
        label input:checked ~ .checkmark:after {
            display: block;
        }
        label .checkmark:after {
            left: 5px;
            top: 1px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }
    }
}

/* App2 Page Section BG */
.app2-bg {
    background: #F5F8FF;
}

/* About Content */
.about-content {
    @include tab {
        margin-top: 60px;
    }
    h2 {
        font-weight: $semi-bold;
        color: $black;
        margin-bottom: 20px;
        line-height: 1.2;
    }
}

//body bg
.body-bg {
    background-color: #F6F8F5;
}

//Service Cta Bg
.service-cta-bg {
    background-color: #857DFF;
}