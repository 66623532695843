/* ************************
   02.2: Animations
   ********************* */ 
   .animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

@-webkit-keyframes grow {
    0% {
      opacity: 0;
      -webkit-transform: scale(.8);
      transform: scale(.8);
    }
  
    100% {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
}

@keyframes grow {
    0% {
        opacity: 0;
        -webkit-transform: scale(.8);
        transform: scale(.8);
    }

    50% {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.grow {
-webkit-animation-name: grow;
animation-name: grow;
}

@-webkit-keyframes grow2 {
    0% {
      opacity: 0;
      -webkit-transform: scale(.9);
      transform: scale(.9);
    }
  
    100% {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
}

@keyframes grow2 {
    0% {
        opacity: 0;
        -webkit-transform: scale(.9);
        transform: scale(.9);
    }

    50% {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.grow2 {
-webkit-animation-name: grow2;
animation-name: grow2;
}

@-webkit-keyframes growDown {
    0% {
      opacity: 0;
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }
  
    100% {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
}

@keyframes growDown {
    0% {
        opacity: 0;
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }

    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.growDown {
-webkit-animation-name: growDown;
animation-name: growDown;
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}
@keyframes fadeIn {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}
.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn
}

@-webkit-keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-20px)
    }
    100% {
        opacity: 1;
        transform: translateY(0)
    }
}
@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-20px)
    }
    100% {
        opacity: 1;
        transform: translateY(0)
    }
}
.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown
}

@-webkit-keyframes fadeInDown2 {
    0% {
        transform: translateY(-40px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 0.2;
    }
}
@keyframes fadeInDown2 {
    0% {
        transform: translateY(-40px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 0.2;
    }
}
.fadeInDown2 {
    -webkit-animation-name: fadeInDown2;
    animation-name: fadeInDown2
}

@-webkit-keyframes fadeInDownBig {
    0% {
        opacity: 0;
        transform: translateY(-100px)
    }
    100% {
        opacity: 1;
        transform: translateY(0)
    }
}
@keyframes fadeInDownBig {
    0% {
        opacity: 0;
        transform: translateY(-100px)
    }
    100% {
        opacity: 1;
        transform: translateY(0)
    }
}
.fadeInDownBig {
    -webkit-animation-name: fadeInDownBig;
    animation-name: fadeInDownBig
}
@-webkit-keyframes fadeInLeft {
    0% {
        opacity: 0;
        transform: translateX(-40px)
    }
    100% {
        opacity: 1;
        transform: translateX(0)
    }
}
@keyframes fadeInLeft {
    0% {
        opacity: 0;
        transform: translateX(-40px)
    }
    100% {
        opacity: 1;
        transform: translateX(0)
    }
}
.fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft
}

@-webkit-keyframes fadeInLeft2 {
    0% {
        opacity: 0;
        transform: translateX(-10px)
    }
    100% {
        opacity: 1;
        transform: translateX(0)
    }
}
@keyframes fadeInLeft2 {
    0% {
        opacity: 0;
        transform: translateX(-10px)
    }
    100% {
        opacity: 1;
        transform: translateX(0)
    }
}
.fadeInLeft2 {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft
}

@-webkit-keyframes fadeInLeftBig {
    0% {
        opacity: 0;
        transform: translateX(-200px)
    }
    100% {
        opacity: 1;
        transform: translateX(0)
    }
}
@keyframes fadeInLeftBig {
    0% {
        opacity: 0;
        transform: translateX(-200px)
    }
    100% {
        opacity: 1;
        transform: translateX(0)
    }
}
.fadeInLeftBig {
    -webkit-animation-name: fadeInLeftBig;
    animation-name: fadeInLeftBig
}
@-webkit-keyframes fadeInRight {
    0% {
        opacity: 0;
        transform: translateX(40px)
    }
    100% {
        opacity: 1;
        transform: translateX(0)
    }
}
@keyframes fadeInRight {
    0% {
        opacity: 0;
        transform: translateX(40px)
    }
    100% {
        opacity: 1;
        transform: translateX(0)
    }
}
.fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight
}

@-webkit-keyframes fadeInRight2 {
    0% {
        opacity: 0;
        transform: translateX(10px)
    }
    100% {
        opacity: 1;
        transform: translateX(0)
    }
}
@keyframes fadeInRight2 {
    0% {
        opacity: 0;
        transform: translateX(10px)
    }
    100% {
        opacity: 1;
        transform: translateX(0)
    }
}
.fadeInRight2 {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight
}

@-webkit-keyframes fadeInRightBig {
    0% {
        opacity: 0;
        transform: translateX(200px)
    }
    100% {
        opacity: 1;
        transform: translateX(0)
    }
}
@keyframes fadeInRightBig {
    0% {
        opacity: 0;
        transform: translateX(200px)
    }
    100% {
        opacity: 1;
        transform: translateX(0)
    }
}
.fadeInRightBig {
    -webkit-animation-name: fadeInRightBig;
    animation-name: fadeInRightBig
}
@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px)
    }
    100% {
        opacity: 1;
        transform: translateY(0)
    }
}
@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(40px)
    }
    100% {
        opacity: 1;
        transform: translateY(0)
    }
}
.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}

@-webkit-keyframes fadeInUp2 {
    0% {
        opacity: 0;
        transform: translateY(200px)
    }
    100% {
        opacity: 1;
        transform: translateY(0)
    }
}
@keyframes fadeInUp2 {
    0% {
        opacity: 0;
        transform: translateY(200px)
    }
    100% {
        opacity: 1;
        transform: translateY(0)
    }
}
.fadeInUp2 {
    -webkit-animation-name: fadeInUp2;
    animation-name: fadeInUp2
}

@-webkit-keyframes fadeInUp10 {
    0% {
        opacity: 0;
        transform: translateY(10px)
    }
    100% {
        opacity: 1;
        transform: translateY(0)
    }
}
@keyframes fadeInUp10 {
    0% {
        opacity: 0;
        transform: translateY(10px)
    }
    100% {
        opacity: 1;
        transform: translateY(0)
    }
}
.fadeInUp10 {
    -webkit-animation-name: fadeInUp10;
    animation-name: fadeInUp10
}

@-webkit-keyframes fadeInUpBig {
    0% {
        opacity: 0;
        transform: translateY(100px)
    }
    100% {
        opacity: 1;
        transform: translateY(0)
    }
}
@keyframes fadeInUpBig {
    0% {
        opacity: 0;
        transform: translateY(100px)
    }
    100% {
        opacity: 1;
        transform: translateY(0)
    }
}
.fadeInUpBig {
    -webkit-animation-name: fadeInUpBig;
    animation-name: fadeInUpBig
}
@-webkit-keyframes fadeOut {
    0% {
        opacity: 1
    }
    100% {
        opacity: 0
    }
}
@keyframes fadeOut {
    0% {
        opacity: 1
    }
    100% {
        opacity: 0
    }
}
.fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut
}
@-webkit-keyframes slideInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
        transform: translateX(-2000px)
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}
@keyframes slideInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
        -ms-transform: translateX(-2000px);
        transform: translateX(-2000px)
    }
    100% {
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0)
    }
}
.slideInLeft {
    -webkit-animation-name: slideInLeft;
    animation-name: slideInLeft
}

@-webkit-keyframes slideInRight {
    0% {
        -webkit-transform: translateX(2000px);
        transform: translateX(2000px)
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}
@keyframes slideInRight {
    0% {
        -webkit-transform: translateX(2000px);
        -ms-transform: translateX(2000px);
        transform: translateX(2000px)
    }
    100% {
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0)
    }
}
.slideInRight {
    -webkit-animation-name: slideInRight;
    animation-name: slideInRight
}

/* Custom */
@-webkit-keyframes bg-scale {
	0% {
        -webkit-transform: translate(100%);
        transform: translate(100%);
	}
	100% {
        -webkit-transform: translate(0);
        transform: translate(0);
	}
}

@keyframes bg-scale {
	0% {
        -webkit-transform: translate(100%);
        transform: translate(100%);
	}
	100% {
        -webkit-transform: translate(0);
        transform: translate(0);
	}
}

.bg-scale {
    -webkit-animation-name: bg-scale;
    animation-name: bg-scale
}

@-webkit-keyframes cbat {
	0% {
        -webkit-transform: translateX(30%) translateY(30%);
        transform: translateX(30%) translateY(30%);
        opacity: 0;
	}
	100% {
        -webkit-transform: translateX(0) translateY(0);
        transform: translateX(0) translateY(0);
        opacity: 1;
	}
}
@keyframes cbat {
	0% {
        -webkit-transform: translateX(30%) translateY(30%);
        transform: translateX(30%) translateY(30%);
        opacity: 0;
	}
	100% {
        -webkit-transform: translateX(0) translateY(0);
        transform: translateX(0) translateY(0);
        opacity: 1;
	}
}
.cbat {
    -webkit-animation-name: cbat;
    animation-name: cbat
}

@-webkit-keyframes cbab {
	0% {
        -webkit-transform: translateX(-30%) translateY(-30%);
        transform: translateX(-30%) translateY(-30%);
        opacity: 0;
	}
	100% {
        -webkit-transform: translateX(0) translateY(0);
        transform: translateX(0) translateY(0);
        opacity: 1;
	}
}
@keyframes cbab {
	0% {
        -webkit-transform: translateX(-30%) translateY(-30%);
        transform: translateX(-30%) translateY(-30%);
        opacity: 0;
	}
	100% {
        -webkit-transform: translateX(0) translateY(0);
        transform: translateX(0) translateY(0);
        opacity: 1;
	}
}
.cbab {
    -webkit-animation-name: cbab;
    animation-name: cbab
}

@-webkit-keyframes zoomIn {
	0% {
        -webkit-transform: scale(.5);
        transform: scale(.5);
	}
	100% {
        -webkit-transform: scale(1);
        transform: scale(1);
	}
}
@keyframes zoomIn {
	0% {
        -webkit-transform: scale(.5);
        transform: scale(.5);
	}
	100% {
        -webkit-transform: scale(1);
        transform: scale(1);
	}
}
.zoomIn {
    -webkit-animation-name: zoomIn;
    animation-name: zoomIn
}

@-webkit-keyframes zoomOut {
	0% {
        transform: scale(1.01);
	}
	100% {
        transform: scale(1);
	}
}
@keyframes zoomOut {
	0% {
        transform: scale(1.01);
	}
	100% {
        transform: scale(1);
	}
}
.zoomOut {
    -webkit-animation-name: zoomOut;
    animation-name: zoomOut
}

@-webkit-keyframes arrowRight {
    0% {
        opacity: 1;
        transform: translateX(0)
    }
    100% {
        opacity: 0;
        transform: translateX(10px)
    }
}
@keyframes arrowRight {
    0% {
        opacity: 1;
        transform: translateX(0)
    }
    100% {
        opacity: 0;
        transform: translateX(10px)
    }
}
.arrowRight {
    -webkit-animation-name: arrowRight;
    animation-name: arrowRight
}

@-webkit-keyframes arrowLeft {
    0% {
        opacity: 1;
        transform: translateX(0)
    }
    100% {
        opacity: 0;
        transform: translateX(-10px)
    }
}
@keyframes arrowLeft {
    0% {
        opacity: 1;
        transform: translateX(0)
    }
    100% {
        opacity: 0;
        transform: translateX(-10px)
    }
}
.arrowLeft {
    -webkit-animation-name: arrowLeft;
    animation-name: arrowLeft
}

@-webkit-keyframes zoomDown {
    0% {
        opacity: 0;
        transform: scale(0);
        transform-origin: 50% 0;
    }
    100% {
        opacity: 1;
        transform: scale(1)
    }
}
@keyframes zoomDown {
    0% {
        opacity: 0;
        transform: scale(0);
        transform-origin: 50% 0;
    }
    100% {
        opacity: 1;
        transform: scale(1)
    }
}
.zoomDown {
    -webkit-animation-name: zoomDown;
    animation-name: zoomDown
}


@keyframes heightWidth {
    0% {
        height: 0;
        width: 0;
    }
    100% {
        height: 100%;
        width: 100%;
    }
}
.heightWidth {
    -webkit-animation-name: height;
    animation-name: height
}

@-webkit-keyframes levitate{
    from{
        -webkit-transform:translate(0, 0);
        transform:translate(0, 0)
    }
    50%{
        -webkit-transform:translate(0, 10px);
        transform:translate(0, 10px)
    }
    to{
        -webkit-transform:translate(0, 0);
        transform:translate(0, 0)
    }
}
@keyframes levitate{
    from{
        -webkit-transform:translate(0, 0);
        transform:translate(0, 0)
    }
    50%{
        -webkit-transform:translate(0, 10px);
        transform:translate(0, 10px)
    }
    to{
        -webkit-transform:translate(0, 0);
        transform:translate(0, 0)
    }
}
.levitate{
    -webkit-animation-name:levitate;
    animation-name:levitate;
    -webkit-animation-duration:3s;
    animation-duration:3s;
    -webkit-animation-iteration-count:infinite;
    animation-iteration-count:infinite;
    -webkit-animation-timing-function:ease-in-out;
    animation-timing-function:ease-in-out
}


@-webkit-keyframes wide{
    0%{
        -webkit-transform:scaleX(0);
        transform:scaleX(0)
    }
    100%{
        -webkit-transform:scaleX(1);
        transform:scaleX(1)
    }
}
@keyframes wide{
    0%{
        -webkit-transform:scaleX(0);
        transform:scaleX(0)
    }
    100%{
        -webkit-transform:scaleX(1);
        transform:scaleX(1)
    }
}

.wide {
    -webkit-animation-name: wide;
    animation-name: wide
}

@keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}

@keyframes rotate {
    from {
        transform: rotate(5deg);
    }
    to {
        transform: rotate(0deg);
    }
}



@-webkit-keyframes banner3Shake {
    0% {
      -webkit-transform: rotate3d(0, 1, 0, 0deg);
              transform: rotate3d(0, 1, 0, 0deg);
    }
    30% {
      -webkit-transform: rotate3d(0, 0, 1, 5deg);
              transform: rotate3d(0, 0, 1, 5deg);
    }
    60% {
      -webkit-transform: rotate3d(1, 0, 0, 0deg);
              transform: rotate3d(1, 0, 0, 0deg);
    }
    80% {
      -webkit-transform: rotate3d(0, 0, 1, 5deg);
              transform: rotate3d(0, 0, 1, 5deg);
    }
    100% {
      -webkit-transform: rotate3d(0, 1, 0, 0deg);
              transform: rotate3d(0, 1, 0, 0deg);
    }
}

@keyframes banner3Shake {
0% {
    -webkit-transform: rotate3d(0, 1, 0, 0deg);
            transform: rotate3d(0, 1, 0, 0deg);
}
30% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
            transform: rotate3d(0, 0, 1, 5deg);
}
60% {
    -webkit-transform: rotate3d(1, 0, 0, 0deg);
            transform: rotate3d(1, 0, 0, 0deg);
}
80% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
            transform: rotate3d(0, 0, 1, 5deg);
}
100% {
    -webkit-transform: rotate3d(0, 1, 0, 0deg);
            transform: rotate3d(0, 1, 0, 0deg);
}
}

@-webkit-keyframes leftToRight {
    from {left: 0;}
    to {right: 0;}
}
@keyframes leftToRight {
    from {left: -130px;}
    to {left: 100%;}
}