@charset "UTF-8";
/*
	*******************
	Template name:  DocLand
	Version:        1.0
	Author:         ThemeLooks
	Author url:     http://themelooks.com

	NOTE:
	-----
	Please DO NOT EDIT THIS CSS, you may need to use "custom.css" file for writing your custom css.
	We may release future updates so it will overwrite this file. it's better and safer to use "custom.css".

    ******** CSS INDEX ********
    01. Base
        1.1 Reset 
        1.2 Margin/Padding
        1.3 color
    02. Components
        2.1 Common Classes
        2.2 Animations
        2.3 Preloader
        2.4 Buttons
        2.5 Section Title
        2.6 Page Title
        2.7 Widgets
        2.8 Back to Top
    03. Layout
        3.1 Header 
        3.2 Banner
        3.3 Blog
        3.4 Footer
        3.5 404
        3.6 Coming Soon
    04. Sections
        4.1 How Earn
        4.2 Service
        4.3 Calculate Earning
        4.4 Earn Path
        4.5 CTA
        4.6 Feature
        4.7 Insight
        4.8 Testimonial
        4.9 Syllabus
        4.10 Price
        4.11 Company Logo Carousel
        4.12 Author
        4.13 FAQ
        4.14 Job
        4.15 SignUp
        4.16 Contact
        4.17 Teamp

    ********************/
/* ************************
   01.1: Reset
   ********************* */
*,
*::before,
*::after {
  outline: none !important;
  box-sizing: border-box;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

::selection {
  text-shadow: none;
  color: #ffffff;
}

::placeholder {
  color: #707070 !important;
  opacity: 1 !important;
}

iframe {
  max-width: 100%;
}

button:focus,
input:focus,
select:focus,
textarea:focus {
  outline: none !important;
}

input[type=checkbox] {
  margin-right: 5px;
  cursor: pointer;
}

label {
  margin: 0;
}

button,
button[type="submit"],
input[type="submit"] {
  border: none;
  background-color: transparent;
  cursor: pointer;
  -webkit-appearance: button;
  -moz-appearance: button;
}

select {
  width: 100%;
  max-width: 100%;
  color: #707070;
}

textarea {
  resize: none;
}

img {
  max-width: 100%;
  height: auto;
}

a,
a:hover,
a:active,
a:focus,
input,
input:hover,
input:focus,
input:active,
select,
textarea {
  text-decoration: none;
  outline: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
  font-weight: 700;
  line-height: 1;
  text-transform: capitalize;
}

p:last-child {
  margin: 0;
}

h1 {
  font-size: 46px;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

table {
  width: 100%;
  max-width: 100%;
}

table th, table td {
  padding: 15px 30px;
}

@media only screen and (max-width: 479px) {
  table th, table td {
    padding: 10px;
  }
}

table th {
  color: #292929;
  font-weight: 700;
  border-bottom: 1px solid #D1D1D1;
}

table td {
  border-bottom: 1px solid #F6F6F6;
}

table td a {
  color: #292929;
  font-weight: 700;
  font-size: 14px;
}

table td a:hover {
  color: #FF937D;
}

.list-unstyled, .list-check, .list-uncheck, .widget ul, .widget.widget_footer_menu .footer_menu ul, .widget.job-info ul, .price-list, .tab-btn ul, .price-feature, .quick-links ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

p {
  margin-bottom: 20px;
  font-weight: 300;
}

@media only screen and (max-width: 575px) {
  p {
    font-size: 18px;
  }
}

.feature-p, .footer .widget.widget_text p, .single-service .service-content P, .testimonial-single .ts-bottom p, .price-box .price-head p {
  font-size: 16px;
  font-weight: 400;
}

pre {
  border: 1px solid;
  border-radius: 4px;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 0.8em 1.6em;
}

code {
  background-color: rgba(27, 31, 35, 0.05);
  border-radius: 3px;
  font-size: 85%;
  margin: 0;
  padding: .2em .4em;
}

html, body {
  overflow-x: hidden !important;
}

body {
  font-size: 20px;
  line-height: 1.78;
  color: #707070;
}

/* ************************
   01.2: Padding/Margin
   ********************* */
.pt-160 {
  padding-top: 160px;
}

@media only screen and (max-width: 991px) {
  .pt-160 {
    padding-top: 100px;
  }
}

.pt-140 {
  padding-top: 140px;
}

@media only screen and (max-width: 991px) {
  .pt-140 {
    padding-top: 80px;
  }
}

.pt-130 {
  padding-top: 130px;
}

@media only screen and (max-width: 991px) {
  .pt-130 {
    padding-top: 70px;
  }
}

.pt-120 {
  padding-top: 120px;
}

@media only screen and (max-width: 991px) {
  .pt-120 {
    padding-top: 60px;
  }
}

.pt-110 {
  padding-top: 110px;
}

@media only screen and (max-width: 991px) {
  .pt-110 {
    padding-top: 50px;
  }
}

.pt-100 {
  padding-top: 100px;
}

@media only screen and (max-width: 991px) {
  .pt-100 {
    padding-top: 40px;
  }
}

.pt-90 {
  padding-top: 90px;
}

@media only screen and (max-width: 991px) {
  .pt-90 {
    padding-top: 30px;
  }
}

.pt-80 {
  padding-top: 80px;
}

@media only screen and (max-width: 991px) {
  .pt-80 {
    padding-top: 20px;
  }
}

.pt-70 {
  padding-top: 70px;
}

@media only screen and (max-width: 991px) {
  .pt-70 {
    padding-top: 10px;
  }
}

.pt-60 {
  padding-top: 60px;
}

@media only screen and (max-width: 991px) {
  .pt-60 {
    padding-top: 0;
  }
}

.pt-50 {
  padding-top: 50px;
}

@media only screen and (max-width: 991px) {
  .pt-50 {
    padding-top: 0;
  }
}

.pt-40 {
  padding-top: 40px;
}

@media only screen and (max-width: 991px) {
  .pt-40 {
    padding-top: 0;
  }
}

.pt-30 {
  padding-top: 30px;
}

@media only screen and (max-width: 991px) {
  .pt-30 {
    padding-top: 0;
  }
}

.pt-20 {
  padding-top: 20px;
}

@media only screen and (max-width: 991px) {
  .pt-20 {
    padding-top: 0;
  }
}

.pt-10 {
  padding-top: 10px;
}

@media only screen and (max-width: 991px) {
  .pt-10 {
    padding-top: 0;
  }
}

.pb-160 {
  padding-bottom: 160px;
}

@media only screen and (max-width: 991px) {
  .pb-160 {
    padding-bottom: 100px;
  }
}

.pb-140 {
  padding-bottom: 140px;
}

@media only screen and (max-width: 991px) {
  .pb-140 {
    padding-bottom: 80px;
  }
}

.pb-130 {
  padding-bottom: 130px;
}

@media only screen and (max-width: 991px) {
  .pb-130 {
    padding-bottom: 70px;
  }
}

.pb-120 {
  padding-bottom: 120px;
}

@media only screen and (max-width: 991px) {
  .pb-120 {
    padding-bottom: 60px;
  }
}

.pb-110 {
  padding-bottom: 110px;
}

@media only screen and (max-width: 991px) {
  .pb-110 {
    padding-bottom: 50px;
  }
}

.pb-100 {
  padding-bottom: 100px;
}

@media only screen and (max-width: 991px) {
  .pb-100 {
    padding-bottom: 40px;
  }
}

.pb-90 {
  padding-bottom: 90px;
}

@media only screen and (max-width: 991px) {
  .pb-90 {
    padding-bottom: 30px;
  }
}

.pb-80 {
  padding-bottom: 80px;
}

@media only screen and (max-width: 991px) {
  .pb-80 {
    padding-bottom: 20px;
  }
}

.pb-70 {
  padding-bottom: 70px;
}

@media only screen and (max-width: 991px) {
  .pb-70 {
    padding-bottom: 10px;
  }
}

.pb-60 {
  padding-bottom: 60px;
}

@media only screen and (max-width: 991px) {
  .pb-60 {
    padding-bottom: 0;
  }
}

.pb-50 {
  padding-bottom: 50px;
}

@media only screen and (max-width: 991px) {
  .pb-50 {
    padding-bottom: 0;
  }
}

.pb-40 {
  padding-bottom: 40px;
}

@media only screen and (max-width: 991px) {
  .pb-40 {
    padding-bottom: 0;
  }
}

.pb-30 {
  padding-bottom: 30px;
}

@media only screen and (max-width: 991px) {
  .pb-30 {
    padding-bottom: 0;
  }
}

.pb-20 {
  padding-bottom: 20px;
}

@media only screen and (max-width: 991px) {
  .pb-20 {
    padding-bottom: 0;
  }
}

.pb-10 {
  padding-bottom: 10px;
}

@media only screen and (max-width: 991px) {
  .pb-10 {
    padding-bottom: 0;
  }
}

.mt-140 {
  margin-top: 140px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-44 {
  margin-top: 44px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-10 {
  margin-top: 10px;
}

.mb-140 {
  margin-bottom: 140px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-44 {
  margin-bottom: 44px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-10 {
  margin-bottom: 10px;
}

/* Margin Static */
@media only screen and (max-width: 991px) {
  .mb-md-60 {
    margin-bottom: 60px;
  }
  .mb-md-80 {
    margin-bottom: 80px;
  }
  .pb-md-80 {
    padding-bottom: 80px;
  }
}

.ml-10 {
  margin-left: 10px;
}

.mb-44 {
  margin-bottom: 44px !important;
}

/* ************************
   01.3: Color
   ********************* */
.c1, a, h1,
h2,
h3,
h4,
h5,
h6, .theme-input-group button:hover, .theme-input-group button.submit-btn:hover, .btn.animate-btn, .animate-btn.btn-crm, .animate-btn.btn-crs, .animate-btn.btn-pay, .animate-btn.e-btn, .animate-btn.btn-book, .animate-btn.m-btn, .btn.animate-btn:hover, .animate-btn.btn-crm:hover, .animate-btn.btn-crs:hover, .animate-btn.btn-pay:hover, .animate-btn.e-btn:hover, .animate-btn.btn-book:hover, .animate-btn.m-btn:hover, .header-btn .link-btn:hover, .widget.widget_footer_menu .footer_menu li a:hover, .header .header-main.email .main-menu .nav li ul li, .header .header-main .main-menu #menu-button, .header .header-main .main-menu .nav li a:hover, .header .header-main .main-menu .nav li ul li, #mobile_menu .mobile-main-menu .nav li.current-menu-parent > a, #mobile_menu .mobile-main-menu .nav li.current-menu-item > a, #mobile_menu .mobile-main-menu .nav li a:hover, .post-details .entry-tags ul li a:hover,
.post-details .entry-categories ul li a:hover, .ui-range-slider .total-earning strong {
  color: #5F52DC;
}

.c1-bg, ::selection, .social-icon a:hover, .owl-carousel button.owl-dot, .theme-input-group input, .btn.s-btn, .s-btn.btn-crm, .s-btn.btn-crs, .s-btn.btn-pay, .s-btn.e-btn, .s-btn.btn-book, .s-btn.m-btn, .header .header-main .main-menu .offcanvas-trigger span {
  background-color: #5F52DC;
}

.c1-bo {
  border-color: #5F52DC !important;
}

.c2, a:hover, .page-title ul li, .widget.widget_service .service-list li a:hover, .widget.widget_service .service-list li a:hover i,
.widget.widget_service .service-list li a:hover svg *, .widget.widget_tag_cloud .tagcloud a:hover, .widget.widget_footer_menu .footer_menu.affiliate li a:hover, .star-rating i, .footer .copyright-footer .copyright-text a:hover, .swiper-button-prev:after, .swiper-button-next:after, .ui-range-slider .sale strong, .testimonial-single .review strong {
  color: #FCBF21;
}

.c2-bg, .owl-carousel button.owl-dot.active, .theme-input-group button, .theme-input-group button.submit-btn, .theme-input-group button:hover, .theme-input-group button.submit-btn:hover, .theme-input-group.crs button:hover, .ba, .btn, .btn-crm, .btn-crs, .btn-pay, .e-btn, .btn-book, .m-btn, .btn.animate-btn:after, .animate-btn.btn-crm:after, .animate-btn.btn-crs:after, .animate-btn.btn-pay:after, .animate-btn.e-btn:after, .animate-btn.btn-book:after, .animate-btn.m-btn:after, .btn.border-btn:hover, .border-btn.btn-crm:hover, .border-btn.btn-crs:hover, .border-btn.btn-pay:hover, .border-btn.e-btn:hover, .border-btn.btn-book:hover, .border-btn.m-btn:hover, .back-to-top {
  background-color: #FCBF21;
}

.c2-bo {
  border-color: #FCBF21 !important;
}

.text-color, .theme-input-group.style--two input, .theme-input-group.email-banner input, .theme-input-group.app-banner input, .theme-input-group.app2 input, .theme-input-group.pay input, .theme-input-group.management input, .theme-input-group.management2 input, .theme-input-group.proto input, .btn.border-btn, .border-btn.btn-crm, .border-btn.btn-crs, .border-btn.btn-pay, .border-btn.e-btn, .border-btn.btn-book, .border-btn.m-btn, .widget.widget_nav_menu ul li a, .widget.widget_categories ul li a, .widget.widget_meta ul li a, .widget.widget_service .service-list li a, .widget.widget_recent_entries .post-summary .posted-on a, .widget.widget_tag_cloud .tagcloud a, .banner.analytic .banner-content p.info, .single-blog-item .blog-content .blog-meta a, .post-details .entry-header .entry-meta li a, .post-details .entry-tags ul li a,
.post-details .entry-categories ul li a, .post-navigation .nav-link .posted-on, .footer.pos .widget .footer_menu li a, .earn-path-feature .single-service .service-content p, .cta-content.book .paperback .order-box .price .stroke-text del, .email-testimonial-slider.app2 .testimonial-item h3 span, .lecture-container .left-content .title, .form-group .theme-input-style, .form-group .entry-content select, .entry-content .form-group select,
.form-group .comment-content select, .comment-content .form-group select,
.form-group .page--content select, .page--content .form-group select, .widget .theme-input-style, .widget .entry-content select, .entry-content .widget select,
.widget .comment-content select, .comment-content .widget select,
.widget .page--content select, .page--content .widget select, .form-group .custom-select .select-selected, .widget .custom-select .select-selected, .form-group .custom-select .select-selected:after, .widget .custom-select .select-selected:after, .fake-btn, .social-links a {
  color: #707070;
}

.text-color-bg, #menu-button span, #menu-button span:before, #menu-button span:after {
  background-color: #707070;
}

.text-color-bo {
  border-color: #707070 !important;
}

/* CRM Page color */
.crm_c1, .social-icon.style--two a:hover, .theme-input-group.style--two button, .btn-crm.s-btn, .btn-crm.border-btn, .header .header-main.style--two .main-menu #menu-button, .header .header-main.style--two .main-menu .nav li a:hover, #mobile_menu .mobile-main-menu.style--two .nav li.current-menu-parent > a, #mobile_menu .mobile-main-menu.style--two .nav li.current-menu-item > a, #mobile_menu .mobile-main-menu.style--two .nav li a:hover, .banner.style--two .banner-content h1, .footer .footer-top .footer-top-left h2, .footer .copyright-footer .copyright-text.style--two a:hover, .cta-content.style--two h2, .single-data strong, .testimonial-single .ts-bottom p:before {
  color: #57548D;
}

.crm_c1-bg, .social-icon.style--two a, .theme-input-group.style--two button:hover, .single-service.style--two > span {
  background-color: #57548D;
}

.crm_c1-bo {
  border-color: #57548D !important;
}

.crm_c2, .list-check li:after, .theme-input-group.style--two button:hover, .header-btn .link-btn.style--two:hover, .widget.widget_footer_menu .footer_menu.crm li a:hover {
  color: #EBD281;
}

.crm_c2-bg, .social-icon.style--two a:hover, .theme-input-group.style--two button, .btn-crm.s-btn, .btn-crm.border-btn:hover, .back-to-top.crm {
  background-color: #EBD281;
}

.crm_c2-bo {
  border-color: #EBD281 !important;
}

/* Course Page color */
.crs_c1, .list-check.style--three li:after, .countdown-wrapper .countdown-tag, .widget.widget_footer_menu .footer_menu.crs li a:hover, .header .header-main.style--three .main-menu #menu-button, .header .header-main.style--three .main-menu .nav li a:hover, #mobile_menu .mobile-main-menu.style--three .nav li.current-menu-parent > a, #mobile_menu .mobile-main-menu.style--three .nav li.current-menu-item > a, #mobile_menu .mobile-main-menu.style--three .nav li a:hover, .rating.student-rating strong, .lecture-container .details .preview-text, .price-box .price-head span strong, .price-box .btn-crs.white, .price-box .theme-input-group.style--four button.btn-crs, .theme-input-group.style--four .price-box button.btn-crs {
  color: #5DD9C1;
}

.crs_c1-bg, .social-icon.course a:hover, .theme-input-group.course input, .video-play-button:hover, .btn-crs.border-btn:hover, .btn-pay.border-btn:hover, .border-btn.e-btn:hover, .price-box.two {
  background-color: #5DD9C1;
}

.crs_c1-bo {
  border-color: #5DD9C1 !important;
}

.crs_c2, .header-btn .link-btn.style--three, .header .header-main.ebook .main-menu .nav li a:hover, #mobile_menu .mobile-main-menu.ebook .nav li.current-menu-parent > a, #mobile_menu .mobile-main-menu.ebook .nav li.current-menu-item > a, #mobile_menu .mobile-main-menu.ebook .nav li a:hover, .banner.book .banner-content .doller, .banner.book .banner-content .star-rating i, .feature-box:before, .feature-box.two:before, .feature-box.two h2, .feature-box.three:before, .feature-box.three h2, .rating strong {
  color: #FBC006;
}

.crs_c2-bg, .theme-input-group.course button, .theme-input-group.course button:hover, .theme-input-group.author-newsletter button, .theme-input-group.author-newsletter button:hover, .video-play-button, .cart-btn span, .btn-crs.s-btn, .header-btn .link-btn.style--three:before, .header-btn .link-btn.style--three:after, .back-to-top.crs, .back-to-top.book, .price-box .btn-crs.white:hover, .price-box .theme-input-group.style--four button.btn-crs:hover, .theme-input-group.style--four .price-box button.btn-crs:hover {
  background-color: #FBC006;
}

.crs_c2-bo {
  border-color: #FBC006 !important;
}

/* Payment Page color */
.pay_c1, .social-icon.pay a i {
  color: #5A50A5;
}

.pay_c1-bg {
  background-color: #5A50A5;
}

.pay_c1-bo {
  border-color: #5A50A5 !important;
}

.pay_c2, .list-check.cards li:after, .list-check.pos li:after, .widget.widget_footer_menu .footer_menu.pay li a:hover, .header .header-main.style--four .main-menu .nav li a:hover, #mobile_menu .mobile-main-menu.style--four .nav li.current-menu-parent > a, #mobile_menu .mobile-main-menu.style--four .nav li.current-menu-item > a, #mobile_menu .mobile-main-menu.style--four .nav li a:hover {
  color: #FE957B;
}

.pay_c2-bg, .social-icon.pay a:hover, .theme-input-group.pay button, .theme-input-group.pay button:hover, .back-to-top.pay {
  background-color: #FE957B;
}

.pay_c2-bo {
  border-color: #FE957B !important;
}

/* Ebook Page color */
.book_c1, .theme-input-group.author-newsletter button:hover, .cta-content.book .paperback .order-box .price strong, .cta-content.book .paperback .order-box .btn-book.s-btn:hover, .cta-content.book .paperback .order-box.box2 .btn-book.s-btn, .feature-box h2, .feature-box.two.four h2 {
  color: #205CE2;
}

.book_c1-bg, .bg-right, .cta-content.book .paperback .order-box .btn-book.s-btn, .cta-content.book .paperback .order-box.box2 .btn-book.s-btn:hover, .bg-left, .chaptar .chaptar-box:hover, .chaptar .chaptar-box.active {
  background-color: #205CE2;
}

.book_c1-bo {
  border-color: #205CE2 !important;
}

.crs_c2, .header-btn .link-btn.style--three, .header .header-main.ebook .main-menu .nav li a:hover, #mobile_menu .mobile-main-menu.ebook .nav li.current-menu-parent > a, #mobile_menu .mobile-main-menu.ebook .nav li.current-menu-item > a, #mobile_menu .mobile-main-menu.ebook .nav li a:hover, .banner.book .banner-content .doller, .banner.book .banner-content .star-rating i, .feature-box:before, .feature-box.two:before, .feature-box.two h2, .feature-box.three:before, .feature-box.three h2, .rating strong {
  color: #FBC006;
}

.crs_c2-bg, .theme-input-group.course button, .theme-input-group.course button:hover, .theme-input-group.author-newsletter button, .theme-input-group.author-newsletter button:hover, .video-play-button, .cart-btn span, .btn-crs.s-btn, .header-btn .link-btn.style--three:before, .header-btn .link-btn.style--three:after, .back-to-top.crs, .back-to-top.book, .price-box .btn-crs.white:hover, .price-box .theme-input-group.style--four button.btn-crs:hover, .theme-input-group.style--four .price-box button.btn-crs:hover {
  background-color: #FBC006;
}

.crs_c2-bo {
  border-color: #FBC006 !important;
}

/* Plugin Page color */
.plug_c1, .header .header-main.plugin .main-menu .nav li a:hover, #mobile_menu .mobile-main-menu.plugin .nav li.current-menu-parent > a, #mobile_menu .mobile-main-menu.plugin .nav li.current-menu-item > a, #mobile_menu .mobile-main-menu.plugin .nav li a:hover {
  color: #5DD9C1;
}

.plug_c1-bg, .btn-crm.border-btn.style--two:hover, .back-to-top.plug {
  background-color: #5DD9C1;
}

.plug_c1-bo {
  border-color: #5DD9C1 !important;
}

.plug_c2, .widget.widget_footer_menu .footer_menu.plug li a:hover, .banner.style--two.plugins .banner-content h1, .banner.email.plugins .banner-content h1 {
  color: #FBF349;
}

.plug_c2-bg, .social-icon.plug a:hover, .theme-input-group.pay.plug button, .theme-input-group.pay.plug button:hover {
  background-color: #FBF349;
}

.plug_c2-bo {
  border-color: #FBF349 !important;
}

/* Email Page color */
.email_c1, .list-check.job li:after, .theme-input-group.style--four button:hover, .line-btn, .line-btn:hover, .page-title ul li:not(:last-child) a:hover, .header .header-main.email .main-menu #menu-button, .content-404 a, .custom-select .select-items div:hover, .custom-select .select-items .same-as-selected, .single-job .job-content .job-category, .single-job .job-content h3 a:hover, .signup-form .login-text a, .member .member-content h3 a {
  color: #857DFF;
}

.email_c1-bg, .social-icon.about a, .theme-input-group.email-banner button, .search-form .theme-input-group input, .widget.widget_search .theme-input-group input, .widget.widget_newsletter.style--two .theme-input-group input, .btn.submit-btn:hover, .submit-btn.btn-crm:hover, .submit-btn.btn-crs:hover, .submit-btn.btn-pay:hover, .submit-btn.e-btn:hover, .submit-btn.btn-book:hover, .submit-btn.m-btn:hover, .line-btn:before, .line-btn:after, .coming-soon-img, .modal-header .modal-title .modal-title-icon {
  background-color: #857DFF;
}

.email_c1-bo, .custom-select .select-selected.select-arrow-active {
  border-color: #857DFF !important;
}

.email_c2, .list-check.email-check li:after, .line-btn.job, .line-btn.job-header, .widget.widget_nav_menu ul li a:hover, .widget.widget_categories ul li a:hover, .widget.widget_meta ul li a:hover, .widget.widget_recent_entries .post-summary .post-title a:hover, .header .header-main.email .main-menu .nav li a:hover, #mobile_menu .mobile-main-menu.email .nav li.current-menu-parent > a, #mobile_menu .mobile-main-menu.email .nav li.current-menu-item > a, #mobile_menu .mobile-main-menu.email .nav li a:hover, .post-details .entry-header .entry-meta li a:hover, .footer.style--four .widget .footer_menu li:hover a, .email-testimonial-slider .testimonial-item h3, .quick-links ul li a:hover, .signup-form .login-text a:hover, .member .member-content h3 a:hover, .social-links a:hover {
  color: #FF937D;
}

.email_c2-bg, .social-icon.email a:hover, .social-icon.about a:hover, .theme-input-group.email-banner button:hover, .theme-input-group.style--four button, .theme-input-group.style--four button:hover, .pagination li a:hover, .pagination li a.current,
.pagination li span:hover,
.pagination li span.current, .search-form .theme-input-group button, .widget.widget_search .theme-input-group button, .widget.widget_newsletter.style--two .theme-input-group button, .btn.submit-btn, .submit-btn.btn-crm, .submit-btn.btn-crs, .submit-btn.btn-pay, .submit-btn.e-btn, .submit-btn.btn-book, .submit-btn.m-btn, .line-btn.job:after, .line-btn.job-header:before, .line-btn.job-header:after, .widget.share .social-icon a:hover, .back-to-top.email, .modal-header button.close {
  background-color: #FF937D;
}

.email_c2-bo {
  border-color: #FF937D !important;
}

/* Management Page color */
.management_c1, .m-btn.border-btn.style--two, .header-btn.management .link-btn:last-child, .banner.style--two.management .banner-content h1, .feature-content.management h2, .feature-one-content h2.management_c1 {
  color: #625FD1;
}

.management_c1-bg, .m-btn.border-btn.style--two:hover, .single-service.style--two.management > span {
  background-color: #625FD1;
}

.management_c1-bo, .m-btn.border-btn.style--two {
  border-color: #625FD1 !important;
}

.management_c2, .header-btn.management .link-btn:not(:last-child):hover, .header-btn.management .link-btn:last-child:hover, .header .header-main.management .main-menu .nav li a:hover, #mobile_menu .mobile-main-menu.management .nav li.current-menu-parent > a, #mobile_menu .mobile-main-menu.management .nav li.current-menu-item > a, #mobile_menu .mobile-main-menu.management .nav li a:hover, .footer.management .widget .footer_menu li a:hover, .footer.management .footer-bottom a:hover {
  color: #FF4D8C;
}

.management_c2-bg, .social-icon.management a:hover, .theme-input-group.management button, .theme-input-group.management2 button, .m-btn.s-btn, .back-to-top.man {
  background-color: #FF4D8C;
}

.management_c2-bo {
  border-color: #FF4D8C !important;
}

/* Analytic Page color */
.analytic_c1, .theme-input-group.style--two.analytic button:hover, .header-btn.pos .link-btn:not(:last-child), .header-btn.pos .link-btn:last-child:hover, .banner.analytic .banner-content h1, .footer .footer-top .footer-top-left.pos h2, .swiper-button-prev.style--two:after, .swiper-button-next.style--two:after, .cta-content a.btn-pay.analytic:hover, .cta-content a.analytic.e-btn:hover, .single-price .price-head .price strong, .single-price .price-body .btn-pay:hover, .single-price .price-body .e-btn:hover {
  color: #00418E;
}

.analytic_c1-bg, .social-icon.analytic a, .social-icon.pos a, .footer.style--two.analytic, .single-price.active:after {
  background-color: #00418E;
}

.analytic_c1-bo {
  border-color: #00418E !important;
}

.analytic_c2, .list-check.pos li:after, .link-btn.analytic:hover, .header-btn.pos .link-btn:not(:last-child):hover, .header-btn.pos .link-btn:last-child, .line-btn.analytic, .widget.widget_footer_menu .footer_menu.analytic li a:hover, .header .header-main.analytic .main-menu .nav li a:hover, #mobile_menu .mobile-main-menu.analytic .nav li.current-menu-parent > a, #mobile_menu .mobile-main-menu.analytic .nav li.current-menu-item > a, #mobile_menu .mobile-main-menu.analytic .nav li a:hover, .footer.pos .widget .footer_menu li a:hover, .single-price.active .price-head .price strong {
  color: #FF5B83;
}

.analytic_c2-bg, .social-icon.analytic a:hover, .social-icon.pos a:hover, .theme-input-group.style--two.analytic button, .theme-input-group.style--two.analytic button:hover, .btn-pay.pos.border-btn:hover, .pos.border-btn.e-btn:hover, .line-btn.analytic:before, .line-btn.analytic:after, .back-to-top.analytic, .cta-content a.btn-pay.analytic:hover, .cta-content a.analytic.e-btn:hover, .toggle, .single-price .price-body .btn-pay:hover, .single-price .price-body .e-btn:hover {
  background-color: #FF5B83;
}

.analytic_c2-bo, .btn-pay.pos.border-btn, .pos.border-btn.e-btn {
  border-color: #FF5B83 !important;
}

/* Hosting Page color */
.host_c1, .list-check.host li:after, .email-testimonial-slider.host .testimonial-item h3 {
  color: #3E4491;
}

.host_c1-bg {
  background-color: #3E4491;
}

.host_c1-bo {
  border-color: #3E4491 !important;
}

.host_c2, .widget.widget_footer_menu .footer_menu.host li a:hover, .header .header-main.host .main-menu .nav li a:hover, #mobile_menu .mobile-main-menu.host .nav li.current-menu-parent > a, #mobile_menu .mobile-main-menu.host .nav li.current-menu-item > a, #mobile_menu .mobile-main-menu.host .nav li a:hover, .pkg .pkg-list .right div {
  color: #F7A400;
}

.host_c2-bg, .list-check.host li:after, .social-icon.host a:hover, .theme-input-group.style--four.host button, .theme-input-group.style--four.host button:hover, .btn-crm.border-btn.style--three:hover, .back-to-top.host, .pricing-toogle.host .switch, .pkg.active .pkg-list .right .btn-pay.host, .pkg.active .pkg-list .right .host.e-btn, .pkg:hover .pkg-list .right .btn-pay.host, .pkg:hover .pkg-list .right .host.e-btn, .pkg.active .pkg-list .right .btn-pay.host:hover, .pkg.active .pkg-list .right .host.e-btn:hover, .pkg:hover .pkg-list .right .btn-pay.host:hover, .pkg:hover .pkg-list .right .host.e-btn:hover {
  background-color: #F7A400;
}

.host_c2-bo {
  border-color: #F7A400 !important;
}

.host_c3, .theme-input-group.style--four.host button:hover, .pkg.active .pkg-list .right .btn-pay.host:hover, .pkg.active .pkg-list .right .host.e-btn:hover, .pkg:hover .pkg-list .right .btn-pay.host:hover, .pkg:hover .pkg-list .right .host.e-btn:hover {
  color: #180D5B;
}

.host_c3-bg, .pricing-toogle.host .toggle, .pkg .pkg-list > span, .pkg.active .pkg-list {
  background-color: #180D5B;
}

.host_c3-bo {
  border-color: #180D5B !important;
}

/* App Landing Page color */
.app_c1, .header .header-main.app .main-menu .nav li a:hover, #mobile_menu .mobile-main-menu.app .nav li.current-menu-parent > a, #mobile_menu .mobile-main-menu.app .nav li.current-menu-item > a, #mobile_menu .mobile-main-menu.app .nav li a:hover {
  color: #8EC9F8;
}

.app_c1-bg, .theme-input-group.app-banner button {
  background-color: #8EC9F8;
}

.app_c1-bo {
  border-color: #8EC9F8 !important;
}

.app_c2, .btn.app-btn, .app-btn.btn-crm, .app-btn.btn-crs, .app-btn.btn-pay, .app-btn.e-btn, .app-btn.btn-book, .app-btn.m-btn, .line-btn.app {
  color: #FB7D4E;
}

.app_c2-bg, .theme-input-group.app-banner button:hover, .btn.app-btn:hover, .app-btn.btn-crm:hover, .app-btn.btn-crs:hover, .app-btn.btn-pay:hover, .app-btn.e-btn:hover, .app-btn.btn-book:hover, .app-btn.m-btn:hover, .line-btn.app:before, .line-btn.app:after, .back-to-top.app {
  background-color: #FB7D4E;
}

.app_c2-bo {
  border-color: #FB7D4E !important;
}

/* Learning App Page color */
.app2_c1 {
  color: #5CB6FE;
}

.app2_c1-bg {
  background-color: #5CB6FE;
}

.app2_c1-bo {
  border-color: #5CB6FE !important;
}

.app2_c2, .header .header-main.app2 .main-menu .nav li a:hover, #mobile_menu .mobile-main-menu.app2 .nav li.current-menu-parent > a, #mobile_menu .mobile-main-menu.app2 .nav li.current-menu-item > a, #mobile_menu .mobile-main-menu.app2 .nav li a:hover, .footer.app2 .widget .footer_menu li a:hover, .footer.app2 .footer-bottom a:hover {
  color: #FF6773;
}

.app2_c2-bg, .social-icon.app2 a:hover, .btn.white-btn:hover, .white-btn.btn-crm:hover, .white-btn.btn-crs:hover, .white-btn.btn-pay:hover, .white-btn.e-btn:hover, .white-btn.btn-book:hover, .white-btn.m-btn:hover, .back-to-top.app2 {
  background-color: #FF6773;
}

.app2_c2-bo {
  border-color: #FF6773 !important;
}

/* Prototype Page color */
.proto_c1, .video-play-button.proto:hover i, .btn.download:hover, .download.btn-crm:hover, .download.btn-crs:hover, .download.btn-pay:hover, .download.e-btn:hover, .download.btn-book:hover, .download.m-btn:hover, .btn.download.s-btn, .download.s-btn.btn-crm, .download.s-btn.btn-crs, .download.s-btn.btn-pay, .download.s-btn.e-btn, .download.s-btn.btn-book, .download.s-btn.m-btn, .banner.proto .banner-content h1, .feature-content.proto h2, .trapezium .feature-content h3, .tab-btn.proto li.active {
  color: #5A50A5;
}

.proto_c1-bg, .theme-input-group.proto button:hover, .btn.proto, .proto.btn-crm, .proto.btn-crs, .proto.btn-pay, .proto.e-btn, .proto.btn-book, .proto.m-btn, .btn.download, .download.btn-crm, .download.btn-crs, .download.btn-pay, .download.e-btn, .download.btn-book, .download.m-btn, .btn.download.s-btn:hover:after, .download.s-btn.btn-crm:hover:after, .download.s-btn.btn-crs:hover:after, .download.s-btn.btn-pay:hover:after, .download.s-btn.e-btn:hover:after, .download.s-btn.btn-book:hover:after, .download.s-btn.m-btn:hover:after, .footer.proto {
  background-color: #5A50A5;
}

.proto_c1-bo {
  border-color: #5A50A5 !important;
}

.proto_c2, .line-btn.proto, .header .header-main.proto .main-menu .nav li a:hover, #mobile_menu .mobile-main-menu.proto .nav li.current-menu-parent > a, #mobile_menu .mobile-main-menu.proto .nav li.current-menu-item > a, #mobile_menu .mobile-main-menu.proto .nav li a:hover, .footer.proto .widget .footer_menu li a:hover {
  color: #FFA68B;
}

.proto_c2-bg, .social-icon.proto a:hover, .theme-input-group.proto button, .video-play-button.proto, .btn.proto:hover, .proto.btn-crm:hover, .proto.btn-crs:hover, .proto.btn-pay:hover, .proto.e-btn:hover, .proto.btn-book:hover, .proto.m-btn:hover, .line-btn.proto:before, .line-btn.proto:after, .back-to-top.proto, .cta-content.proto:after, .tab-btn.proto li:after {
  background-color: #FFA68B;
}

.proto_c2-bo {
  border-color: #FFA68B !important;
}

/* ************************
   02.1: Common Classes
   ********************* */
/* Transitions */
.trans2, .social-icon.proto a i, .owl-carousel.email-testimonial-slider .owl-nav button .svg g, .owl-carousel.app-testimonial-slider .owl-nav button .svg g, .owl-carousel.email-testimonial-slider .owl-nav button .svg g path, .owl-carousel.app-testimonial-slider .owl-nav button .svg g path, .theme-input-group button, .theme-input-group button.submit-btn, .theme-input-group.style--two button, .theme-input-group.style--four button, .video-play-button:hover i, .btn.white-btn, .white-btn.btn-crm, .white-btn.btn-crs, .white-btn.btn-pay, .white-btn.e-btn, .white-btn.btn-book, .white-btn.m-btn, .btn.download .svg path, .download.btn-crm .svg path, .download.btn-crs .svg path, .download.btn-pay .svg path, .download.e-btn .svg path, .download.btn-book .svg path, .download.m-btn .svg path, .download-btn2 .icon .svg path, .download-btn2 .content p, .download-btn2 .content h5, .header, .header .header-main .main-menu .nav li ul li ul, .banner .banner-img img, .chaptar .chaptar-box, .switch, .single-price.app .price-footer .white-btn, .custom-select .select-selected {
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear;
}

.trans3, a, .list-proses li, .social-icon.pay a i, .social-icon.plug a i, .social-icon.email a i, .social-icon.management a i, .owl-carousel button.owl-dot, .owl-carousel .owl-nav button, .owl-carousel.book-review-slider .owl-nav button .svg g path, .owl-carousel.customer-review-slider .owl-nav button .svg g path, .theme-input-style, .entry-content select,
.comment-content select,
.page--content select, .theme-input-group.email-banner button, .theme-input-group.app2 button:after, .theme-input-group.crs button .svg g path, .theme-input-group.crs button .svg g g, .btn, .btn-crm, .btn-crs, .btn-pay, .e-btn, .btn-book, .m-btn, .btn.animate-btn:after, .animate-btn.btn-crm:after, .animate-btn.btn-crs:after, .animate-btn.btn-pay:after, .animate-btn.e-btn:after, .animate-btn.btn-book:after, .animate-btn.m-btn:after, .btn.app2:after, .app2.btn-crm:after, .app2.btn-crs:after, .app2.btn-pay:after, .app2.e-btn:after, .app2.btn-book:after, .app2.m-btn:after, .btn.download:after, .download.btn-crm:after, .download.btn-crs:after, .download.btn-pay:after, .download.e-btn:after, .download.btn-book:after, .download.m-btn:after, .arrow-btn .svg, .btn-crm.btn-view-more .svg line, .btn-crm.btn-view-more .svg path, .btn-crs.btn-view-more .svg line, .btn-crs.btn-view-more .svg path, .btn-pay.btn-view-more .svg line, .btn-view-more.e-btn .svg line, .btn-pay.btn-view-more .svg path, .btn-view-more.e-btn .svg path, .e-btn:after, .widget.widget_service .service-list li img,
.widget.widget_service .service-list li svg,
.widget.widget_service .service-list li i, .header .header-main.app2.sticky .btn.white-btn:after, .header .header-main.app2.sticky .white-btn.btn-crm:after, .header .header-main.app2.sticky .white-btn.btn-crs:after, .header .header-main.app2.sticky .white-btn.btn-pay:after, .header .header-main.app2.sticky .white-btn.e-btn:after, .header .header-main.app2.sticky .white-btn.btn-book:after, .header .header-main.app2.sticky .white-btn.m-btn:after, .header .header-main .main-menu .offcanvas-trigger span, .offcanvas-overlay, #mobile_menu, #menu-button span, #menu-button span:before, #menu-button span:after, .post-navigation .nav-link .nav-title, .dot-line, .dot-line:after, .dot-line.style--two:before, .single-service, .single-service.style--two .service-icon, .single-service.style--two .service-icon .svg path, .single-service.style--two .service-content h3, .single-service.style--two .service-content P, .single-service.style--six .service-icon .svg, .host-wc-right .single-service .service-icon img, .host-wc-right .single-service .service-icon .svg, .trapezium:before, .tab-btn.proto li:after, .crm-testimonial.owl-carousel > .owl-nav button .svg g path, .pkg .pkg-list, .pkg .pkg-list .right div, .pkg .pkg-list .right div strong, .owl-carousel.logo-carousel .owl-item img, .single-job, .modal-header button.close {
  -webkit-transition: 0.3s linear;
  transition: 0.3s linear;
}

.trans4, .header .header-main .logo, .single-service.style--three.pay .service-icon .svg g path {
  -webkit-transition: 0.4s linear;
  transition: 0.4s linear;
}

.trans5, .theme-input-group.crs .input-wrap .highlight, .header .header-main.email .main-menu .nav li.has-sub-item:after, .single-service.style--three.pay .service-icon, .pkg .pkg-list .left h3, .pkg .pkg-list .left p {
  -webkit-transition: 0.5s linear;
  transition: 0.5s linear;
}

/* Font Family */
h1,
h2,
h3,
h4,
h5,
h6,
.hind,
.list-check.host li,
.pagination li a,
.pagination li span,
.btn,
.btn-crm,
.btn-crs,
.btn-pay,
.e-btn,
.btn-book,
.m-btn,
.link-btn.analytic,
.arrow-btn,
.header-btn .link-btn,
.line-btn,
.widget.widget_service .service-list,
.widget.share .social-icon span,
.widget.widget_calendar table caption,
.widget.widget_calendar table th,
.course-info h3,
.post-details .entry-tags ul li:first-child,
.post-details .entry-categories ul li:first-child,
.comment-respond .comment-form label,
.labelText,
.customer span,
.ui-range-slider .sale strong,
.ui-range-slider .total-earning strong,
.cta-content.book .paperback .order-box .price strong,
.cta-content.book .paperback .order-box .price .stroke-text del,
.feature-box:before,
.work-slider-dots .active .dots-count,
.tab-btn.proto li,
.single-data strong,
.email-testimonial-slider .testimonial-item P,
.accordion > .card .accordion-title .accordion-title-meta span,
.lecture-container .details .preview-text,
.price-box .price-head span strong,
.price-box .price-head span del,
.single-price .price-head .price strong,
.single-price.app .price-footer span,
.single-price.app2 .price-head span,
.pkg .pkg-list .right div strong,
.form-group .col-form-label,
.widget .col-form-label,
.fake-btn,
.signup-form .login-text {
  font-family: "Hind", sans-serif;
}

body,
.ubuntu,
.email-testimonial-slider.app2 .testimonial-item h3 span,
.email-testimonial-slider.app2 .testimonial-item P,
.app-testimonial-slider .single-slide h4 span {
  font-family: "Ubuntu", sans-serif;
}

/* Text White */
.text-white *, .price-box.two *,
.text-white h1,
.price-box.two h1,
.text-white h2,
.price-box.two h2,
.text-white h3,
.price-box.two h3,
.text-white h4,
.price-box.two h4,
.text-white h5,
.price-box.two h5,
.text-white h6,
.price-box.two h6 {
  color: #ffffff;
}

/* Border Radius */
.radius-5, .owl-carousel .owl-nav button, .theme-input-group.app2, .theme-input-group.app2 input, .theme-input-group.pay.plug input, .theme-input-group.pay.plug button, .btn.white-btn, .white-btn.btn-crm, .white-btn.btn-crs, .white-btn.btn-pay, .white-btn.e-btn, .white-btn.btn-book, .white-btn.m-btn, .btn.app2, .app2.btn-crm, .app2.btn-crs, .app2.btn-pay, .app2.e-btn, .app2.btn-book, .app2.m-btn, .btn-pay.pos, .pos.e-btn {
  border-radius: 5px;
}

.radius-10, .list-proses li, .theme-input-group.proto, .theme-input-group.proto input, .btn.proto, .proto.btn-crm, .proto.btn-crs, .proto.btn-pay, .proto.e-btn, .proto.btn-book, .proto.m-btn, .btn.download, .download.btn-crm, .download.btn-crs, .download.btn-pay, .download.e-btn, .download.btn-book, .download.m-btn, .btn-pay.pos.border-btn.two, .pos.border-btn.two.e-btn, .btn-pay.host, .host.e-btn, .swiper-container.how-work-slider .swiper-slide.active, .single-service .service-icon {
  border-radius: 10px;
}

.radius-20, .countdown-wrapper, .single-service, .single-service.style--two .service-icon, .single-service.proto .service-icon, .host-wc-right .single-service, .earn-path-feature .single-service .service-icon, .cta-content.proto:after, .row.cta-bg:after, .feature-wrap.plug, .single-feature.box, .row.feature-bg:after, .feature-single, .trapezium:before, .trapezium .feature-icon, .testimonial-single, .testimonial-single.style--two.plug, .accordion > .card, .chaptar .chaptar-box, .price-box, .pkg .pkg-list, .pkg-info {
  border-radius: 20px;
}

.radius-30, .ui-range-slider {
  border-radius: 30px;
}

.radius-40 {
  border-radius: 40px;
}

.radius-50, .theme-input-group input, .countdown-wrapper .countdown-tag, .btn, .btn-crm, .btn-crs, .btn-pay, .e-btn, .btn-book, .m-btn, .btn.animate-btn:after, .animate-btn.btn-crm:after, .animate-btn.btn-crs:after, .animate-btn.btn-pay:after, .animate-btn.e-btn:after, .animate-btn.btn-book:after, .animate-btn.m-btn:after {
  border-radius: 50px;
}

.radius-60, .earn-path-feature, .feature-wrapper:after {
  border-radius: 60px;
}

.radius, .list-check.host li:after, .social-icon a, .owl-carousel.book-review-slider .owl-nav button, .owl-carousel.customer-review-slider .owl-nav button, .video-play-button, .cart-btn span, .dot-line:after, .dot-line.style--two:before, .single-service.style--two > span, .single-service.style--three.pay .service-icon, .testimonial-single .tst-content .ts-img img, .pkg .pkg-list > span, .modal-header button.close, .modal-header .modal-title .modal-title-icon {
  border-radius: 50%;
}

/* Box Shadow */
.box-shadow, .social-icon.app2 a, .theme-input-group.app-banner, .theme-input-group.app2, .theme-input-group.proto, .countdown-wrapper, .btn.white-btn, .white-btn.btn-crm, .white-btn.btn-crs, .white-btn.btn-pay, .white-btn.e-btn, .white-btn.btn-book, .white-btn.m-btn, .download-btn2, .header .header-main.sticky, .header .header-main .main-menu .nav li ul, #mobile_menu, .swiper-container.how-work-slider .swiper-slide.active, .single-service.style--two, .single-service.style--six, .single-service.host:hover, .feature-img.l-app img, .feature-wrap, .feature-single, .testimonial-single.style--two.plug, .testimonial-single.style--three, .testimonial-slider.student-review .owl-stage-outer .testimonial-single.style--two, .accordion > .card, .price-box, .toggle, .single-price.app, .pkg .pkg-list, .pkg-info, .custom-select .select-items, .single-job:hover, .modal-dialog, .signup-form-wrap, .signin-form-wrap, .contact-form, .member {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.06);
}

.no-shadow {
  box-shadow: none;
}

/* List Check */
.list-check li {
  font-size: 20px;
  padding-left: 35px;
  position: relative;
  font-weight: 500;
}

.list-check li:not(:last-child) {
  margin-bottom: 20px;
}

.list-check li:after {
  position: absolute;
  font-family: 'FontAwesome';
  content: "";
  line-height: inherit;
  left: 0px;
  top: -2px;
}

.list-check.style--two {
  margin-top: 40px;
  margin-bottom: 44px;
}

.list-check.style--two li {
  padding-left: 50px;
  font-weight: 500;
}

.list-check.style--two li h3 {
  font-size: 26px;
  color: #292929;
  font-weight: 500;
  margin-bottom: 12px;
}

.list-check.style--two li:not(:last-child) {
  margin-bottom: 30px;
}

.list-check.style--two li:after {
  left: 0px;
  top: 0px;
  content: url("../img/feature/fb1.png");
}

.list-check.style--two li:nth-child(2):after {
  content: url("../img/feature/fb2.png");
}

.list-check.style--two.pay {
  margin-top: 40px;
  margin-bottom: 0px;
}

.list-check.style--two.pay li {
  padding-left: 50px;
  font-weight: 500;
}

.list-check.style--two.pay li h3 {
  font-size: 26px;
  color: #292929;
  font-weight: 500;
  margin-bottom: 12px;
}

.list-check.style--two.pay li:not(:last-child) {
  margin-bottom: 30px;
}

.list-check.style--two.pay li:after {
  left: 0px;
  top: 0px;
  content: url("../img/icons/pay-a1.png");
}

.list-check.style--two.pay li:nth-child(2):after {
  content: url("../img/icons/pay-a2.png");
}

.list-check.style--two.pay li:nth-child(3):after {
  content: url("../img/icons/pay-a3.png");
}

.list-check.style--two.pay2 li:after {
  left: 0px;
  top: 0px;
  content: url("../img/icons/pay-a4.png");
}

.list-check.style--two.pay2 li:nth-child(2):after {
  content: url("../img/icons/pay-a5.png");
}

.list-check.style--two.pay2 li:nth-child(3):after {
  content: url("../img/icons/pay-a6.png");
}

.list-check.style--three {
  margin-bottom: 44px;
}

.list-check.style--three li {
  font-size: 16px;
  font-weight: 400;
  color: #292929;
  padding-left: 30px;
}

.list-check.style--three li:not(:last-child) {
  margin-bottom: 5px;
}

.list-check.style--three li:after {
  top: -1px;
}

.list-check.style--three.price {
  margin-bottom: 32px;
}

.list-check.author-meta li {
  font-size: 16px;
  font-weight: 300;
}

.list-check.author-meta li:not(:last-child) {
  margin-bottom: 5px;
}

.list-check.author-meta li:after {
  content: url("../img/icons/a1.png");
  top: 4px;
}

.list-check.author-meta li:nth-child(2):after {
  content: url("../img/icons/a2.png");
}

.list-check.author-meta li:nth-child(3):after {
  content: url("../img/icons/a3.png");
}

.list-check.cards li:not(:last-child), .list-check.pos li:not(:last-child) {
  margin-bottom: 30px;
}

.list-check.cards li h3, .list-check.pos li h3 {
  color: #292929;
  font-weight: 500;
  margin-bottom: 10px;
}

.list-check.cards li:after, .list-check.pos li:after {
  top: -8px;
  font-size: 22px;
}

.list-check.email-check {
  display: flex;
  flex-wrap: wrap;
}

.list-check.email-check li {
  flex: 0 0 50%;
  margin-bottom: 14px;
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 300;
}

@media only screen and (max-width: 575px) {
  .list-check.email-check li {
    flex: 0 0 100%;
  }
}

.list-check.host li {
  margin-bottom: 18px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.3;
  padding-left: 30px;
}

@media only screen and (max-width: 575px) {
  .list-check.host li {
    flex: 0 0 100%;
  }
}

.list-check.host li:after {
  content: "";
  width: 10px;
  height: 10px;
  top: 4px;
}

.list-check.pos li {
  padding-left: 40px;
}

.list-check.job li {
  font-weight: 300;
}

.list-check.job li:not(:last-child) {
  margin-bottom: 15px;
}

.list-check.job li:after {
  content: "\f10c";
  font-size: 12px;
  top: 8px;
}

.list-uncheck li:not(:last-child) {
  margin-bottom: 35px;
}

.list-uncheck li h3 {
  font-size: 22px;
  font-weight: 500;
  color: #292929;
  margin-bottom: 8px;
}

.list-uncheck.host li:not(:last-child) {
  margin-bottom: 25px;
}

.list-uncheck.host li h3 {
  font-size: 24px;
}

.list-uncheck.host li p {
  font-size: 18px;
  max-width: 500px;
}

.list-inline li {
  display: inline-block;
}

/* List Proses */
.list-proses {
  padding-left: 50px;
}

@media only screen and (max-width: 1199px) {
  .list-proses {
    padding-left: 0;
  }
}

.list-proses li {
  padding: 40px;
  padding-bottom: 35px;
}

@media only screen and (max-width: 575px) {
  .list-proses li {
    padding: 10px;
  }
}

.list-proses li:hover {
  background: #F1FAFF;
}

.list-proses li:not(:last-child) {
  margin-bottom: 10px;
}

.list-proses li .img {
  margin-right: 30px;
}

.list-proses li .content h3 {
  color: #292929;
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 10px;
}

.list-proses li .content p {
  font-weight: 400;
}

.list-proses li:nth-child(2):hover {
  background: #E9FFFA;
}

.list-proses li:nth-child(3):hover {
  background: #FFF4F3;
}

.list-how-works li:not(:last-child) {
  margin-bottom: 60px;
}

.list-how-works li .img {
  margin-right: 50px;
}

@media only screen and (max-width: 1199px) {
  .list-how-works li .img {
    margin-right: 40px;
  }
}

@media only screen and (max-width: 479px) {
  .list-how-works li .img {
    margin: 0 auto 30px;
  }
}

@media only screen and (max-width: 479px) {
  .list-how-works li {
    flex-direction: column;
    text-align: center;
  }
}

.list-how-works li .content h3 {
  color: #292929;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 18px;
}

.list-how-works li .content p {
  font-weight: 400;
}

/* Social Icons */
.social-icon a {
  width: 40px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #292929;
  background: #f9f9f9;
}

.social-icon a:not(:last-child) {
  margin-right: 10px;
}

.social-icon a:hover {
  color: #ffffff;
}

.social-icon.style--two a {
  color: #ffffff;
}

.social-icon.analytic a {
  color: #ffffff;
}

.social-icon.analytic a:hover {
  color: #ffffff;
}

.social-icon.pos a {
  color: #ffffff;
}

.social-icon.pos a:hover {
  color: #ffffff;
}

.social-icon.course a:hover {
  color: #ffffff;
}

.social-icon.pay a {
  background: #ffffff;
}

.social-icon.pay a:hover i {
  color: #ffffff;
}

.social-icon.plug a {
  background: #ffffff;
}

.social-icon.plug a i {
  color: #292929;
}

.social-icon.plug a:hover i {
  color: #4E2FA9;
}

.social-icon.email a {
  background: #ffffff;
}

.social-icon.email a i {
  color: #857DFF;
}

.social-icon.email a:hover i {
  color: #ffffff;
}

.social-icon.management a {
  background: #ffffff;
}

.social-icon.management a i {
  color: #625FD1;
}

.social-icon.management a:hover i {
  color: #ffffff;
}

.social-icon.host a {
  background: #ffffff;
}

.social-icon.host a i {
  color: #180D5B;
}

.social-icon.host a:hover i {
  color: #ffffff;
}

.social-icon.app a {
  background: #ffffff;
}

.social-icon.app a i {
  color: #2154FC;
}

.social-icon.app a:hover {
  background-color: #2154FC;
}

.social-icon.app a:hover i {
  color: #ffffff;
  animation: fadeInUp10 .3s linear .1s 1 backwards;
}

.social-icon.app a:nth-child(2) i {
  color: #3DB1FF;
}

.social-icon.app a:nth-child(2):hover {
  background-color: #3DB1FF;
}

.social-icon.app a:nth-child(2):hover i {
  color: #ffffff;
}

.social-icon.app a:nth-child(3) i {
  color: #FF65A8;
}

.social-icon.app a:nth-child(3):hover {
  background-color: #FF65A8;
}

.social-icon.app a:nth-child(3):hover i {
  color: #ffffff;
}

.social-icon.app a:nth-child(4) i {
  color: #238BDE;
}

.social-icon.app a:nth-child(4):hover {
  background-color: #238BDE;
}

.social-icon.app a:nth-child(4):hover i {
  color: #ffffff;
}

.social-icon.app2 a {
  background: #ffffff;
}

.social-icon.app2 a i {
  color: #292929;
}

.social-icon.app2 a:hover i {
  color: #ffffff;
}

.social-icon.proto a {
  background: #ffffff;
}

.social-icon.proto a i {
  color: #5A50A5;
}

.social-icon.proto a:hover i {
  color: #ffffff;
}

.social-icon.about span {
  display: block;
  margin-bottom: 10px;
}

.social-icon.about a {
  color: #ffffff;
}

.social-icon.about a:hover {
  color: #ffffff;
}

.social-icon.style--three a {
  background-color: #FFF6F1;
  color: #292929;
}

.social-icon.style--three a:hover {
  background-color: #FFE8DC;
  color: #292929;
}

@media only screen and (max-width: 991px) {
  .footer-social-icons {
    margin-bottom: 15px;
  }
}

.footer-social-icons span {
  display: inline-block;
  margin-right: 5px;
  font-size: 18px;
}

.footer-social-icons a {
  display: inline-block;
  padding: 0 10px;
  font-size: 16px;
}

.footer-social-icons a:hover i {
  color: #FBC006;
}

/* Owl Dots */
.owl-carousel .owl-dots {
  position: absolute;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
}

.owl-carousel button.owl-dot {
  display: inline-block;
  height: 3px;
  width: 16px;
}

.owl-carousel button.owl-dot.active {
  width: 28px;
}

/* Owl Nav */
.owl-carousel .owl-nav button {
  position: absolute;
  right: 0;
  top: -80px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #C9FFF5 !important;
}

@media only screen and (max-width: 991px) {
  .owl-carousel .owl-nav button {
    top: -70px;
  }
}

.owl-carousel .owl-nav button i {
  color: #5DD9C1;
  font-size: 30px;
}

.owl-carousel .owl-nav button:hover {
  background: #5DD9C1 !important;
}

.owl-carousel .owl-nav button:hover i {
  color: #ffffff;
}

.owl-carousel .owl-nav button.owl-prev {
  right: 66px;
}

@media only screen and (max-width: 991px) {
  .owl-carousel .owl-nav {
    display: none;
  }
}

.owl-carousel.logo-carousel .owl-nav button {
  position: absolute;
  left: auto;
  right: -50px;
  top: calc(50% - 25px);
  width: 50px;
  height: 50px;
  background: #ffffff !important;
}

.owl-carousel.logo-carousel .owl-nav button i {
  color: #707070;
  font-size: 30px;
}

.owl-carousel.logo-carousel .owl-nav button:hover {
  background: #ffffff !important;
}

.owl-carousel.logo-carousel .owl-nav button:hover i {
  color: #FE957B;
}

.owl-carousel.logo-carousel .owl-nav button.owl-prev {
  right: auto;
  left: -50px;
}

@media only screen and (max-width: 767px) {
  .owl-carousel.logo-carousel .owl-nav {
    display: none;
  }
}

.owl-carousel.logo-carousel.analytic .owl-nav button:hover i {
  color: #FF5B83;
}

.owl-carousel.book-review-slider .owl-nav button, .owl-carousel.customer-review-slider .owl-nav button {
  position: absolute;
  left: auto;
  right: -90px;
  top: calc(50% - 25px);
  width: 50px;
  height: 50px;
  background: #ffffff !important;
}

@media only screen and (max-width: 991px) {
  .owl-carousel.book-review-slider .owl-nav button, .owl-carousel.customer-review-slider .owl-nav button {
    display: none;
  }
}

.owl-carousel.book-review-slider .owl-nav button i, .owl-carousel.customer-review-slider .owl-nav button i {
  display: none;
}

.owl-carousel.book-review-slider .owl-nav button.owl-prev, .owl-carousel.customer-review-slider .owl-nav button.owl-prev {
  right: auto;
  left: -90px;
}

.owl-carousel.book-review-slider .owl-nav button .svg, .owl-carousel.customer-review-slider .owl-nav button .svg {
  width: 22px;
}

.owl-carousel.book-review-slider .owl-nav button .svg g path, .owl-carousel.customer-review-slider .owl-nav button .svg g path {
  stroke: #292929;
}

.owl-carousel.book-review-slider .owl-nav button:hover, .owl-carousel.customer-review-slider .owl-nav button:hover {
  background: #205CE2 !important;
}

.owl-carousel.book-review-slider .owl-nav button:hover .svg g path, .owl-carousel.customer-review-slider .owl-nav button:hover .svg g path {
  stroke: #ffffff;
}

@media only screen and (max-width: 767px) {
  .owl-carousel.book-review-slider .owl-nav, .owl-carousel.customer-review-slider .owl-nav {
    display: none;
  }
}

.owl-carousel.customer-review-slider .owl-nav button:hover {
  background: #4E2FA9 !important;
}

.owl-carousel.student-review .owl-nav button {
  top: -110px;
}

@media only screen and (max-width: 991px) {
  .owl-carousel.student-review .owl-nav button {
    top: -86px;
  }
}

.owl-carousel.email-testimonial-slider .owl-nav button, .owl-carousel.app-testimonial-slider .owl-nav button {
  top: auto;
  bottom: -50px;
  left: 0;
  background: transparent !important;
  padding: 0 !important;
  width: auto;
  height: auto;
}

.owl-carousel.email-testimonial-slider .owl-nav button.owl-next, .owl-carousel.app-testimonial-slider .owl-nav button.owl-next {
  left: 53px;
}

.owl-carousel.email-testimonial-slider .owl-nav button .svg g, .owl-carousel.app-testimonial-slider .owl-nav button .svg g {
  fill: #707070;
}

.owl-carousel.email-testimonial-slider .owl-nav button .svg g path, .owl-carousel.app-testimonial-slider .owl-nav button .svg g path {
  stroke: #707070;
}

.owl-carousel.email-testimonial-slider .owl-nav button:hover .svg g, .owl-carousel.app-testimonial-slider .owl-nav button:hover .svg g {
  fill: #FF937D;
}

.owl-carousel.email-testimonial-slider .owl-nav button:hover .svg g path, .owl-carousel.app-testimonial-slider .owl-nav button:hover .svg g path {
  stroke: #FF937D;
}

.owl-carousel.email-testimonial-slider.host .owl-nav button:hover .svg g, .owl-carousel.app-testimonial-slider.host .owl-nav button:hover .svg g {
  fill: #F7A400;
}

.owl-carousel.email-testimonial-slider.host .owl-nav button:hover .svg g path, .owl-carousel.app-testimonial-slider.host .owl-nav button:hover .svg g path {
  stroke: #F7A400;
}

.owl-carousel.email-testimonial-slider.app2 .owl-nav button:hover .svg g, .owl-carousel.app-testimonial-slider.app2 .owl-nav button:hover .svg g {
  fill: #FF6773;
}

.owl-carousel.email-testimonial-slider.app2 .owl-nav button:hover .svg g path, .owl-carousel.app-testimonial-slider.app2 .owl-nav button:hover .svg g path {
  stroke: #FF6773;
}

.owl-carousel.pos-feature-slider .owl-nav button {
  top: -140px;
  background: transparent !important;
}

@media only screen and (max-width: 991px) {
  .owl-carousel.pos-feature-slider .owl-nav button {
    top: -70px;
  }
}

.owl-carousel.pos-feature-slider .owl-nav button i {
  color: #707070;
  font-size: 30px;
}

.owl-carousel.pos-feature-slider .owl-nav button:hover {
  background: transparent !important;
}

.owl-carousel.pos-feature-slider .owl-nav button:hover i {
  color: #00418E;
}

.owl-carousel.pos-feature-slider .owl-nav button.owl-prev {
  right: 66px;
}

@media only screen and (max-width: 767px) {
  .owl-carousel.pos-feature-slider .owl-nav {
    display: none;
  }
}

.owl-carousel.app-testimonial-slider .owl-nav button {
  top: auto;
  bottom: 190px;
  left: -30px;
}

@media only screen and (max-width: 991px) {
  .owl-carousel.app-testimonial-slider .owl-nav button {
    bottom: 190px;
  }
}

.owl-carousel.app-testimonial-slider .owl-nav button i {
  display: none;
}

.owl-carousel.app-testimonial-slider .owl-nav button .svg {
  width: 26px;
}

.owl-carousel.app-testimonial-slider .owl-nav button:hover .svg g path {
  stroke: #8EC9F8;
}

.owl-carousel.app-testimonial-slider .owl-nav button.owl-next {
  left: auto;
  right: -30px;
}

@media only screen and (max-width: 767px) {
  .owl-carousel.app-testimonial-slider .owl-nav {
    display: none;
  }
}

/* Theme Input Style */
.theme-input-style, .entry-content select,
.comment-content select,
.page--content select {
  border: 1px solid #eeeeee;
  height: 52px;
  width: 100%;
  padding: 0 20px;
  background-color: #ffffff;
  font-size: 16px;
  margin-bottom: 20px;
}

.theme-input-style:focus, .entry-content select:focus,
.comment-content select:focus,
.page--content select:focus {
  background-color: #F8F8FF;
}

textarea.theme-input-style {
  height: 200px;
  padding: 20px 20px;
}

/* Theme Input Group */
.theme-input-group {
  position: relative;
  width: 100%;
}

.theme-input-group input {
  width: 100%;
  height: 60px;
  border: none;
  padding-left: 25px;
  padding-right: 150px;
  color: #ffffff;
  margin: 0;
}

@media only screen and (max-width: 479px) {
  .theme-input-group input {
    padding-right: 130px;
    padding-left: 10px;
  }
}

.theme-input-group button, .theme-input-group button.submit-btn {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  padding: 15px 30px;
  line-height: 1;
  font-weight: 600;
  color: #ffffff;
}

@media only screen and (max-width: 479px) {
  .theme-input-group button, .theme-input-group button.submit-btn {
    font-size: 16px;
    padding: 20px;
  }
}

.theme-input-group.style--two {
  display: flex;
  margin-bottom: 12px;
}

.theme-input-group.style--two input {
  width: calc(100% - 170px);
  height: 50px;
  border: none;
  border-bottom: 1px solid #57548D;
  padding-left: 0px;
  padding-right: 0px;
  background: transparent;
  border-radius: 0;
  font-size: 18px;
  font-weight: 300;
}

@media only screen and (max-width: 479px) {
  .theme-input-group.style--two input {
    width: calc(100% - 100px);
  }
}

.theme-input-group.style--two button {
  position: static;
  padding: 15px 30px;
  margin-left: 30px;
  line-height: 1;
  border-radius: 6px;
  font-weight: 600;
}

@media only screen and (max-width: 479px) {
  .theme-input-group.style--two button {
    margin-left: 5px;
    padding: 15px;
  }
}

.theme-input-group.style--two.analytic input {
  border-bottom: 1px solid #ffffff;
  color: #ffffff;
  border-radius: 0;
}

.theme-input-group.style--two.analytic button {
  border-radius: 0px;
  color: #ffffff;
}

.theme-input-group.email-banner {
  display: inline-flex;
  margin-bottom: 12px;
}

@media only screen and (max-width: 479px) {
  .theme-input-group.email-banner {
    flex-direction: column;
  }
}

.theme-input-group.email-banner input {
  flex: 1;
  height: 58px;
  border: 1px solid #BFBFBF;
  background: transparent;
  padding-right: 25px;
  border-radius: 0;
  font-size: 16px;
  font-weight: 400;
}

@media only screen and (max-width: 575px) {
  .theme-input-group.email-banner input {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media only screen and (max-width: 479px) {
  .theme-input-group.email-banner input {
    margin-bottom: 10px;
    padding: 20px 10px;
  }
}

.theme-input-group.email-banner button {
  position: static;
  padding: 20px 35px;
  margin-left: 20px;
  line-height: 1;
  border-radius: 0px;
  font-weight: 600;
  color: #ffffff;
}

.theme-input-group.email-banner button:hover {
  color: #ffffff;
}

@media only screen and (max-width: 479px) {
  .theme-input-group.email-banner button {
    margin: 0px;
  }
}

.theme-input-group.app-banner {
  border-radius: 50px;
}

.theme-input-group.app-banner input {
  height: 58px;
  background: #ffffff;
  padding-right: 180px;
  font-size: 16px;
  font-weight: 400;
}

@media only screen and (max-width: 479px) {
  .theme-input-group.app-banner input {
    padding-left: 10px;
    padding-right: 120px;
  }
}

.theme-input-group.app-banner button {
  padding: 20px 35px;
  color: #ffffff;
  border-radius: 0 50px 50px 0;
}

.theme-input-group.app-banner button:hover {
  color: #ffffff;
}

@media only screen and (max-width: 479px) {
  .theme-input-group.app-banner button {
    padding: 20px 14px;
  }
}

.theme-input-group.app-banner.cta {
  max-width: 810px;
  margin: 0 auto;
}

.theme-input-group.app2 input {
  height: 58px;
  background: #ffffff;
  padding-right: 180px;
  font-size: 16px;
  font-weight: 400;
}

@media only screen and (max-width: 479px) {
  .theme-input-group.app2 input {
    padding-left: 10px;
    padding-right: 120px;
  }
}

.theme-input-group.app2 button {
  padding: 20px 35px;
  color: #ffffff;
  border-radius: 0 5px 5px 0;
  z-index: 1;
  overflow: hidden;
  background: #FF6773;
  background: -moz-linear-gradient(top, #FF6773 0%, #FF896B 100%);
  background: -webkit-linear-gradient(top, #FF6773 0%, #FF896B 100%);
  background: linear-gradient(to bottom, #FF6773 0%, #FF896B 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FF6773', endColorstr='#FF896B',GradientType=0);
}

.theme-input-group.app2 button:after {
  left: 0;
  top: -100%;
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  border-radius: 0 5px 5px 0;
  z-index: -1;
  background: #FF896B;
  background: -moz-linear-gradient(top, #FF896B 0%, #FF6773 100%);
  background: -webkit-linear-gradient(top, #FF896B 0%, #FF6773 100%);
  background: linear-gradient(to bottom, #FF896B 0%, #FF6773 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FF896B', endColorstr='#FF6773',GradientType=0);
}

.theme-input-group.app2 button:hover {
  color: #ffffff;
}

.theme-input-group.app2 button:hover:after {
  top: 0;
}

@media only screen and (max-width: 479px) {
  .theme-input-group.app2 button {
    padding: 20px 14px;
  }
}

@media only screen and (max-width: 991px) {
  .theme-input-group.app2.footer input {
    padding-left: 10px;
    padding-right: 109px;
  }
  .theme-input-group.app2.footer button {
    padding: 15px;
  }
}

.theme-input-group.crs {
  max-width: 690px;
  margin: 0 auto;
  padding: 0 15px;
}

.theme-input-group.crs .input-wrap {
  position: relative;
}

.theme-input-group.crs .input-wrap .highlight {
  position: absolute;
  display: block;
  width: 0%;
  height: 1px;
  left: 0;
  bottom: 0;
  background: #FBC006;
}

.theme-input-group.crs .input-wrap input:focus + .highlight {
  width: 100%;
}

.theme-input-group.crs input {
  padding-left: 0px;
  padding-right: 30px;
  background: transparent;
  border-radius: 0;
  border-bottom: 1px solid #ffffff;
  font-size: 18px;
}

.theme-input-group.crs input::placeholder {
  color: #ffffff !important;
}

.theme-input-group.crs button {
  position: absolute;
  top: 50%;
  right: 5px;
  height: auto;
  padding: 10px;
  background: transparent;
  transform: translateY(-50%);
}

.theme-input-group.crs button:hover {
  background: transparent;
}

.theme-input-group.crs button:hover .svg g path {
  stroke: #FBC006;
}

.theme-input-group.crs button:hover .svg g g {
  fill: #FBC006;
}

.theme-input-group.course input {
  color: #ffffff;
}

.theme-input-group.course button:hover {
  color: #292929;
}

.theme-input-group.pay input {
  background: #ffffff;
  border-radius: 0;
}

.theme-input-group.pay button {
  border-radius: 0;
}

.theme-input-group.pay.plug input {
  color: #292929;
}

.theme-input-group.pay.plug button {
  color: #4E2FA9;
}

.theme-input-group.pay.plug button:hover {
  opacity: .9;
}

.theme-input-group.author-newsletter {
  max-width: 630px;
  margin: 0 auto;
}

.theme-input-group.author-newsletter input {
  background: #205CE2;
}

.theme-input-group.author-newsletter input::placeholder {
  color: #ffffff !important;
}

.theme-input-group.author-newsletter button {
  right: -1px;
}

.theme-input-group.style--four {
  display: flex;
  margin-bottom: 15px;
}

.theme-input-group.style--four input {
  width: calc(100% - 160px);
  height: 50px;
  border: 1px solid #ffffff;
  padding-left: 25px;
  padding-right: 25px;
  color: #ffffff;
  background: transparent;
  border-radius: 0;
  font-size: 18px;
  font-weight: 300;
}

@media only screen and (max-width: 479px) {
  .theme-input-group.style--four input {
    width: calc(100% - 100px);
    padding-left: 10px;
    padding-right: 10px;
  }
}

.theme-input-group.style--four button {
  position: static;
  padding: 16px 30px;
  height: 50px;
  margin-left: 20px;
  line-height: 1;
  border-radius: 6px;
  font-weight: 600;
  border-radius: 0;
}

@media only screen and (max-width: 479px) {
  .theme-input-group.style--four button {
    margin-left: 5px;
    padding: 15px;
  }
}

.theme-input-group.style--four.host input {
  border-radius: 6px;
}

.theme-input-group.style--four.host button {
  border-radius: 6px;
}

.theme-input-group.management {
  max-width: 570px;
  margin-left: auto;
  margin-right: auto;
}

.theme-input-group.management input {
  background: #ffffff;
  font-size: 20px;
  font-weight: 300;
  padding-right: 170px;
}

@media only screen and (max-width: 1199px) {
  .theme-input-group.management input {
    padding-right: 150px;
  }
}

.theme-input-group.management button {
  font-size: 18px;
  padding: 20px 40px;
}

@media only screen and (max-width: 1199px) {
  .theme-input-group.management button {
    padding: 20px 30px;
  }
}

.theme-input-group.management2 input {
  background: #ffffff;
  font-size: 20px;
  font-weight: 300;
  padding-right: 170px;
}

@media only screen and (max-width: 1199px) {
  .theme-input-group.management2 input {
    padding-right: 150px;
  }
}

@media only screen and (max-width: 479px) {
  .theme-input-group.management2 input {
    padding-right: 120px;
  }
}

.theme-input-group.management2 button {
  font-size: 18px;
  padding: 20px 30px;
}

@media only screen and (max-width: 479px) {
  .theme-input-group.management2 button {
    padding: 20px;
  }
}

.theme-input-group.pos input {
  border-color: #707070;
}

.theme-input-group.pos button {
  background: #FF5B83;
  color: #ffffff;
}

.theme-input-group.pos button:hover {
  background: #00418E;
  color: #ffffff;
}

.theme-input-group.proto input {
  height: 60px;
  background: #ffffff;
  padding-right: 160px;
  font-size: 16px;
  font-weight: 400;
}

@media only screen and (max-width: 767px) {
  .theme-input-group.proto input {
    height: 50px;
  }
}

@media only screen and (max-width: 479px) {
  .theme-input-group.proto input {
    padding-left: 10px;
    padding-right: 120px;
  }
}

.theme-input-group.proto button {
  padding: 20px 35px;
  color: #ffffff;
  border-radius: 0 10px 10px 0;
  z-index: 1;
}

.theme-input-group.proto button:hover {
  color: #ffffff;
}

@media only screen and (max-width: 479px) {
  .theme-input-group.proto button {
    padding: 20px 14px;
  }
}

.theme-input-group.affiliate .theme-input-style, .theme-input-group.affiliate .entry-content select, .entry-content .theme-input-group.affiliate select,
.theme-input-group.affiliate .comment-content select, .comment-content .theme-input-group.affiliate select,
.theme-input-group.affiliate .page--content select, .page--content .theme-input-group.affiliate select {
  color: #ffffff;
}

.theme-input-group.affiliate .theme-input-style:focus, .theme-input-group.affiliate .entry-content select:focus, .entry-content .theme-input-group.affiliate select:focus,
.theme-input-group.affiliate .comment-content select:focus, .comment-content .theme-input-group.affiliate select:focus,
.theme-input-group.affiliate .page--content select:focus, .page--content .theme-input-group.affiliate select:focus {
  background-color: #5F52DC;
}

/* Others */
.ov-hidden {
  overflow: hidden;
}

.ovx-hidden {
  overflow-x: hidden;
}

/* Before After */
.ba {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

/* Pagination*/
.pagination > ul {
  margin-bottom: 0;
}

.pagination li {
  margin: 0 5px;
}

.pagination li a,
.pagination li span {
  width: 40px;
  height: 40px;
  line-height: 1;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  color: #5E5E5E;
  font-size: 22px;
  font-weight: 600;
}

.pagination li a:hover, .pagination li a.current,
.pagination li span:hover,
.pagination li span.current {
  color: #ffffff;
}

.pagination li.nav-btn a.disabled {
  cursor: not-allowed;
  opacity: .5;
}

/* Blockquote */
blockquote {
  padding: 0px 20px 30px 20px;
  position: relative;
  font-size: 18px;
  font-style: italic;
  line-height: 1.33;
  font-weight: 400;
  max-width: 540px;
  margin: 0 auto;
}

blockquote cite {
  display: block;
  margin-top: 15px;
  font-size: 16px;
  line-height: 1.44;
  font-style: normal;
  font-weight: 400;
}

/* Custom Container */
@media (min-width: 1500px) {
  .container {
    max-width: 1410px;
  }
}

/* Form Response */
.form-response {
  background-color: rgba(95, 82, 220, 0.2);
}

.form-response span {
  display: block;
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 10px 15px;
}

/* Bg */
.light-bg {
  background-color: #FFFCF6;
}

/* Bg Img */
.bg-img {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

/* Search Form */
.search-form .theme-input-group input, .widget.widget_search .theme-input-group input, .widget.widget_newsletter.style--two .theme-input-group input {
  color: #ffffff;
  border-radius: 0;
  color: #ffffff;
  padding-right: 100px;
}

.search-form .theme-input-group input::placeholder, .widget.widget_search .theme-input-group input::placeholder, .widget.widget_newsletter.style--two .theme-input-group input::placeholder {
  color: #ffffff !important;
}

.search-form .theme-input-group button:hover, .widget.widget_search .theme-input-group button:hover, .widget.widget_newsletter.style--two .theme-input-group button:hover {
  color: #ffffff;
}

.search-form.faq h2, .faq.widget.widget_search h2, .faq.widget.widget_newsletter.style--two h2 {
  color: #292929;
  font-weight: 600;
  margin-bottom: 30px;
  text-align: center;
}

.search-form.faq .theme-input-group input, .faq.widget.widget_search .theme-input-group input, .faq.widget.widget_newsletter.style--two .theme-input-group input {
  background-color: #ffffff;
  color: #707070;
  border: 1px solid #292929;
  width: calc(100% - 150px);
}

.search-form.faq .theme-input-group input::placeholder, .faq.widget.widget_search .theme-input-group input::placeholder, .faq.widget.widget_newsletter.style--two .theme-input-group input::placeholder {
  color: #707070 !important;
}

@media only screen and (max-width: 479px) {
  .search-form.faq .theme-input-group input, .faq.widget.widget_search .theme-input-group input, .faq.widget.widget_newsletter.style--two .theme-input-group input {
    width: calc(100% - 100px);
    padding: 0 10px;
  }
}

.search-form.faq .theme-input-group button, .faq.widget.widget_search .theme-input-group button, .faq.widget.widget_newsletter.style--two .theme-input-group button {
  padding: 15px 38px;
  font-weight: 500;
}

@media only screen and (max-width: 479px) {
  .search-form.faq .theme-input-group button, .faq.widget.widget_search .theme-input-group button, .faq.widget.widget_newsletter.style--two .theme-input-group button {
    padding: 15px 25px;
  }
}

form.search-form .theme-input-group .theme-input-style, form.widget.widget_search .theme-input-group .theme-input-style, form.widget.widget_newsletter.style--two .theme-input-group .theme-input-style, form.search-form .theme-input-group .entry-content select, .entry-content form.search-form .theme-input-group select, form.widget.widget_search .theme-input-group .entry-content select, .entry-content form.widget.widget_search .theme-input-group select, form.widget.widget_newsletter.style--two .theme-input-group .entry-content select, .entry-content form.widget.widget_newsletter.style--two .theme-input-group select,
form.search-form .theme-input-group .comment-content select, .comment-content form.search-form .theme-input-group select,
form.widget.widget_search .theme-input-group .comment-content select, .comment-content form.widget.widget_search .theme-input-group select,
form.widget.widget_newsletter.style--two .theme-input-group .comment-content select, .comment-content form.widget.widget_newsletter.style--two .theme-input-group select,
form.search-form .theme-input-group .page--content select, .page--content form.search-form .theme-input-group select,
form.widget.widget_search .theme-input-group .page--content select, .page--content form.widget.widget_search .theme-input-group select,
form.widget.widget_newsletter.style--two .theme-input-group .page--content select, .page--content form.widget.widget_newsletter.style--two .theme-input-group select, form.newsletter-form .theme-input-group .theme-input-style, form.newsletter-form .theme-input-group .entry-content select, .entry-content form.newsletter-form .theme-input-group select,
form.newsletter-form .theme-input-group .comment-content select, .comment-content form.newsletter-form .theme-input-group select,
form.newsletter-form .theme-input-group .page--content select, .page--content form.newsletter-form .theme-input-group select {
  border-bottom: 1px solid #999999 !important;
  padding: 0 10px;
  background-color: transparent;
  height: 40px;
  color: #707070;
  border-radius: 0;
}

form.search-form .theme-input-group .theme-input-style::placeholder, form.widget.widget_search .theme-input-group .theme-input-style::placeholder, form.widget.widget_newsletter.style--two .theme-input-group .theme-input-style::placeholder, form.search-form .theme-input-group .entry-content select::placeholder, .entry-content form.search-form .theme-input-group select::placeholder, form.widget.widget_search .theme-input-group .entry-content select::placeholder, .entry-content form.widget.widget_search .theme-input-group select::placeholder, form.widget.widget_newsletter.style--two .theme-input-group .entry-content select::placeholder, .entry-content form.widget.widget_newsletter.style--two .theme-input-group select::placeholder,
form.search-form .theme-input-group .comment-content select::placeholder, .comment-content form.search-form .theme-input-group select::placeholder,
form.widget.widget_search .theme-input-group .comment-content select::placeholder, .comment-content form.widget.widget_search .theme-input-group select::placeholder,
form.widget.widget_newsletter.style--two .theme-input-group .comment-content select::placeholder, .comment-content form.widget.widget_newsletter.style--two .theme-input-group select::placeholder,
form.search-form .theme-input-group .page--content select::placeholder, .page--content form.search-form .theme-input-group select::placeholder,
form.widget.widget_search .theme-input-group .page--content select::placeholder, .page--content form.widget.widget_search .theme-input-group select::placeholder,
form.widget.widget_newsletter.style--two .theme-input-group .page--content select::placeholder, .page--content form.widget.widget_newsletter.style--two .theme-input-group select::placeholder, form.newsletter-form .theme-input-group .theme-input-style::placeholder, form.newsletter-form .theme-input-group .entry-content select::placeholder, .entry-content form.newsletter-form .theme-input-group select::placeholder,
form.newsletter-form .theme-input-group .comment-content select::placeholder, .comment-content form.newsletter-form .theme-input-group select::placeholder,
form.newsletter-form .theme-input-group .page--content select::placeholder, .page--content form.newsletter-form .theme-input-group select::placeholder {
  color: #D1D1D1 !important;
}

form.search-form .theme-input-group .submit-btn, form.widget.widget_search .theme-input-group .submit-btn, form.widget.widget_newsletter.style--two .theme-input-group .submit-btn, form.newsletter-form .theme-input-group .submit-btn {
  border: none;
  padding: 10px;
  background-color: transparent;
}

/* Count Down Timer */
.countdown-wrapper {
  padding: 50px;
  padding-bottom: 44px;
  background: #ffffff;
}

@media only screen and (max-width: 991px) {
  .countdown-wrapper {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .countdown-wrapper {
    padding: 30px 20px 26px;
  }
}

.countdown-wrapper .countdown-tag {
  display: inline-block;
  background-color: #C9FFF5;
  padding: 4px 22px;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 26px;
}

#countdown .single-countdown {
  text-align: center;
}

#countdown .single-countdown h2 {
  font-size: 60px;
  font-weight: 600;
  color: #292929;
}

@media only screen and (max-width: 575px) {
  #countdown .single-countdown h2 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 575px) {
  #countdown .single-countdown p {
    font-size: 14px;
  }
}

/* Video Play Button */
.video-play-button {
  width: 68px;
  height: 68px;
  color: #ffffff;
  position: absolute;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.video-play-button:hover {
  color: #ffffff;
}

/* Trapezium Video */
.trapezium-video {
  position: relative;
  z-index: 1;
}

.trapezium-video:after {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #ffffff;
  opacity: 0.3;
  content: "";
  position: absolute;
}

/* Course BG */
#course {
  background: #F6F8F5;
}

.bg-right {
  position: absolute;
  left: auto;
  right: -300%;
  top: 0;
  width: calc(400% - 15px);
  height: 100%;
  border-radius: 150px 0 0 0;
  z-index: -1;
}

@media only screen and (max-width: 767px) {
  .bg-right {
    width: 600%;
  }
}

/* Custom Checkbox */
.custom-checkbox input[type=checkbox] {
  position: relative;
  top: 1px;
}

.custom-checkbox.pos label {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  user-select: none;
}

.custom-checkbox.pos label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.custom-checkbox.pos .checkmark {
  position: absolute;
  top: 6px;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: transparent;
  border: 1px solid #00418E;
}

.custom-checkbox.pos label input:checked ~ .checkmark {
  background-color: #00418E;
}

.custom-checkbox.pos .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox.pos label input:checked ~ .checkmark:after {
  display: block;
}

.custom-checkbox.pos label .checkmark:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* App2 Page Section BG */
.app2-bg {
  background: #F5F8FF;
}

/* About Content */
@media only screen and (max-width: 991px) {
  .about-content {
    margin-top: 60px;
  }
}

.about-content h2 {
  font-weight: 600;
  color: #292929;
  margin-bottom: 20px;
  line-height: 1.2;
}

.body-bg {
  background-color: #F6F8F5;
}

.service-cta-bg {
  background-color: #857DFF;
}

/* ************************
   02.2: Animations
   ********************* */
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes grow {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes grow {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.grow {
  -webkit-animation-name: grow;
  animation-name: grow;
}

@-webkit-keyframes grow2 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes grow2 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.grow2 {
  -webkit-animation-name: grow2;
  animation-name: grow2;
}

@-webkit-keyframes growDown {
  0% {
    opacity: 0;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes growDown {
  0% {
    opacity: 0;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.growDown {
  -webkit-animation-name: growDown;
  animation-name: growDown;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@-webkit-keyframes fadeInDown2 {
  0% {
    transform: translateY(-40px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 0.2;
  }
}

@keyframes fadeInDown2 {
  0% {
    transform: translateY(-40px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 0.2;
  }
}

.fadeInDown2 {
  -webkit-animation-name: fadeInDown2;
  animation-name: fadeInDown2;
}

@-webkit-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInDownBig {
  -webkit-animation-name: fadeInDownBig;
  animation-name: fadeInDownBig;
}

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-40px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-40px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInLeft2 {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInLeft2 {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInLeft2 {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    transform: translateX(-200px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    transform: translateX(-200px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInLeftBig {
  -webkit-animation-name: fadeInLeftBig;
  animation-name: fadeInLeftBig;
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(40px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(40px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@-webkit-keyframes fadeInRight2 {
  0% {
    opacity: 0;
    transform: translateX(10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight2 {
  0% {
    opacity: 0;
    transform: translateX(10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInRight2 {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@-webkit-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    transform: translateX(200px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    transform: translateX(200px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInRightBig {
  -webkit-animation-name: fadeInRightBig;
  animation-name: fadeInRightBig;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeInUp2 {
  0% {
    opacity: 0;
    transform: translateY(200px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp2 {
  0% {
    opacity: 0;
    transform: translateY(200px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInUp2 {
  -webkit-animation-name: fadeInUp2;
  animation-name: fadeInUp2;
}

@-webkit-keyframes fadeInUp10 {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp10 {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInUp10 {
  -webkit-animation-name: fadeInUp10;
  animation-name: fadeInUp10;
}

@-webkit-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUpBig {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInUpBig {
  -webkit-animation-name: fadeInUpBig;
  animation-name: fadeInUpBig;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes slideInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes slideInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
  100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
}

@-webkit-keyframes slideInRight {
  0% {
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  0% {
    -webkit-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    transform: translateX(2000px);
  }
  100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}

/* Custom */
@-webkit-keyframes bg-scale {
  0% {
    -webkit-transform: translate(100%);
    transform: translate(100%);
  }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@keyframes bg-scale {
  0% {
    -webkit-transform: translate(100%);
    transform: translate(100%);
  }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

.bg-scale {
  -webkit-animation-name: bg-scale;
  animation-name: bg-scale;
}

@-webkit-keyframes cbat {
  0% {
    -webkit-transform: translateX(30%) translateY(30%);
    transform: translateX(30%) translateY(30%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
    opacity: 1;
  }
}

@keyframes cbat {
  0% {
    -webkit-transform: translateX(30%) translateY(30%);
    transform: translateX(30%) translateY(30%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
    opacity: 1;
  }
}

.cbat {
  -webkit-animation-name: cbat;
  animation-name: cbat;
}

@-webkit-keyframes cbab {
  0% {
    -webkit-transform: translateX(-30%) translateY(-30%);
    transform: translateX(-30%) translateY(-30%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
    opacity: 1;
  }
}

@keyframes cbab {
  0% {
    -webkit-transform: translateX(-30%) translateY(-30%);
    transform: translateX(-30%) translateY(-30%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
    opacity: 1;
  }
}

.cbab {
  -webkit-animation-name: cbab;
  animation-name: cbab;
}

@-webkit-keyframes zoomIn {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes zoomIn {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
}

@-webkit-keyframes zoomOut {
  0% {
    transform: scale(1.01);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes zoomOut {
  0% {
    transform: scale(1.01);
  }
  100% {
    transform: scale(1);
  }
}

.zoomOut {
  -webkit-animation-name: zoomOut;
  animation-name: zoomOut;
}

@-webkit-keyframes arrowRight {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(10px);
  }
}

@keyframes arrowRight {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(10px);
  }
}

.arrowRight {
  -webkit-animation-name: arrowRight;
  animation-name: arrowRight;
}

@-webkit-keyframes arrowLeft {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-10px);
  }
}

@keyframes arrowLeft {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-10px);
  }
}

.arrowLeft {
  -webkit-animation-name: arrowLeft;
  animation-name: arrowLeft;
}

@-webkit-keyframes zoomDown {
  0% {
    opacity: 0;
    transform: scale(0);
    transform-origin: 50% 0;
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes zoomDown {
  0% {
    opacity: 0;
    transform: scale(0);
    transform-origin: 50% 0;
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.zoomDown {
  -webkit-animation-name: zoomDown;
  animation-name: zoomDown;
}

@keyframes heightWidth {
  0% {
    height: 0;
    width: 0;
  }
  100% {
    height: 100%;
    width: 100%;
  }
}

.heightWidth {
  -webkit-animation-name: height;
  animation-name: height;
}

@-webkit-keyframes levitate {
  from {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  50% {
    -webkit-transform: translate(0, 10px);
    transform: translate(0, 10px);
  }
  to {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@keyframes levitate {
  from {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  50% {
    -webkit-transform: translate(0, 10px);
    transform: translate(0, 10px);
  }
  to {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

.levitate {
  -webkit-animation-name: levitate;
  animation-name: levitate;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

@-webkit-keyframes wide {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@keyframes wide {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

.wide {
  -webkit-animation-name: wide;
  animation-name: wide;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    transform: rotate(5deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@-webkit-keyframes banner3Shake {
  0% {
    -webkit-transform: rotate3d(0, 1, 0, 0deg);
    transform: rotate3d(0, 1, 0, 0deg);
  }
  30% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }
  60% {
    -webkit-transform: rotate3d(1, 0, 0, 0deg);
    transform: rotate3d(1, 0, 0, 0deg);
  }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }
  100% {
    -webkit-transform: rotate3d(0, 1, 0, 0deg);
    transform: rotate3d(0, 1, 0, 0deg);
  }
}

@keyframes banner3Shake {
  0% {
    -webkit-transform: rotate3d(0, 1, 0, 0deg);
    transform: rotate3d(0, 1, 0, 0deg);
  }
  30% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }
  60% {
    -webkit-transform: rotate3d(1, 0, 0, 0deg);
    transform: rotate3d(1, 0, 0, 0deg);
  }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }
  100% {
    -webkit-transform: rotate3d(0, 1, 0, 0deg);
    transform: rotate3d(0, 1, 0, 0deg);
  }
}

@-webkit-keyframes leftToRight {
  from {
    left: 0;
  }
  to {
    right: 0;
  }
}

@keyframes leftToRight {
  from {
    left: -130px;
  }
  to {
    left: 100%;
  }
}

/* ************************
   02.3: Preloader
   ********************* */
.preloader {
  background: linear-gradient(90deg, #00b377, #00d68f);
  background: #000;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  z-index: 9999999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-circle {
  position: absolute;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1);
}

.loader-circle .content {
  font-size: 40px;
  font-weight: 700;
  color: #ffffff;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.loader-line-mask {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 60px;
  height: 120px;
  margin-left: -60px;
  margin-top: -60px;
  overflow: hidden;
  transform-origin: 60px 60px;
  -webkit-mask-image: -webkit-linear-gradient(top, #000000, rgba(0, 0, 0, 0));
  mask-image: -webkit-linear-gradient(top, #000000, rgba(0, 0, 0, 0));
  animation: spin 1.2s infinite linear;
}

.loader-line-mask .loader-line {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.5);
}

/* ************************
    02.4: Buttons
    ********************* */
/* Default Btn */
.btn, .btn-crm, .btn-crs, .btn-pay, .e-btn, .btn-book, .m-btn {
  display: inline-block;
  padding: 20px 33px;
  font-weight: 500;
  line-height: 1;
  font-size: 18px;
  position: relative;
  cursor: pointer;
  color: #ffffff;
  border: 1px solid transparent;
  text-transform: capitalize;
  /* Secondary Button */
  /* Animate Btn */
  /* Border Button */
  /* Pricing Button */
  /* App Page btn*/
}

.btn:hover, .btn-crm:hover, .btn-crs:hover, .btn-pay:hover, .e-btn:hover, .btn-book:hover, .m-btn:hover {
  color: #ffffff;
  background: #5F52DC;
}

.btn.two:hover, .two.btn-crm:hover, .two.btn-crs:hover, .two.btn-pay:hover, .two.e-btn:hover, .two.btn-book:hover, .two.m-btn:hover {
  background: #ffffff;
  color: #5F52DC;
}

.btn:focus, .btn-crm:focus, .btn-crs:focus, .btn-pay:focus, .e-btn:focus, .btn-book:focus, .m-btn:focus, .btn.focus, .focus.btn-crm, .focus.btn-crs, .focus.btn-pay, .focus.e-btn, .focus.btn-book, .focus.m-btn {
  box-shadow: none;
}

.btn.s-btn:hover, .s-btn.btn-crm:hover, .s-btn.btn-crs:hover, .s-btn.btn-pay:hover, .s-btn.e-btn:hover, .s-btn.btn-book:hover, .s-btn.m-btn:hover {
  color: #ffffff;
  background: #FCBF21;
}

.btn.animate-btn, .animate-btn.btn-crm, .animate-btn.btn-crs, .animate-btn.btn-pay, .animate-btn.e-btn, .animate-btn.btn-book, .animate-btn.m-btn {
  background: #ffffff;
  position: relative;
  padding: 12px 20px;
  transform: translateX(-20px);
}

.btn.animate-btn:after, .animate-btn.btn-crm:after, .animate-btn.btn-crs:after, .animate-btn.btn-pay:after, .animate-btn.e-btn:after, .animate-btn.btn-book:after, .animate-btn.m-btn:after {
  width: 48px;
  height: 100%;
  opacity: .3;
  position: absolute;
  content: "";
  right: 0;
  top: 0;
}

.btn.animate-btn:hover, .animate-btn.btn-crm:hover, .animate-btn.btn-crs:hover, .animate-btn.btn-pay:hover, .animate-btn.e-btn:hover, .animate-btn.btn-book:hover, .animate-btn.m-btn:hover {
  transform: translateX(0px);
}

.btn.animate-btn:hover:after, .animate-btn.btn-crm:hover:after, .animate-btn.btn-crs:hover:after, .animate-btn.btn-pay:hover:after, .animate-btn.e-btn:hover:after, .animate-btn.btn-book:hover:after, .animate-btn.m-btn:hover:after {
  width: 100%;
  height: 100%;
}

.btn.animate-btn i, .animate-btn.btn-crm i, .animate-btn.btn-crs i, .animate-btn.btn-pay i, .animate-btn.e-btn i, .animate-btn.btn-book i, .animate-btn.m-btn i {
  font-size: 20px;
  padding-left: 10px;
  position: relative;
  top: 2px;
}

.btn.border-btn, .border-btn.btn-crm, .border-btn.btn-crs, .border-btn.btn-pay, .border-btn.e-btn, .border-btn.btn-book, .border-btn.m-btn {
  border: 1px solid #707070;
  background: transparent;
}

.btn.border-btn:hover, .border-btn.btn-crm:hover, .border-btn.btn-crs:hover, .border-btn.btn-pay:hover, .border-btn.e-btn:hover, .border-btn.btn-book:hover, .border-btn.m-btn:hover {
  border: 1px solid transparent;
  color: #ffffff;
}

.btn.pricing-btn, .pricing-btn.btn-crm, .pricing-btn.btn-crs, .pricing-btn.btn-pay, .pricing-btn.e-btn, .pricing-btn.btn-book, .pricing-btn.m-btn {
  border: 1px solid transparent;
}

.btn.pricing-btn:hover, .pricing-btn.btn-crm:hover, .pricing-btn.btn-crs:hover, .pricing-btn.btn-pay:hover, .pricing-btn.e-btn:hover, .pricing-btn.btn-book:hover, .pricing-btn.m-btn:hover {
  border: 1px solid transparent;
}

.btn.app-btn, .app-btn.btn-crm, .app-btn.btn-crs, .app-btn.btn-pay, .app-btn.e-btn, .app-btn.btn-book, .app-btn.m-btn {
  padding: 15px 33px;
  background-color: #FFE5DB;
}

.btn.app-btn:hover, .app-btn.btn-crm:hover, .app-btn.btn-crs:hover, .app-btn.btn-pay:hover, .app-btn.e-btn:hover, .app-btn.btn-book:hover, .app-btn.m-btn:hover {
  color: #ffffff;
}

.btn.app-btn.price1, .app-btn.price1.btn-crm, .app-btn.price1.btn-crs, .app-btn.price1.btn-pay, .app-btn.price1.e-btn, .app-btn.price1.btn-book, .app-btn.price1.m-btn {
  background-color: #E6F4FF;
  color: #4EA8F1;
}

.btn.app-btn.price1:hover, .app-btn.price1.btn-crm:hover, .app-btn.price1.btn-crs:hover, .app-btn.price1.btn-pay:hover, .app-btn.price1.e-btn:hover, .app-btn.price1.btn-book:hover, .app-btn.price1.m-btn:hover {
  background-color: #4EA8F1;
  color: #ffffff;
}

.btn.app-btn.price2, .app-btn.price2.btn-crm, .app-btn.price2.btn-crs, .app-btn.price2.btn-pay, .app-btn.price2.e-btn, .app-btn.price2.btn-book, .app-btn.price2.m-btn {
  background-color: #DCFEF6;
  color: #3BEBC5;
}

.btn.app-btn.price2:hover, .app-btn.price2.btn-crm:hover, .app-btn.price2.btn-crs:hover, .app-btn.price2.btn-pay:hover, .app-btn.price2.e-btn:hover, .app-btn.price2.btn-book:hover, .app-btn.price2.m-btn:hover {
  background-color: #3BEBC5;
  color: #ffffff;
}

.btn.white-btn, .white-btn.btn-crm, .white-btn.btn-crs, .white-btn.btn-pay, .white-btn.e-btn, .white-btn.btn-book, .white-btn.m-btn {
  background-color: #ffffff;
  color: #292929;
  padding-top: 15px;
  padding-bottom: 15px;
}

.btn.white-btn:hover, .white-btn.btn-crm:hover, .white-btn.btn-crs:hover, .white-btn.btn-pay:hover, .white-btn.e-btn:hover, .white-btn.btn-book:hover, .white-btn.m-btn:hover {
  color: #ffffff;
}

.btn.app2, .app2.btn-crm, .app2.btn-crs, .app2.btn-pay, .app2.e-btn, .app2.btn-book, .app2.m-btn {
  padding: 12px 26px;
  z-index: 1;
  overflow: hidden;
  background: #FF6773;
  background: -moz-linear-gradient(top, #FF6773 0%, #FF896B 100%);
  background: -webkit-linear-gradient(top, #FF6773 0%, #FF896B 100%);
  background: linear-gradient(to bottom, #FF6773 0%, #FF896B 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FF6773', endColorstr='#FF896B',GradientType=0);
}

.btn.app2:after, .app2.btn-crm:after, .app2.btn-crs:after, .app2.btn-pay:after, .app2.e-btn:after, .app2.btn-book:after, .app2.m-btn:after {
  left: 0;
  top: -100%;
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  border-radius: 0 5px 5px 0;
  z-index: -1;
  background: #FF896B;
  background: -moz-linear-gradient(top, #FF896B 0%, #FF6773 100%);
  background: -webkit-linear-gradient(top, #FF896B 0%, #FF6773 100%);
  background: linear-gradient(to bottom, #FF896B 0%, #FF6773 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FF896B', endColorstr='#FF6773',GradientType=0);
}

.btn.app2:hover, .app2.btn-crm:hover, .app2.btn-crs:hover, .app2.btn-pay:hover, .app2.e-btn:hover, .app2.btn-book:hover, .app2.m-btn:hover {
  color: #ffffff;
}

.btn.app2:hover:after, .app2.btn-crm:hover:after, .app2.btn-crs:hover:after, .app2.btn-pay:hover:after, .app2.e-btn:hover:after, .app2.btn-book:hover:after, .app2.m-btn:hover:after {
  top: 0;
}

.btn.proto, .proto.btn-crm, .proto.btn-crs, .proto.btn-pay, .proto.e-btn, .proto.btn-book, .proto.m-btn {
  padding: 15px 30px;
}

.btn.proto .banner-content, .proto.btn-crm .banner-content, .proto.btn-crs .banner-content, .proto.btn-pay .banner-content, .proto.e-btn .banner-content, .proto.btn-book .banner-content, .proto.m-btn .banner-content {
  padding-right: 0;
}

.btn.download, .download.btn-crm, .download.btn-crs, .download.btn-pay, .download.e-btn, .download.btn-book, .download.m-btn {
  padding: 14px 28px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  border: none;
}

.btn.download .svg, .download.btn-crm .svg, .download.btn-crs .svg, .download.btn-pay .svg, .download.e-btn .svg, .download.btn-book .svg, .download.m-btn .svg {
  margin-right: 10px;
}

.btn.download:after, .download.btn-crm:after, .download.btn-crs:after, .download.btn-pay:after, .download.e-btn:after, .download.btn-book:after, .download.m-btn:after {
  position: absolute;
  left: 0;
  top: -100%;
  width: 100%;
  height: 100%;
  background-color: #FFE4DC;
  content: "";
  z-index: -1;
}

.btn.download:hover:after, .download.btn-crm:hover:after, .download.btn-crs:hover:after, .download.btn-pay:hover:after, .download.e-btn:hover:after, .download.btn-book:hover:after, .download.m-btn:hover:after {
  top: 0;
}

.btn.download:hover .svg g path, .download.btn-crm:hover .svg g path, .download.btn-crs:hover .svg g path, .download.btn-pay:hover .svg g path, .download.e-btn:hover .svg g path, .download.btn-book:hover .svg g path, .download.m-btn:hover .svg g path {
  fill: #5A50A5;
}

.btn.download.s-btn, .download.s-btn.btn-crm, .download.s-btn.btn-crs, .download.s-btn.btn-pay, .download.s-btn.e-btn, .download.s-btn.btn-book, .download.s-btn.m-btn {
  background-color: #FFE4DC;
}

.btn.download.s-btn:hover, .download.s-btn.btn-crm:hover, .download.s-btn.btn-crs:hover, .download.s-btn.btn-pay:hover, .download.s-btn.e-btn:hover, .download.s-btn.btn-book:hover, .download.s-btn.m-btn:hover {
  color: #ffffff;
}

.btn.download.s-btn:hover .svg path, .download.s-btn.btn-crm:hover .svg path, .download.s-btn.btn-crs:hover .svg path, .download.s-btn.btn-pay:hover .svg path, .download.s-btn.e-btn:hover .svg path, .download.s-btn.btn-book:hover .svg path, .download.s-btn.m-btn:hover .svg path {
  fill: #ffffff;
}

.btn.submit-btn, .submit-btn.btn-crm, .submit-btn.btn-crs, .submit-btn.btn-pay, .submit-btn.e-btn, .submit-btn.btn-book, .submit-btn.m-btn {
  padding: 20px 35px;
  border-radius: 0px;
  font-weight: 600;
  color: #ffffff;
}

.btn.submit-btn:hover, .submit-btn.btn-crm:hover, .submit-btn.btn-crs:hover, .submit-btn.btn-pay:hover, .submit-btn.e-btn:hover, .submit-btn.btn-book:hover, .submit-btn.m-btn:hover {
  color: #ffffff;
}

.link-btn.analytic {
  color: #292929;
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 500;
}

.cart-btn {
  position: relative;
}

.cart-btn span {
  width: 16px;
  height: 16px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  right: -7px;
  top: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

/* Arrow Btn */
.arrow-btn {
  color: #FF5B83;
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
}

.arrow-btn .svg {
  margin-left: 10px;
  width: 24px;
}

.arrow-btn:hover {
  color: #FF5B83;
}

.arrow-btn:hover .svg {
  margin-left: 15px;
}

/* Home CRM Btn */
.btn-crm {
  border-radius: 6px;
  background: #57548D;
}

.btn-crm:hover {
  background: #EBD281;
}

.btn-crm.s-btn:hover {
  color: #EBD281 !important;
  background: #57548D;
}

.btn-crm.s-btn.two:hover {
  color: #57548D !important;
  background: #ffffff;
}

.btn-crm.border-btn {
  border: 1px solid #57548D;
}

.btn-crm.border-btn:hover {
  border: 1px solid transparent;
  color: #57548D;
}

.btn-crm.border-btn.style--two {
  color: #ffffff;
  border: 1px solid #5DD9C1;
}

.btn-crm.border-btn.style--two .svg {
  margin-right: 5px;
}

.btn-crm.border-btn.style--two:hover {
  border: 1px solid transparent;
  color: #ffffff;
}

.btn-crm.border-btn.style--two:hover .svg {
  margin-right: 5px;
}

.btn-crm.border-btn.style--two:hover .svg path {
  fill: #FBF349;
}

.btn-crm.border-btn.style--three {
  color: #ffffff;
  border: 1px solid #ffffff;
}

.btn-crm.border-btn.style--three .svg {
  margin-right: 5px;
}

.btn-crm.border-btn.style--three .svg path {
  fill: #ffffff;
}

.btn-crm.border-btn.style--three:hover {
  border: 1px solid transparent;
  color: #ffffff;
}

.btn-crm.btn-view-more {
  background: transparent;
  color: #57548D;
}

.btn-crm.btn-view-more .svg {
  margin-left: 5px;
}

.btn-crm.btn-view-more:hover {
  color: #EBD281;
}

.btn-crm.btn-view-more:hover .svg {
  animation: arrowRight .8s linear .2s infinite;
}

.btn-crm.btn-view-more:hover .svg line, .btn-crm.btn-view-more:hover .svg path {
  stroke: #EBD281;
}

/* Home Corse & Plugins Btn */
.btn-crs {
  border-radius: 6px;
  background: #5DD9C1;
  padding: 15px 33px;
}

.btn-crs.plug {
  background: #FBF349;
  color: #4E2FA9;
}

.btn-crs.plug:hover {
  background: #5DD9C1;
  color: #ffffff;
}

.btn-crs.host {
  background: #F7A400;
  color: #ffffff;
  overflow: hidden;
  position: relative;
}

.btn-crs.host:hover {
  background: #BDC2FC;
  color: #3E4491;
}

.btn-crs.host3 {
  background: rgba(247, 164, 0, 0.3);
  color: #F7A400;
}

.btn-crs.host3:hover {
  background: #F7A400;
  color: #ffffff;
}

.btn-crs:hover {
  background: #FBC006;
}

.btn-crs.s-btn {
  color: #ffffff;
}

.btn-crs.s-btn:hover {
  color: #ffffff !important;
  background: #5DD9C1;
}

.btn-crs.s-btn.plug {
  background: #5DD9C1;
  color: #ffffff;
}

.btn-crs.s-btn.plug:hover {
  background: #ffffff;
  color: #4E2FA9 !important;
}

.btn-crs.s-btn.host {
  background: #BDC2FC;
  color: #3E4491;
}

.btn-crs.s-btn.host:hover {
  background: #F7A400;
  color: #ffffff;
}

.btn-crs.border-btn {
  color: #292929;
  border: 1px solid #5DD9C1;
}

.btn-crs.border-btn:hover {
  border: 1px solid transparent;
  color: #ffffff;
}

.btn-crs.line-btn {
  color: #292929;
  background: transparent;
  padding: 0;
}

.btn-crs.line-btn:before {
  position: absolute;
  content: '';
  background: #292929;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  transition: .3s;
}

.btn-crs.line-btn:after {
  position: absolute;
  content: '';
  background: #292929;
  left: 0;
  bottom: 0;
  width: 0;
  height: 1px;
  transition: .3s;
  transition-delay: .3s;
}

.btn-crs.line-btn:hover {
  background: transparent;
}

.btn-crs.line-btn:hover:before {
  left: auto;
  right: 0;
  width: 0;
}

.btn-crs.line-btn:hover:after {
  width: 100%;
}

.btn-crs.btn-view-more {
  background: transparent;
  color: #5DD9C1;
}

.btn-crs.btn-view-more .svg {
  margin-left: 5px;
}

.btn-crs.btn-view-more:hover {
  color: #FBC006;
}

.btn-crs.btn-view-more:hover .svg line, .btn-crs.btn-view-more:hover .svg path {
  stroke: #FBC006;
}

.btn-crs.syllabus-btn {
  position: absolute;
  right: 15px;
  top: -10px;
}

/* Home Payment Btn */
.btn-pay, .e-btn {
  border-radius: 0px;
  background: #FE957B;
  padding: 15px 33px;
}

.btn-pay:hover, .e-btn:hover {
  background: #BBAAFF;
  color: #ffffff;
}

.btn-pay.s-btn, .s-btn.e-btn {
  background: #BBAAFF;
  color: #ffffff;
}

.btn-pay.s-btn:hover, .s-btn.e-btn:hover {
  color: #ffffff !important;
  background: #FE957B;
}

.btn-pay.border-btn, .border-btn.e-btn {
  color: #292929;
  border: 1px solid #5DD9C1;
}

.btn-pay.border-btn:hover, .border-btn.e-btn:hover {
  border: 1px solid transparent;
  color: #ffffff;
}

.btn-pay.line-btn, .line-btn.e-btn {
  color: #292929;
  background: transparent;
  padding: 0;
}

.btn-pay.line-btn:before, .line-btn.e-btn:before {
  position: absolute;
  content: '';
  background: #292929;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  transition: .3s;
}

.btn-pay.line-btn:after, .line-btn.e-btn:after {
  position: absolute;
  content: '';
  background: #292929;
  left: 0;
  bottom: 0;
  width: 0;
  height: 1px;
  transition: .3s;
  transition-delay: .3s;
}

.btn-pay.line-btn:hover, .line-btn.e-btn:hover {
  background: transparent;
}

.btn-pay.line-btn:hover:before, .line-btn.e-btn:hover:before {
  left: auto;
  right: 0;
  width: 0;
}

.btn-pay.line-btn:hover:after, .line-btn.e-btn:hover:after {
  width: 100%;
}

.btn-pay.btn-view-more, .btn-view-more.e-btn {
  background: transparent;
  color: #5DD9C1;
}

.btn-pay.btn-view-more .svg, .btn-view-more.e-btn .svg {
  margin-left: 5px;
}

.btn-pay.btn-view-more:hover, .btn-view-more.e-btn:hover {
  color: #FBC006;
}

.btn-pay.btn-view-more:hover .svg line, .btn-view-more.e-btn:hover .svg line, .btn-pay.btn-view-more:hover .svg path, .btn-view-more.e-btn:hover .svg path {
  stroke: #FBC006;
}

.btn-pay.syllabus-btn, .syllabus-btn.e-btn {
  position: absolute;
  right: 15px;
  top: -10px;
}

.btn-pay.analytic, .analytic.e-btn {
  background: #FF5B83;
}

.btn-pay.analytic:hover, .analytic.e-btn:hover {
  background: #00418E;
}

.btn-pay.analytic.s-btn, .analytic.s-btn.e-btn {
  background: #00418E;
}

.btn-pay.analytic.s-btn:hover, .analytic.s-btn.e-btn:hover {
  background: #FF5B83;
}

.btn-pay.pos, .pos.e-btn {
  background: #FF5B83;
  padding: 17px 33px;
}

.btn-pay.pos:hover, .pos.e-btn:hover {
  background: #00418E;
}

.btn-pay.pos.s-btn, .pos.s-btn.e-btn {
  background: #00418E;
  padding: 17px 33px;
}

.btn-pay.pos.s-btn:hover, .pos.s-btn.e-btn:hover {
  background: #FF5B83;
}

.btn-pay.pos.border-btn, .pos.border-btn.e-btn {
  color: #292929;
  background-color: transparent;
}

.btn-pay.pos.border-btn:hover, .pos.border-btn.e-btn:hover {
  color: #ffffff;
}

.btn-pay.host, .host.e-btn {
  background: #180D5B;
}

.btn-pay.host:hover, .host.e-btn:hover {
  background: #F7A400;
}

.btn-pay.host.s-btn, .host.s-btn.e-btn {
  background: #F7A400;
  padding: 20px 35px;
}

.btn-pay.host.s-btn:hover, .host.s-btn.e-btn:hover {
  color: #180D5B !important;
}

/* Home Ebook Btn */
.btn-book {
  padding: 15px 33px;
  background: #ffffff;
  color: #FBC006;
}

.btn-book:hover {
  background: #FBC006;
  color: #ffffff;
}

.btn-book.s-btn {
  background: #FBC006;
  color: #ffffff;
}

.btn-book.s-btn:hover {
  background: #ffffff;
  color: #FBC006;
}

.btn-book.line-btn {
  color: #ffffff;
  background: transparent;
  padding: 0;
}

.btn-book.line-btn:before {
  position: absolute;
  content: '';
  background: #ffffff;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  transition: .3s;
}

.btn-book.line-btn:after {
  position: absolute;
  content: '';
  background: #ffffff;
  left: 0;
  bottom: 0;
  width: 0;
  height: 1px;
  transition: .3s;
  transition-delay: .3s;
}

.btn-book.line-btn:hover {
  background: transparent;
}

.btn-book.line-btn:hover:before {
  left: auto;
  right: 0;
  width: 0;
}

.btn-book.line-btn:hover:after {
  width: 100%;
}

.btn-book.style--two {
  color: #FBC006;
}

.btn-book.style--two:before {
  background: #FBC006;
}

.btn-book.style--two:after {
  background: #FBC006;
}

.btn-book.style--three {
  color: #205CE2;
}

.btn-book.style--three:before {
  background: #205CE2;
}

.btn-book.style--three:after {
  background: #205CE2;
}

.btn-book.style--four {
  color: #5DD9C1;
}

.btn-book.style--four:before {
  background: #5DD9C1;
}

.btn-book.style--four:after {
  background: #5DD9C1;
}

/* Home Management Btn */
.m-btn.s-btn:hover {
  background: #625FD1;
}

.m-btn.border-btn {
  border-color: #FF4D8C;
  color: #292929;
}

.m-btn.border-btn:hover {
  background: #FF4D8C;
  color: #ffffff;
}

.m-btn.border-btn.style--two:hover {
  color: #ffffff;
}

/* Header Button */
.header-btn a:not(:last-child) {
  margin-right: 36px;
}

.header-btn.book a {
  display: inline-block;
}

.header-btn.book a:not(:last-child) {
  margin-right: 50px;
}

.header-btn .btn-crm.style--two, .header-btn .btn-crm.style--three {
  padding: 15px 33px;
}

.header-btn .btn-crs.style--three {
  padding: 15px 33px;
}

.header-btn .link-btn {
  color: #292929;
  font-size: 18px;
  font-weight: 500;
  position: relative;
  text-transform: capitalize;
}

.header-btn .link-btn.style--two {
  color: #292929;
}

.header-btn .link-btn.style--three:before {
  position: absolute;
  content: '';
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  transition: .3s;
}

.header-btn .link-btn.style--three:after {
  position: absolute;
  content: '';
  left: 0;
  bottom: 0;
  width: 0;
  height: 1px;
  transition: .3s;
  transition-delay: .3s;
}

.header-btn .link-btn.style--three:hover:before {
  left: auto;
  right: 0;
  width: 0;
}

.header-btn .link-btn.style--three:hover:after {
  width: 100%;
}

.header-btn.management .link-btn {
  position: relative;
  line-height: 1;
}

.header-btn.management .link-btn:not(:last-child) {
  padding-right: 20px;
  margin-right: 16px;
}

.header-btn.management .link-btn:not(:last-child):after {
  position: absolute;
  content: '';
  top: 0;
  right: -1px;
  width: 1.5px;
  height: 100%;
  background: #292929;
}

.header-btn.pos .link-btn {
  position: relative;
  line-height: 1;
}

.header-btn.pos .link-btn:not(:last-child) {
  padding-right: 20px;
  margin-right: 16px;
}

.header-btn.pos .link-btn:not(:last-child):after {
  position: absolute;
  content: '';
  top: 0;
  right: -1px;
  width: 1.5px;
  height: 100%;
  background-color: #8A8A8A;
}

/* Banner Btn Group */
.banner-btn-group a {
  display: inline-block;
}

.banner-btn-group a:not(:last-child) {
  margin-right: 26px;
}

@media only screen and (max-width: 1199px) {
  .banner-btn-group a:not(:last-child) {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .banner-btn-group a:not(:last-child) {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

/* CTA Button Group */
@media only screen and (max-width: 575px) {
  .cta-btn-group {
    display: inline-flex;
    flex-direction: column;
  }
}

.cta-btn-group a:not(:last-child) {
  margin-right: 20px;
}

@media only screen and (max-width: 575px) {
  .cta-btn-group a:not(:last-child) {
    margin-right: 0px;
    margin-bottom: 10px;
  }
}

.cta-btn-group .btn-crs.plug {
  padding: 20px 35px;
}

/* Ebook-btn */
.e-btn {
  top: 6px;
  left: -6px;
}

.e-btn:after {
  position: absolute;
  left: 6px;
  top: -6px;
  width: 100%;
  height: 100%;
  background: transparent;
  border: 1px solid #ffffff;
  content: "";
  z-index: -1;
}

.e-btn:hover {
  background: #FF937D;
  top: 0;
  left: 0;
}

.e-btn:hover:after {
  left: 0px;
  top: 0px;
}

.e-btn.s-btn {
  background: #857DFF;
  color: #ffffff;
}

.e-btn.s-btn:after {
  border-color: #292929;
}

.e-btn.s-btn:hover {
  background: #857DFF;
}

.e-btn.inner:after {
  border: 1px solid #292929;
}

/* Line Btn */
.line-btn {
  position: relative;
  padding: 0;
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 600;
}

.line-btn:before {
  position: absolute;
  content: '';
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  transition: .3s;
}

.line-btn:after {
  position: absolute;
  content: '';
  left: 0;
  bottom: 0;
  width: 0;
  height: 1px;
  transition: .3s;
  transition-delay: .3s;
}

.line-btn:hover:before {
  left: auto;
  right: 0;
  width: 0;
}

.line-btn:hover:after {
  width: 100%;
}

.line-btn.job:before {
  display: none;
}

.line-btn.job:after {
  width: 60%;
}

.line-btn.job:hover:after {
  width: 100%;
}

.line-btn.reading-btn {
  color: #292929;
}

.line-btn.reading-btn:before {
  background-color: #292929;
}

.line-btn.reading-btn:after {
  background-color: #292929;
}

/* Download Button */
.download-btn {
  margin-right: 30px;
  display: inline-block;
  padding: 16px 30px !important;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .download-btn {
    width: 195px;
  }
}

@media only screen and (max-width: 575px) {
  .download-btn {
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.download-btn .icon {
  margin-right: 10px;
}

.download-btn .content p {
  line-height: 1;
  color: #ffffff;
  margin-bottom: 5px !important;
  font-size: 12px;
  font-weight: 400;
}

.download-btn .content h5 {
  color: #ffffff;
  font-weight: 600;
}

.download-btn2 {
  display: inline-block;
  padding: 16px 30px !important;
  background: #ffffff !important;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .download-btn2 {
    width: 180px;
  }
}

.download-btn2 .icon {
  margin-right: 10px;
}

.download-btn2 .content p {
  line-height: 1;
  color: #292929;
  margin-bottom: 5px !important;
  font-size: 12px;
  font-weight: 400;
}

.download-btn2 .content h5 {
  color: #292929;
  font-weight: 600;
}

.download-btn2:hover .icon {
  margin-right: 10px;
}

.download-btn2:hover .icon .svg path {
  fill: #ffffff;
}

.download-btn2:hover .content p {
  color: #ffffff;
}

.download-btn2:hover .content h5 {
  color: #ffffff;
}

/* CTA Button Group Responsive */
@media only screen and (max-width: 767px) {
  .cta-btn-group.proto a:not(:last-child) {
    margin-right: 0;
    margin-bottom: 14px;
  }
}

/* ************************
   02.5: Section Title
   ********************* */
.section-title {
  margin-bottom: 60px;
}

@media only screen and (max-width: 991px) {
  .section-title {
    margin-bottom: 40px;
  }
}

.section-title h2 {
  font-size: 40px;
  line-height: 1.35;
  margin-top: -10px;
  margin-bottom: 10px;
}

.section-title h2.black {
  color: #292929;
}

@media only screen and (max-width: 575px) {
  .section-title h2 {
    font-size: 36px;
  }
}

.section-title p {
  max-width: 660px;
  margin: 0 auto;
}

@media only screen and (max-width: 575px) {
  .section-title p {
    font-size: 16px;
  }
}

.section-title.style-two {
  margin-bottom: 40px;
}

.section-title.crs {
  margin-bottom: 30px;
}

@media only screen and (max-width: 991px) {
  .section-title.crs {
    margin-bottom: 10px;
  }
}

.section-title.book h2 {
  font-weight: 600;
}

.section-title.style--two {
  margin-bottom: 24px;
}

.section-title.style--two h2 {
  font-size: 40px;
  margin-bottom: 10px;
}

.section-title.style--two h2.black {
  color: #292929;
}

@media only screen and (max-width: 575px) {
  .section-title.style--two h2 {
    font-size: 36px;
  }
}

.section-title.style--two p {
  font-size: 20px;
  line-height: 1.67;
  margin-bottom: 20px;
}

@media only screen and (max-width: 575px) {
  .section-title.style--two p {
    font-size: 16px;
  }
}

.section-title.style--two p b {
  font-weight: 600;
  color: #131313;
}

.section-title.style--three {
  margin-bottom: 42px;
}

.section-title.style--three h2 {
  margin-bottom: 10px;
  font-weight: 600;
}

.section-title.style--three p {
  max-width: 910px;
}

.section-title.style--three p.mxw-640 {
  max-width: 640px;
  margin: 0;
}

.section-title.analytic.m-less {
  margin-bottom: 20px;
}

.section-title.analytic h2 {
  font-size: 46px;
  font-weight: 600;
  margin-bottom: 10px;
}

@media only screen and (max-width: 575px) {
  .section-title.analytic h2 {
    font-size: 36px;
  }
}

.section-title.analytic p {
  max-width: 840px;
  font-size: 22px;
}

.section-title.host {
  margin-bottom: 50px;
}

.section-title.host.m-less {
  margin-bottom: 20px;
}

.section-title.host.mb-40 {
  margin-bottom: 40px;
}

.section-title.host h2 {
  font-size: 46px;
  font-weight: 600;
  margin-bottom: 13px;
  line-height: 1.25;
}

@media only screen and (max-width: 575px) {
  .section-title.host h2 {
    font-size: 36px;
  }
}

@media only screen and (max-width: 991px) {
  .section-title br {
    display: none;
  }
}

.section-title.author-newsletter {
  margin-bottom: 35px;
}

.section-title.app2 {
  margin-bottom: 40px;
}

.section-title.app2 h2 {
  font-size: 50px;
  line-height: 1;
}

@media only screen and (max-width: 575px) {
  .section-title.app2 h2 {
    font-size: 36px;
  }
}

.section-title.proto {
  margin-bottom: 40px;
}

.section-title.proto h2 {
  margin-bottom: 8px;
}

@media only screen and (max-width: 767px) {
  .section-title.proto h2 {
    font-size: 36px;
  }
}

.section-title.about h2 {
  font-weight: 600;
  color: #292929;
}

.section-title.semi-bold h2 {
  font-weight: 600;
}

/* ************************
   02.6: Page Title
   ********************* */
.page-title-wrap {
  background-color: #ECF7FF;
  margin-top: 100px;
  padding: 100px 0;
  z-index: 1;
}

.page-title h2 {
  margin-top: -13px;
  margin-bottom: 8px;
  font-size: 50px;
  font-weight: 600;
  line-height: 1.4;
  color: #292929;
}

.page-title ul {
  margin-bottom: -8px;
}

.page-title ul li {
  font-size: 20px;
  font-weight: 400;
  color: #292929;
}

.page-title ul li:not(:last-child) {
  position: relative;
  padding-right: 15px;
  margin-right: 10px;
  color: #292929;
}

.page-title ul li:not(:last-child) a {
  color: #292929;
}

.page-title ul li:not(:last-child):after {
  right: 0;
  position: absolute;
  content: '/';
  top: 50%;
  transform: translateY(-50%);
}

.breadcrumb-icon {
  position: absolute;
  left: 5%;
  top: 10%;
  z-index: -1;
}

.bread1 {
  animation: banner3Shake 5s linear 0s infinite forwards;
}

.bread2 {
  left: 0;
  top: auto;
  bottom: 0;
  animation: leftToRight 300s linear .3s infinite forwards;
}

.bread3 {
  left: 85%;
  top: 30%;
  animation: spin 80s linear .2s infinite reverse forwards;
}

.bread4 {
  left: 30%;
  top: auto;
  bottom: 0;
  animation: banner3Shake 15s ease-in 0s infinite forwards;
}

/* ************************
   02.7: Widget
   ********************* */
.widget {
  font-size: 16px;
}

.widget:not(:last-child) {
  margin-bottom: 50px;
}

.widget ul ul {
  padding-left: 10px;
  margin-top: 20px;
}

@media only screen and (max-width: 1199px) {
  .widget ul ul {
    padding-left: 0;
  }
}

.widget .widget-title {
  margin-bottom: 30px;
}

.widget .widget-title h4 {
  font-weight: 500;
}

.widget .widget_title {
  font-size: 20px;
  color: #292929;
  margin-bottom: 20px;
}

.widget .custom-select {
  margin-bottom: 0 !important;
}

.widget .custom-select .select-selected {
  background-color: #FFF6F1;
  border: 1px solid transparent;
  font-weight: 400 !important;
}

.widget .custom-select .select-selected.select-arrow-active {
  border-color: transparent !important;
}

.widget .custom-select .select-items {
  background-color: #FFFBF9;
}

.widget.widget_nav_menu ul li, .widget.widget_categories ul li, .widget.widget_meta ul li {
  position: relative;
  padding-left: 15px;
}

.widget.widget_nav_menu ul li a, .widget.widget_categories ul li a, .widget.widget_meta ul li a {
  display: inline-block;
  margin-right: 10px;
}

.widget.widget_nav_menu ul li:after, .widget.widget_categories ul li:after, .widget.widget_meta ul li:after {
  position: absolute;
  left: 0;
  top: 12px;
  content: '';
  height: 1px;
  width: 5px;
  background-color: #292929;
}

.widget.widget_nav_menu ul li:not(:last-child), .widget.widget_categories ul li:not(:last-child), .widget.widget_meta ul li:not(:last-child) {
  margin-bottom: 18px;
}

.widget.widget_contact .contact-content p {
  margin-bottom: 20px;
}

.widget.widget_contact .contact-content ul li {
  padding-left: 30px;
  position: relative;
  font-size: 14px;
  line-height: 2;
}

.widget.widget_contact .contact-content ul li:not(:last-child) {
  margin-bottom: 5px;
}

.widget.widget_contact .contact-content ul li i {
  position: absolute;
  left: 0;
  line-height: inherit;
}

.widget.widget_service .service-list li {
  line-height: 2;
  display: flex;
  align-items: center;
}

.widget.widget_service .service-list li a:hover i,
.widget.widget_service .service-list li a:hover svg * {
  fill: #FCBF21;
}

.widget.widget_service .service-list li i {
  font-size: 18px;
}

.widget.widget_service .service-list li img,
.widget.widget_service .service-list li svg,
.widget.widget_service .service-list li i {
  margin-right: 10px;
}

.widget.widget_service .service-list li:not(:last-child) {
  margin-bottom: 24px;
}

.widget.widget_recent_entries ul li {
  padding-bottom: 15px;
  border-bottom: 1px solid #EEEEEE;
}

.widget.widget_recent_entries ul li:not(:last-child) {
  margin-bottom: 10px;
}

.widget.widget_recent_entries .post-summary .post-title a {
  color: #292929;
  font-size: 16px;
  line-height: 1.2;
}

.widget.widget_recent_entries .post-summary .posted-on a {
  font-size: 12px;
}

.widget.widget_recent_entries .post-summary .post-excerpt {
  margin-top: 7px;
  font-size: 14px;
}

.widget.widget_recent_entries.has_thumb li .post_thumb {
  min-width: 80px;
  margin-right: 20px;
}

.widget.widget_recent_comments li:not(:last-child) {
  margin-bottom: 20px;
}

.widget.widget_recent_comments li a {
  font-weight: 700;
  color: #292929;
  background-image: linear-gradient(transparent 96%, currentColor 1px);
  background-repeat: no-repeat;
  background-size: 0 100%;
}

.widget.widget_recent_comments li a:hover {
  background-size: 100% 100%;
}

.widget.widget_newsletter > p {
  margin-bottom: 20px;
  font-weight: 400;
}

.widget.widget_newsletter .newsletter-content p {
  margin-bottom: 25px;
}

.widget.widget_newsletter.style--two .newsletter-content p {
  font-size: 14px;
}

.widget.widget_newsletter.style--two .newsletter-content .theme-input-group button {
  position: static;
  width: 100%;
  margin-top: 16px;
  height: 52px;
}

.widget.widget_newsletter.style--two .newsletter-content .theme-input-group input {
  background-color: #ffffff;
}

.widget.widget_search, .widget.widget_newsletter.style--two {
  padding: 0;
  background-color: transparent;
}

.widget.widget_tag_cloud .tagcloud {
  margin: 0 -6px -6px 0;
}

.widget.widget_tag_cloud .tagcloud a {
  display: inline-block;
  padding: 5px 7px;
  background-color: #ffffff;
  margin: 0 6px 6px 0;
}

.widget.widget_footer_menu .footer_menu li {
  display: block;
}

.widget.widget_footer_menu .footer_menu li:after {
  display: none;
}

.widget.widget_footer_menu .footer_menu li a {
  color: #ffffff;
  font-weight: 300;
  text-transform: capitalize;
  padding-bottom: 10px;
  display: inline-block;
  position: relative;
}

.widget.widget_footer_menu .footer_menu li a:hover {
  padding-left: 5px;
}

.widget.widget_footer_menu .footer_menu.affiliate li a {
  color: #707070;
}

.widget.widget_footer_menu .footer_menu.analytic li a {
  color: #707070;
}

.widget.widget_footer_menu .footer_menu.crm li a {
  color: #707070;
}

.widget.widget_footer_menu .footer_menu.crs li a {
  color: #707070;
}

.widget.widget_footer_menu .footer_menu.pay li a {
  color: #ffffff;
}

.widget.widget_footer_menu .footer_menu.plug li a {
  color: #ffffff;
}

.widget.widget_footer_menu .footer_menu.analytic li a {
  font-weight: 400;
}

.widget.job-info {
  border: 1px solid #eeeeee;
  background-color: #ffffff;
  padding: 60px;
}

@media only screen and (max-width: 1199px) {
  .widget.job-info {
    padding: 60px 40px;
  }
}

@media only screen and (max-width: 479px) {
  .widget.job-info {
    padding: 40px 30px;
  }
}

.widget.job-info li:not(:last-child) {
  margin-bottom: 30px;
}

.widget.job-info li h3 {
  color: #292929;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 5px;
}

.widget.job-info li p {
  font-size: 20px;
}

.widget.share {
  border: 1px solid #eeeeee;
  background-color: #ffffff;
  padding: 24px;
}

.widget.share .social-icon span {
  font-size: 26px;
  font-weight: 500;
  color: #292929;
  margin-right: 10px;
  display: inline-block;
}

@media only screen and (max-width: 479px) {
  .widget.share .social-icon span {
    font-size: 22px;
  }
}

.widget.share .social-icon a {
  color: #707070;
  margin-right: 0;
  background-color: #ffffff;
}

.widget.share .social-icon a:hover {
  color: #ffffff;
}

.widget.widget_calendar table {
  width: 100%;
  background-color: #FFFBF9;
}

.widget.widget_calendar table caption {
  padding: 5px 10px;
  caption-side: top;
  background-color: #FFFBF9;
  font-weight: 700;
  color: #292929;
}

.widget.widget_calendar table th {
  background-color: #FFF6F1;
  border: none;
}

.widget.widget_calendar table td {
  background-color: #ffffff;
  border: 1px solid #FFFBF9;
}

.widget.widget_calendar table th, .widget.widget_calendar table td {
  text-align: center;
  padding: 5px 0;
}

/* ************************
   02.8: Back to Top
   ********************* */
.back-to-top {
  position: fixed;
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
  width: 35px;
  height: 35px;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  color: #ffffff !important;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  line-height: 35px;
  bottom: 50%;
  right: 4%;
  transform: scale(0);
  transition: .3s ease-in;
}

.back-to-top.show {
  transform: scale(1);
  opacity: 1;
  visibility: visible;
  bottom: 10%;
  z-index: 999;
}

.back-to-top:hover {
  opacity: .6;
}

/* ************************
   03.1: Header
   ********************* */
.header {
  min-height: 100px;
}

.header.scrollDown {
  animation: fadeInDown .5s linear;
}

.header.scrollUp {
  transform: translateY(-100%);
  box-shadow: none !important;
}

.header.payment-header .header-main {
  margin-top: 15px;
  animation: fadeInUp .5s linear .9s backwards;
}

.header.payment-header .header-main.sticky {
  margin-top: 0;
}

.header.payment-header .header-main.sticky .header-btn .btn-pay.s-btn, .header.payment-header .header-main.sticky .header-btn .s-btn.e-btn {
  color: #ffffff !important;
  background: #FE957B;
}

.header.payment-header .header-main.sticky .header-btn .btn-pay.s-btn:hover, .header.payment-header .header-main.sticky .header-btn .s-btn.e-btn:hover {
  background: #BBAAFF;
  color: #ffffff;
}

.header.inner-page {
  background: #ffffff;
}

.header .header-main {
  display: flex;
  align-items: center;
  min-height: 100px;
}

.header .header-main.sticky {
  background-color: #ffffff;
}

.header .header-main.sticky .logo .default-logo {
  display: none;
}

.header .header-main.sticky .logo .sticky-logo {
  display: inline-block;
}

.header .header-main.sticky .main-menu .nav > li {
  position: relative;
}

.header .header-main.sticky .main-menu .nav > li.current-menu-parent > a, .header .header-main.sticky .main-menu .nav > li.current-menu-item > a, .header .header-main.sticky .main-menu .nav > li:hover > a {
  color: #5F52DC !important;
}

.header .header-main.sticky .main-menu .nav > li > a {
  color: #292929 !important;
}

.header .header-main.sticky .main-menu .nav > li > a:hover {
  color: #5F52DC !important;
}

.header .header-main .logo {
  line-height: 1;
}

.header .header-main .logo a {
  display: inline-block;
}

.header .header-main .logo .sticky-logo {
  display: none;
}

.header .header-main.style--two.sticky .main-menu .nav > li {
  position: relative;
}

.header .header-main.style--two.sticky .main-menu .nav > li.current-menu-parent > a, .header .header-main.style--two.sticky .main-menu .nav > li.current-menu-item > a, .header .header-main.style--two.sticky .main-menu .nav > li:hover > a {
  color: #57548D !important;
}

.header .header-main.style--two.sticky .main-menu .nav > li > a {
  color: #292929 !important;
}

.header .header-main.style--two.sticky .main-menu .nav > li > a:hover {
  color: #57548D !important;
}

.header .header-main.style--two .main-menu {
  flex: 1;
}

.header .header-main.style--two .main-menu .nav {
  margin: 0 auto;
}

.header .header-main.style--two .main-menu .nav > li > a {
  color: #292929;
}

.header .header-main.style--two .main-menu .nav li.current-menu-parent > a, .header .header-main.style--two .main-menu .nav li.current-menu-item > a, .header .header-main.style--two .main-menu .nav li:hover > a {
  color: #57548D;
}

.header .header-main.email.sticky .main-menu .nav > li {
  position: relative;
}

.header .header-main.email.sticky .main-menu .nav > li.current-menu-parent > a, .header .header-main.email.sticky .main-menu .nav > li.current-menu-item > a, .header .header-main.email.sticky .main-menu .nav > li:hover > a {
  color: #857DFF !important;
}

.header .header-main.email.sticky .main-menu .nav > li > a {
  color: #292929 !important;
}

.header .header-main.email.sticky .main-menu .nav > li > a:hover {
  color: #857DFF !important;
}

.header .header-main.email.sticky .header-btn .e-btn {
  background: #857DFF;
}

.header .header-main.email.sticky .header-btn .e-btn:after {
  border: 1px solid #292929;
}

.header .header-main.email.sticky .header-btn .e-btn:hover {
  background: #857DFF;
}

.header .header-main.email.sticky .header-btn .btn-book.line-btn {
  color: #FF937D;
}

.header .header-main.email.sticky .header-btn .btn-book.line-btn:before, .header .header-main.email.sticky .header-btn .btn-book.line-btn:after {
  background: #FF937D;
}

.header .header-main.email .main-menu {
  flex: 1;
}

.header .header-main.email .main-menu .nav {
  margin: 0 auto;
}

.header .header-main.email .main-menu .nav > li > a {
  color: #292929;
}

.header .header-main.email .main-menu .nav li.current-menu-parent > a, .header .header-main.email .main-menu .nav li:hover > a {
  color: #857DFF;
}

.header .header-main.email .main-menu .nav li.current-menu-item > a {
  color: #FF937D;
}

.header .header-main.email .main-menu .nav li.has-sub-item:after {
  content: "+";
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  font-size: 30px;
  color: #292929;
}

.header .header-main.email .main-menu .nav li.has-sub-item:hover:after {
  content: '-';
  color: #FF937D;
}

.header .header-main.email .main-menu .nav li ul li:hover > a {
  color: #FF937D;
}

.header .header-main.style--three.sticky .main-menu .nav > li {
  position: relative;
}

.header .header-main.style--three.sticky .main-menu .nav > li.current-menu-parent > a, .header .header-main.style--three.sticky .main-menu .nav > li.current-menu-item > a, .header .header-main.style--three.sticky .main-menu .nav > li:hover > a {
  color: #5DD9C1 !important;
}

.header .header-main.style--three.sticky .main-menu .nav > li > a:hover {
  color: #5DD9C1 !important;
}

.header .header-main.style--three .main-menu {
  flex: 1;
}

.header .header-main.style--three .main-menu .nav {
  margin-left: auto;
  margin-right: 26px;
}

.header .header-main.style--three .main-menu .nav > li > a {
  color: #5C6B56;
}

.header .header-main.style--three .main-menu .nav li.current-menu-parent > a, .header .header-main.style--three .main-menu .nav li.current-menu-item > a, .header .header-main.style--three .main-menu .nav li:hover > a {
  color: #5DD9C1;
}

.header .header-main.style--four #menu-button span, .header .header-main.style--four #menu-button span:before, .header .header-main.style--four #menu-button span:after {
  background: #ffffff;
}

.header .header-main.style--four.sticky #menu-button span, .header .header-main.style--four.sticky #menu-button span:before, .header .header-main.style--four.sticky #menu-button span:after {
  background: #FE957B;
}

.header .header-main.style--four.sticky .main-menu .nav > li {
  position: relative;
}

.header .header-main.style--four.sticky .main-menu .nav > li.current-menu-parent > a, .header .header-main.style--four.sticky .main-menu .nav > li.current-menu-item > a, .header .header-main.style--four.sticky .main-menu .nav > li:hover > a {
  color: #FE957B !important;
}

.header .header-main.style--four.sticky .main-menu .nav > li > a:hover {
  color: #FE957B !important;
}

.header .header-main.style--four .main-menu {
  flex: 1;
}

.header .header-main.style--four .main-menu .nav > li > a {
  color: #ffffff;
}

.header .header-main.style--four .main-menu .nav li.current-menu-parent > a, .header .header-main.style--four .main-menu .nav li.current-menu-item > a, .header .header-main.style--four .main-menu .nav li:hover > a {
  color: #FE957B;
}

.header .header-main.ebook #menu-button span, .header .header-main.ebook #menu-button span:before, .header .header-main.ebook #menu-button span:after {
  background: #FBC006;
}

.header .header-main.ebook.sticky .main-menu .nav > li.current-menu-parent > a, .header .header-main.ebook.sticky .main-menu .nav > li.current-menu-item > a, .header .header-main.ebook.sticky .main-menu .nav > li:hover > a {
  color: #FBC006 !important;
}

.header .header-main.ebook.sticky .main-menu .nav > li > a:hover {
  color: #FBC006 !important;
}

.header .header-main.ebook.sticky .header-btn.book .btn-book {
  background: #FBC006;
  color: #ffffff;
}

.header .header-main.ebook.sticky .header-btn.book .btn-book:hover {
  background: #205CE2;
  color: #FBC006;
}

.header .header-main.ebook.sticky .header-btn.book .cart-btn .svg g path {
  fill: #205CE2;
}

.header .header-main.ebook.sticky #menu-button span, .header .header-main.ebook.sticky #menu-button span:before, .header .header-main.ebook.sticky #menu-button span:after {
  background: #205CE2;
}

.header .header-main.ebook .main-menu {
  flex: 1;
}

.header .header-main.ebook .main-menu .nav > li > a {
  color: #ffffff;
}

.header .header-main.ebook .main-menu {
  margin-right: 26px;
}

.header .header-main.ebook .main-menu .nav li.current-menu-parent > a, .header .header-main.ebook .main-menu .nav li.current-menu-item > a, .header .header-main.ebook .main-menu .nav li:hover > a {
  color: #FBC006;
}

.header .header-main.plugin #menu-button span, .header .header-main.plugin #menu-button span:before, .header .header-main.plugin #menu-button span:after {
  background: #5DD9C1;
}

.header .header-main.plugin.sticky .main-menu .nav > li.current-menu-parent > a, .header .header-main.plugin.sticky .main-menu .nav > li.current-menu-item > a, .header .header-main.plugin.sticky .main-menu .nav > li:hover > a {
  color: #5DD9C1 !important;
}

.header .header-main.plugin.sticky .main-menu .nav > li > a:hover {
  color: #5DD9C1 !important;
}

.header .header-main.plugin .main-menu {
  flex: 1;
}

.header .header-main.plugin .main-menu .nav > li > a {
  color: #ffffff;
}

.header .header-main.plugin .main-menu {
  margin-right: 26px;
}

.header .header-main.plugin .main-menu .nav li.current-menu-parent > a, .header .header-main.plugin .main-menu .nav li.current-menu-item > a, .header .header-main.plugin .main-menu .nav li:hover > a {
  color: #5DD9C1;
}

.header .header-main.management #menu-button span, .header .header-main.management #menu-button span:before, .header .header-main.management #menu-button span:after {
  background: #FF4D8C;
}

.header .header-main.management.sticky .main-menu .nav > li.current-menu-parent > a, .header .header-main.management.sticky .main-menu .nav > li.current-menu-item > a, .header .header-main.management.sticky .main-menu .nav > li:hover > a {
  color: #FF4D8C !important;
}

.header .header-main.management.sticky .main-menu .nav > li > a:hover {
  color: #FF4D8C !important;
}

.header .header-main.management .main-menu {
  flex: 1;
}

.header .header-main.management .main-menu .nav > li > a {
  color: #292929;
}

.header .header-main.management .main-menu .nav li.current-menu-parent > a, .header .header-main.management .main-menu .nav li.current-menu-item > a, .header .header-main.management .main-menu .nav li:hover > a {
  color: #FF4D8C;
}

.header .header-main.analytic.sticky .main-menu .nav > li.current-menu-parent > a, .header .header-main.analytic.sticky .main-menu .nav > li.current-menu-item > a, .header .header-main.analytic.sticky .main-menu .nav > li:hover > a {
  color: #FF5B83 !important;
}

.header .header-main.analytic.sticky .main-menu .nav > li > a:hover {
  color: #FF5B83 !important;
}

.header .header-main.analytic .main-menu {
  flex: 1;
}

.header .header-main.analytic .main-menu .nav > li > a {
  color: #292929;
}

.header .header-main.analytic .main-menu {
  margin-right: 26px;
}

.header .header-main.analytic .main-menu .nav li.current-menu-parent > a, .header .header-main.analytic .main-menu .nav li.current-menu-item > a, .header .header-main.analytic .main-menu .nav li:hover > a {
  color: #FF5B83;
}

.header .header-main.host #menu-button span, .header .header-main.host #menu-button span:before, .header .header-main.host #menu-button span:after {
  background: #F7A400;
}

.header .header-main.host.sticky .main-menu .nav > li.current-menu-parent > a, .header .header-main.host.sticky .main-menu .nav > li.current-menu-item > a, .header .header-main.host.sticky .main-menu .nav > li:hover > a {
  color: #F7A400 !important;
}

.header .header-main.host.sticky .main-menu .nav > li > a:hover {
  color: #F7A400 !important;
}

.header .header-main.host .main-menu {
  flex: 1;
}

.header .header-main.host .main-menu .nav > li > a {
  color: #ffffff;
}

.header .header-main.host .main-menu .nav li.current-menu-parent > a, .header .header-main.host .main-menu .nav li.current-menu-item > a, .header .header-main.host .main-menu .nav li:hover > a {
  color: #F7A400;
}

.header .header-main.app {
  margin-top: 20px;
}

@media only screen and (max-width: 991px) {
  .header .header-main.app {
    margin-top: 0;
  }
}

.header .header-main.app #menu-button span, .header .header-main.app #menu-button span:before, .header .header-main.app #menu-button span:after {
  background: #8EC9F8;
}

.header .header-main.app.sticky {
  margin-top: 0;
}

.header .header-main.app.sticky .main-menu .nav > li.current-menu-parent > a, .header .header-main.app.sticky .main-menu .nav > li.current-menu-item > a, .header .header-main.app.sticky .main-menu .nav > li:hover > a {
  color: #8EC9F8 !important;
}

.header .header-main.app.sticky .main-menu .nav > li > a:hover {
  color: #8EC9F8 !important;
}

.header .header-main.app .main-menu {
  margin-right: 25px;
}

.header .header-main.app .main-menu .nav > li > a {
  color: #292929;
}

.header .header-main.app .main-menu .nav li.current-menu-parent > a, .header .header-main.app .main-menu .nav li.current-menu-item > a, .header .header-main.app .main-menu .nav li:hover > a {
  color: #8EC9F8;
}

.header .header-main.app2 {
  margin-top: 20px;
}

@media only screen and (max-width: 991px) {
  .header .header-main.app2 {
    margin-top: 0;
  }
}

.header .header-main.app2 #menu-button span, .header .header-main.app2 #menu-button span:before, .header .header-main.app2 #menu-button span:after {
  background: #FF6773;
}

.header .header-main.app2.sticky {
  margin-top: 0;
}

.header .header-main.app2.sticky .main-menu .nav > li.current-menu-parent > a, .header .header-main.app2.sticky .main-menu .nav > li.current-menu-item > a, .header .header-main.app2.sticky .main-menu .nav > li:hover > a {
  color: #FF6773 !important;
}

.header .header-main.app2.sticky .main-menu .nav > li > a:hover {
  color: #FF6773 !important;
}

.header .header-main.app2.sticky .btn.white-btn, .header .header-main.app2.sticky .white-btn.btn-crm, .header .header-main.app2.sticky .white-btn.btn-crs, .header .header-main.app2.sticky .white-btn.btn-pay, .header .header-main.app2.sticky .white-btn.e-btn, .header .header-main.app2.sticky .white-btn.btn-book, .header .header-main.app2.sticky .white-btn.m-btn {
  color: #ffffff;
  position: relative;
  z-index: 1;
  overflow: hidden;
  background: #FF6773;
  background: -moz-linear-gradient(top, #FF6773 0%, #FF896B 100%);
  background: -webkit-linear-gradient(top, #FF6773 0%, #FF896B 100%);
  background: linear-gradient(to bottom, #FF6773 0%, #FF896B 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FF6773', endColorstr='#FF896B',GradientType=0);
}

.header .header-main.app2.sticky .btn.white-btn:after, .header .header-main.app2.sticky .white-btn.btn-crm:after, .header .header-main.app2.sticky .white-btn.btn-crs:after, .header .header-main.app2.sticky .white-btn.btn-pay:after, .header .header-main.app2.sticky .white-btn.e-btn:after, .header .header-main.app2.sticky .white-btn.btn-book:after, .header .header-main.app2.sticky .white-btn.m-btn:after {
  left: 0;
  top: -100%;
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  border-radius: 0 5px 5px 0;
  z-index: -1;
  background: #FF896B;
  background: -moz-linear-gradient(top, #FF896B 0%, #FF6773 100%);
  background: -webkit-linear-gradient(top, #FF896B 0%, #FF6773 100%);
  background: linear-gradient(to bottom, #FF896B 0%, #FF6773 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FF896B', endColorstr='#FF6773',GradientType=0);
}

.header .header-main.app2.sticky .btn.white-btn:hover, .header .header-main.app2.sticky .white-btn.btn-crm:hover, .header .header-main.app2.sticky .white-btn.btn-crs:hover, .header .header-main.app2.sticky .white-btn.btn-pay:hover, .header .header-main.app2.sticky .white-btn.e-btn:hover, .header .header-main.app2.sticky .white-btn.btn-book:hover, .header .header-main.app2.sticky .white-btn.m-btn:hover {
  color: #ffffff;
}

.header .header-main.app2.sticky .btn.white-btn:hover:after, .header .header-main.app2.sticky .white-btn.btn-crm:hover:after, .header .header-main.app2.sticky .white-btn.btn-crs:hover:after, .header .header-main.app2.sticky .white-btn.btn-pay:hover:after, .header .header-main.app2.sticky .white-btn.e-btn:hover:after, .header .header-main.app2.sticky .white-btn.btn-book:hover:after, .header .header-main.app2.sticky .white-btn.m-btn:hover:after {
  top: 0;
}

.header .header-main.app2 .main-menu {
  margin-right: 25px;
}

.header .header-main.app2 .main-menu .nav > li > a {
  color: #292929;
}

.header .header-main.app2 .main-menu .nav li.current-menu-parent > a, .header .header-main.app2 .main-menu .nav li.current-menu-item > a, .header .header-main.app2 .main-menu .nav li:hover > a {
  color: #FF6773;
}

.header .header-main.proto {
  margin-top: 20px;
}

@media only screen and (max-width: 991px) {
  .header .header-main.proto {
    margin-top: 0;
  }
}

.header .header-main.proto #menu-button span, .header .header-main.proto #menu-button span:before, .header .header-main.proto #menu-button span:after {
  background: #FFA68B;
}

.header .header-main.proto.sticky {
  margin-top: 0;
}

.header .header-main.proto.sticky .main-menu .nav > li.current-menu-parent > a, .header .header-main.proto.sticky .main-menu .nav > li.current-menu-item > a, .header .header-main.proto.sticky .main-menu .nav > li:hover > a {
  color: #FFA68B !important;
}

.header .header-main.proto.sticky .main-menu .nav > li > a:hover {
  color: #FFA68B !important;
}

.header .header-main.proto .main-menu {
  margin-right: 25px;
}

.header .header-main.proto .main-menu .nav > li > a {
  color: #292929;
}

.header .header-main.proto .main-menu .nav li.current-menu-parent > a, .header .header-main.proto .main-menu .nav li.current-menu-item > a, .header .header-main.proto .main-menu .nav li:hover > a {
  color: #FFA68B;
}

.header .header-main .main-menu #menu-button {
  display: none;
  font-size: 28px;
  cursor: pointer;
}

.header .header-main .main-menu .nav li {
  position: relative;
}

.header .header-main .main-menu .nav li.current-menu-parent > a, .header .header-main .main-menu .nav li.current-menu-item > a, .header .header-main .main-menu .nav li:hover > a {
  color: #5F52DC;
}

.header .header-main .main-menu .nav li a {
  display: block;
  line-height: 1;
  position: relative;
  z-index: 1;
  text-transform: capitalize;
  font-weight: 400;
}

.header .header-main .main-menu .nav li ul {
  position: absolute;
  left: 0;
  top: -2000px;
  opacity: 0;
  visibility: hidden;
  background-color: #ffffff;
  width: 270px;
  padding: 24px 0;
  transform: translate3d(0, -5px, 0);
  transition: transform 0.2s ease-out;
  z-index: 9;
}

.header .header-main .main-menu .nav li ul li {
  display: block;
  width: 100%;
  line-height: 1;
  font-size: 18px;
}

.header .header-main .main-menu .nav li ul li:hover > a {
  margin-left: 5px;
  opacity: 0.8;
}

.header .header-main .main-menu .nav li ul li a {
  padding: 13px 40px;
  color: #292929;
}

.header .header-main .main-menu .nav li ul li ul {
  top: 0;
  left: calc(100% + 20px);
}

.header .header-main .main-menu .nav li ul li:hover > ul {
  opacity: 1;
  visibility: visible;
  top: 0;
  left: 100%;
}

.header .header-main .main-menu .nav > li {
  display: inline-block;
}

.header .header-main .main-menu .nav > li > a {
  line-height: 100px;
  position: relative;
  color: #81724D;
  font-size: 20px;
  padding: 0 24px;
}

@media only screen and (max-width: 1199px) {
  .header .header-main .main-menu .nav > li > a {
    padding: 0 10px;
  }
}

.header .header-main .main-menu .nav > li:hover > ul {
  opacity: 1;
  visibility: visible;
  top: 100%;
  transform: translate3d(0, 0, 0);
}

.header .header-main .main-menu .offcanvas-trigger {
  display: inline-block;
  width: 30px;
  cursor: pointer;
}

.header .header-main .main-menu .offcanvas-trigger span {
  display: block;
  height: 3px;
}

.header .header-main .main-menu .offcanvas-trigger span:nth-child(2) {
  margin: 5px 0;
  margin-left: 5px;
}

.header .header-main .main-menu .offcanvas-trigger span:nth-child(3) {
  margin-left: 10px;
}

.header .header-main .main-menu .offcanvas-trigger:hover span {
  margin-left: 0 !important;
}

.header .header-main:not(.sticky).text-white .main-menu #menu-button span, .header .header-main.price-box.two:not(.sticky) .main-menu #menu-button span,
.header .header-main:not(.sticky).text-white .main-menu #menu-button span:before,
.header .header-main.price-box.two:not(.sticky) .main-menu #menu-button span:before,
.header .header-main:not(.sticky).text-white .main-menu #menu-button span:after,
.header .header-main.price-box.two:not(.sticky) .main-menu #menu-button span:after {
  background-color: #ffffff;
}

.header .header-main:not(.sticky).text-white .main-menu .nav > li.current-menu-item > a, .header .header-main.price-box.two:not(.sticky) .main-menu .nav > li.current-menu-item > a, .header .header-main:not(.sticky).text-white .main-menu .nav > li.current-menu-parent > a, .header .header-main.price-box.two:not(.sticky) .main-menu .nav > li.current-menu-parent > a, .header .header-main:not(.sticky).text-white .main-menu .nav > li:hover > a, .header .header-main.price-box.two:not(.sticky) .main-menu .nav > li:hover > a {
  opacity: .8;
  color: #ffffff !important;
}

.header .header-main:not(.sticky).text-white .main-menu .offcanvas-trigger span, .header .header-main.price-box.two:not(.sticky) .main-menu .offcanvas-trigger span {
  background-color: #fff;
}

/* Offcanvas Overlay */
.offcanvas-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #000;
  opacity: 0;
  visibility: hidden;
  z-index: 9998;
}

.offcanvas-overlay.opened {
  opacity: .3;
  visibility: visible;
}

/* Offcanvas Panel */
#mobile_menu {
  position: fixed;
  width: 400px;
  height: 100vh;
  left: auto;
  right: 0;
  top: 0;
  z-index: 9999;
  background-color: #fff;
  padding: 50px;
  overflow: hidden;
  overflow-y: auto;
  transform: translateX(100%);
}

@media only screen and (max-width: 575px) {
  #mobile_menu {
    width: 100%;
  }
}

#mobile_menu.opened {
  transform: translateX(0);
}

#mobile_menu .offcanvas-header {
  margin-bottom: 50px;
}

#mobile_menu .offcanvas-header .offcanvas-close {
  cursor: pointer;
}

#mobile_menu .mobile-main-menu .nav li {
  display: block;
}

#mobile_menu .mobile-main-menu .nav li.current-menu-parent span.sub-menu a, #mobile_menu .mobile-main-menu .nav li.current-menu-item span.sub-menu a {
  color: #292929;
}

#mobile_menu .mobile-main-menu .nav li a {
  display: block;
  color: #81724D;
  font-size: 20px;
  padding: 8px 0px;
  text-transform: capitalize;
}

#mobile_menu .mobile-main-menu .nav li .sub-menu {
  padding-left: 20px;
  margin-top: 10px;
}

#mobile_menu .mobile-main-menu .nav li .sub-menu a {
  font-size: 18px;
}

#mobile_menu .mobile-main-menu .nav span.submenu-button {
  width: 48px;
  height: 48px;
  position: absolute;
  right: 30px;
  cursor: pointer;
}

#mobile_menu .mobile-main-menu .nav span.submenu-button:after {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-family: 'FontAwesome';
  content: "";
}

#mobile_menu .mobile-main-menu .nav span.submenu-button.sub-menu-oppened:after {
  font-family: 'FontAwesome';
  content: "";
}

#mobile_menu .mobile-main-menu .nav .sub-menu {
  display: none;
}

#mobile_menu .mobile-main-menu .nav .sub-menu.sub-menu-oppened {
  display: block;
}

#mobile_menu .mobile-main-menu.style--two .nav li a {
  color: #292929;
}

#mobile_menu .mobile-main-menu.email .nav li a {
  color: #292929;
}

#mobile_menu .mobile-main-menu.style--three .nav li a {
  color: #5C6B56;
}

#mobile_menu .mobile-main-menu.style--four .nav li a {
  color: #292929;
}

#mobile_menu .mobile-main-menu.ebook .nav li a {
  color: #292929;
}

#mobile_menu .mobile-main-menu.plugin .nav li a {
  color: #292929;
}

#mobile_menu .mobile-main-menu.management .nav li a {
  color: #292929;
}

#mobile_menu .mobile-main-menu.host .nav li a {
  color: #292929;
}

#mobile_menu .mobile-main-menu.analytic .nav li a {
  color: #292929;
}

#mobile_menu .mobile-main-menu.app .nav li a {
  color: #292929;
}

#mobile_menu .mobile-main-menu.app2 .nav li a {
  color: #292929;
}

#mobile_menu .mobile-main-menu.proto .nav li a {
  color: #292929;
}

/* Menu Button */
#menu-button {
  height: 25px;
  float: right;
  z-index: 99999;
  position: relative;
  top: 12px;
  cursor: pointer;
}

#menu-button span {
  position: relative;
}

#menu-button span:before, #menu-button span:after {
  position: absolute;
  content: '';
}

#menu-button span, #menu-button span:before, #menu-button span:after {
  display: block;
  width: 25px;
  height: 2px;
}

#menu-button span::before {
  top: -7px;
}

#menu-button span::after {
  top: 7px;
}

/* ************************
   03.2: Banner
   ********************* */
/* Default Banner */
.banner {
  padding-top: 240px;
  padding-bottom: 140px;
  position: relative;
  background-size: cover;
  z-index: 1;
  /* Banner Affilliate */
  /* Banner CRM */
}

@media only screen and (max-width: 1199px) {
  .banner {
    padding-top: 180px;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .banner {
    padding-top: 150px;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 479px) {
  .banner {
    padding-top: 120px;
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 991px) {
  .banner .banner-content {
    margin-bottom: 60px;
  }
}

.banner .banner-content h1 {
  font-size: 60px;
  font-weight: 600;
  line-height: 1.17;
  color: #ffffff;
  margin-bottom: 0px;
}

@media only screen and (max-width: 575px) {
  .banner .banner-content h1 {
    font-size: 50px;
  }
  .banner .banner-content h1 br {
    display: none;
  }
}

.banner .banner-content p {
  margin-bottom: 44px;
}

.banner.cource-banner {
  background: -moz-linear-gradient(top, white 0%, rgba(228, 255, 239, 0.8) 20%, rgba(228, 255, 239, 0) 100%);
  background: -webkit-linear-gradient(top, white 0%, rgba(228, 255, 239, 0.8) 20%, rgba(228, 255, 239, 0) 100%);
  background: linear-gradient(to bottom, white 0%, rgba(228, 255, 239, 0.8) 20%, rgba(228, 255, 239, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00e4ffef',GradientType=0 );
  padding-bottom: 100px;
}

.banner.cource-banner .banner-content .content {
  margin-bottom: 34px;
}

@media only screen and (max-width: 1199px) {
  .banner.cource-banner .banner-content {
    margin-bottom: 60px;
  }
}

.banner.cource-banner .banner-content h1 {
  color: #292929;
}

.banner.cource-banner .banner-content p {
  color: #5C6B56;
  margin-bottom: 34px;
}

.banner.cource-banner .banner-right {
  position: relative;
  z-index: 1;
}

.banner.cource-banner .banner-right .cbat {
  position: absolute;
  left: -75px;
  top: -60px;
  z-index: -1;
}

@media only screen and (max-width: 991px) {
  .banner.cource-banner .banner-right .cbat {
    display: none;
  }
}

.banner.cource-banner .banner-right .cbab {
  position: absolute;
  right: 0;
  bottom: -70px;
  z-index: -1;
}

@media only screen and (max-width: 991px) {
  .banner.cource-banner .banner-right .cbab {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  .banner.cource-banner .banner-right .course-b {
    margin-bottom: 50px;
  }
}

.banner.style--one {
  padding: 295px 0;
  background: #FFF5DB;
}

@media only screen and (min-width: 1200px) and (max-width: 1602px) {
  .banner.style--one {
    padding: 250px 0;
  }
}

@media only screen and (max-width: 1199px) {
  .banner.style--one {
    padding-top: 200px;
    padding-bottom: 100px;
  }
}

@media only screen and (max-width: 991px) {
  .banner.style--one {
    padding-top: 150px;
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 575px) {
  .banner.style--one {
    padding-bottom: 20px;
  }
}

.banner.style--one .banner-content h1 {
  font-size: 70px;
  color: #292929;
  margin-bottom: 22px;
  line-height: 1.15;
  font-weight: 700;
}

@media only screen and (max-width: 1199px) {
  .banner.style--one .banner-content h1 br {
    display: none;
  }
}

@media only screen and (max-width: 575px) {
  .banner.style--one .banner-content h1 {
    font-size: 50px;
  }
}

.banner.style--one .banner-img .b-thumb {
  right: 0px;
  top: 80px;
  position: absolute;
  max-width: 56%;
}

@media only screen and (min-width: 1200px) and (max-width: 1602px) {
  .banner.style--one .banner-img .b-thumb {
    top: 50%;
    margin-top: -349px;
  }
}

@media only screen and (max-width: 1199px) {
  .banner.style--one .banner-img .b-thumb {
    top: 50%;
    margin-top: -220px;
  }
}

@media only screen and (max-width: 991px) {
  .banner.style--one .banner-img {
    display: none;
  }
}

.banner.style--two {
  padding-top: 240px;
  padding-bottom: 140px;
}

@media only screen and (max-width: 1199px) {
  .banner.style--two {
    padding-top: 180px;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .banner.style--two {
    padding-top: 150px;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 479px) {
  .banner.style--two {
    padding-top: 120px;
    padding-bottom: 50px;
  }
}

.banner.style--two .banner-content {
  animation: fadeInLeft .6s linear 1.5s backwards;
}

.banner.style--two .banner-content.crm h1 {
  margin-bottom: 25px;
}

.banner.style--two .banner-content h1 {
  font-size: 60px;
  margin-bottom: 14px;
  line-height: 1.3;
  font-weight: 700;
}

@media only screen and (max-width: 1199px) {
  .banner.style--two .banner-content h1 {
    font-size: 44px;
  }
}

@media only screen and (max-width: 479px) {
  .banner.style--two .banner-content h1 {
    font-size: 42px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .banner.style--two .banner-content h1 br {
    display: none;
  }
}

.banner.style--two .crm-bg {
  position: absolute;
  right: 0;
  top: 0;
  animation: bg-scale .6s linear .3s backwards;
  width: 58%;
}

@media only screen and (min-width: 1200px) and (max-width: 1602px) {
  .banner.style--two .crm-bg {
    width: 62%;
  }
}

@media only screen and (max-width: 1199px) {
  .banner.style--two .crm-bg {
    width: 64%;
  }
}

@media only screen and (max-width: 991px) {
  .banner.style--two .crm-bg {
    width: auto;
  }
}

.banner.style--two .banner-img .b-thumb {
  animation: fadeInRight .6s linear .9s backwards;
}

@media only screen and (max-width: 1199px) {
  .banner.style--two .banner-img .b-thumb {
    margin-bottom: 60px;
  }
}

.banner.style--two.plugins {
  z-index: 2;
  padding-top: 200px;
  padding-bottom: 140px;
}

@media only screen and (max-width: 991px) {
  .banner.style--two.plugins {
    padding-top: 140px;
  }
}

.banner.style--two.plugins .plug-banner-shape {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 160px;
  z-index: 0;
}

.banner.style--two.plugins .banner-content {
  position: relative;
}

.banner.style--two.plugins .banner-content h1 {
  font-size: 70px;
  line-height: 1;
  margin-bottom: 14px;
}

@media only screen and (max-width: 575px) {
  .banner.style--two.plugins .banner-content h1 {
    font-size: 50px;
  }
}

.banner.style--two.plugins .banner-img {
  position: relative;
  margin-bottom: -140px;
}

@media only screen and (max-width: 991px) {
  .banner.style--two.plugins .banner-img {
    margin-bottom: 0;
  }
}

.banner.style--two.management {
  padding-top: 150px;
  padding-bottom: 150px;
}

@media only screen and (max-width: 1199px) {
  .banner.style--two.management {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 479px) {
  .banner.style--two.management {
    padding-top: 120px;
    padding-bottom: 0px;
  }
}

.banner.style--two.management .banner-content {
  animation: fadeInLeft2 .6s linear 1.5s backwards;
}

.banner.style--two.management .banner-content .content {
  margin-bottom: 34px;
}

.banner.style--two.management .banner-content h1 {
  font-size: 48px;
  line-height: 1.2;
  font-weight: 600;
}

@media only screen and (max-width: 1199px) {
  .banner.style--two.management .banner-content h1 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 1199px) {
  .banner.style--two.management .banner-content h1 br {
    display: none;
  }
}

.banner.style--two.management .banner-content p {
  margin-bottom: 34px;
}

.banner.style--two.management .crm-bg {
  animation: bg-scale .6s linear .3s backwards;
  width: 64%;
}

@media only screen and (max-width: 991px) {
  .banner.style--two.management .crm-bg {
    width: 100%;
  }
}

@media only screen and (max-width: 575px) {
  .banner.style--two.management .crm-bg {
    display: none;
  }
}

.banner.style--two.management .banner-img .b-thumb {
  animation: fadeInRight2 .6s linear .9s backwards;
  padding-left: 50px;
}

@media only screen and (max-width: 1199px) {
  .banner.style--two.management .banner-img .b-thumb {
    padding-left: 0;
  }
}

.banner.email {
  min-height: 900px;
  padding-top: 140px;
  padding-bottom: 30px;
}

.banner.email:after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #857DFF;
  z-index: -1;
  border-radius: 50% 0 0 50%;
  transform-origin: right center;
  animation: wide 1s linear .3s backwards;
}

@media only screen and (max-width: 991px) {
  .banner.email:after {
    display: none;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1680px) {
  .banner.email {
    min-height: 800px;
  }
}

@media only screen and (max-width: 1199px) {
  .banner.email {
    min-height: 700px;
    padding-top: 180px;
  }
}

@media only screen and (max-width: 991px) {
  .banner.email {
    min-height: auto;
    background-color: #ffffff;
    padding-bottom: 0px;
  }
}

@media only screen and (max-width: 575px) {
  .banner.email {
    padding-top: 140px;
  }
}

.banner.email .banner-content {
  animation: fadeInLeft2 .7s linear .7s backwards;
}

.banner.email .banner-content h1 {
  font-size: 60px;
  margin-bottom: 27px;
  line-height: 1.17;
  font-weight: 600;
  color: #292929;
}

@media only screen and (max-width: 1199px) {
  .banner.email .banner-content h1 {
    font-size: 44px;
  }
}

@media only screen and (max-width: 479px) {
  .banner.email .banner-content h1 {
    font-size: 34px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .banner.email .banner-content h1 br {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  .banner.email .banner-content {
    margin-bottom: 60px;
  }
}

.banner.email .email-bg {
  position: absolute;
  left: 0;
  top: 0;
  bottom: -1px;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 991px) {
  .banner.email .email-bg {
    display: none;
  }
}

.banner.email .banner-img {
  position: relative;
  z-index: 1;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .banner.email .banner-img {
    height: 500px;
  }
}

.banner.email .banner-img .email-thumb {
  position: relative;
  z-index: -1;
  right: -130px;
  bottom: -40px;
  animation: fadeInUp10 .7s linear .5s backwards;
}

@media only screen and (min-width: 1200px) and (max-width: 1680px) {
  .banner.email .banner-img .email-thumb {
    padding-left: 20px;
    right: -110px;
    bottom: 0px;
  }
}

@media only screen and (max-width: 1199px) {
  .banner.email .banner-img .email-thumb {
    padding-left: 70px;
    right: -40px;
    bottom: 0px;
  }
}

@media only screen and (max-width: 991px) {
  .banner.email .banner-img .email-thumb {
    padding-left: 0px;
    right: 0px;
    position: relative;
  }
}

.banner.email.plugins {
  z-index: 2;
  padding-top: 200px;
  padding-bottom: 140px;
}

@media only screen and (max-width: 991px) {
  .banner.email.plugins {
    padding-top: 140px;
  }
}

.banner.email.plugins .plug-banner-shape {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 160px;
  z-index: 0;
}

.banner.email.plugins .banner-content {
  position: relative;
}

.banner.email.plugins .banner-img {
  position: relative;
  margin-bottom: -140px;
}

@media only screen and (max-width: 991px) {
  .banner.email.plugins .banner-img {
    margin-bottom: 0;
  }
}

.banner.payment-banner {
  padding-top: 230px;
  padding-bottom: 435px;
  position: relative;
}

.banner.payment-banner .payment-banner-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: -50%;
  z-index: -1;
  background: #9578FA;
  background: -moz-linear-gradient(top, #9578FA 0%, #6449D6 100%);
  background: -webkit-linear-gradient(top, #9578FA 0%, #6449D6 100%);
  background: linear-gradient(to bottom, #9578FA 0%, #6449D6 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9578FA', endColorstr='#6449D6',GradientType=0);
  animation: zoomDown .6s linear .3s backwards;
  border-radius: 0 0 50% 50%;
}

@media only screen and (max-width: 991px) {
  .banner.payment-banner .payment-banner-bg {
    border-radius: 0;
    animation: none;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1602px) {
  .banner.payment-banner {
    padding-top: 180px;
    padding-bottom: 300px;
  }
}

@media only screen and (max-width: 1199px) {
  .banner.payment-banner {
    padding-top: 140px;
    padding-bottom: 100px;
  }
}

@media only screen and (max-width: 991px) {
  .banner.payment-banner {
    padding-bottom: 180px;
  }
}

.banner.payment-banner .pay-banner-shape {
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .banner.payment-banner .pay-banner-shape {
    display: none;
  }
}

.banner.payment-banner .banner-content {
  animation: fadeInUp .8s linear 1.4s backwards;
}

.banner.payment-banner .banner-content .content h1 {
  color: #ffffff;
  font-size: 60px;
  margin-bottom: 15px;
}

@media only screen and (max-width: 767px) {
  .banner.payment-banner .banner-content .content h1 {
    font-size: 50px;
  }
}

.banner.payment-banner .banner-content .content p {
  max-width: 100%;
  margin-bottom: 30px;
}

.banner.book {
  padding-top: 240px;
  border-bottom-right-radius: 200px;
}

@media only screen and (max-width: 991px) {
  .banner.book {
    padding-top: 180px;
  }
}

@media only screen and (max-width: 767px) {
  .banner.book {
    padding-top: 140px;
    border-bottom-right-radius: 100px;
  }
}

@media only screen and (max-width: 991px) {
  .banner.book .banner-content {
    margin-bottom: 60px;
  }
}

.banner.book .banner-content .doller {
  font-weight: 500;
  font-size: 36px;
  margin-bottom: 18px;
}

.banner.book .banner-content h1 {
  font-size: 70px;
  font-weight: 600;
  color: #ffffff;
}

@media only screen and (min-width: 992px) and (max-width: 1500px) {
  .banner.book .banner-content h1 {
    font-size: 60px;
  }
  .banner.book .banner-content h1 br {
    display: none;
  }
}

@media only screen and (max-width: 575px) {
  .banner.book .banner-content h1 {
    font-size: 50px;
  }
  .banner.book .banner-content h1 br {
    display: none;
  }
}

.banner.book .banner-content .star-rating h4 {
  font-size: 22px;
  display: inline-block;
  font-weight: 500;
  margin-right: 26px;
}

.banner.book .banner-content .star-rating i {
  font-size: 16px;
}

.banner.analytic {
  margin-bottom: 140px;
  padding-top: 220px;
}

@media only screen and (max-width: 991px) {
  .banner.analytic {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 1199px) {
  .banner.analytic {
    padding-top: 180px;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .banner.analytic {
    padding-top: 150px;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 479px) {
  .banner.analytic {
    padding-top: 120px;
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 991px) {
  .banner.analytic {
    background-color: #ffffff;
    background-color: -moz-linear-gradient(left, #ffffff 0%, #DBF0FF 100%);
    background-color: -webkit-linear-gradient(left, #ffffff 0%, #DBF0FF 100%);
    background-color: linear-gradient(to right, #ffffff 0%, #DBF0FF 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#DBF0FF',GradientType=1);
  }
}

.banner.analytic .analytic-shape {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: -1;
}

@media only screen and (max-width: 991px) {
  .banner.analytic .analytic-shape {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  .banner.analytic .banner-content {
    margin-bottom: 60px;
  }
}

.banner.analytic .banner-content h1 {
  font-size: 70px;
  font-size: 600;
  line-height: 1.17;
  margin-bottom: 24px;
}

@media only screen and (max-width: 575px) {
  .banner.analytic .banner-content h1 {
    font-size: 50px;
  }
  .banner.analytic .banner-content h1 br {
    display: none;
  }
}

.banner.analytic .banner-content p {
  font-size: 22px;
  margin-bottom: 44px;
  color: #292929;
}

.banner.analytic .banner-content p.info {
  font-size: 16px;
  font-weight: 400;
  margin-top: 15px;
}

.banner.hosting {
  padding-top: 325px;
  padding-bottom: 366px;
  background-size: cover;
  background-position: center;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .banner.hosting {
    padding-top: 280px;
    padding-bottom: 315px;
  }
}

@media only screen and (max-width: 1199px) {
  .banner.hosting {
    padding-top: 180px;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .banner.hosting {
    padding-top: 150px;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 479px) {
  .banner.hosting {
    padding-top: 120px;
    padding-bottom: 50px;
  }
}

.banner.hosting .banner-content {
  padding-right: 118px;
  margin-bottom: 0;
}

@media only screen and (max-width: 1199px) {
  .banner.hosting .banner-content {
    padding-right: 0;
  }
}

.banner.hosting .banner-content h1 {
  margin-bottom: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .banner.hosting .banner-content h1 {
    font-size: 53px;
  }
  .banner.hosting .banner-content h1 br {
    display: none;
  }
}

@media only screen and (max-width: 1199px) {
  .banner.hosting .banner-content h1 {
    font-size: 54px;
  }
  .banner.hosting .banner-content h1 br {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .banner.hosting .banner-content h1 {
    font-size: 50px;
  }
}

.banner.hosting .banner-content p {
  margin-bottom: 40px;
}

.banner.pos {
  padding-top: 220px;
  padding-bottom: 200px;
}

@media only screen and (max-width: 991px) {
  .banner.pos {
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 1199px) {
  .banner.pos {
    padding-top: 180px;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .banner.pos {
    padding-top: 150px;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 479px) {
  .banner.pos {
    padding-top: 120px;
    padding-bottom: 50px;
  }
}

.banner.pos .pos-banner-bg {
  background-color: #F3FFF7;
  position: absolute;
  width: 35%;
  height: 100%;
  right: 0;
  top: 0;
}

@media only screen and (max-width: 991px) {
  .banner.pos .pos-banner-bg {
    width: 100%;
  }
}

@media only screen and (max-width: 991px) {
  .banner.pos .banner-content {
    margin-bottom: 60px;
  }
}

.banner.pos .banner-content h1 {
  font-size: 70px;
  color: #292929;
  margin-bottom: 10px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .banner.pos .banner-content h1 br {
    display: none;
  }
}

@media only screen and (max-width: 1199px) {
  .banner.pos .banner-content h1 {
    font-size: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .banner.pos .banner-content h1 {
    font-size: 50px;
  }
}

@media only screen and (max-width: 379px) {
  .banner.pos .banner-content h1 {
    font-size: 44px;
  }
}

.banner.pos .banner-content p {
  margin-bottom: 44px;
}

.banner.app {
  padding-top: 180px;
  padding-bottom: 160px;
}

@media only screen and (max-width: 1199px) {
  .banner.app {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .banner.app {
    padding-top: 150px;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 479px) {
  .banner.app {
    padding-top: 120px;
    padding-bottom: 50px;
  }
}

.banner.app .pos-banner-bg {
  background-color: #F3FFF7;
  position: absolute;
  width: 35%;
  height: 100%;
  right: 0;
  top: 0;
}

@media only screen and (max-width: 991px) {
  .banner.app .pos-banner-bg {
    width: 100%;
  }
}

@media only screen and (max-width: 991px) {
  .banner.app .banner-content {
    margin-bottom: 60px;
  }
}

.banner.app .banner-content h1 {
  font-size: 80px;
  color: #292929;
  line-height: 1.1;
  margin-bottom: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .banner.app .banner-content h1 br {
    display: none;
  }
}

@media only screen and (max-width: 1199px) {
  .banner.app .banner-content h1 {
    font-size: 76px;
  }
  .banner.app .banner-content h1 br {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .banner.app .banner-content h1 {
    font-size: 48px;
  }
}

.banner.app .banner-content p {
  margin-bottom: 34px;
}

.banner.app2 {
  background-color: #F5F8FF;
  padding-top: 130px;
  padding-bottom: 80px;
}

@media only screen and (max-width: 1199px) {
  .banner.app2 {
    padding-top: 180px;
  }
}

@media only screen and (max-width: 479px) {
  .banner.app2 {
    padding-top: 120px;
    padding-bottom: 50px;
  }
}

.banner.app2 .banner-content {
  animation: fadeInUp10 .7s ease-in forwards;
  padding-right: 120px;
}

.banner.app2 .banner-content .content {
  margin-bottom: 34px;
}

@media only screen and (min-width: 991px) and (max-width: 1500px) {
  .banner.app2 .banner-content {
    padding-right: 0;
  }
}

@media only screen and (max-width: 991px) {
  .banner.app2 .banner-content {
    margin-bottom: 60px;
    padding-right: 0;
  }
}

.banner.app2 .banner-content .star-rating {
  font-size: 16px;
}

.banner.app2 .banner-content .star-rating span {
  font-weight: 300;
}

.banner.app2 .banner-content h1 {
  font-size: 60px;
  color: #292929;
  line-height: 1.18;
  margin-bottom: 20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .banner.app2 .banner-content h1 br {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  .banner.app2 .banner-content h1 br {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .banner.app2 .banner-content h1 {
    font-size: 48px;
  }
}

.banner.app2 .banner-content p {
  margin-bottom: 25px;
}

.banner.app2 .banner-img {
  padding-right: 50px;
  animation: rotate .7s ease-in forwards;
}

@media only screen and (max-width: 991px) {
  .banner.app2 .banner-img {
    padding-right: 0;
  }
}

.banner.proto {
  padding-top: 150px;
  padding-bottom: 150px;
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 1199px) {
  .banner.proto {
    padding-top: 180px;
    padding-bottom: 120px;
  }
}

@media only screen and (max-width: 479px) {
  .banner.proto {
    padding-top: 120px;
    padding-bottom: 50px;
  }
}

.banner.proto .proto-bg {
  left: 10px;
  top: 0;
  position: absolute;
  z-index: -1;
  height: 100%;
}

@media only screen and (max-width: 991px) {
  .banner.proto .proto-bg {
    left: 0px;
  }
}

.banner.proto .banner-content {
  animation: fadeInUp10 1.1s linear 0s 1 forwards;
}

.banner.proto .banner-content h1 {
  font-size: 60px;
  line-height: 1.18;
  margin-bottom: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .banner.proto .banner-content h1 br {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  .banner.proto .banner-content h1 br {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .banner.proto .banner-content h1 {
    font-size: 48px;
  }
}

@media only screen and (max-width: 479px) {
  .banner.proto .banner-content h1 {
    font-size: 40px;
  }
}

.banner.proto .banner-content p {
  margin-bottom: 25px;
}

.banner.proto .banner-img {
  padding-left: 35px;
}

@media only screen and (max-width: 991px) {
  .banner.proto .banner-img {
    padding-left: 0px;
  }
}

.banner.proto .banner-img img {
  max-width: none;
}

@media only screen and (max-width: 991px) {
  .banner.proto .banner-img img {
    max-width: 100%;
  }
}

.banner-content {
  position: relative;
  z-index: 2;
}

.banner-content h1 {
  font-size: 50px;
  font-weight: 600;
  color: #292929;
  text-transform: capitalize;
  margin-bottom: 8px;
}

.banner-content .content {
  margin-bottom: 44px;
}

.banner-content .content P {
  max-width: 560px;
  font-weight: 300;
}

.banner-content .content P.affiliate {
  color: #81724D;
}

.banner-content .person {
  margin-bottom: 10px;
  display: inline-block;
}

.banner-content .person span {
  color: #5DD9C1;
  font-weight: 600;
}

/* Star Rating */
.star-rating {
  margin-bottom: 6px;
}

.star-rating span {
  display: inline-block;
  margin-left: 5px;
}

.star-rating.mb12 {
  margin-bottom: 12px;
}

.pay-img-wrap {
  animation: fadeInUp .8s linear 1.4s backwards;
  position: relative;
  margin-top: -400px;
  z-index: 2;
}

@media only screen and (min-width: 1200px) and (max-width: 1602px) {
  .pay-img-wrap {
    margin-top: -230px;
  }
}

@media only screen and (max-width: 1199px) {
  .pay-img-wrap {
    margin-top: -100px;
  }
}

@media only screen and (max-width: 991px) {
  .pay-img-wrap {
    margin-top: -180px;
  }
}

/* Course Info */
.course-info {
  text-align: center;
  margin-bottom: 30px;
}

.course-info h3 {
  font-size: 30px;
  font-weight: 500;
  color: #292929;
  text-transform: capitalize;
  margin-bottom: 15px;
}

@media only screen and (max-width: 1199px) {
  .course-info h3 {
    font-size: 28px;
  }
}

.course-info p {
  text-transform: capitalize;
  color: #5C6B56;
}

/* App Page Banner Icons */
.app-line, .app-circle, .app-triangle, .app-hexa {
  position: absolute;
  z-index: -1;
}

.app-line {
  left: 7%;
  top: 28%;
  animation: levitate 15s linear alternate backwards;
}

.app-circle {
  left: 2%;
  top: 53%;
  animation: zoomDown 3s linear 0s infinite both;
}

.app-triangle {
  left: 32%;
  top: 16%;
  animation: spin 180s linear 1s infinite alternate both;
}

.app-hexa {
  left: 38%;
  top: 76%;
  animation: spin 10s linear infinite both;
}

/* ************************
   03.3: Blog
   ********************* */
/* Single Blog */
.single-blog-item {
  position: relative;
  margin-bottom: 50px;
}

.single-blog-item .blog-image img {
  display: block;
  width: 100%;
}

.single-blog-item .blog-content {
  margin-top: 15px;
}

.single-blog-item .blog-content .blog-meta a {
  font-size: 14px;
}

.single-blog-item .blog-content h3 {
  line-height: 1.25;
  margin-bottom: 10px;
  margin-top: 10px;
  color: #292929;
}

.single-blog-item .blog-content p {
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 0;
}

/* Blog Details */
.post-details {
  font-size: 16px;
}

.post-details figcaption {
  margin-top: 10px;
}

.post-details .entry-header .entry-thumbnail {
  margin-bottom: 50px;
}

.post-details .entry-header .entry-title {
  font-size: 36px;
  line-height: 1.28;
  margin-bottom: 5px;
  color: #292929;
}

.post-details .entry-header .entry-meta {
  margin-bottom: 42px;
}

.post-details .entry-header .entry-meta li:not(:last-child) {
  margin-right: 50px;
}

.post-details .entry-header .entry-meta li span {
  font-weight: 700;
  margin-right: 7px;
  font-size: 16px;
}

.post-details .entry-header .entry-meta li a {
  font-size: 14px;
}

.post-details .entry-tags ul li:first-child,
.post-details .entry-categories ul li:first-child {
  color: #292929;
  font-size: 16px;
  font-weight: 700;
  margin-right: 5px;
}

.post-details .entry-tags ul li a,
.post-details .entry-categories ul li a {
  font-size: 14px;
  line-height: 1.57;
}

.post-details .entry-tags ul li a:not(:last-child):after,
.post-details .entry-categories ul li a:not(:last-child):after {
  content: ',';
}

/* Blog Details Content */
.entry-content h1,
.entry-content h2,
.entry-content h3,
.entry-content h4,
.entry-content h5,
.entry-content h6,
.comment-content h1,
.comment-content h2,
.comment-content h3,
.comment-content h4,
.comment-content h5,
.comment-content h6,
.page--content h1,
.page--content h2,
.page--content h3,
.page--content h4,
.page--content h5,
.page--content h6 {
  line-height: 1.5;
  margin-bottom: 15px;
  color: #292929;
}

.entry-content p,
.comment-content p,
.page--content p {
  margin-bottom: 30px;
  color: #292929;
}

.entry-content table,
.comment-content table,
.page--content table {
  margin-bottom: 50px;
}

.entry-content select,
.comment-content select,
.page--content select {
  margin-bottom: 50px;
}

.entry-content > *:last-child,
.comment-content > *:last-child,
.page--content > *:last-child {
  margin-bottom: 0;
}

.entry-content a,
.comment-content a,
.page--content a {
  color: #292929;
  font-weight: 700;
  font-size: 14px;
}

.entry-content a:hover,
.comment-content a:hover,
.page--content a:hover {
  text-decoration: underline;
}

.entry-content a i,
.comment-content a i,
.page--content a i {
  margin-right: 5px;
}

/* Post Author Info */
.post-author {
  padding: 40px 0;
}

.post-author .author-thumb {
  margin-right: 30px;
}

.post-author .author-info h4 {
  font-size: 18px;
  margin-bottom: 5px;
  color: #292929;
}

.post-author .author-info p {
  font-size: 16px;
}

.post-title {
  color: #292929;
}

/* Post Navigation */
.post-navigation .nav-link {
  flex-basis: 50%;
  max-width: 50%;
}

.post-navigation .nav-link .nav-title {
  display: block;
  font-size: 14px;
  font-weight: 700;
  opacity: .5;
  margin-bottom: 17px;
  color: #292929;
}

.post-navigation .nav-link .nav-title i {
  margin-right: 10px;
}

.post-navigation .nav-link .nav-title.text-right i {
  margin-left: 10px;
}

.post-navigation .nav-link .posted-on {
  margin: 10px 0 2px;
  display: block;
  font-size: 14px;
}

.post-navigation .nav-link:hover .nav-title {
  opacity: 1;
}

/* Related Post */
.related-post h4 {
  color: #292929;
  margin-bottom: 30px;
}

/* Post Comments */
.comments-area .comments-title {
  margin-bottom: 25px;
  color: #292929;
}

.comments-area ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.comments-area .comment .single-comment {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #E2E2E2;
}

@media only screen and (max-width: 479px) {
  .comments-area .comment .single-comment {
    flex-direction: column;
  }
}

.comments-area .comment .single-comment .comment-author-image {
  min-width: 65px;
  margin-right: 30px;
  border-radius: 50%;
  overflow: hidden;
}

@media only screen and (max-width: 479px) {
  .comments-area .comment .single-comment .comment-author-image {
    margin-right: 0;
    margin-bottom: 30px;
  }
}

.comments-area .comment .single-comment:not(.trackback):not(.pingback) .comment-content {
  max-width: calc(100% - 95px);
  width: 100%;
}

@media only screen and (max-width: 479px) {
  .comments-area .comment .single-comment:not(.trackback):not(.pingback) .comment-content {
    max-width: 100%;
  }
}

.comments-area .comment .single-comment .comment-content .author_name {
  margin-right: 10px;
  margin-bottom: 0;
  font-size: 16px;
}

.comments-area .comment .single-comment .comment-content .commented-on {
  font-size: 12px;
}

.comments-area .comment .single-comment .comment-content p {
  margin: 7px 0 5px;
  font-size: 16px;
  color: #707070;
  font-weight: 400;
}

.comments-area .comment ul.children {
  margin-left: 95px;
}

@media only screen and (max-width: 479px) {
  .comments-area .comment ul.children {
    margin-left: 20px;
  }
}

/* Comments Form */
@media only screen and (max-width: 991px) {
  .comment-respond {
    margin-bottom: 60px;
  }
}

.comment-respond .comment-reply-title {
  margin-bottom: 20px;
  color: #292929;
}

.comment-respond .comment-form .comment-notes {
  margin-bottom: 30px;
  font-size: 16px;
}

.comment-respond .comment-form .theme-input-style, .comment-respond .comment-form .entry-content select, .entry-content .comment-respond .comment-form select,
.comment-respond .comment-form .comment-content select, .comment-content .comment-respond .comment-form select,
.comment-respond .comment-form .page--content select, .page--content .comment-respond .comment-form select {
  margin-bottom: 35px;
}

.comment-respond .comment-form label {
  margin-bottom: 10px;
}

.comment-respond .comment-form .custom-checkbox {
  margin-bottom: 25px;
}

.comment-respond .comment-form .custom-checkbox label {
  font-size: 16px;
}

/* Responsive Table */
@media only screen and (max-width: 991px) {
  .responsive_table thead {
    display: none;
  }
  .responsive_table tbody tr {
    display: block;
  }
  .responsive_table tbody tr:not(:last-child) {
    margin-bottom: 50px;
  }
  .responsive_table tbody td {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.labelText {
  color: #292929;
  font-size: 18px;
  font-weight: 700;
}

/* ************************
   03.4: Footer
   ********************* */
.footer {
  padding-top: 98px;
  padding-bottom: 50px;
}

.footer .theme-input-group input:placeholder {
  color: #707070 !important;
}

.footer .theme-input-group.affiliate input[type=email]::placeholder, .footer .theme-input-group.course input[type=email]::placeholder, .footer .theme-input-group.style--four input[type=email]::placeholder, .footer .theme-input-group.style--two.analytic input[type=email]::placeholder, .footer .theme-input-group.error404 input[type=email]::placeholder {
  color: #ffffff !important;
}

.footer .theme-input-group.affiliate input[type=email]::-webkit-input-placeholder, .footer .theme-input-group.course input[type=email]::-webkit-input-placeholder, .footer .theme-input-group.style--four input[type=email]::-webkit-input-placeholder, .footer .theme-input-group.style--two.analytic input[type=email]::-webkit-input-placeholder, .footer .theme-input-group.error404 input[type=email]::-webkit-input-placeholder {
  color: #ffffff !important;
}

.footer .widget {
  margin-bottom: 30px;
  padding: 0;
  background-color: transparent;
}

.footer .widget:last-child {
  margin-bottom: 50px;
}

.footer .widget .widget_title h3 {
  font-size: 26px;
  color: #292929;
  font-weight: 700;
  margin-bottom: 24px;
}

.footer .widget .widget-logo {
  margin-bottom: 30px;
}

.footer .widget.widget_text p {
  margin-bottom: 30px;
  font-weight: 300;
  max-width: 380px;
}

.footer .footer-top {
  margin-bottom: 90px;
}

.footer .footer-top .footer-top-left h2 {
  margin-bottom: 16px;
  font-size: 46px;
  font-weight: 600;
}

@media only screen and (max-width: 575px) {
  .footer .footer-top .footer-top-left h2 {
    font-size: 30px;
  }
}

.footer .footer-top .footer-top-left p {
  max-width: 550px;
  font-size: 22px;
}

.footer .footer-top .footer-top-right .custom-checkbox label {
  font-size: 16px;
}

.footer.style--two {
  background-color: #fcf3f1;
  padding-top: 138px;
  padding-bottom: 140px;
}

@media only screen and (max-width: 991px) {
  .footer.style--two {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.footer.style--two .footer-main .footer-boxed {
  background: #ffffff;
  padding-top: 100px;
  padding-bottom: 50px;
}

@media only screen and (max-width: 575px) {
  .footer.style--two .footer-main .footer-boxed {
    padding-top: 60px;
    padding-bottom: 10px;
  }
}

.footer.style--two .widget .widget_title h3 {
  color: #57548D;
  margin-bottom: 34px;
}

.footer.style--two.analytic {
  padding-bottom: 0;
}

.footer.style--two.analytic .widget .widget_title h3 {
  color: #292929;
  margin-bottom: 34px;
}

.footer.style--two.analytic .widget.widget_text p {
  font-weight: 400;
}

.footer.style--two.pos {
  background-color: #EEF8F0;
}

.footer.pay-footer {
  padding-top: 210px;
  padding-bottom: 50px;
  background: #9578FA;
  background: -moz-linear-gradient(top, #9578FA 0%, #6449D6 100%);
  background: -webkit-linear-gradient(top, #9578FA 0%, #6449D6 100%);
  background: linear-gradient(to bottom, #9578FA 0%, #6449D6 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9578FA', endColorstr='#6449D6',GradientType=0);
}

.footer.pay-footer .widget .widget_title h3 {
  color: #ffffff;
}

.footer.plugin-footer {
  padding-top: 180px;
  padding-bottom: 50px;
  position: relative;
}

.footer.plugin-footer .footer-shape {
  position: absolute;
  left: 0;
  top: -1px;
  width: 100%;
  height: 80px;
}

.footer.plugin-footer .widget .widget_title h3 {
  color: #ffffff;
}

.footer.course {
  background: #F6F8F5;
  background: -moz-linear-gradient(top, #F6F8F5 0%, #FFFFFF 100%);
  background: -webkit-linear-gradient(top, #F6F8F5 0%, #FFFFFF 100%);
  background: linear-gradient(to bottom, #F6F8F5 0%, #FFFFFF 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#F6F8F5', endColorstr='#FFFFFF',GradientType=0);
}

.footer.style--three {
  font-size: 18px;
  font-weight: 300;
  padding: 48px 0 34px;
  line-height: 1;
}

.footer.style--three .footer-logo {
  margin-bottom: 15px;
}

.footer.style--four {
  padding-top: 140px;
  padding-bottom: 130px;
}

@media only screen and (max-width: 991px) {
  .footer.style--four {
    padding-top: 80px;
    padding-bottom: 70px;
  }
}

.footer.style--four .footer-main .footer-main-inner {
  border-bottom: 1px solid #BFBFBF;
  padding-bottom: 10px;
}

.footer.style--four .footer-bottom {
  padding-top: 60px;
}

.footer.style--four .footer-bottom .footer-bottom-left h2 {
  font-weight: 600;
  margin-bottom: 15px;
}

.footer.style--four .footer-bottom p {
  font-size: 16px;
}

.footer.style--four .widget .widget_title h3 {
  color: #ffffff;
}

.footer.style--four .widget .footer_menu li a {
  color: #ffffff;
}

.footer.style--four .footer-icon1, .footer.style--four .footer-icon2, .footer.style--four .footer-icon3, .footer.style--four .footer-icon4 {
  position: absolute;
  opacity: 0.6;
  left: 1%;
  top: 3%;
}

.footer.style--four .footer-icon2 {
  left: auto;
  right: 2.5%;
  top: 4%;
}

.footer.style--four .footer-icon3 {
  left: 40%;
  top: 62%;
}

.footer.style--four .footer-icon4 {
  left: auto;
  right: 2%;
  top: auto;
  bottom: 2%;
}

.footer.management {
  padding-top: 0;
  padding-bottom: 0;
}

.footer.management .footer-main {
  padding-top: 100px;
  padding-bottom: 50px;
}

.footer.management .widget .widget_title h3 {
  color: #ffffff;
}

.footer.management .widget .footer_menu li a {
  color: #ffffff;
}

.footer.management .footer-bottom {
  background-color: #6C69CF;
  font-size: 18px;
  padding: 23px 0 25px;
}

.footer.pos {
  padding-top: 118px;
  padding-bottom: 120px;
}

@media only screen and (max-width: 991px) {
  .footer.pos {
    padding-top: 78px;
    padding-bottom: 80px;
  }
}

.footer.pos .widget.widget_text p {
  font-weight: 400;
}

.footer.pos .widget .widget_title h3 {
  color: #292929;
}

.footer.pos .widget .footer_menu li a {
  font-weight: 400;
}

.footer.app {
  background-size: cover;
  background-position: top center;
  position: relative;
  padding-top: 300px;
  margin-top: 240px;
}

@media only screen and (max-width: 1199px) {
  .footer.app {
    padding-top: 250px;
  }
}

@media only screen and (max-width: 575px) {
  .footer.app {
    margin-top: 120px;
  }
}

@media only screen and (max-width: 479px) {
  .footer.app {
    padding-top: 170px;
  }
}

.footer.app .footer-img {
  display: flex;
  justify-content: center;
}

.footer.app .footer-img img {
  position: absolute;
  bottom: 300px;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .footer.app .footer-img img {
    left: 8%;
  }
}

.footer.app .footer-content .footer-logo {
  margin-bottom: 30px;
}

.footer.app .footer-content p {
  font-size: 16px;
  max-width: 560px;
  margin: 0 auto 30px;
}

.footer.app2 {
  padding-top: 0;
  padding-bottom: 0;
}

.footer.app2 .footer-main {
  padding-top: 100px;
  padding-bottom: 50px;
}

.footer.app2 .widget .widget_title h3 {
  color: #292929;
}

.footer.app2 .widget .footer_menu li a {
  color: #707070;
}

.footer.app2 .footer-bottom {
  background-color: #E8EFFF;
  font-size: 18px;
  padding: 23px 0 25px;
  color: #292929;
}

.footer.app2 .footer-bottom a {
  color: #292929;
}

.footer.proto {
  padding-top: 370px;
  padding-bottom: 115px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .footer.proto {
    padding-top: 80px;
    padding-bottom: 10px;
  }
}

.footer.proto > img {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: -1px;
}

@media only screen and (max-width: 767px) {
  .footer.proto > img {
    display: none;
  }
}

.footer.proto .widget .widget_title h3 {
  color: #ffffff;
}

.footer.proto .widget .footer_menu li a {
  color: #ffffff;
}

.footer .copyright-footer {
  padding: 56px 0;
  font-size: 16px;
}

.footer .copyright-footer .copyright-text {
  color: #ffffff;
}

.footer .copyright-footer .copyright-text a {
  color: #ffffff;
}

.footer .copyright-footer .copyright-text.style--two {
  color: #707070;
}

.footer .copyright-footer .copyright-text.style--two a {
  color: #707070;
}

/* ************************
   03.5: 404
   ********************* */
@media only screen and (max-width: 991px) {
  .error-404 {
    padding: 80px 0;
  }
}

.content-404 h1 {
  font-size: 60px;
  line-height: 1.4;
  margin-bottom: 8px;
  font-weight: 600;
  color: #292929;
}

@media only screen and (max-width: 575px) {
  .content-404 h1 {
    font-size: 50px;
  }
}

.content-404 a {
  font-weight: 500;
}

.content-404 > p {
  margin-bottom: 34px;
}

.content-404 .search-form p, .content-404 .widget.widget_search p, .content-404 .widget.widget_newsletter.style--two p {
  font-weight: 600;
  margin-bottom: 20px;
}

.img-404-absolute {
  display: none;
}

@media only screen and (min-width: 1980px) and (max-width: 3600px) {
  .img-404-absolute {
    display: block;
    position: absolute;
    width: 50%;
    right: 0;
    top: 0;
    height: 100%;
  }
}

.img-404 img {
  max-width: none;
}

@media only screen and (min-width: 1980px) and (max-width: 3600px) {
  .img-404 img {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  .img-404 img {
    max-width: 100%;
    margin-bottom: 60px;
  }
}

.icon404-1 {
  position: absolute;
  animation: banner3Shake 3s linear 0s infinite forwards;
  left: 5%;
  top: 20%;
  z-index: -1;
}

/* ************************
   03.6: Coming Soon
   ********************* */
.content-coming-soon h1 {
  font-size: 60px;
  line-height: 1.4;
  margin-bottom: 8px;
  font-weight: 600;
  color: #292929;
}

@media only screen and (max-width: 575px) {
  .content-coming-soon h1 {
    font-size: 50px;
  }
}

.content-coming-soon > p {
  margin-bottom: 24px;
}

/* ************************
   04.1: How Earn
   ********************* */
.swiper-container {
  width: 100%;
  height: 400px;
  margin-left: auto;
  margin-right: auto;
}

.swiper-container.style--two {
  height: 440px;
}

@media only screen and (max-width: 1199px) {
  .swiper-container.style--two {
    height: 600px;
  }
}

@media only screen and (max-width: 991px) {
  .swiper-container.style--two {
    height: 400px;
    margin-bottom: 80px;
    padding-top: 10px;
  }
}

@media only screen and (max-width: 575px) {
  .swiper-container.style--two {
    height: 500px;
  }
}

@media only screen and (max-width: 379px) {
  .swiper-container.style--two {
    height: 600px;
  }
}

.swiper-slide {
  font-size: 18px;
  background: #fff;
  padding-left: 60px;
}

@media only screen and (max-width: 575px) {
  .swiper-slide {
    font-size: 14px;
    padding-left: 50px;
  }
}

.swiper-slide h3 {
  color: #292929;
  font-weight: 500;
  margin-bottom: 12px;
  cursor: pointer;
}

@media only screen and (max-width: 575px) {
  .swiper-slide h3 {
    font-size: 20px;
    margin-bottom: 6px;
  }
}

@media only screen and (max-width: 479px) {
  .swiper-slide P {
    font-size: 16px;
    line-height: 1.5;
  }
}

.dot-line {
  width: 2px;
  background: #CECECE;
  height: 127%;
  position: absolute;
  left: 10px;
}

.dot-line:after {
  position: absolute;
  content: "";
  width: 12px;
  height: 12px;
  background: #ffffff;
  border: 2px solid #CECECE;
  left: -4.5px;
  top: 0;
}

.swiper-slide.slide-active .dot-line {
  background: #5F52DC;
  animation: fullHeight .5s linear 0s backwards;
}

.swiper-slide.slide-active .dot-line:after {
  width: 20px;
  height: 20px;
  border: 3px solid #5F52DC;
  left: -9px;
}

/* Swiper Slider Next Prev Button*/
.swiper-button-prev, .swiper-button-next {
  background: transparent;
  position: absolute;
  top: 0px;
  left: 14.5px;
}

.swiper-button-prev:after, .swiper-button-next:after {
  position: absolute;
  top: -30px;
  left: -1px;
  content: "\f106";
  font-family: fontAwesome;
  font-size: 40px;
}

.swiper-button-prev.swiper-button-disabled:after, .swiper-button-next.swiper-button-disabled:after {
  color: #B8B8B8;
}

.swiper-button-next {
  top: auto;
  bottom: -50px;
}

.swiper-button-next:after {
  content: "\f107";
  top: auto;
  bottom: 0;
}

/* HomePage POS Swiper */
.swiper-button-prev.style--two, .swiper-button-next.style--two {
  left: 4px;
  bottom: -60px;
}

.swiper-button-prev.style--two {
  top: 10px;
}

.dot-line.style--two {
  left: 15px;
}

.dot-line.style--two:after, .dot-line.style--two:before {
  width: 16px;
  height: 16px;
  border: 3px solid #00418E;
  left: -6.5px;
  top: 0;
}

.dot-line.style--two:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  background-color: #FF5B83;
  left: 50%;
  transform: translate(-50%);
  top: 8px;
  border: none;
  z-index: 1;
}

.swiper-slide.style--two p {
  font-size: 20px;
}

.swiper-slide.style--two.slide-active .dot-line {
  background: #CECECE;
  animation: fullHeight .5s linear 0s backwards;
}

.swiper-slide.style--two.slide-active .dot-line:after {
  width: 30px;
  height: 30px;
  border: 2px solid #00418E;
  left: -14px;
}

.swiper-slide.style--two.slide-active .dot-line:before {
  width: 18px;
  height: 18px;
  top: 6px;
  left: 50%;
  transform: translate(-50%);
}

/* HomePage Learning App Swiper */
.swiper-container.how-work-slider {
  height: 600px;
}

@media only screen and (max-width: 1199px) {
  .swiper-container.how-work-slider {
    height: 700px;
  }
}

@media only screen and (max-width: 991px) {
  .swiper-container.how-work-slider {
    height: 600px;
    margin-bottom: 60px;
  }
}

.swiper-container.how-work-slider .swiper-slide {
  background: transparent;
  padding-left: 0;
  padding: 20px 30px;
}

@media only screen and (max-width: 479px) {
  .swiper-container.how-work-slider .swiper-slide {
    padding: 10px;
  }
}

.swiper-container.how-work-slider .swiper-slide .img {
  margin-right: 30px;
}

@media only screen and (max-width: 479px) {
  .swiper-container.how-work-slider .swiper-slide .img {
    margin-right: 20px;
  }
}

.swiper-container.how-work-slider .swiper-slide .content h3 {
  font-size: 30px;
  font-weight: 600;
}

@media only screen and (max-width: 575px) {
  .swiper-container.how-work-slider .swiper-slide .content h3 {
    font-size: 22px;
  }
}

.swiper-container.how-work-slider .swiper-slide .content p {
  font-size: 20px;
}

@media only screen and (max-width: 575px) {
  .swiper-container.how-work-slider .swiper-slide .content p {
    font-size: 16px;
  }
}

.swiper-container.how-work-slider .swiper-slide.active {
  background-color: #ffffff;
}

/* Swiper Slider Tab CSS*/
[data-tab] {
  display: none;
}

[data-tab].active {
  display: block;
}

/* ************************
04.2: Service
********************* */
.service-bg {
  background-color: #F5F8FF;
}

/* Analitic Service BG */
.analytic-fbg {
  background: #F7FBFE;
}

.single-service {
  padding: 60px 40px;
  margin-bottom: 30px;
  background-color: #ffffff;
  position: relative;
  transform: translateY(0);
}

@media only screen and (max-width: 575px) {
  .single-service {
    padding: 40px 20px;
  }
}

.single-service:hover {
  transform: translateY(-5px);
}

.single-service .service-icon {
  width: 80px;
  height: 80px;
  background: #6BF1D6;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
}

.single-service .service-icon.two {
  background: #AE88E6;
}

.single-service .service-icon.three {
  background: #47CBF3;
}

.single-service .service-icon.four {
  background: #FFBD52;
}

.single-service .service-icon.five {
  background: #F091BA;
}

.single-service .service-icon.six {
  background: #77A8FF;
}

.single-service .service-content h3 {
  color: #292929;
  margin-bottom: 8px;
  font-weight: 500;
}

@media only screen and (max-width: 479px) {
  .single-service {
    flex-direction: column;
    justify-content: center;
  }
  .single-service .service-icon {
    margin-right: 0px;
    margin-bottom: 30px;
  }
  .single-service .service-content {
    text-align: center;
  }
}

.single-service.style--two {
  overflow: hidden;
}

.single-service.style--two > span {
  position: absolute;
  width: 0;
  height: 0;
  display: block;
  z-index: -1;
  transform: translate(-50%, -50%);
  transition: .7s;
}

.single-service.style--two:hover > span {
  width: 300%;
  height: 1320px;
}

.single-service.style--two .service-icon {
  margin: 0px;
  margin-bottom: 38px;
  width: 120px;
  height: 120px;
  background-color: #ECEBFF;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.single-service.style--two .service-icon.bg-2 {
  background-color: #FFF1D9;
}

.single-service.style--two .service-icon.bg-3 {
  background-color: #D9FFF1;
}

.single-service.style--two .service-content h3 {
  color: #57548D;
  margin-bottom: 15px;
  font-weight: 600;
}

.single-service.style--two .service-content P {
  font-size: 18px;
  font-weight: 300;
}

.single-service.style--two:hover {
  transform: translateY(0);
}

.single-service.style--two:hover .service-icon {
  background-color: #ffffff;
}

.single-service.style--two:hover .service-icon .svg path {
  fill: #EBD281;
}

.single-service.style--two:hover .service-content h3 {
  color: #EBD281;
}

.single-service.style--two:hover .service-content P {
  color: #ffffff;
}

.single-service.style--two:hover .service-content .btn-crm.btn-view-more {
  color: #EBD281;
}

.single-service.style--two:hover .service-content .btn-crm.btn-view-more .svg line, .single-service.style--two:hover .service-content .btn-crm.btn-view-more .svg path {
  stroke: #EBD281;
}

.single-service.style--two.management .service-content h3 {
  color: #625FD1;
}

.single-service.style--two.management .service-content P {
  font-size: 18px;
  font-weight: 300;
}

.single-service.style--two.management .service-content .btn-crm.btn-view-more {
  color: #625FD1;
}

.single-service.style--two.management .service-content .btn-crm.btn-view-more .svg line, .single-service.style--two.management .service-content .btn-crm.btn-view-more .svg path {
  stroke: #625FD1;
}

.single-service.style--two.management:hover {
  transform: translateY(0);
}

.single-service.style--two.management:hover .service-icon {
  background-color: #ffffff;
}

.single-service.style--two.management:hover .service-content h3 {
  color: #ffffff;
}

.single-service.style--two.management:hover .service-content .btn-crm.btn-view-more {
  color: #FF4D8C;
}

.single-service.style--two.management:hover .service-content .btn-crm.btn-view-more .svg line, .single-service.style--two.management:hover .service-content .btn-crm.btn-view-more .svg path {
  stroke: #FF4D8C;
}

.single-service.style--three {
  padding: 0 40px;
  margin-bottom: 52px;
}

@media only screen and (max-width: 479px) {
  .single-service.style--three {
    padding: 0;
  }
}

.single-service.style--three .service-icon {
  margin: 0px auto;
  margin-bottom: 38px;
  background: transparent;
}

.single-service.style--three .service-content h3 {
  color: #292929;
  margin-bottom: 15px;
  font-weight: 600;
}

.single-service.style--three .service-content P {
  font-size: 18px;
  font-weight: 300;
}

.single-service.style--three.pay .service-icon {
  margin-bottom: 28px;
  width: 120px;
  height: 120px;
  background: #DFF5FF;
  display: flex;
  justify-content: center;
  align-items: center;
}

.single-service.style--three.pay .service-icon.two {
  background: #FFF3EB;
}

.single-service.style--three.pay .service-icon.three {
  background: #D9FFF6;
}

.single-service.style--three.pay .service-icon.four {
  background: #FFF0EF;
}

.single-service.style--three.pay .service-icon.five {
  background: #DFFFF6;
}

.single-service.style--three.pay .service-icon.six {
  background: #E9F3FF;
}

.single-service.style--three.pay:hover .service-icon {
  background: #21BCFF;
}

.single-service.style--three.pay:hover .service-icon .svg g path {
  fill: #ffffff;
}

.single-service.style--three.pay:hover .service-icon.two {
  background: #F99C5E;
}

.single-service.style--three.pay:hover .service-icon.three {
  background: #2DCEA5;
}

.single-service.style--three.pay:hover .service-icon.four {
  background: #FF8781;
}

.single-service.style--three.pay:hover .service-icon.five {
  background: #04E2A3;
}

.single-service.style--three.pay:hover .service-icon.six {
  background: #0472FA;
}

.single-service.style--three.pay .service-content h3 {
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 26px;
}

.single-service.style--three.pay:hover {
  transform: translateY(0);
}

.single-service.style--three.plug .service-icon {
  margin-bottom: 28px;
  width: 120px;
  height: 120px;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.single-service.style--three.plug .service-content h3 {
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 26px;
}

.single-service.style--four {
  padding: 40px;
  background: #929DFF;
  text-align: center;
  margin-bottom: 0;
}

.single-service.style--four:hover {
  box-shadow: 0 10px 24px rgba(146, 157, 255, 0.5);
}

.single-service.style--four .service-icon {
  margin: 0px;
  margin-bottom: 58px;
  height: auto;
  width: auto;
  background: transparent;
}

.single-service.style--four .service-content h3 {
  color: #ffffff;
  margin-bottom: 15px;
  font-weight: 600;
}

.single-service.style--four:hover {
  transform: translateY(0px);
}

.single-service.style--four.one {
  background: #85CFFF;
}

.single-service.style--four.one:hover {
  box-shadow: 0 10px 24px rgba(133, 207, 255, 0.5);
}

.single-service.style--four.two {
  background: #BF87FF;
}

.single-service.style--four.two:hover {
  box-shadow: 0 10px 24px rgba(191, 135, 255, 0.5);
}

.single-service.style--four.three {
  background: #FFCB87;
}

.single-service.style--four.three:hover {
  box-shadow: 0 10px 24px rgba(255, 203, 135, 0.5);
}

.single-service.style--four.four {
  background: #85b6ff;
}

.single-service.style--four.four:hover {
  box-shadow: 0 10px 24px rgba(133, 182, 255, 0.5);
}

.single-service.style--five {
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: relative;
}

.single-service.style--five .service-icon {
  padding: 10px;
  background-color: #E6FFFA;
  margin-right: 0;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  border-radius: 0;
}

.single-service.style--five .service-icon.one {
  background: #D7EFF9;
}

.single-service.style--five .service-icon.two {
  background: #E9E6FF;
}

.single-service.style--five .service-icon.three {
  background: #FFF6DB;
}

.single-service.style--five .service-content {
  padding: 28px 20px 22px;
  padding-left: 100px;
}

.single-service.style--five .service-content h3 {
  margin-bottom: 5px;
  font-weight: 500;
}

.single-service.style--five .service-content p {
  font-weight: 300;
}

.single-service.style--five:hover {
  transform: translateY(0px);
}

.single-service.style--six .service-icon {
  background: #DFF5FF;
}

.single-service.style--six .service-icon.two {
  background: #FFF3EB;
}

.single-service.style--six .service-icon.three {
  background: #DFFFF6;
}

.single-service.style--six .service-icon.four {
  background: #E9F3FF;
}

.single-service.style--six .service-content p {
  font-weight: 300;
}

.single-service.style--six:hover .service-icon .svg {
  transform: rotate(10deg);
}

.single-service.style--seven {
  margin-bottom: 50px;
  padding: 0px;
  background: transparent;
}

.single-service.style--seven img {
  margin-bottom: 30px;
}

.single-service.style--seven h3 {
  font-size: 22px;
}

.single-service.style--seven.management {
  margin-bottom: 73px;
}

@media only screen and (max-width: 575px) {
  .single-service.style--seven.management {
    margin-bottom: 53px;
  }
}

.single-service.style--seven.management2 {
  padding: 0 10px;
}

.single-service.style--seven.management2 h3 {
  font-size: 26px;
  line-height: 1.3;
  margin-bottom: 0;
}

.single-service.analytic {
  padding: 0;
  margin-bottom: 53px;
  background: transparent;
}

.single-service.analytic .service-icon {
  margin: 0px auto;
  margin-bottom: 18px;
  background: transparent;
}

.single-service.analytic .service-content h3 {
  color: #292929;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 26px;
}

.single-service.analytic .service-content P {
  font-size: 22px;
  font-weight: 300;
  max-width: 340px;
  margin: 0 auto;
}

.single-service.host {
  text-align: center;
  padding: 50px;
  margin-bottom: 0;
}

@media only screen and (max-width: 1199px) {
  .single-service.host {
    padding: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .single-service.host {
    padding: 30px 20px;
    margin-bottom: 10px;
  }
}

.single-service.host .service-icon {
  margin: 0 auto 28px;
  background: #FDCE36;
}

.single-service.host .service-icon.two {
  background: #AE88E6;
}

.single-service.host .service-icon.three {
  background: #82D0E8;
}

.single-service.host .service-icon.four {
  background: #6BF1D6;
}

.single-service.host .service-icon.five {
  background: #F091BA;
}

.single-service.host .service-icon.six {
  background: #B6CDF6;
}

.single-service.host .service-content h3 {
  margin-bottom: 13px;
}

.single-service.host .service-content p {
  font-weight: 300;
}

.single-service.host.style-two .service-icon {
  background: transparent;
}

.single-service.proto {
  padding: 0;
  background-color: transparent;
  margin-bottom: 60px;
}

.single-service.proto .service-icon {
  width: 140px;
  height: 140px;
  background-color: #ffffff;
}

@media only screen and (max-width: 479px) {
  .single-service.proto .service-icon {
    margin-bottom: 20px;
  }
}

.single-service.proto .service-content h3 {
  color: #5A50A5;
}

.single-service.proto:hover {
  transform: translateY(0);
}

.host-wc-right {
  position: relative;
}

.host-wc-right span.bg {
  position: absolute;
  width: 1100%;
  right: -1000%;
  height: 100%;
  top: 0px;
  left: 0;
  right: 0;
  background-color: #F3F7FF;
  z-index: -1;
  border-radius: 20px 0 0 20px;
}

.host-wc-right .single-service {
  padding: 20px 0 20px 117px;
  margin-bottom: 14px;
  background-color: transparent;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .host-wc-right .single-service {
    padding-left: 90px;
  }
}

@media only screen and (max-width: 1199px) {
  .host-wc-right .single-service {
    padding: 20px 0 20px 50px;
  }
}

@media only screen and (max-width: 575px) {
  .host-wc-right .single-service {
    padding: 20px;
  }
}

.host-wc-right .single-service .service-icon {
  background-color: #AF86E6;
}

.host-wc-right .single-service .service-icon.two {
  background-color: #9ED2E3;
}

.host-wc-right .single-service .service-icon.three {
  background-color: #FECF2F;
}

.host-wc-right .single-service .service-content p {
  font-size: 18px;
  font-weight: 300;
}

.host-wc-right .single-service:hover .service-icon img, .host-wc-right .single-service:hover .service-icon .svg {
  transform: rotate(10deg);
}

.service-slider.owl-carousel .owl-stage-outer {
  padding: 30px;
  margin: 0 -30px;
}

/* ************************
   04.3: Calculate Earning
   ********************* */
.ce-content h2 {
  font-weight: 600;
  margin-bottom: 14px;
  line-height: 1.28;
}

.ce-content p {
  margin-bottom: 30px;
}

@media only screen and (max-width: 991px) {
  .ce-content {
    margin-bottom: 60px;
  }
}

.customer {
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  opacity: .5;
}

@media only screen and (max-width: 479px) {
  .customer {
    font-size: 12px;
  }
}

.customer span {
  display: block;
  font-size: 22px;
  font-weight: 600;
  color: #292929;
  margin-bottom: 8px;
}

@media only screen and (max-width: 479px) {
  .customer span {
    font-size: 16px;
  }
}

.customer.one {
  opacity: 1;
}

.ui-range-slider {
  padding: 60px;
}

@media only screen and (max-width: 575px) {
  .ui-range-slider {
    padding: 30px;
  }
}

.ui-range-slider p {
  font-weight: 400;
}

.ui-range-slider strong {
  font-size: 60px;
  font-weight: 600;
  line-height: 1.3;
}

.ui-range-slider .sale {
  margin-bottom: 38px;
}

.ui-range-slider .sale strong {
  font-weight: 600;
}

.ui-range-slider .total-earning {
  margin-bottom: 90px;
}

.ui-range-slider .total-earning strong {
  font-weight: 600;
}

#slider {
  border: none;
  height: 2px;
  background: #CECECE;
  position: relative;
}

#slider span {
  width: 12px;
  height: 12px;
  background: white;
  border: 2px solid #CECECE;
  border-radius: 50%;
  left: 0;
  top: -6px;
  position: absolute;
  z-index: 999;
}

#slider span.one {
  border-color: #5F52DC;
}

#slider span.two {
  left: 33.33%;
}

#slider span.three {
  left: 66.66%;
}

#slider span.four {
  left: 100%;
}

#slider .focus {
  left: 0;
  top: -1px;
  width: 33.33%;
  height: 4px;
  background: #5F52DC;
  position: absolute;
}

#slider span.ui-slider-handle {
  border-color: #5F52DC;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: -10px;
}

/* ************************
   04.4: Earn Path
   ********************* */
.earn-path-feature {
  background: #FBFBFB;
  padding: 100px 120px 20px;
}

@media only screen and (max-width: 1199px) {
  .earn-path-feature {
    padding: 80px 50px 0px;
  }
}

.earn-path-feature .single-service {
  background: transparent;
  padding: 0;
  margin-bottom: 80px;
}

.earn-path-feature .single-service .service-icon {
  width: 100px;
  height: 100px;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 40px;
}

@media only screen and (max-width: 479px) {
  .earn-path-feature .single-service .service-icon {
    margin: 0;
    margin-bottom: 20px;
  }
}

/* ************************
   04.5: Call To Action
   ********************* */
.pos-cta {
  padding-top: 80px;
}

@media only screen and (max-width: 1199px) {
  .pos-cta {
    padding-top: 120px;
  }
}

@media only screen and (max-width: 991px) {
  .pos-cta {
    padding-top: 60px;
  }
}

/* Cta Shape */
.cta-line1, .cta-line2 {
  position: absolute;
  left: 5%;
  top: 50%;
  transform: translateY(-50%);
}

.cta-line2 {
  left: auto;
  right: 5%;
}

.cta-icon1, .cta-icon2, .cta-icon3 {
  position: absolute;
  opacity: 0.6;
  left: 5%;
  top: 8%;
}

.cta-icon2 {
  left: 60%;
  top: auto;
  bottom: 5%;
}

.cta-icon3 {
  left: auto;
  right: 5%;
}

.analytic-cta-img {
  position: absolute;
  left: 0;
  bottom: 0;
}

@media only screen and (max-width: 767px) {
  .cta-proto {
    padding-top: 40px;
  }
}

/* Cta Content */
.cta-content {
  position: relative;
  z-index: 2;
  padding-left: 15px;
  padding-right: 15px;
}

.cta-content h2 {
  line-height: 1.2;
  font-size: 46px;
  font-weight: 600;
  margin-bottom: 44px;
}

@media only screen and (max-width: 991px) {
  .cta-content h2 br {
    display: none;
  }
}

@media only screen and (max-width: 575px) {
  .cta-content h2 {
    font-size: 28px;
  }
}

.cta-content .cta-quote {
  padding-bottom: 100px;
  margin-top: 152px;
}

@media only screen and (max-width: 575px) {
  .cta-content .cta-quote {
    padding-bottom: 40px;
    margin-top: 100px;
  }
}

.cta-content .cta-quote h3 {
  font-size: 26px;
  font-weight: 500;
  color: #ffffff;
  position: relative;
}

.cta-content .cta-quote h3:after {
  content: url(../img/icons/quote.svg);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -52px;
}

.cta-content.email h2 {
  font-size: 40px;
  line-height: 1.35;
}

@media only screen and (max-width: 991px) {
  .cta-content.style--two {
    margin-bottom: 60px;
  }
}

.cta-content.style--two h2 {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 15px;
}

@media only screen and (max-width: 575px) {
  .cta-content.style--two h2 {
    font-size: 30px;
  }
}

.cta-content.style--two p {
  margin-bottom: 44px;
}

.cta-content.crs h2 {
  font-size: 40px;
  margin-bottom: 17px;
  margin-top: -6px;
}

@media only screen and (max-width: 991px) {
  .cta-content.crs h2 br {
    display: none;
  }
}

@media only screen and (max-width: 575px) {
  .cta-content.crs h2 {
    font-size: 30px;
  }
}

.cta-content.book h2 {
  font-size: 40px;
  margin-bottom: 8px;
}

@media only screen and (max-width: 479px) {
  .cta-content.book h2 {
    font-size: 36px;
  }
}

.cta-content.book h3 {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 15px;
}

.cta-content.book p {
  margin-bottom: 34px;
}

@media only screen and (max-width: 991px) {
  .cta-content.book .paperback {
    margin-bottom: 60px;
  }
}

.cta-content.book .paperback .order-box {
  padding: 40px 60px;
  background: #FFF0B8;
}

.cta-content.book .paperback .order-box:not(:last-child) {
  margin-right: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .cta-content.book .paperback .order-box {
    padding: 40px 40px;
  }
}

@media only screen and (max-width: 1199px) {
  .cta-content.book .paperback .order-box {
    padding: 40px 60px;
  }
  .cta-content.book .paperback .order-box:not(:last-child) {
    margin-right: 0px;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 991px) {
  .cta-content.book .paperback .order-box {
    padding: 40px;
  }
  .cta-content.book .paperback .order-box:not(:last-child) {
    margin-right: 20px;
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 575px) {
  .cta-content.book .paperback .order-box:not(:last-child) {
    margin-right: 0px;
    margin-bottom: 20px;
  }
}

.cta-content.book .paperback .order-box h3 {
  color: #292929;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}

.cta-content.book .paperback .order-box .price {
  margin-bottom: 25px;
}

.cta-content.book .paperback .order-box .price strong {
  font-size: 30px;
  font-weight: 600;
  margin-right: 14px;
  line-height: 1;
}

.cta-content.book .paperback .order-box .price .stroke-text del {
  font-size: 22px;
  font-weight: 400;
  line-height: 1;
}

.cta-content.book .paperback .order-box .btn-book.s-btn {
  color: #ffffff;
}

.cta-content.book .paperback .order-box .btn-book.s-btn:hover {
  background: #D2E5FF;
}

.cta-content.book .paperback .order-box.box2 {
  background: #ffffff;
}

.cta-content.book .paperback .order-box.box2 .btn-book.s-btn {
  background: #D2E5FF;
}

.cta-content.book .paperback .order-box.box2 .btn-book.s-btn:hover {
  color: #ffffff;
}

.cta-content.management h2 {
  font-size: 40px;
  margin-bottom: 10px;
}

.cta-content.management p {
  max-width: 590px;
  margin: 0 auto;
  margin-bottom: 40px;
}

.cta-content.pos {
  padding: 0;
}

.cta-content.pos h2 {
  margin-bottom: 14px;
}

.cta-content.pos p {
  max-width: 560px;
}

.cta-content.app {
  padding: 0;
}

.cta-content.app h2 {
  margin-bottom: 24px;
  color: #292929;
  font-size: 50px;
  font-weight: 700;
}

@media only screen and (max-width: 575px) {
  .cta-content.app h2 {
    font-size: 40px;
  }
}

.cta-content.app p {
  font-weight: 400;
  margin-bottom: 55px;
}

.cta-content.proto {
  position: relative;
  z-index: 1;
  padding-top: 100px;
  padding-bottom: 100px;
  margin-bottom: -300px;
}

@media only screen and (max-width: 767px) {
  .cta-content.proto {
    margin-bottom: 0;
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.cta-content.proto:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 111%;
  height: 100%;
  content: "";
  transform: perspective(2850px) rotateY(30deg);
  z-index: -1;
}

@media only screen and (max-width: 991px) {
  .cta-content.proto:after {
    width: 115%;
    left: -28px;
  }
}

@media only screen and (max-width: 767px) {
  .cta-content.proto:after {
    transform: perspective(2850px) rotateY(0deg);
    width: 200%;
    left: -50%;
  }
}

.cta-content.proto h2 {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 15px;
}

@media only screen and (max-width: 767px) {
  .cta-content.proto h2 {
    font-size: 36px;
  }
}

@media only screen and (max-width: 479px) {
  .cta-content.proto h2 {
    font-size: 32px;
  }
}

.cta-content.proto p {
  max-width: 640px;
  margin: 0 auto 44px;
}

@media only screen and (min-width: 0px) and (max-width: 1500px) {
  .cta-content.email h2 br {
    display: none;
  }
}

/* Cta Img */
@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .cta-img.book {
    padding-right: 15px;
  }
}

@media only screen and (max-width: 1199px) {
  .cta-img.book {
    padding-right: 15px;
  }
}

.cta-img.pos {
  padding-bottom: 10px;
}

@media only screen and (max-width: 991px) {
  .cta-img.pos {
    margin-bottom: 40px;
  }
}

/* Payment Cta */
.Payment-cta {
  margin-bottom: -110px;
  position: relative;
}

.Payment-cta .cta-wrap {
  padding: 85px 30px;
  background: #3D52A2;
}

@media only screen and (max-width: 479px) {
  .Payment-cta .cta-wrap {
    padding: 60px 30px;
  }
}

.Payment-cta .cta-wrap .cta-content h2 {
  margin-bottom: 0;
  font-size: 40px;
  text-align: center;
}

@media only screen and (min-width: 992px) and (max-width: 1500px) {
  .Payment-cta .cta-wrap .cta-content h2 {
    font-size: 36px;
  }
}

@media only screen and (max-width: 479px) {
  .Payment-cta .cta-wrap .cta-content h2 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 1199px) {
  .Payment-cta .cta-wrap .cta-content h2 {
    margin-bottom: 10px;
  }
}

/* Plugins Cta */
.plug-cta {
  background-color: #4E2FA9;
}

/* Book CTA */
.bg-left {
  position: absolute;
  left: -200%;
  top: 0;
  width: calc(300% - 15px);
  height: 100%;
  border-radius: 0 0 150px 0;
  z-index: -1;
}

@media only screen and (max-width: 767px) {
  .bg-left {
    width: 600%;
  }
}

/* POS CTA ROW BG */
@media only screen and (max-width: 1199px) {
  .pos-cta-bg {
    background-color: #F2FFFF;
  }
}

.row.cta-bg {
  position: relative;
  z-index: 1;
}

.row.cta-bg:after {
  position: absolute;
  background-color: #F2FFFF;
  content: "";
  left: 15px;
  top: auto;
  bottom: 0;
  width: calc(100% - 30px);
  height: calc(100% - 40px);
  z-index: -1;
}

@media only screen and (max-width: 1199px) {
  .row.cta-bg:after {
    display: none;
  }
}

/* ************************
   04.6: Feature
   ********************* */
.feature-content h2 {
  margin-bottom: 10px;
}

@media only screen and (max-width: 991px) {
  .feature-content {
    margin-bottom: 60px;
  }
}

.feature-content.style--two.pay {
  padding: 140px 0 130px;
  padding-right: 100px;
}

@media only screen and (max-width: 1199px) {
  .feature-content.style--two.pay {
    padding-right: 60px;
  }
}

@media only screen and (max-width: 991px) {
  .feature-content.style--two.pay {
    padding: 80px 0 70px;
    padding-right: 0;
    margin-bottom: 60px;
  }
}

.feature-content.style--two.pay2 {
  padding: 140px 0 90px;
  padding-left: 140px;
}

@media only screen and (max-width: 1199px) {
  .feature-content.style--two.pay2 {
    padding-left: 100px;
  }
}

@media only screen and (max-width: 991px) {
  .feature-content.style--two.pay2 {
    padding-left: 0px;
    padding: 80px 0 30px;
  }
}

.feature-content.plug {
  margin-bottom: 0;
}

.feature-content.plug .section-title {
  margin-bottom: 40px;
}

.feature-content.plug .section-title h2 {
  margin-bottom: 10px;
  font-weight: 600;
}

.feature-content.plug.style--two .section-title {
  margin-bottom: 10px;
}

.feature-content.plug .content p {
  margin-bottom: 14px;
}

.feature-content.plug .content p.mb-30 {
  margin-bottom: 30px;
}

.feature-content.management .section-title {
  margin-bottom: 30px;
}

.feature-content.management .section-title h2 {
  font-weight: 600;
  margin-bottom: 10px;
  line-height: 1.2;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .feature-content.management .section-title h2 br {
    display: none;
  }
}

.feature-content.management .section-title p {
  margin: 0;
}

.feature-content.pos {
  position: relative;
  padding: 60px 75px;
  background-color: #FFFCF2;
  margin-left: -30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .feature-content.pos {
    margin-left: 0;
    padding: 50px;
  }
}

@media only screen and (max-width: 1199px) {
  .feature-content.pos {
    margin-left: 0;
    padding: 50px;
  }
  .feature-content.pos .list-check.pos li p {
    font-size: 18px;
  }
}

@media only screen and (max-width: 575px) {
  .feature-content.pos {
    padding: 50px 15px;
  }
}

.feature-content.app {
  padding: 0 50px;
}

@media only screen and (max-width: 991px) {
  .feature-content.app {
    padding: 0;
  }
}

.feature-content.app h2 {
  font-size: 50px;
  font-weight: 600;
  line-height: 1.3;
  color: #292929;
  margin-bottom: 20px;
}

@media only screen and (max-width: 575px) {
  .feature-content.app h2 {
    font-size: 40px;
  }
}

.feature-content.app p {
  margin-bottom: 30px;
}

.feature-content.app.style--two {
  padding: 0;
  padding-right: 120px;
  margin: 0;
}

@media only screen and (max-width: 1199px) {
  .feature-content.app.style--two {
    padding-right: 50px;
  }
}

@media only screen and (max-width: 991px) {
  .feature-content.app.style--two {
    padding: 0;
    padding-bottom: 20px;
  }
}

.feature-content.app2 {
  padding-left: 120px;
  margin: 0;
}

@media only screen and (max-width: 1199px) {
  .feature-content.app2 {
    padding-left: 40px;
  }
}

@media only screen and (max-width: 991px) {
  .feature-content.app2 {
    padding-left: 0;
  }
}

.feature-content.l-app h2 {
  font-size: 50px;
  color: #292929;
  line-height: 1.2;
  margin-bottom: 20px;
}

@media only screen and (max-width: 991px) {
  .feature-content.l-app h2 {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 1199px) {
  .feature-content.l-app h2 br {
    display: none;
  }
}

@media only screen and (max-width: 575px) {
  .feature-content.l-app h2 {
    font-size: 40px;
  }
}

.feature-content.l-app p {
  margin-bottom: 30px;
  font-weight: 400;
}

.feature-content.proto h2 {
  margin-bottom: 20px;
  line-height: 1.25;
}

@media only screen and (max-width: 767px) {
  .feature-content.proto h2 {
    font-size: 36px;
  }
}

.feature-content.proto p {
  margin-bottom: 30px;
}

.feature-img {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
}

.feature-img .f-bg {
  position: relative;
  left: auto;
  right: -250px;
  width: 118%;
  top: 0px;
  z-index: -1;
}

.feature-img .f-bg.crm {
  position: absolute;
  top: -70px;
}

@media only screen and (min-width: 1200px) and (max-width: 1602px) {
  .feature-img .f-bg.crm {
    top: 0;
  }
}

@media only screen and (max-width: 1199px) {
  .feature-img .f-bg.crm {
    top: -100px;
  }
}

@media only screen and (max-width: 991px) {
  .feature-img .f-bg.crm {
    top: 0px;
    left: auto;
    right: -15px;
    width: 100%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1602px) {
  .feature-img .f-bg {
    left: 140px;
    right: auto;
    width: 100%;
  }
}

@media only screen and (max-width: 1199px) {
  .feature-img .f-bg {
    left: 0px;
  }
}

.feature-img .f-img {
  position: absolute;
  left: auto;
  right: -50px;
}

.feature-img .f-img.crm {
  position: relative;
}

@media only screen and (max-width: 1199px) {
  .feature-img .f-img {
    right: 0px;
    padding: 0 15px;
  }
}

.feature-img .mobile-bg {
  position: absolute;
  z-index: -1;
  animation: spin 60s linear .3s infinite forwards;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .feature-img .mobile-bg {
    bottom: 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1500px) {
  .feature-img .pay-f2, .feature-img .host-f2 {
    padding-right: 30px;
  }
}

.feature-img.style--two .f-bg {
  left: -270px;
}

@media only screen and (max-width: 1199px) {
  .feature-img.style--two .f-bg {
    left: -100px;
  }
}

.feature-img.style--two .f-img {
  right: 0px;
}

@media only screen and (max-width: 1199px) {
  .feature-img.style--two .f-img {
    right: 0px;
  }
}

.feature-img.style--three .f-bg {
  left: 290px;
}

@media only screen and (max-width: 1199px) {
  .feature-img.style--three .f-bg {
    left: 100px;
  }
}

@media only screen and (max-width: 991px) {
  .feature-img.style--three .f-bg {
    right: 0;
  }
}

.feature-img.style--three .f-img {
  right: -90px;
}

@media only screen and (min-width: 1200px) and (max-width: 1602px) {
  .feature-img.style--three .f-img {
    right: -60px;
  }
}

@media only screen and (max-width: 1199px) {
  .feature-img.style--three .f-img {
    right: 0px;
  }
}

@media only screen and (max-width: 991px) {
  .feature-img.style--three .f-img {
    right: 0;
  }
}

@media only screen and (max-width: 991px) {
  .feature-img.plug {
    margin-bottom: 60px;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .feature-img.analytic .feature-map3 {
    width: 100%;
  }
}

.feature-img.app img {
  margin-left: -150px;
  max-width: none;
}

@media only screen and (min-width: 991px) and (max-width: 1500px) {
  .feature-img.app img {
    max-width: 140%;
  }
}

@media only screen and (max-width: 991px) {
  .feature-img.app img {
    max-width: 100%;
    margin-left: 0;
    margin-bottom: 30px;
  }
}

.feature-img.app2 img {
  margin-left: -15px;
  max-width: none;
}

@media only screen and (min-width: 991px) and (max-width: 1500px) {
  .feature-img.app2 img {
    max-width: 120%;
  }
}

@media only screen and (max-width: 991px) {
  .feature-img.app2 img {
    max-width: 100%;
    margin-left: 0;
    margin-bottom: 40px;
  }
}

.feature-img.l-app {
  margin-left: 60px;
}

@media only screen and (max-width: 991px) {
  .feature-img.l-app {
    margin-left: 0;
  }
}

.feature-img.l-app.two {
  margin: 0;
  margin-right: 60px;
}

@media only screen and (max-width: 991px) {
  .feature-img.l-app.two {
    margin: 0;
  }
}

.feature-img.proto {
  padding-left: 20px;
}

@media only screen and (max-width: 991px) {
  .feature-img.proto {
    padding-left: 0px;
  }
}

.feature-img.proto.left {
  padding-left: 0;
}

.feature-img.proto .proto-img {
  max-width: none;
}

@media only screen and (max-width: 991px) {
  .feature-img.proto .proto-img {
    max-width: 100%;
  }
}

.feature-img.proto .proto-img.left {
  left: -270px;
  position: relative;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .feature-img.proto .proto-img.left {
    left: -360px;
  }
}

@media only screen and (max-width: 1199px) {
  .feature-img.proto .proto-img.left {
    left: -400px;
  }
}

@media only screen and (max-width: 991px) {
  .feature-img.proto .proto-img.left {
    left: 0px;
  }
}

@-moz-document url-prefix() {
  .feature-img.proto .proto-img {
    max-width: none;
    min-width: 808px;
  }
  @media only screen and (max-width: 991px) {
    .feature-img.proto .proto-img {
      max-width: 100%;
      min-width: auto;
    }
  }
}

.pay-bg1, .pay-bg2 {
  position: absolute;
  left: -1000%;
  top: 0;
  background: #F9F9F9;
  width: 1100%;
  height: 100%;
  z-index: -1;
}

@media only screen and (max-width: 991px) {
  .pay-bg1, .pay-bg2 {
    height: calc(100% - 60px);
  }
}

.pay-bg2 {
  left: auto;
  right: -1000%;
}

@media only screen and (max-width: 991px) {
  .pay-bg2 {
    height: calc(100% - 60px);
  }
}

.feature {
  padding-top: 70px;
  padding-bottom: 190px;
}

@media only screen and (min-width: 1200px) and (max-width: 1602px) {
  .feature {
    padding-top: 0px;
    padding-bottom: 140px;
  }
}

@media only screen and (max-width: 1199px) {
  .feature {
    padding-top: 100px;
    padding-bottom: 140px;
  }
}

@media only screen and (max-width: 991px) {
  .feature {
    margin-top: 0;
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.e-feature-img {
  width: 745px;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .e-feature-img {
    width: 110%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1680px) {
  .e-feature-img {
    width: 110%;
  }
}

@media only screen and (max-width: 1199px) {
  .e-feature-img {
    width: 100%;
  }
}

.e-feature-img.two {
  position: relative;
  left: -190px;
}

@media only screen and (min-width: 1200px) and (max-width: 1602px) {
  .e-feature-img.two {
    left: -10%;
  }
}

@media only screen and (max-width: 1199px) {
  .e-feature-img.two {
    left: 0;
  }
}

.feature-wrap {
  padding-top: 90px;
  padding-bottom: 8px;
}

@media only screen and (max-width: 575px) {
  .feature-wrap {
    padding-top: 60px;
  }
}

.feature-wrap .single-service {
  margin-bottom: 80px;
}

@media only screen and (max-width: 575px) {
  .feature-wrap .single-service {
    margin-bottom: 50px;
    padding: 0;
  }
}

.feature-wrap .single-service.proto {
  margin-bottom: 60px;
}

@media only screen and (max-width: 575px) {
  .feature-wrap .single-service.proto {
    margin-bottom: 40px;
  }
}

.feature-wrap.plug {
  padding-top: 80px;
  padding-bottom: 0;
  background-color: #4E2FA9;
}

.feature-wrap.plug .single-service {
  background: transparent;
  margin-bottom: 80px;
}

.feature-wrap.plug .single-service .service-content h3 {
  color: #ffffff;
}

.feature-wrap.proto {
  margin: 0 30px;
  padding-top: 60px;
  padding-bottom: 40px;
  background-color: #D9E5FF;
  border-radius: 60px;
  position: relative;
  z-index: 1;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .feature-wrap.proto {
    margin: 0 -50px;
  }
}

@media only screen and (max-width: 1199px) {
  .feature-wrap.proto {
    margin: 0 -50px;
  }
}

@media only screen and (max-width: 991px) {
  .feature-wrap.proto {
    margin: 0;
  }
}

@media only screen and (max-width: 575px) {
  .feature-wrap.proto {
    padding-top: 40px;
    padding-bottom: 20px;
  }
}

.feature-wrap.proto:after {
  content: "";
  position: absolute;
  background-color: #F1F5FE;
  width: calc(100% + 60px);
  height: 100%;
  left: -30px;
  bottom: 20px;
  border-radius: 60px;
  z-index: -1;
}

.tab-content.app2-tab div > img {
  animation: banner3Shake 10s ease-in infinite;
}

.get-card-wrap, .choose-card-wrap {
  position: relative;
}

.get-card-wrap .feature-img, .choose-card-wrap .feature-img {
  margin-bottom: -140px;
}

@media only screen and (max-width: 991px) {
  .get-card-wrap .feature-img, .choose-card-wrap .feature-img {
    margin-bottom: 60px;
  }
}

.get-card-wrap .pay-bg3, .get-card-wrap .pay-bg4, .choose-card-wrap .pay-bg3, .choose-card-wrap .pay-bg4 {
  position: absolute;
  width: 1030%;
  height: 100%;
  background: #F9F9F9;
  left: -1000%;
  top: 0;
  z-index: -1;
}

@media only screen and (max-width: 991px) {
  .get-card-wrap .pay-bg3, .get-card-wrap .pay-bg4, .choose-card-wrap .pay-bg3, .choose-card-wrap .pay-bg4 {
    display: none;
  }
}

.get-card-wrap {
  padding-bottom: 140px;
}

@media only screen and (max-width: 991px) {
  .get-card-wrap {
    padding-bottom: 0;
  }
}

.choose-card-wrap {
  position: relative;
}

.choose-card-wrap .feature-img {
  margin-bottom: 0px;
}

@media only screen and (max-width: 991px) {
  .choose-card-wrap .feature-img {
    margin-bottom: 60px;
  }
}

.choose-card-wrap .pay-bg4 {
  left: auto;
  right: -1000%;
}

/* Book Page Feature*/
.single-feature-wrap:nth-child(even) {
  margin-top: 50px;
}

@media only screen and (max-width: 767px) {
  .single-feature-wrap:nth-child(even) {
    margin-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .single-feature-wrap:not(:last-child) {
    margin-bottom: 30px;
  }
}

.single-feature {
  padding: 50px 40px 44px;
  background: #FFFCF1;
  border-top-left-radius: 50px;
}

@media only screen and (max-width: 1199px) {
  .single-feature {
    padding: 40px 20px 34px;
  }
}

.single-feature .feature-icon {
  margin-bottom: 35px;
}

.single-feature .feature-content {
  margin-bottom: 0;
}

.single-feature .feature-content h3 {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 6px;
  color: #292929;
}

@media only screen and (max-width: 575px) {
  .single-feature .feature-content h3 {
    font-size: 24px;
  }
}

.single-feature .feature-content p {
  font-size: 20px;
}

@media only screen and (max-width: 575px) {
  .single-feature .feature-content p {
    font-size: 16px;
  }
}

.single-feature.two {
  background: #F8FBFF;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 50px;
}

.single-feature.three {
  background: #F9FFF1;
}

.single-feature.four {
  background: #FFF3F1;
  border-top-left-radius: 0px;
  border-top-right-radius: 50px;
}

.single-feature.app {
  text-align: center;
  background-color: transparent;
  padding: 0;
  margin-bottom: 40px;
}

.single-feature.app .feature-icon {
  margin-bottom: 45px;
}

@media only screen and (max-width: 479px) {
  .single-feature.app .feature-icon {
    margin-bottom: 30px;
  }
}

.single-feature.app .feature-content h3 {
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 20px;
}

.single-feature.app2 {
  text-align: center;
  background-color: transparent;
  padding: 0;
  margin-bottom: 40px;
}

.single-feature.app2 .feature-icon {
  margin-bottom: 30px;
}

.single-feature.app2 .feature-icon .circle {
  position: relative;
  width: 172px;
  height: 172px;
}

.single-feature.app2 .feature-icon .circle img, .single-feature.app2 .feature-icon .circle .svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.single-feature.app2 .feature-icon .circle.left:after {
  position: absolute;
  content: "";
  z-index: -1;
  height: 100%;
  width: 50%;
  background: #EBF6FF;
  border-bottom-left-radius: 172px;
  border-top-left-radius: 172px;
  left: 0;
  top: 0;
}

.single-feature.app2 .feature-icon .circle.bottom:after {
  position: absolute;
  content: "";
  z-index: -1;
  height: 50%;
  width: 100%;
  background: #E2FFF9;
  border-bottom-left-radius: 172px;
  border-bottom-right-radius: 172px;
  left: 0;
  top: auto;
  bottom: 0;
}

.single-feature.app2 .feature-icon .circle.right:after {
  position: absolute;
  content: "";
  z-index: -1;
  height: 100%;
  width: 50%;
  background: #FFF6F3;
  border-bottom-right-radius: 172px;
  border-top-right-radius: 172px;
  left: auto;
  right: 0;
  top: 0;
}

.single-feature.app2 .feature-content h3 {
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 20px;
}

.single-feature.style--two {
  background: transparent;
  border-radius: 0;
  text-align: center;
}

/* Ebook Feature */
.feature-box {
  position: relative;
}

@media only screen and (max-width: 991px) {
  .feature-box {
    margin-bottom: 80px;
  }
}

.feature-box:after, .feature-box .ebook-reveal {
  background: #F8FBFF;
  position: absolute;
  width: 1116%;
  height: 100%;
  content: "";
  left: -1000%;
  top: 0;
  z-index: -1;
  border-top-right-radius: 150px;
}

.feature-box:before {
  position: absolute;
  content: "01.";
  left: 0;
  top: -50px;
  font-size: 150px;
  font-weight: 600;
  line-height: 1;
}

@media only screen and (max-width: 575px) {
  .feature-box:before {
    font-size: 100px;
    top: -30px;
  }
}

.feature-box .img {
  margin-bottom: 60px;
}

.feature-box h2 {
  font-weight: 600;
  margin-bottom: 18px;
}

@media only screen and (max-width: 575px) {
  .feature-box h2 {
    font-size: 30px;
  }
}

.feature-box.two {
  margin-top: 140px;
}

@media only screen and (max-width: 991px) {
  .feature-box.two {
    margin-top: 0;
  }
}

.feature-box.two:after {
  width: 1116%;
  height: 100%;
  left: auto;
  right: -1000%;
  border-top-right-radius: 0px;
  border-top-left-radius: 150px;
}

.feature-box.two:before {
  content: "02.";
}

.feature-box.two.four:after {
  border-radius: 0 0 0 150px;
}

.feature-box.two.four:before {
  content: "04.";
}

.feature-box.three:after {
  border-radius: 0 0 150px 0;
}

.feature-box.three:before {
  content: "03.";
}

/* Plugins Feature*/
.single-feature.box {
  text-align: center;
  background: #DFF5FF;
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 30px;
}

.single-feature.box h2 {
  font-weight: 500;
  font-size: 60px;
  color: #21BCFF;
  margin-bottom: 5px;
}

.single-feature.box p {
  font-weight: 400;
  text-transform: capitalize;
  color: #292929;
}

.single-feature.box.box2 {
  background: #FFF3EB;
}

.single-feature.box.box2 h2 {
  color: #F99C5E;
}

.single-feature.box.box3 {
  background: #DFFFF6;
}

.single-feature.box.box3 h2 {
  color: #2DCEA5;
}

.single-feature.box.box4 {
  background: #E9F3FF;
}

.single-feature.box.box4 h2 {
  color: #0472FA;
}

.plugin-feature-bg, .plugin-feature-bg2 {
  position: absolute;
  left: 0;
  top: 40px;
  width: 40%;
  height: calc(100% - 180px);
  background-color: #857DFB;
}

@media only screen and (max-width: 991px) {
  .plugin-feature-bg, .plugin-feature-bg2 {
    height: calc(55% - 100px);
    width: 70%;
  }
}

@media only screen and (max-width: 767px) {
  .plugin-feature-bg, .plugin-feature-bg2 {
    display: none;
  }
}

.plugin-feature-bg2 {
  left: auto;
  right: 0;
  bottom: 140px;
}

/* ONLY IE */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .feature-img .f-bg {
    height: 560px;
  }
  .feature-img .f-img.crm2, .feature-img .f-img.crm3 {
    top: 60px;
  }
}

/* Email Feature */
.email-fbg {
  background-color: #FAFAFA;
}

.feature-one-content {
  position: relative;
  z-index: 1;
}

.feature-one-content h2 {
  color: #292929;
  font-weight: 600;
  margin-bottom: 15px;
}

.feature-one-content p {
  margin-bottom: 40px;
}

.feature-one-content.two h2 {
  line-height: 1.23;
}

.feature-one-content.two p {
  margin-bottom: 32px;
}

.feature-two-content h3 {
  font-weight: 500;
  font-size: 22px;
  color: #292929;
  margin-bottom: 25px;
}

.integration-bg {
  background-color: #F5F8FF;
}

/* Works Slider */
.work-slider .work-slide-text h2 {
  color: #292929;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
}

.work-slider .work-slide-text p {
  font-size: 22px;
}

@media only screen and (max-width: 991px) {
  .work-slider .work-slide-image {
    margin-bottom: 60px;
  }
}

.work-slider-dots {
  margin-top: -5px;
  margin-bottom: 55px;
  padding: 0px 40px;
}

@media only screen and (max-width: 991px) {
  .work-slider-dots {
    display: none !important;
  }
}

.work-slider-dots .owl-stage {
  display: flex;
  align-items: flex-end;
}

.work-slider-dots .owl-item {
  text-align: center;
}

.work-slider-dots .active .dots-count {
  padding-bottom: 17px;
  position: relative;
  font-size: 26px;
  line-height: 1.2;
  font-weight: 500;
  color: #BCBCBC;
  cursor: pointer;
  display: inline-block;
}

@media only screen and (max-width: 1199px) {
  .work-slider-dots .active .dots-count {
    font-size: 22px;
  }
}

.work-slider-dots .active .dots-count .process-bar {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background-color: transparent;
}

.work-slider-dots .active .dots-count .process-bar-active {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 0;
  height: 2px;
  background-color: #232323;
}

.work-slider-dots .active.current .dots-count {
  color: #232323;
}

.work-slider-dots .active.current .dots-count .process-bar {
  background-color: #dbdbdb;
}

.work-slider-dots .active.current .dots-count .process-bar-active {
  transition: width 4.9s;
  width: 100%;
}

/* POS Feature Slider */
.row.feature-bg {
  padding-left: 80px;
  padding-right: 80px;
  position: relative;
}

@media only screen and (max-width: 575px) {
  .row.feature-bg {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.row.feature-bg:after {
  background-color: #F3FFF7;
  position: absolute;
  content: "";
  width: 100%;
  height: calc(100% + 20px);
  left: 0;
  top: -120px;
  z-index: -1;
}

.feature-wrapper {
  padding-left: 80px;
  padding-right: 80px;
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 575px) {
  .feature-wrapper {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.feature-wrapper:after {
  background-color: #F1F5FE;
  position: absolute;
  content: "";
  width: 100%;
  height: 70%;
  left: 0;
  top: -100px;
  z-index: -1;
}

@media only screen and (max-width: 991px) {
  .feature-wrapper:after {
    height: 90%;
    top: -80px;
  }
}

@media only screen and (max-width: 575px) {
  .feature-wrapper:after {
    height: 100%;
    top: -60px;
    left: -15px;
    width: calc(100% + 30px);
  }
}

.pos-feature-slider .owl-stage-outer {
  padding: 20px;
  margin: -20px;
}

.feature-single {
  background-color: #ffffff;
  padding: 60px 20px;
  text-align: center;
}

.feature-single .feature-icon {
  margin-bottom: 28px;
}

.feature-single .feature-content h3 {
  font-weight: 500;
  color: #292929;
}

.feature-single.retailer {
  margin-bottom: 50px;
}

@media only screen and (max-width: 991px) {
  .feature-single.retailer {
    margin-bottom: 30px;
  }
}

.feature-single.retailer .feature-content {
  margin: 0;
}

.feature-single.retailer:hover .feature-icon {
  animation: fadeInUp10 .5s ease-in;
}

/* Trapezium */
@media only screen and (max-width: 767px) {
  .trapezium-section {
    margin-bottom: 50px;
  }
}

.trapezium {
  position: relative;
  padding: 80px 20px;
  text-align: center;
  margin-bottom: 100px;
}

@media only screen and (max-width: 767px) {
  .trapezium {
    margin-bottom: 50px;
  }
}

.trapezium:before {
  content: "";
  position: absolute;
  background-color: #F1F5FE;
  width: 114%;
  top: 0;
  bottom: 0;
  left: -1%;
  right: 0;
  z-index: -1;
  -webkit-transform: perspective(50em) rotateX(30deg);
  transform: perspective(800px) rotateY(30deg);
}

@media only screen and (min-width: 992px) and (max-width: 1500px) {
  .trapezium:before {
    left: -4%;
  }
}

@media only screen and (max-width: 767px) {
  .trapezium:before {
    left: 15px;
    width: 112%;
  }
}

@media only screen and (max-width: 575px) {
  .trapezium:before {
    left: 0;
  }
}

.trapezium.two:before {
  left: auto;
  right: -1%;
  -webkit-transform: perspective(50em) rotateX(-30deg);
  transform: perspective(800px) rotateY(-30deg);
}

@media only screen and (max-width: 1199px) {
  .trapezium.two:before {
    right: -3%;
  }
}

@media only screen and (max-width: 767px) {
  .trapezium.two:before {
    right: 15px;
  }
}

@media only screen and (max-width: 575px) {
  .trapezium.two:before {
    right: 0;
  }
}

.trapezium.two:hover:before {
  left: auto;
  right: 0;
}

.trapezium:hover:before {
  width: 100%;
  left: 0;
  -webkit-transform: perspective(50em) rotateX(0deg);
  transform: perspective(800px) rotateY(0deg);
}

.trapezium .feature-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  background-color: #ffffff;
  margin: 0 auto 30px;
}

.trapezium .feature-content h3 {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 10px;
}

.trapezium .feature-content p {
  font-size: 18px;
  max-width: 390px;
  margin-left: auto;
  margin-right: auto;
}

/* Prototype Availability */
.tab-btn.proto {
  margin-bottom: 40px;
}

.tab-btn.proto li {
  display: inline-block;
  font-size: 26px;
  font-weight: 500;
  cursor: pointer;
  position: relative;
  user-select: none;
  color: #BCBCBC;
}

@media only screen and (max-width: 379px) {
  .tab-btn.proto li {
    font-size: 24px;
  }
}

.tab-btn.proto li:not(:last-child) {
  margin-right: 65px;
}

@media only screen and (max-width: 767px) {
  .tab-btn.proto li:not(:last-child) {
    margin-right: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .tab-btn.proto li:not(:last-child) {
    margin-bottom: 10px;
  }
}

.tab-btn.proto li:after {
  position: absolute;
  content: "";
  width: 0%;
  height: 2px;
  left: 50%;
  bottom: 0;
  transition-delay: .1s;
}

.tab-btn.proto li.active:after {
  width: 100%;
  left: 0;
}

.tab-content.proto img {
  margin-bottom: 30px;
}

.tab-content.proto img:not(:last-child) {
  margin-right: 55px;
}

@media only screen and (max-width: 1199px) {
  .tab-content.proto img:not(:last-child) {
    margin-right: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .tab-content.proto img:nth-child(even) {
    margin-right: 0;
  }
}

.tab-content.proto div.active {
  animation: fadeInUp10 .4s linear 0s forwards;
}

/* ************************
   04.7: Insight
   ********************* */
.insight-title {
  padding: 140px 80px;
  height: 100%;
  border-radius: 30px 0 0 30px;
}

.insight-title .title-content {
  max-width: 440px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .insight-title {
    padding: 100px 40px;
  }
}

@media only screen and (max-width: 1199px) {
  .insight-title {
    flex: 0 0 100%;
    padding: 100px 30px;
  }
}

@media only screen and (max-width: 991px) {
  .insight-title {
    border-radius: 30px 30px 0 0;
  }
}

@media only screen and (max-width: 575px) {
  .insight-title {
    padding: 80px 30px;
  }
}

.title-content h2 {
  margin-bottom: 20px;
}

@media only screen and (max-width: 575px) {
  .title-content h2 {
    font-size: 34px;
  }
}

.title-content p {
  margin-bottom: 44px;
}

@media only screen and (max-width: 1199px) {
  .title-content {
    flex: 0 0 100%;
    height: auto;
  }
}

.insight-data {
  background-color: #FCF3F1;
  border-radius: 0 30px 30px 0;
  padding: 75px 40px 68px;
  justify-content: space-between;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .insight-data {
    padding: 90px 40px 100px;
  }
}

@media only screen and (max-width: 1199px) {
  .insight-data {
    flex: 0 0 100%;
    padding: 70px 30px 78px;
  }
}

@media only screen and (max-width: 991px) {
  .insight-data {
    border-radius: 0 0 30px 30px;
  }
}

@media only screen and (max-width: 575px) {
  .insight-data {
    padding: 50px 30px 58px;
    justify-content: center;
  }
}

.single-data {
  text-align: center;
  padding: 15px;
  flex: 0 0 50%;
  margin-bottom: 15px;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .single-data {
    flex: 0 0 45%;
  }
}

@media only screen and (max-width: 575px) {
  .single-data {
    padding: 40px 0px;
    justify-content: center;
    flex: 0 0 100%;
  }
}

.single-data strong {
  font-size: 80px;
  font-weight: 600;
  line-height: 1.3;
}

.single-data h3 {
  font-weight: 400;
  color: #292929;
  line-height: 1.3;
}

@media only screen and (max-width: 479px) {
  .single-data h3 {
    font-size: 22px;
  }
}

/* ************************
   04.8: Testimonials
   ********************* */
@media only screen and (max-width: 767px) {
  .crm-testimonial.owl-carousel > .owl-nav {
    display: none;
  }
}

.crm-testimonial.owl-carousel > .owl-nav button {
  top: -175px;
  background: transparent !important;
}

.crm-testimonial.owl-carousel > .owl-nav button i {
  display: none;
}

.crm-testimonial.owl-carousel > .owl-nav button:hover .svg {
  animation: arrowRight .8s linear .2s infinite;
}

.crm-testimonial.owl-carousel > .owl-nav button:hover .svg g path {
  stroke: #EBD281;
}

.crm-testimonial.owl-carousel > .owl-nav button:hover .svg.left-svg {
  animation: arrowLeft .8s linear .2s infinite;
}

.testimonial-single {
  background-color: #ffffff;
  padding: 60px 40px 55px;
}

@media only screen and (max-width: 575px) {
  .testimonial-single {
    padding: 40px 20px 35px;
  }
}

.testimonial-single .ts-top {
  margin-bottom: 20px;
}

@media only screen and (max-width: 575px) {
  .testimonial-single .ts-top {
    flex-direction: column;
  }
}

.testimonial-single .tst-content .ts-img {
  margin-right: 20px;
}

.testimonial-single .tst-content .ts-img img {
  width: 70px;
  height: 70px;
}

.testimonial-single .tst-content .content h4 {
  font-weight: 500;
  color: #57548D;
}

.testimonial-single .tst-content .content span {
  font-size: 14px;
  font-weight: 300;
  display: block;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .testimonial-single .tst-content {
    width: 100%;
  }
}

@media only screen and (max-width: 575px) {
  .testimonial-single .review {
    margin-top: -15px;
    margin-left: 5px;
  }
}

.testimonial-single .review strong {
  font-weight: 600;
  font-size: 20px;
}

.testimonial-single .ts-bottom p {
  position: relative;
  padding-left: 28px;
}

.testimonial-single .ts-bottom p:before {
  content: '"';
  position: absolute;
  left: -3px;
  top: 0;
  font-size: 60px;
  line-height: 1;
}

.testimonial-single.style--two {
  padding: 50px 50px 46px;
}

@media only screen and (max-width: 1199px) {
  .testimonial-single.style--two {
    padding: 50px 20px 46px;
  }
}

@media only screen and (max-width: 575px) {
  .testimonial-single.style--two {
    padding: 30px 30px 26px;
  }
}

.testimonial-single.style--two .tst-content .ts-img {
  margin-right: 20px;
}

.testimonial-single.style--two .tst-content .ts-img img {
  width: 80px;
  height: 80px;
}

.testimonial-single.style--two .tst-content .content .star-rating {
  margin-bottom: 6px;
}

.testimonial-single.style--two .tst-content .content .star-rating i {
  font-size: 16px;
}

.testimonial-single.style--two .tst-content .content h5 {
  font-weight: 600;
  font-size: 18px;
  color: #5F52DC;
}

.testimonial-single.style--two .tst-content .content span {
  font-size: 14px;
  font-weight: 300;
  display: block;
}

.testimonial-single.style--two .ts-bottom p {
  padding-left: 0px;
  font-size: 20px;
  font-weight: 300;
}

.testimonial-single.style--two .ts-bottom p:before {
  display: none;
}

@media only screen and (max-width: 479px) {
  .testimonial-single.style--two .ts-bottom p {
    font-size: 16px;
  }
}

.testimonial-single.style--two.book {
  background: #FFF3F1;
  border-radius: 0 50px 0 0;
  padding: 60px;
}

@media only screen and (max-width: 1199px) {
  .testimonial-single.style--two.book {
    padding: 30px;
  }
}

.testimonial-single.style--two.book .ts-bottom p {
  font-size: 18px;
}

.testimonial-single.style--two.plug {
  background: #ffffff;
}

.testimonial-single.style--three {
  padding: 60px 40px 54px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 575px) {
  .testimonial-single.style--three {
    padding: 30px 30px 26px;
  }
}

.testimonial-single.style--three .ts-top {
  margin-bottom: 30px;
}

.testimonial-single.style--three .tst-content .ts-img {
  margin-right: 30px;
  max-width: 44%;
}

.testimonial-single.style--three .tst-content .ts-img img {
  width: auto;
  height: auto;
  border-radius: 0;
}

.testimonial-single.style--three .tst-content .content h3 {
  font-weight: 600;
  color: #292929;
}

.testimonial-single.style--three .tst-content .content span {
  font-size: 18px;
  font-weight: 400;
  display: block;
  margin-bottom: 15px;
}

@media only screen and (max-width: 1199px) {
  .testimonial-single.style--three .tst-content {
    justify-content: flex-start;
  }
  .testimonial-single.style--three .tst-content .ts-img {
    margin: 0px;
    margin-bottom: 30px;
    max-width: 60%;
  }
}

@media only screen and (max-width: 991px) {
  .testimonial-single.style--three .tst-content {
    justify-content: center;
  }
  .testimonial-single.style--three .tst-content .ts-img {
    margin-bottom: 0;
    margin-right: 30px;
    max-width: 44%;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-single.style--three .tst-content {
    justify-content: flex-start;
  }
  .testimonial-single.style--three .tst-content .ts-img {
    margin: 0px;
    margin-bottom: 30px;
    max-width: 60%;
  }
}

.testimonial-single.style--three .ts-bottom p {
  padding-left: 0px;
  font-size: 20px;
  font-weight: 300;
}

.testimonial-single.style--three .ts-bottom p:before {
  display: none;
}

@media only screen and (max-width: 479px) {
  .testimonial-single.style--three .ts-bottom p {
    font-size: 16px;
  }
}

/* Book Review Slider */
.book-review-slider .owl-item:nth-child(even) .testimonial-single {
  margin-top: 50px;
  background: #F9FFF1;
  border-radius: 0 0 0 50px;
}

@media only screen and (max-width: 991px) {
  .book-review-slider .owl-item:nth-child(even) .testimonial-single {
    margin-top: 0;
  }
}

/* Student Review Slider Rating */
.rating {
  font-size: 20px;
  color: #5C6B56;
}

.rating strong {
  font-size: 60px;
  font-weight: 600;
  line-height: 1.5;
  display: block;
}

@media only screen and (max-width: 575px) {
  .rating.student-rating {
    margin-top: 20px;
  }
}

/* Customer Review Slider Shadow */
.customer-review-slider.owl-carousel .owl-stage-outer {
  padding: 15px;
  margin: -15px;
}

/* Email Page Testimonial Slider */
.email-testimonial-slider .testimonial-item h3 {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 25px;
}

.email-testimonial-slider .testimonial-item h3 span {
  font-size: 20px;
  font-weight: 300;
  color: #292929;
}

.email-testimonial-slider .testimonial-item P {
  color: #292929;
  font-size: 22px;
  line-height: 1.6;
  font-weight: 500;
}

@media only screen and (max-width: 479px) {
  .email-testimonial-slider .testimonial-item P {
    font-size: 16px;
  }
}

.email-testimonial-slider.host .testimonial-item h3 {
  font-size: 24px;
  margin-bottom: 6px;
}

.email-testimonial-slider.host .testimonial-item P {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.8;
  margin-bottom: 24px;
}

@media only screen and (max-width: 479px) {
  .email-testimonial-slider.host .testimonial-item P {
    font-size: 16px;
  }
}

.email-testimonial-slider.app2 .testimonial-item h3 {
  font-size: 20px;
  color: #292929;
  font-weight: 600;
  margin-bottom: 6px;
}

.email-testimonial-slider.app2 .testimonial-item h3 span {
  font-size: 16px;
  display: block;
  margin-top: 7px;
}

.email-testimonial-slider.app2 .testimonial-item P {
  font-size: 22px;
  font-weight: 300;
  font-style: italic;
  line-height: 1.9;
  margin-bottom: 46px;
}

@media only screen and (max-width: 479px) {
  .email-testimonial-slider.app2 .testimonial-item P {
    font-size: 18px;
  }
}

.email-clients-slider .clients-item {
  padding: 0 20px;
}

@media only screen and (max-width: 991px) {
  .email-clients-slider .clients-item {
    padding: 0;
  }
}

.email-clients-slider.host .clients-item {
  padding: 0;
}

@media only screen and (max-width: 1199px) {
  .logo-carousel.email {
    padding-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .logo-carousel.email {
    padding-top: 0px;
  }
}

/* App Page Testimonial Slider */
.app-testimonial-slider {
  background-position: center bottom;
  background-size: auto;
}

@media only screen and (max-width: 575px) {
  .app-testimonial-slider {
    background-size: 180% 30%;
  }
}

.app-testimonial-slider .single-slide {
  text-align: center;
  padding-bottom: 100px;
}

@media only screen and (max-width: 575px) {
  .app-testimonial-slider .single-slide {
    padding-bottom: 40px;
  }
}

.app-testimonial-slider .single-slide p {
  max-width: 810px;
  font-size: 24px;
  font-weight: 300;
  color: #292929;
  margin: 0 auto 50px;
  font-style: italic;
}

@media only screen and (max-width: 575px) {
  .app-testimonial-slider .single-slide p {
    font-size: 18px;
  }
}

.app-testimonial-slider .single-slide img {
  margin-bottom: 30px;
  width: auto;
  display: inline-block;
}

@media only screen and (max-width: 575px) {
  .app-testimonial-slider .single-slide img {
    width: 200px;
  }
}

.app-testimonial-slider .single-slide h4 {
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
}

.app-testimonial-slider .single-slide h4 span {
  font-size: 18px;
  font-weight: 400;
  display: block;
  margin-top: 5px;
}

.testimonial-slider.student-review .owl-stage-outer {
  padding: 20px;
  margin: -20px;
}

/* ************************
   04.9: Syllabus
   ********************* */
.accordion > .card {
  padding: 40px 0;
  border: 0;
  background: #ffffff;
  overflow: hidden;
  margin-bottom: 30px;
}

.accordion > .card.active .accordion-title {
  border-bottom-color: #EEEEEE;
  padding-bottom: 30px;
}

.accordion > .card.active .accordion-title > h3:after {
  font-family: 'FontAwesome';
  content: "";
}

.accordion > .card .accordion-title {
  padding: 18px 0 10px;
  margin-left: 300px;
  margin-right: 100px;
  border-bottom: 1px solid transparent;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .accordion > .card .accordion-title {
    margin-left: 250px;
  }
}

@media only screen and (max-width: 1199px) {
  .accordion > .card .accordion-title {
    margin-left: 200px;
  }
}

@media only screen and (max-width: 991px) {
  .accordion > .card .accordion-title {
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 100px;
  }
}

@media only screen and (max-width: 575px) {
  .accordion > .card .accordion-title {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.accordion > .card .accordion-title > h3 {
  font-size: 30px;
  color: #292929;
  margin-bottom: 20px;
  font-weight: 600;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .accordion > .card .accordion-title > h3 {
    font-size: 24px;
  }
}

.accordion > .card .accordion-title > h3:after {
  font-family: 'FontAwesome';
  content: "";
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 30px;
  font-weight: 400;
  cursor: pointer;
}

.accordion > .card .accordion-title p {
  margin-bottom: 6px;
  max-width: 900px;
}

@media only screen and (max-width: 767px) {
  .accordion > .card .accordion-title p {
    font-size: 16px;
  }
}

.accordion > .card .accordion-title .accordion-title-meta span {
  display: inline-block;
  font-size: 18px;
  color: #292929;
  font-weight: 500;
}

@media only screen and (max-width: 767px) {
  .accordion > .card .accordion-title .accordion-title-meta span {
    font-size: 14px;
  }
}

.accordion > .card .accordion-title .accordion-title-meta span:not(:last-child) {
  margin-right: 40px;
}

@media only screen and (max-width: 767px) {
  .accordion > .card .accordion-title .accordion-title-meta span:not(:last-child) {
    margin-right: 20px;
  }
}

.accordion > .card .accordion-title .accordion-absolute {
  text-align: center;
  position: absolute;
  left: 100px;
  top: 79px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .accordion > .card .accordion-title .accordion-absolute {
    left: 75px;
  }
}

@media only screen and (max-width: 1199px) {
  .accordion > .card .accordion-title .accordion-absolute {
    top: 100px;
    left: 50px;
  }
}

@media only screen and (max-width: 991px) {
  .accordion > .card .accordion-title .accordion-absolute {
    top: 50px;
    left: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .accordion > .card .accordion-title .accordion-absolute {
    left: 15px;
  }
}

.accordion > .card .accordion-title .accordion-absolute h3 {
  font-weight: 600;
  color: #292929;
}

.accordion > .card .accordion-title .accordion-absolute .svg {
  margin-bottom: 17px;
}

.accordion > .card .accordion-content {
  max-width: 900px;
  margin-left: 300px;
  margin-top: 31px;
  margin-right: 100px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .accordion > .card .accordion-content {
    margin-left: 250px;
  }
}

@media only screen and (max-width: 1199px) {
  .accordion > .card .accordion-content {
    margin-left: 200px;
  }
}

@media only screen and (max-width: 991px) {
  .accordion > .card .accordion-content {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.lecture-container {
  margin-bottom: 15px;
}

.lecture-container .left-content {
  flex: 0 0 50%;
}

.lecture-container .left-content .play-circle {
  margin-right: 20px;
  display: inline-block;
}

.lecture-container .left-content .title {
  font-size: 18px;
}

@media only screen and (max-width: 767px) {
  .lecture-container .left-content .title {
    font-size: 14px;
  }
}

.lecture-container .left-content .title a {
  color: #292929;
}

.lecture-container .details {
  flex: 0 0 50%;
  justify-content: flex-end;
}

.lecture-container .details .preview {
  width: 30%;
  display: block;
  text-align: right;
}

@media only screen and (max-width: 767px) {
  .lecture-container .details .preview {
    width: 40%;
  }
}

.lecture-container .details .preview .preview-text {
  width: 100%;
}

.lecture-container .details .preview-text {
  font-size: 18px;
  font-weight: 600;
  width: 30%;
  display: block;
  text-align: right;
}

@media only screen and (max-width: 767px) {
  .lecture-container .details .preview-text {
    font-size: 14px;
    width: 40%;
  }
}

.lecture-container .details .preview-text .svg {
  margin-right: 23px;
}

@media only screen and (max-width: 767px) {
  .lecture-container .details .preview-text .svg {
    margin-right: 15px;
  }
}

.lecture-container .details .content-summary {
  font-size: 18px;
}

@media only screen and (max-width: 767px) {
  .lecture-container .details .content-summary {
    font-size: 14px;
  }
}

/* Bootstrap */
.accordion > .card:not(:first-of-type):not(:last-of-type) {
  border-radius: 20px;
}

.accordion > .card:first-of-type {
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.accordion > .card:last-of-type {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

/* Book Chaptar */
.chaptar .chaptar-box {
  padding: 40px 30px;
  background: #F8FBFF;
}

.chaptar .chaptar-box:not(:last-child) {
  margin-bottom: 20px;
}

@media only screen and (max-width: 991px) {
  .chaptar .chaptar-box {
    margin-bottom: 20px;
  }
}

.chaptar .chaptar-box h3 {
  color: #292929;
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 10px;
  cursor: pointer;
}

.chaptar .chaptar-box h3 span {
  margin-right: 5px;
}

@media only screen and (max-width: 575px) {
  .chaptar .chaptar-box {
    padding: 30px 20px;
  }
  .chaptar .chaptar-box h3 {
    font-size: 20px;
  }
  .chaptar .chaptar-box p {
    font-size: 16px;
  }
}

.chaptar .chaptar-box:hover h3, .chaptar .chaptar-box:hover p, .chaptar .chaptar-box.active h3, .chaptar .chaptar-box.active p {
  color: #ffffff;
}

@media only screen and (max-width: 991px) {
  .chaptar-img {
    margin-bottom: 40px;
  }
}

/* ************************
   04.10: Price
   ********************* */
.price-box {
  padding: 50px 60px 60px;
  background: #ffffff;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1199px) {
  .price-box {
    padding: 30px 30px 40px;
  }
}

.price-box .price-head {
  margin-bottom: 12px;
}

.price-box .price-head span {
  font-size: 18px;
}

.price-box .price-head span strong {
  font-size: 60px;
  font-weight: 600;
  line-height: 1.48;
  padding-right: 10px;
}

.price-box .price-head span .d-price {
  position: relative;
  bottom: -7px;
}

.price-box .price-head span del {
  font-size: 24px;
  font-weight: 600;
  line-height: 1;
  color: #d5d5d5;
}

.price-box .price-head p {
  font-weight: 300;
  max-width: 320px;
}

.price-box.two .price-head span strong {
  color: #ffffff;
}

.price-box.two .price-head span .d-price del {
  color: #cbfff5;
}

.price-box.two ul.list-check li {
  color: #ffffff;
}

.price-box.two ul.list-check li:after {
  color: #ffffff;
}

.price-box .btn-crs.white, .price-box .theme-input-group.style--four button.btn-crs, .theme-input-group.style--four .price-box button.btn-crs {
  background: #ffffff;
}

.price-box .btn-crs.white:hover, .price-box .theme-input-group.style--four button.btn-crs:hover, .theme-input-group.style--four .price-box button.btn-crs:hover {
  color: #ffffff;
}

/* Analytic Price toggle button */
.pricing-toogle {
  line-height: 1;
}

@media only screen and (max-width: 991px) {
  .pricing-toogle.host {
    margin-top: 30px;
  }
}

.pricing-toogle.host .toggler--is-active {
  color: #180D5B;
}

.toggle, .toggler {
  display: inline-block;
  vertical-align: middle;
}

.toggler {
  color: #292929;
  transition: .2s;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  line-height: 1;
}

.toggler--is-active {
  color: #FF5B83;
}

.b {
  display: block;
}

.toggle {
  position: relative;
  width: 60px;
  height: 26px;
  border-radius: 100px;
  overflow: hidden;
  margin: 0 16px;
}

.check {
  position: absolute;
  display: block;
  cursor: pointer;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 6;
}

.check:checked ~ .switch {
  right: 2px;
  left: 57.5%;
  transition: 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-property: left, right;
  transition-delay: .08s, 0s;
}

.switch {
  position: absolute;
  left: 2px;
  top: 2px;
  bottom: 2px;
  right: 57.5%;
  background-color: #ffffff;
  border-radius: 26px;
  z-index: 1;
  transition-property: left, right;
  transition-delay: 0s, .08s;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.hide {
  display: none;
}

/* Analytic Single Price */
.single-price {
  padding: 60px 100px;
  border: 1px solid #00418E;
  text-align: center;
  position: relative;
  z-index: 1;
}

.single-price .price-head {
  padding-bottom: 35px;
  border-bottom: 1px solid #BCBCBC;
}

.single-price .price-head h3 {
  font-size: 30px;
  font-weight: 600;
  color: #292929;
  margin-bottom: 8px;
}

.single-price .price-head p {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 30px;
}

.single-price .price-head .price strong {
  font-size: 46px;
  line-height: 1.2;
}

.single-price .price-head > span {
  font-size: 16px;
  font-weight: 400;
}

.single-price .price-body .price-list {
  padding: 42px 0;
}

.single-price .price-body .price-list li {
  font-size: 22px;
  font-weight: 300;
}

.single-price .price-body .price-list li:not(:last-child) {
  margin-bottom: 5px;
}

.single-price.active:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
  transform: scale(1.15, 1.06);
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .single-price.active:after {
    transform: scale(1.18, 1.04);
  }
}

@media only screen and (max-width: 1199px) {
  .single-price.active:after {
    transform: scale(1.21, 1.03);
  }
}

@media only screen and (max-width: 991px) {
  .single-price.active:after {
    transform: scale(1, 1);
  }
}

.single-price.active .price-head h3 {
  color: #ffffff;
}

@media only screen and (min-width: 1200px) and (max-width: 1880px) {
  .single-price {
    padding: 60px 50px;
  }
}

@media only screen and (max-width: 1199px) {
  .single-price {
    padding: 60px 50px;
  }
}

@media only screen and (max-width: 991px) {
  .single-price {
    margin-bottom: 30px;
  }
}

.single-price.app {
  background-color: #A7D3F6;
  border: none;
  background-position: center bottom;
  padding-bottom: 18px;
}

@media only screen and (max-width: 1199px) {
  .single-price.app {
    padding: 60px 30px 20px;
  }
}

.single-price.app .price-head {
  padding-bottom: 60px;
  border-bottom: 1px solid #EFEFEF;
}

.single-price.app .price-head h3 {
  margin-bottom: 3px;
}

.single-price.app .price-head p {
  font-size: 16px;
  margin-bottom: 25px;
  color: #292929;
}

.single-price.app .price-body .price-list {
  padding-top: 50px;
  padding-bottom: 80px;
}

.single-price.app .price-body .price-list li {
  font-size: 20px;
  font-weight: 400;
  color: #292929;
}

.single-price.app .price-body .price-list li:not(:last-child) {
  margin-bottom: 12px;
}

.single-price.app .price-footer span {
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
}

.single-price.app .price-footer span strong {
  font-size: 40px;
  font-weight: 700;
  line-height: 1;
}

.single-price.app.two {
  background-color: #FFB99F;
}

.single-price.app.three {
  background-color: #86E9D4;
  margin-bottom: 0;
}

.single-price.app .price-footer .white-btn {
  color: #ffffff;
  text-transform: capitalize;
}

.single-price.app .price-footer .white-btn:hover {
  letter-spacing: 1px;
}

.single-price.app2 .price-head {
  padding-bottom: 30px;
}

.single-price.app2 .price-head h3 {
  margin-bottom: 3px;
}

.single-price.app2 .price-head span {
  color: #292929;
  font-size: 18px;
  font-weight: 500;
  margin-top: 12px;
}

.single-price.app2 .price-head span .link-btn {
  color: #5CB6FE;
}

.single-price.app2 .price-head span .link-btn:hover {
  color: #FF6773;
}

.single-price.app2 .price-body .price-list li {
  color: #707070;
}

/* Hosting Pricing */
.pkg .pkg-list {
  background-color: #ffffff;
  cursor: pointer;
  padding: 60px;
  align-items: center;
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;
  z-index: 2;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .pkg .pkg-list {
    padding: 60px 40px;
  }
}

@media only screen and (max-width: 575px) {
  .pkg .pkg-list {
    flex-direction: column;
    padding: 40px 20px;
  }
}

.pkg .pkg-list > span {
  position: absolute;
  width: 0;
  height: 0;
  display: block;
  z-index: -1;
  transform: translate(-50%, -50%);
  transition: .7s;
}

.pkg .pkg-list:hover > span {
  width: 300%;
  height: 2380px;
}

.pkg .pkg-list .left {
  margin-right: 20px;
}

@media only screen and (max-width: 575px) {
  .pkg .pkg-list .left {
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.pkg .pkg-list .left h3 {
  font-size: 30px;
  color: #292929;
  margin-bottom: 8px;
  font-weight: 600;
}

.pkg .pkg-list .left p {
  font-size: 18px;
  max-width: 320px;
}

@media only screen and (max-width: 575px) {
  .pkg .pkg-list .right {
    margin-right: auto;
  }
}

.pkg .pkg-list .right div {
  font-weight: 300;
  margin-bottom: 14px;
}

.pkg .pkg-list .right div strong {
  font-size: 46px;
  font-weight: 600;
  line-height: 1;
}

.pkg.active .pkg-list .left h3, .pkg.active .pkg-list .left p, .pkg:hover .pkg-list .left h3, .pkg:hover .pkg-list .left p {
  color: #ffffff;
}

.pkg.active .pkg-list .right div, .pkg:hover .pkg-list .right div {
  color: #ffffff;
}

.pkg-info {
  padding: 50px 60px 57px;
  background-color: #ffffff;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .pkg-info {
    padding: 50px 33px 57px;
  }
}

@media only screen and (max-width: 1199px) {
  .pkg-info {
    padding: 40px 30px;
  }
}

@media only screen and (max-width: 991px) {
  .pkg-info {
    padding: 50px 60px;
  }
}

@media only screen and (max-width: 575px) {
  .pkg-info {
    padding: 40px 20px;
  }
}

.pkg-info h3.pkg-name {
  display: inline-block;
  background-color: #FFF4DE;
  color: #F7A400;
  margin-bottom: 40px;
  font-size: 30px;
  font-weight: 600;
  border-radius: 7px;
  padding: 15px 20px;
}

.pkg-info .price-feature li:not(:last-child) {
  margin-bottom: 36px;
}

.pkg-info .price-feature h3 {
  color: #292929;
  font-weight: 500;
  margin-bottom: 6px;
}

.pkg-info .price-feature p {
  font-size: 18px;
  line-height: 1.5;
}

/* ************************
   04.11: Company Logo
   ********************* */
.owl-carousel.logo-carousel .single-slide {
  text-align: center;
}

.owl-carousel.logo-carousel .owl-item img {
  display: inline-block;
  width: auto;
  opacity: .4;
}

.owl-carousel.logo-carousel .owl-item img:hover {
  opacity: 1;
  transform: scale(0.9);
}

/* ************************
   04.12: Author
   ********************* */
.author-content {
  padding-left: 50px;
}

@media only screen and (max-width: 767px) {
  .author-content {
    padding-left: 0px;
  }
}

.author-content .author-badge {
  font-size: 16px;
  padding-left: 35px;
  margin-bottom: 12px;
}

.author-content .author-badge:before {
  position: absolute;
  content: url("../img/icons/author-badge.png");
  left: 0;
  top: 5px;
}

.author-content h2 {
  font-weight: 600;
}

.author-content span {
  font-size: 22px;
  margin-bottom: 10px;
  display: block;
}

.author-img {
  padding-left: 15px;
}

@media only screen and (max-width: 991px) {
  .author-img {
    padding-left: 50px;
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .author-img {
    padding-left: 0px;
  }
}

/* Author Newsletter */
.author-newsletter h3 {
  font-weight: 600;
}

/* ************************
   04.13: FAQ
   ********************* */
.accordion.management .accordion-card {
  border-bottom: 1px solid #EEEEEE;
  padding: 38px 0 34px 40px;
}

.accordion.management .accordion-card:first-child {
  border-top: 1px solid #EEEEEE;
}

.accordion.management .accordion-card.active .accordion-title h3:after {
  content: "\f106";
}

.accordion.management .accordion-title {
  position: relative;
}

.accordion.management .accordion-title h3 {
  color: #292929;
  cursor: pointer;
  font-weight: 500;
  font-size: 22px;
  position: relative;
  line-height: 1.2;
}

.accordion.management .accordion-title h3:after {
  content: "\f107";
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: #292929;
  font-family: fontAwesome;
}

.accordion.management .accordion-title .accordion-whatsign {
  position: absolute;
  left: -40px;
  top: -1px;
}

.accordion.management .accordion-content {
  margin-top: 15px;
}

.quick-links {
  margin-bottom: 40px;
}

.quick-links h3 {
  font-size: 30px;
  font-weight: 500;
  color: #292929;
  margin-bottom: 20px;
}

.quick-links ul li {
  margin-bottom: 10px;
}

.quick-links ul li a {
  color: #292929;
  font-size: 18px;
}

.quick-links ul li a i {
  margin-right: 5px;
}

.quick-links ul li a:hover {
  margin-left: 5px;
}

/* ************************
   04.14: JOB
   ********************* */
@media only screen and (max-width: 991px) {
  .filter-control {
    flex-direction: column;
  }
  .filter-control .filter-title {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 479px) {
  .filter {
    flex-direction: column;
  }
}

.filter-title h3 {
  font-weight: 600;
  font-size: 30px;
  color: #292929;
}

/* Custom Select Dropdown */
.custom-select {
  position: relative;
  background: #ffffff;
  height: 44px;
  padding: 0;
  background: #fff;
  border: none;
  width: auto;
}

.custom-select:not(:last-child) {
  margin-right: 40px;
}

@media only screen and (max-width: 479px) {
  .custom-select:not(:last-child) {
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.custom-select select {
  display: none;
}

.custom-select .select-items div, .custom-select .select-selected {
  padding: 8px  20px;
  cursor: pointer;
  color: #707070;
  user-select: none;
  text-transform: capitalize;
}

.custom-select .select-selected {
  padding: 8px 70px 8px 20px;
  color: #292929;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  font-size: 18px;
}

.custom-select .select-selected:after {
  position: absolute;
  content: "\f107";
  top: 8px;
  right: 30px;
  width: 0;
  height: 0;
  color: #292929;
  font-family: fontAwesome;
}

.custom-select .select-selected.select-arrow-active:after {
  content: "\f106";
}

.custom-select .select-items {
  position: absolute;
  background-color: #ffffff;
  top: calc(100% + 1px);
  left: 0;
  right: 0;
  z-index: 99;
  font-size: 16px;
  padding-top: 20px;
  padding-bottom: 15px;
}

.custom-select .select-hide {
  display: none;
}

/* Single Job Box */
.single-job {
  border: 1px solid #eeeeee;
  padding: 40px 40px 60px;
  margin-bottom: 60px;
}

.single-job.style--two {
  padding: 70px 60px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 991px) {
  .single-job.style--two {
    flex-direction: column;
  }
}

@media only screen and (max-width: 575px) {
  .single-job.style--two {
    padding: 50px 40px;
  }
}

@media only screen and (max-width: 575px) {
  .single-job.style--two {
    padding: 40px 20px;
  }
}

.single-job.style--two .job-logo {
  margin-bottom: 0px;
  flex: 0 0 29%;
}

@media only screen and (max-width: 1199px) {
  .single-job.style--two .job-logo {
    flex: 0 0 35%;
  }
}

@media only screen and (max-width: 991px) {
  .single-job.style--two .job-logo {
    flex: 0 0 100%;
    margin-bottom: 20px;
  }
}

.single-job.style--two .job-content .meta-box {
  margin-bottom: 0px;
}

@media only screen and (max-width: 991px) {
  .single-job.style--two .job-content {
    margin-bottom: 20px;
  }
}

.single-job.style--two .job-button {
  margin-left: auto;
}

@media only screen and (max-width: 991px) {
  .single-job.style--two .job-button {
    margin-left: 0;
  }
}

.single-job:hover {
  border-color: transparent;
}

.single-job .job-logo {
  margin-bottom: 30px;
}

.single-job .job-content .job-category {
  display: block;
  font-size: 16px;
  margin-bottom: 10px;
}

.single-job .job-content h3 {
  font-size: 26px;
  font-weight: 500;
  color: #292929;
  margin-bottom: 15px;
  line-height: 1.1;
}

.single-job .job-content h3 a {
  color: #292929;
}

.single-job .job-content .meta-box {
  margin-bottom: 20px;
}

.single-job .job-content .meta-box p {
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
}

.single-job .job-content .meta-box p:not(:last-child) {
  margin-right: 40px;
}

.single-job .job-content .meta-box p img {
  margin-right: 10px;
}

/* Job Details */
@media only screen and (max-width: 991px) {
  .job-details {
    margin-bottom: 70px;
  }
}

.job-details-content h3 {
  font-size: 30px;
  font-weight: 600;
  color: #292929;
  margin-bottom: 22px;
}

.job-details-content p {
  margin-bottom: 15px;
}

.job-details-img {
  margin-bottom: 70px;
}

.post-meta {
  margin-bottom: 45px;
}

.post-meta li {
  display: inline-block;
  font-weight: 300;
}

.post-meta li img, .post-meta li i {
  margin-right: 10px;
}

.post-meta li:not(:last-child) {
  margin-right: 30px;
  margin-bottom: 5px;
}

/* Modal */
.form-group, .widget {
  margin-bottom: 0;
}

.form-group .custom-select, .widget .custom-select {
  margin-bottom: 20px;
}

.form-group .col-form-label, .widget .col-form-label {
  padding: 0;
  margin-bottom: 15px;
  line-height: 1;
  text-transform: capitalize;
}

.form-group .theme-input-style, .form-group .entry-content select, .entry-content .form-group select,
.form-group .comment-content select, .comment-content .form-group select,
.form-group .page--content select, .page--content .form-group select, .widget .theme-input-style, .widget .entry-content select, .entry-content .widget select,
.widget .comment-content select, .comment-content .widget select,
.widget .page--content select, .page--content .widget select {
  font-size: 18px;
  font-weight: 300;
}

.form-group .custom-select .select-selected, .widget .custom-select .select-selected {
  font-size: 18px;
  font-weight: 300;
}

.form-group .custom-select .select-selected.select-arrow-active, .widget .custom-select .select-selected.select-arrow-active {
  border-color: #eeeeee !important;
}

.form-group textarea.theme-input-style, .widget textarea.theme-input-style {
  height: 340px;
}

@media only screen and (max-width: 575px) {
  .form-group textarea.theme-input-style, .widget textarea.theme-input-style {
    height: 200px;
  }
}

@media only screen and (max-width: 479px) {
  .form-group textarea.theme-input-style, .widget textarea.theme-input-style {
    height: 150px;
  }
}

.modal-dialog {
  max-width: 930px;
  margin: 50px auto;
}

@media only screen and (max-width: 991px) {
  .modal-dialog {
    max-width: 700px;
  }
}

@media only screen and (max-width: 767px) {
  .modal-dialog {
    max-width: 520px;
  }
}

@media only screen and (max-width: 575px) {
  .modal-dialog {
    max-width: 100%;
    margin: 50px 15px;
  }
}

.modal-content {
  border: none;
  padding: 64px 104px;
  border-radius: 0;
}

@media only screen and (max-width: 991px) {
  .modal-content {
    padding: 50px 60px;
  }
}

@media only screen and (max-width: 575px) {
  .modal-content {
    padding: 40px;
  }
}

@media only screen and (max-width: 479px) {
  .modal-content {
    padding: 24px 0px;
  }
}

.modal-header {
  border: none;
  margin-bottom: 50px;
}

.modal-header button.close {
  position: absolute;
  top: -25px;
  left: auto;
  right: -25px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  opacity: 1;
}

@media only screen and (max-width: 575px) {
  .modal-header button.close {
    width: 30px;
    height: 30px;
    right: -10px;
    top: -10px;
  }
  .modal-header button.close .svg {
    width: 10px;
    height: 10px;
  }
}

.modal-header .modal-title {
  margin: 0 auto;
}

.modal-header .modal-title .modal-title-icon {
  width: 72px;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 25px;
}

.modal-header .modal-title h3 {
  font-size: 26px;
  font-weight: 600;
  color: #292929;
}

.file-drop-area {
  background-color: #FBFBFB;
  color: #D7D7EF;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  padding: 40px 20px 35px;
  border: 1px dashed #707070;
  border-radius: 3px;
  margin-bottom: 40px;
}

.file-drop-area img, .file-drop-area .svg {
  margin-bottom: 15px;
}

.fake-btn {
  flex-shrink: 0;
  font-size: 12px;
  text-transform: capitalize;
  font-size: 22px;
  font-weight: 600;
  line-height: 1;
}

.file-msg {
  font-size: small;
  font-weight: 300;
  line-height: 1.4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-input {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  opacity: 0;
}

.file-input:focus {
  outline: none;
}

/* ************************
04.15: Sign Up
********************* */
.signup-form-left {
  flex: 0 0 40%;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .signup-form-left {
    flex: 0 0 38%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .signup-form-left {
    flex: 0 0 52%;
  }
}

@media only screen and (max-width: 1199px) {
  .signup-form-left {
    display: none;
  }
}

.signup-form-right {
  padding: 60px;
  flex: 0 0 60%;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .signup-form-right {
    flex: 0 0 53%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .signup-form-right {
    flex: 0 0 48%;
  }
}

@media only screen and (max-width: 1199px) {
  .signup-form-right {
    flex: 0 0 100%;
  }
}

@media only screen and (max-width: 575px) {
  .signup-form-right {
    padding: 30px 15px;
  }
}

.signup-form-title, .signin-form-title {
  margin-bottom: 60px;
}

.signup-form-title h2, .signin-form-title h2 {
  font-size: 36px;
  color: #292929;
  font-weight: 600;
  margin-bottom: 15px;
}

@media only screen and (max-width: 575px) {
  .signup-form-title h2, .signin-form-title h2 {
    font-size: 30px;
  }
}

.signup-form-title p, .signin-form-title p {
  max-width: 540px;
  margin: 0 auto;
}

.signup-form label {
  margin-bottom: 15px;
  padding: 0;
}

.signup-form .login-text {
  font-weight: 400;
  color: #292929;
}

/* Sign In */
.signin-form-left {
  flex: 0 0 50%;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .signin-form-left {
    flex: 0 0 46%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .signin-form-left {
    display: none;
  }
}

@media only screen and (max-width: 1199px) {
  .signin-form-left {
    display: none;
  }
}

.signin-form-right {
  padding: 60px;
  flex: 0 0 50%;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .signin-form-right {
    flex: 0 0 45%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .signin-form-right {
    flex: 0 0 80%;
  }
}

@media only screen and (max-width: 1199px) {
  .signin-form-right {
    flex: 0 0 100%;
  }
}

@media only screen and (max-width: 575px) {
  .signin-form-right {
    padding: 30px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .signin-help {
    flex-direction: column;
  }
}

@media only screen and (max-width: 767px) {
  .signin-help .forgot-password {
    margin-bottom: 6px;
  }
}

/* ************************
04.16: Contact
********************* */
@media only screen and (max-width: 991px) {
  .contact-form-left {
    margin-bottom: 80px;
  }
}

.contact-form-left .content {
  margin-bottom: 70px;
}

.contact-form-left h2 {
  color: #292929;
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 20px;
}

.contact-form-left p {
  max-width: 540px;
}

.contact-form {
  padding: 80px 60px;
  background-color: #ffffff;
}

@media only screen and (max-width: 575px) {
  .contact-form {
    padding: 50px 15px;
  }
}

.contact-form.style--two {
  margin-top: -200px;
}

@media only screen and (max-width: 991px) {
  .contact-form.style--two {
    margin-top: -150px;
  }
}

@media only screen and (max-width: 575px) {
  .contact-form.style--two {
    margin-top: -100px;
  }
}

.contact-form .contact-form-top {
  margin-bottom: 65px;
}

.contact-form .contact-form-top h2 {
  margin-bottom: 15px;
  font-size: 36px;
  color: #292929;
  font-weight: 600;
}

.contact-form .contact-form-top p {
  max-width: 540px;
  margin: 0 auto;
}

/* Map */
#map {
  width: 100%;
  height: 700px;
}

@media only screen and (max-width: 991px) {
  #map {
    height: 550px;
  }
}

@media only screen and (max-width: 575px) {
  #map {
    height: 400px;
  }
}

#map.map2 {
  height: 800px;
}

@media only screen and (max-width: 991px) {
  #map.map2 {
    height: 600px;
  }
}

@media only screen and (max-width: 575px) {
  #map.map2 {
    height: 400px;
  }
}

/* Marker Popup */
.gm-style-iw {
  border-radius: 0px !important;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.06) !important;
  padding: 17px 5px 5px 17px !important;
  text-align: center;
  left: 100px !important;
  width: 210px !important;
}

.gm-style-iw button {
  display: none !important;
}

.gm-style .gm-style-iw-t::after {
  display: none !important;
}

#marker-info {
  line-height: 1.5;
}

/* ************************
04.17: Team
********************* */
.member {
  text-align: center;
  background-color: #ffffff;
  padding: 40px 20px;
  margin-bottom: 40px;
}

.member .member-img {
  margin-bottom: 28px;
}

.member .member-content h3 {
  font-weight: 500;
}

.member .member-content p {
  font-size: 18px;
  margin: 0;
}

.social-links a {
  display: inline-block;
  padding: 5px;
  font-size: 14px;
}
