/* ************************
   01.2: Padding/Margin
   ********************* */
// Paddings
$p-list: 160, 140, 130, 120, 110, 100, 90, 80, 70, 60, 50, 40, 30, 20, 10;
$tab: 60;

// Padding Top
@each $padding in $p-list {
    .pt-#{$padding} {
        padding-top: $padding + px;
        @include tab{
            @if $padding > $tab {
                padding-top: ($padding - $tab) + px;
            } @else {
                padding-top: 0;
            }
        }
    }
}
// Padding Bottom
@each $padding in $p-list {
    .pb-#{$padding} {
        padding-bottom: $padding + px;
        @include tab{
            @if $padding > $tab {
                padding-bottom: ($padding - $tab) + px;
            } @else {
                padding-bottom: 0;
            }
        }
    }
}


$m-list: 140, 100, 90, 80, 70, 60, 50, 44, 40, 30, 20, 10;

// Margin Top
@each $margin in $m-list {
    .mt-#{$margin} {
        margin-top: $margin + px;
    }
}
// Margin Bottom
@each $margin in $m-list {
    .mb-#{$margin} {
        margin-bottom: $margin + px;
    }
}



/* Margin Static */
@include tab {
    .mb-md-60 {
        margin-bottom: 60px;
    }
    .mb-md-80 {
        margin-bottom: 80px;
    }
    .pb-md-80 {
        padding-bottom: 80px;
    }
}
.ml-10 {
    margin-left: 10px;
}
.mb-44 {
    margin-bottom: 44px !important;
}

