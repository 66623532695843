/* ************************
   02.7: Widget
   ********************* */
.widget {
    font-size: 16px;
    &:not(:last-child) {
        margin-bottom: 50px;
    }
    ul {
        @extend .list-unstyled;
        ul {
            padding-left: 10px;
            margin-top: 20px;
            @include medium {
                padding-left: 0;
            }
        }
    }
    .widget-title {
        margin-bottom: 30px;
        h4 {
            font-weight: $medium;
        }
    }
    .widget_title {
        font-size: 20px;
        color: $black;
        margin-bottom: 20px;
    }
    .custom-select {
        margin-bottom: 0 !important;
        .select-selected {
            background-color: #FFF6F1;
            border: 1px solid transparent;
            font-weight: $regular !important;
            &.select-arrow-active {
                border-color: transparent !important;
            }
        }
        .select-items {
            background-color: #FFFBF9;
        }
    }
    &.widget_nav_menu, &.widget_categories, &.widget_meta {
        ul {
            li {
                position: relative;
                padding-left: 15px;
                a {
                    display: inline-block;
                    margin-right: 10px;
                    @extend .text-color;
                    &:hover {
                        @extend .email_c2;
                    }
                }
                &:after {
                    position: absolute;
                    left: 0;
                    top: 12px;
                    content: '';
                    height: 1px;
                    width: 5px;
                    background-color: $black;
                }
                &:not(:last-child) {
                    margin-bottom: 18px;
                }
            }
        }
    }
    &.widget_contact {
        .contact-content {
            p {
                margin-bottom: 20px;
            }
            ul {
                li {
                    padding-left: 30px;
                    position: relative;
                    font-size: 14px;
                    line-height: 2;
                    &:not(:last-child) {
                        margin-bottom: 5px;
                    }
                    i {
                        position: absolute;
                        left: 0;
                        line-height: inherit;
                    }
                }
            }
        }
    }
    &.widget_service {
        .service-list {
            @extend .hind;
            li {
                line-height: 2;
                display: flex;
                align-items: center;
                a {
                    @extend .text-color;
                    &:hover {
                        @extend .c2;
                        i,
                        svg * {
                            @extend .c2;
                            fill: $c2;
                        }
                    }
                }
                i {
                    font-size: 18px;
                }
                img,
                svg,
                i {
                    margin-right: 10px;
                    @extend .trans3;
                }
                &:not(:last-child) {
                    margin-bottom: 24px;
                }
            }
        }
    }
    &.widget_recent_entries {
        ul {
            li {
                padding-bottom: 15px;
                border-bottom: 1px solid #EEEEEE;
                &:not(:last-child) {
                    margin-bottom: 10px;
                }
            }
        }
        .post-summary {
            .post-title a {
                color: $black;
                font-size: 16px;
                line-height: 1.2;
                &:hover {
                    @extend .email_c2;
                }
            }
            .posted-on a {
                font-size: 12px;
                @extend .text-color;
            }
            .post-excerpt {
                margin-top: 7px;
                font-size: 14px;
            }
        }
        &.has_thumb {
            li {
                .post_thumb {
                    min-width: 80px;
                    margin-right: 20px;
                }
            }
        }
    }
    &.widget_recent_comments {
        li {
            &:not(:last-child) {
                margin-bottom: 20px;
            }
            a {
                font-weight: $bold;
                color: $black;
                background-image: linear-gradient(transparent 96%, currentColor 1px);
                background-repeat: no-repeat;
                background-size: 0 100%;
                &:hover {
                    background-size: 100% 100%;
                }
            }
        }
    }
    &.widget_newsletter {
        > p {
            margin-bottom: 20px;
            font-weight: $regular;
        }
        .newsletter-content {
            p {
                margin-bottom: 25px;
            }
        }
        &.style--two {
            .newsletter-content {
                p {
                    font-size: 14px;
                }
                .theme-input-group button {
                    position: static;
                    width: 100%;
                    margin-top: 16px;
                    height: 52px;
                }
                .theme-input-group input {
                    background-color: $white;
                }
            }
        }
    }
    &.widget_search,
    &.widget_newsletter.style--two {
        padding: 0;
        background-color: transparent;
        @extend .search-form;
    }
    &.widget_tag_cloud {
        .tagcloud {
            margin: 0 -6px -6px 0;
            a {
                display: inline-block;
                padding: 5px 7px;
                background-color: $white;
                margin: 0 6px 6px 0;
                @extend .text-color;
                &:hover {
                    @extend .c2;
                }
            }
        }
    }
    &.widget_footer_menu {
        .footer_menu {
            ul {
                @extend .list-unstyled;
            }
            li {
                display: block;
                &:after {
                    display: none;
                }

                a {
                    color: $white;
                    font-weight: $light;
                    text-transform: capitalize;
                    padding-bottom: 10px;
                    display: inline-block;
                    position: relative;
                    &:hover {
                        @extend .c1;
                        padding-left: 5px;
                    }
                }
            }
            &.affiliate {
                li {
                    a {
                        color: $text-color;
                        &:hover {
                            @extend .c2;
                        }
                    }
                }
            }
            &.analytic {
                li {
                    a {
                        color: $text-color;
                        &:hover {
                            @extend .analytic_c2;
                        }
                    }
                }
            }
            &.crm {
                li {
                    a {
                        color: $text-color;
                        &:hover {
                            @extend .crm_c2;
                        }
                    }
                }
            }
            &.crs {
                li {
                    a {
                        color: $text-color;
                        &:hover {
                            @extend .crs_c1;
                        }
                    }
                }
            }
            &.pay {
                li {
                    a {
                        color: $white;
                        &:hover {
                            @extend .pay_c2;
                        }
                    }
                }
            }
            &.plug {
                li {
                    a {
                        color: $white;
                        &:hover {
                            @extend .plug_c2;
                        }
                    }
                }
            }
            &.analytic {
                li {
                    a {
                        font-weight: $regular;
                        &:hover {
                            @extend .analytic_c2;
                        }
                    }
                }
            }
            &.host {
                li {
                    a {
                        &:hover {
                            @extend .host_c2;
                        }
                    }
                }
            }
        }
    }
    &.job-info {
        border: 1px solid #eeeeee;
        background-color: $white;
        padding: 60px;
        @include medium {
            padding: 60px 40px;
        }
        @include mobileSm {
            padding: 40px 30px;
        }
        ul {
            @extend .list-unstyled;
        }
        li {
            &:not(:last-child) {
                margin-bottom: 30px;
            }
            h3 {
                color: $black;
                font-size: 22px;
                font-weight: $medium;
                margin-bottom: 5px;
            }
            p {
                font-size: 20px;
            }
        }
    }
    &.share {
        border: 1px solid #eeeeee;
        background-color: $white;
        padding: 24px;
        .social-icon {
            span {
                @extend .hind;
                font-size: 26px;
                font-weight: $medium;
                color: $black;
                margin-right: 10px;
                display: inline-block;
                @include mobileSm {
                    font-size: 22px;
                }
            }
            a {
                color: $text-color;
                margin-right: 0;
                background-color: $white;
                &:hover {
                    @extend .email_c2-bg;
                    color: $white;
                }
            }
        }
    }
    &.widget_calendar {
        table {
            width: 100%;
            background-color: #FFFBF9;
            caption {
                padding: 5px 10px;
                caption-side: top;
                background-color: #FFFBF9;
                font-weight: $bold;
                color: $black;
                @extend .hind;
            }
            th {
                background-color: #FFF6F1;
                @extend .hind;
                border: none;
            }

            td {
                background-color: $white;
                border: 1px solid #FFFBF9;
            }
            
            th,td {
                text-align: center;
                padding: 5px 0;
            }
        }
    }
}